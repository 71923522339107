import { createActions, createReducer } from "reduxsauce";
import { setObject } from "./../../utils/Object";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addNewRoute: ["id"],
  addRouteEvent: ["id", "position"],
  addRoutePosition: ["id", "position"],
  addRoutePositionTransmissionQueue: ["id", "position"],
  addRoutePositionTransmissionOffQueue: ["id", "position"],
  removeRouteEvent: ["id", "position"],
  removeRoutePosition: ["id", "position"],
  resetRoutePositionTransmissionOffQueue: ["id"],
  resetRoute: ["id"],
  setRouteAction: ["id", "routeAction"],
  setRouteInterval: ["id", "interval"],
  setRouteParams: ["id", "param", "value"],
  setRoutePolyline: ["id", "param", "value"],
  updateRouteEvent: ["id", "position"],
  updateRoutePosition: ["id", "position"],
  updateRoutePositionTransmissionQueue: ["id", "position"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  routes: []
};

const format = (position) => {
  return position;
};

const getRoute = (state = INITIAL_STATE, action) => {
  const { id } = action;
  const routes = state.routes;
  const filterIndex = routes.findIndex(x => x.id === id);
  if(filterIndex !== -1) {
    return routes[filterIndex];
  }
  return false;
};

const setRoute = (state = INITIAL_STATE, action) => {
  const { id, param, value } = action;
  const routes = state.routes;
  const filterIndex = routes.findIndex(x => x.id === id);
  if(filterIndex !== -1) {
    routes[filterIndex][param] = value;
    return {
      ...state,
      routes
    };
  }
  return false;
};

const addNewRoute = (state = INITIAL_STATE, action) => {
  const { id } = action;
  const routes = state.routes;
  const filterIndex = routes.findIndex(x => x.id === id);
  if(filterIndex === -1) {
    routes.push({
      id,
      routeAction: false,
      routeActions: [],
      routeEvents: [],
      routeInterval: false,
      routeParams: {
        apnConnect: "CON",
        course: 45,
        distance: 0,
        duration: 0,
        ignition: false,
        renderCount: 1,
        renderTime: 0.2,
        scale: 1,
        speed: 100,
        status: -1,
        timeIntervalOff: 3600,
        timeIntervalOn: 2
      },
      routePolyline: {
        routeBackground: false,
        routeBackgroundBorder: false,
        routeBackgroundComplete: false,
        routeBackgroundDisconnected: [],
        routeBackgroundEvent: [],
        routePathEncoded: "",
        routePath: []
      },
      routePositions: [],
      routePositionsTransmissionQueue: [],
      routePositionsTransmissionOffQueue: []
    });
  }
  else {
    return resetRoute(state, action);
  }
  return {
    ...state,
    routes
  };
};

const resetRoute = (state = INITIAL_STATE, action) => {
  const { id } = action;
  const routes = state.routes;
  const filterIndex = routes.findIndex(x => x.id === id);
  if(filterIndex !== -1) {
    routes[filterIndex] = {
      id,
      routeAction: false,
      routeActions: [],
      routeEvents: [],
      routeInterval: false,
      routeParams: {
        apnConnect: "CON",
        course: 45,
        distance: 0,
        duration: 0,
        ignition: false,
        renderCount: 1,
        renderTime: 0.2,
        scale: 1,
        speed: 100,
        status: -1,
        timeIntervalOff: 3600,
        timeIntervalOn: 2
      },
      routePolyline: {
        routeBackground: false,
        routeBackgroundBorder: false,
        routeBackgroundComplete: false,
        routeBackgroundDisconnected: [],
        routeBackgroundEvent: [],
        routePathEncoded: "",
        routePath: []
      },
      routePositions: [],
      routePositionsTransmissionQueue: [],
      routePositionsTransmissionOffQueue: []
    };
  }
  return {
    ...state,
    routes
  };
};

const addRouteEvent = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routeEvents = route.routeEvents;
    routeEvents.push({ id: position.id, position, type: position.type });
    const newRoute = setRoute(state, { id, param: "routeEvents", value: routeEvents });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const addRoutePosition = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routePositions = route.routePositions;
    routePositions.push({ id: position.id, position: format(position), type: position.type });
    const newRoute = setRoute(state, { id, param: "routePositions", value: routePositions });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const addRoutePositionTransmissionQueue = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routePositionsTransmissionQueue = route.routePositionsTransmissionQueue;
    routePositionsTransmissionQueue.push({ id: position.id, position: format(position), type: position.type });
    const newRoute = setRoute(state, { id, param: "routePositionsTransmissionQueue", value: routePositionsTransmissionQueue });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const addRoutePositionTransmissionOffQueue = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routePositionsTransmissionOffQueue = route.routePositionsTransmissionOffQueue;
    routePositionsTransmissionOffQueue.push({ id: position.id, position: format(position), type: position.type });
    const newRoute = setRoute(state, { id, param: "routePositionsTransmissionOffQueue", value: routePositionsTransmissionOffQueue });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const setRouteAction = (state = INITIAL_STATE, action) => {
  const { id, routeAction } = action;
  const route = getRoute(state, { id });
  if(route) {
    const position = { id: routeAction.id, position: format(routeAction), type: routeAction.type };
    const newRoute = setRoute(state, { id, param: "routeAction", value: position });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const removeRouteEvent = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routeEvents = route.routeEvents;
    const filterIndex = routeEvents.findIndex(x => x.id === position.id);
    if(filterIndex !== -1) {
      routeEvents.splice(filterIndex, 1);
    }
    const newRoute = setRoute(state, { id, param: "routeEvents", value: routeEvents });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const removeRoutePosition = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routePositions = route.routePositions;
    const filterIndex = routePositions.findIndex(x => x.id === position.id);
    if(filterIndex !== -1) {
      routePositions.splice(filterIndex, 1);
    }
    const newRoute = setRoute(state, { id, param: "routePositions", value: routePositions });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const resetRoutePositionTransmissionOffQueue = (state = INITIAL_STATE, action) => {
  const { id } = action;
  const route = getRoute(state, { id });
  if(route) {
    const newRoute = setRoute(state, { id, param: "routePositionsTransmissionOffQueue", value: [] });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const setRouteInterval = (state = INITIAL_STATE, action) => {
  const { id, interval } = action;
  const route = getRoute(state, { id });
  if(route) {
    const newRoute = setRoute(state, { id, param: "routeInterval", value: interval });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const setRouteParams = (state = INITIAL_STATE, action) => {
  const { id, param, value } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routeParams = route.routeParams;
    setObject(routeParams, param, value);
    const newRoute = setRoute(state, { id, param: "routeParams", value: routeParams });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const setRoutePolyline = (state = INITIAL_STATE, action) => {
  const { id, param, value } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routePolyline = route.routePolyline;
    setObject(routePolyline, param, value);
    const newRoute = setRoute(state, { id, param: "routePolyline", value: routePolyline });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const updateRouteEvent = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routeEvents = route.routeEvents;
    const filterIndex = routeEvents.findIndex(x => x.id === position.id);
    if(filterIndex !== -1) {
      routeEvents[filterIndex].position = position;
    }
    const newRoute = setRoute(state, { id, param: "routeEvents", value: routeEvents });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

const updateRoutePosition = (state = INITIAL_STATE, action) => {
  const { id, position } = action;
  const route = getRoute(state, { id });
  if(route) {
    const routePositions = route.routePositions;
    const filterIndex = routePositions.findIndex(x => x.id === position.id);
    if(filterIndex !== -1) {
      routePositions[filterIndex].position = position;
    }
    const newRoute = setRoute(state, { id, param: "routePositions", value: routePositions });
    if(newRoute) {
      return newRoute;
    }
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_NEW_ROUTE]: addNewRoute,
  [Types.ADD_ROUTE_EVENT]: addRouteEvent,
  [Types.ADD_ROUTE_POSITION]: addRoutePosition,
  [Types.ADD_ROUTE_POSITION_TRANSMISSION_QUEUE]: addRoutePositionTransmissionQueue,
  [Types.ADD_ROUTE_POSITION_TRANSMISSION_OFF_QUEUE]: addRoutePositionTransmissionOffQueue,
  [Types.REMOVE_ROUTE_EVENT]: removeRouteEvent,
  [Types.REMOVE_ROUTE_POSITION]: removeRoutePosition,
  [Types.RESET_ROUTE_POSITION_TRANSMISSION_OFF_QUEUE]: resetRoutePositionTransmissionOffQueue,
  [Types.RESET_ROUTE]: resetRoute,
  [Types.SET_ROUTE_ACTION]: setRouteAction,
  [Types.SET_ROUTE_INTERVAL]: setRouteInterval,
  [Types.SET_ROUTE_PARAMS]: setRouteParams,
  [Types.SET_ROUTE_POLYLINE]: setRoutePolyline,
  [Types.UPDATE_ROUTE_EVENT]: updateRouteEvent,
  [Types.UPDATE_ROUTE_POSITION]: updateRoutePosition
});
