import ClientSchemaReduced from "../Client/SchemaReduced";
import DriverSchemaReduced from "../Driver/SchemaReduced";
import ExpenseTypeSchemaReduced from "../ExpenseType/SchemaReduced";
import PatrimonySchemaReduced from "../Patrimony/SchemaReduced";

const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
const date = new Date(`${todayDate} 00:00:00`);

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  date,
  description: "",
  driver: JSON.parse(JSON.stringify(DriverSchemaReduced)),
  expenseType: JSON.parse(JSON.stringify(ExpenseTypeSchemaReduced)),
  id: "",
  name: "",
  patrimony: JSON.parse(JSON.stringify(PatrimonySchemaReduced)),
  price: 0
};

export default schema;
