import React, { useState, useEffect } from "react";
import {
  Form
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LastExporterErrorActions } from "./../../store/ducks/lastExporterErrors";

const LastExporterErrorSearch = ({
  props: {
    lastPositionsSearchQuery,
    lastPositionSelected
  },
  funcs: {
    searchLastExporterError
  }
}) => {
  const { t } = useTranslation();
  const [exporterErrorsSearch, setExporterErrorsSearch] = useState({
    patrimony: lastPositionSelected ? lastPositionSelected.position.patrimony.id : "",
    niple: lastPositionsSearchQuery.niple,
    word: ""
  });

  useEffect(() => {
    searchLastExporterError(exporterErrorsSearch);
  }, [exporterErrorsSearch, searchLastExporterError]);

  useEffect(() => {
    setExporterErrorsSearch(prevState => ({
      ...prevState,
      client: lastPositionsSearchQuery.client,
      niple: lastPositionsSearchQuery.niple,
      patrimony: lastPositionSelected ? lastPositionSelected.position.patrimony.id : ""
    }));
  }, [lastPositionsSearchQuery, lastPositionSelected]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form.Group controlId="exporter-errors-item-form-search">
        <Form.Control
          type="text"
          placeholder={t("Placeholder.Search")}
          autoComplete={"off"}
          onChange={e => setExporterErrorsSearch(prevState => ({
            ...prevState,
            word: e.target.value
          }))}
          value={exporterErrorsSearch.word}
          disabled={false}
        />
      </Form.Group>
    </form>
  );
};

const mapStateToProps = state => ({
  props: {
    lastPositionsSearchQuery: state.lastPositions.positionsSearchQuery,
    lastPositionSelected: state.lastPositions.positionSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastExporterErrorActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastExporterErrorSearch);
