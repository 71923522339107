import axios from "axios";
import CityService from "./../City";

export default class ZipCode {
  async findZipCode({ zipCode }) {
    const cityService = new CityService();
    const response = await axios.get(`https://viacep.com.br/ws/${zipCode}/json/`).then(response => {
      if(response.data.erro === true) {
        return {
          success: false,
          data: false
        };
      }
      return {
        success: true,
        data: response.data
      };
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      response.data.dataAux = await cityService.findByExternalId({ externalId: response.data.ibge });
      return response.data;
    }
    return false;
  }
}
