import { format, isValid, strip } from "@fnando/cnpj";

export function isCNPJ(cnpj) {
  return isValid(cnpj);
}

export function maskCNPJ(cnpj) {
  return format(cnpj);
}

export function unmaskCNPJ(cnpj) {
  return strip(cnpj);
}
