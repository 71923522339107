import { ApiV2 } from "../Api";

import { getCronName, getCommadName } from "../../utils/Command";

export default class Command {
  async create({ data, token }) {
    const response = await ApiV2.post("/command", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async findAll({ data, token }) {
    const response = await ApiV2.post("/command/find/all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data;
    }
    return {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    };
  }

  async findAllGrid({ data, token }) {
    const response = await ApiV2.post("/command/find/all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return this.findAllGridTranslate({ data: response.data });
    }
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [
      {
        width: "30%",
        type: "string",
        label: "Title.Client"
      },
      {
        width: "30%",
        type: "string",
        label: "Title.Cron"
      },
      {
        width: "15%",
        type: "string",
        label: "Title.Command"
      },
      {
        width: "15%",
        type: "string",
        label: "Title.Name"
      },
      {
        width: "10%",
        type: "string",
        label: "Title.Active"
      }
    ];
    data.rows = [];
    data.docs.map(item => {
      const cronItem = this.findAllGridTranslateItem({ data: item, item: "cron" }) ? (getCronName(item.cron) ? getCronName(item.cron).label : item.cron) : "";
      const commandItem = this.findAllGridTranslateItem({ data: item, item: "type" }) ? (getCommadName(item.type) ? getCommadName(item.type).label : item.type) : "";
      const itemAux = [
        {
          value: this.findAllGridTranslateItem({ data: item, item: "client" }) !== undefined ? (item.client.type === "LEG" ? item.client.legal.socialReason : item.client.personal.name) : ""
        },
        {
          value: cronItem
        },
        {
          value: commandItem
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "name" }) !== undefined ? item.name : ""
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "active" }) !== undefined ? (item.active ? "%{Title.Active}%" : "%{Title.Inactive}%") : ""
        }
      ];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async update({ id, data, token }) {
    const response = await ApiV2.put(`/command/${id}`, data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
