import { createActions, createReducer } from "reduxsauce";
import { filterByClient, filterByClientAndWord, sortPatrimonies } from "./../../utils/Patrimony";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  searchPatrimonies: ["search"],
  setPatrimonies: ["patrimonies"],
  setPatrimoniesSelected: ["patrimonies"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  patrimonies: [],
  patrimoniesSearch: [],
  patrimoniesSearchOnlyClient: [],
  patrimoniesSearchSelected: [],
  patrimoniesSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: -1
  },
  patrimoniesSearchQueryEnabled: false
};

const searchPatrimonies = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: patrimoniesSearchEnabled, results: patrimoniesSearchResults } = filterByClientAndWord(state.patrimonies, search);
  const { results: patrimoniesSearchOnlyClientResults } = filterByClient(state.patrimonies, search);
  return {
    ...state,
    patrimoniesSearch: patrimoniesSearchResults,
    patrimoniesSearchOnlyClient: patrimoniesSearchOnlyClientResults,
    patrimoniesSearchQuery: search,
    patrimoniesSearchQueryEnabled: patrimoniesSearchEnabled
  };
};

const setPatrimonies = (state = INITIAL_STATE, action) => {
  let { patrimonies } = action;
  return {
    ...state,
    patrimonies: sortPatrimonies(patrimonies),
    patrimoniesSearch: INITIAL_STATE.patrimoniesSearch,
    patrimoniesSearchOnlyClient: sortPatrimonies(patrimonies),
    patrimoniesSearchSelected: INITIAL_STATE.patrimoniesSearchSelected,
    patrimoniesSearchQuery: INITIAL_STATE.patrimoniesSearchQuery,
    patrimoniesSearchQueryEnabled: INITIAL_STATE.patrimoniesSearchQueryEnabled
  };
};

const setPatrimoniesSelected = (state = INITIAL_STATE, action) => {
  let { patrimonies } = action;
  return {
    ...state,
    patrimoniesSearchSelected: patrimonies
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEARCH_PATRIMONIES]: searchPatrimonies,
  [Types.SET_PATRIMONIES]: setPatrimonies,
  [Types.SET_PATRIMONIES_SELECTED]: setPatrimoniesSelected
});
