import React from "react";
import "./index.css";

import SwitchUser from "./../Switch/User";

const FilterUserItem = ({ checkbox = true, name, user, onClick, selected } = {}) => {
  return (
    <li className={"filter-user-list-item"} onClick={onClick}>
      <SwitchUser checkbox={checkbox} name={name} user={user} selected={selected} />
    </li>
  );
};

export default FilterUserItem;
