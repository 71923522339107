export function isValidForm(e) {
  if(e.currentTarget.checkValidity() === false || e.currentTarget.getElementsByClassName("is-invalid").length > 0) {
    var eventKey = -1;
    var message = false;
    var invalid = e.currentTarget.getElementsByClassName("is-invalid");
    if(invalid.length > 0) {
      setTimeout(() => {
        invalid[0].focus();
      }, 100);
      eventKey = invalid[0].getAttribute("dataeventkey");
      var invalidFeedback = invalid[0].parentElement.getElementsByClassName("invalid-feedback");
      if(invalidFeedback.length > 0) {
        message = invalidFeedback[0].innerHTML;
      }
    }
    return {
      eventKey,
      message,
      valid: false
    };
  }
  return {
    valid: true,
    message: false,
    eventKey: -1
  };
}
