import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addRegions: ["regions"],
  removeRegions: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  regions: false
};

const addRegions = (state = INITIAL_STATE, action) => {
  const { regions } = action;
  return {
    ...state,
    regions
  };
};

const removeRegions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    regions: false
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_REGIONS]: addRegions,
  [Types.REMOVE_REGIONS]: removeRegions
});
