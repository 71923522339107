import React, { useState, useEffect } from "react";
import {
  Form
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LastNotificationActions } from "./../../store/ducks/lastNotifications";

const LastNotificationSearch = ({
  props: { lastPositionsSearchQuery, lastPositionSelected },
  funcs: { searchLastNotification }
}) => {
  const { t } = useTranslation();
  const [notificationsSearch, setNotificationsSearch] = useState({
    client: lastPositionsSearchQuery.client,
    patrimony: lastPositionSelected ? lastPositionSelected.position.patrimony.id : "",
    word: ""
  });

  useEffect(() => {
    searchLastNotification(notificationsSearch);
  }, [notificationsSearch, searchLastNotification]);

  useEffect(() => {
    setNotificationsSearch(prevState => ({
      ...prevState,
      client: lastPositionsSearchQuery.client,
      patrimony: lastPositionSelected ? lastPositionSelected.position.patrimony.id : ""
    }));
  }, [lastPositionsSearchQuery, lastPositionSelected]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form.Group controlId="notifications-item-form-search">
        <Form.Control
          type="text"
          placeholder={t("Placeholder.Search")}
          autoComplete={"off"}
          onChange={e => setNotificationsSearch(prevState => ({
            ...prevState,
            word: e.target.value
          }))}
          value={notificationsSearch.word}
          disabled={false}
        />
      </Form.Group>
    </form>
  );
};

const mapStateToProps = state => ({
  props: {
    lastPositionsSearchQuery: state.lastPositions.positionsSearchQuery,
    lastPositionSelected: state.lastPositions.positionSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastNotificationActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastNotificationSearch);
