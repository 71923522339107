export function jsonToForm(json) {
  return json;
}

export function formToJson(json) {
  const newJson = JSON.parse(JSON.stringify(json));
  if (typeof newJson.client !== "undefined") {
    newJson.clientId = newJson.client.id;
    newJson.client = undefined;
  }
  if (typeof newJson.driver !== "undefined") {
    newJson.driverId = newJson.driver.id;
    newJson.driver = undefined;
  }
  return newJson;
}