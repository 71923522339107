import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import CurrencyInput from "react-currency-input";
import "./index.css";

import { Creators as SpeedingActions } from "../../store/ducks/speeding";

const FilterSpeeding = ({
  props: { currentUser },
  funcs: { setPeriodSpeeding }
}) => {
  const { t } = useTranslation();
  const [speeding, setSpeeding] = useState(80);

  useEffect(() => {
    setPeriodSpeeding(speeding);
  }, [speeding]); /* eslint-disable-line */

  return (
    <Card>
      <Card.Header>{t("Title.Speeding")}</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <div className="filter-speeding-fields">
              <Form.Group>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text><i className="fas fa-tachometer-alt" /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <CurrencyInput
                    className={"form-control"}
                    type="text"
                    placeholder={t("Placeholder.Speeding")}
                    decimalSeparator="."
                    thousandSeparator=""
                    precision="0"
                    prefix=""
                    value={speeding}
                    onChangeEvent={e => setSpeeding(e.target.value)}
                  />
                  <div className="filter-speeding-form-control-shaddow" />
                </InputGroup>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, SpeedingActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterSpeeding));
