import DateUtil from "./../../utils/Date";

import { getDescriptionPatrimony } from "./../../utils/Patrimony";

export function getCols() {
  return [{
    width: "10%",
    type: "string",
    label: "PatrimonyDischarge"
  }, {
    width: "15%",
    type: "string",
    label: "From"
  }, {
    width: "15%",
    type: "string",
    label: "To"
  }, {
    width: "10%",
    type: "string",
    label: "TotalAccounted"
  }, {
    width: "10%",
    type: "string",
    label: "PatrimonyCharge"
  }, {
    width: "15%",
    type: "string",
    label: "From"
  }, {
    width: "15%",
    type: "string",
    label: "To"
  }, {
    width: "10%",
    type: "string",
    label: "TotalAccounted"
  }];
}

export function getRows(docs, t) {
  const rows = [];
  docs.map(item => {
    return rows.push([{
      id: item.id,
      value: getDescriptionPatrimony(item.patrimony)
    }, {
      value: DateUtil.to({ date: new Date(item.transhipment.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: DateUtil.to({ date: new Date(item.transhipment.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: DateUtil.toHHMMSS(item.transhipment.all.seconds)
    }, {
      value: getDescriptionPatrimony(item.transhipment.charges[0].patrimony)
    }, {
      value: DateUtil.to({ date: new Date(item.transhipment.charges[0].transhipment.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: DateUtil.to({ date: new Date(item.transhipment.charges[0].transhipment.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: DateUtil.toHHMMSS(item.transhipment.charges[0].transhipment.all.seconds)
    }]);
  });
  return rows;
}
