import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setPeriodCron: ["cron"],
  setRepeatCron: ["repeat"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  periodCron: "0 * * * * *",
  repeatCron: true
};

const setPeriodCron = (state = INITIAL_STATE, action) => {
  const { cron  } = action;
  return {
    ...state,
    periodCron: cron
  };
};

const setRepeatCron = (state = INITIAL_STATE, action) => {
  const { cron  } = action;
  return {
    ...state,
    repeatCron: cron
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_PERIOD_CRON]: setPeriodCron,
  [Types.SET_REPEAT_CRON]: setRepeatCron
});
