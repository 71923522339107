import React, { memo } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import "./index.css";

const MenuSingleExportedItem = ({ getCount, icon, id, onClick, selected, title }) => {
  return (
    <li>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {title}
            {getCount ? ` (${getCount()})` : null}
          </Tooltip>
        }
      >
        <button
          className={`mse-rp-icon ${selected && selected() ? "active" : ""} ${id}`}
          type="button"
          onClick={onClick}>
          {icon}
          {getCount && getCount() > 0 ? <div className="mse-rp-icon-count">{getCount()}</div> : null}
        </button>
      </OverlayTrigger>
    </li>
  );
};

export default memo(MenuSingleExportedItem);
