import React, { memo, useState } from "react";
import {
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";
import { Creators as UsersActions } from "./../../store/ducks/users";

import { signIn } from "./../../services/Auth";
import { isCPF, unmaskCPF } from "./../../utils/CPF";
import { isValidForm } from "./../../utils/Form";
import { isEmpty } from "./../../utils/String";

const Login = ({ addNotice, setCurrentUser }) => {
  const { t } = useTranslation();
  const [cpf, setCpf] = useState("");
  const [password, setPassword] = useState("");
  const [running, setRunning] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Authenticate"),
          content: message
        });
      }
      else {
        const response = await signIn({ cpf: unmaskCPF(cpf), password });
        if(response.success) {
          return setCurrentUser(response.data);
        }
        addNotice({
          title: t("Title.Authenticate"),
          content: t(`Error.User.${response.error.type}`)
        });
      }
    }
    setRunning(false);
  };

  const buttonDisabled = () => {
    if((!isEmpty(cpf) && isCPF(cpf)) && !isEmpty(password) && !running) {
      return false;
    }
    return true;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  return (
    <div className="login-form">
      <div className="login-form-title">
        <div><i className="fa fa-info" aria-hidden="true"></i></div>
        <p>{t("Title.Authenticate")}</p>
      </div>
      <div className="login-form-content">
        <Form id="forms-login" onSubmit={handleSubmit}>
          <Form.Group controlId="forms-login-cpf">
            <Form.Label>{t("Label.CPF")}:</Form.Label>
            <InputMask
              id="forms-login-cpf"
              className={`form-control ${!isEmpty(cpf) && isCPF(cpf) ? "is-valid" : "is-invalid"}`}
              type="text"
              placeholder={t("Label.CPF")}
              onChange={e => setCpf(e.target.value)}
              mask="999.999.999-99"
              autoComplete="username"
              value={cpf}
              disabled={inputDisabled()}
              required={true}
            />
          </Form.Group>
          <Form.Group controlId="forms-login-password">
            <Form.Label>{t("Label.Password")}:</Form.Label>
            <Form.Control
              type="password"
              placeholder={t("Placeholder.Password")}
              onChange={e => setPassword(e.target.value)}
              autoComplete="current-password"
              value={password}
              isValid={!isEmpty(password)}
              isInvalid={isEmpty(password)}
              disabled={inputDisabled()}
              required={true}
            />
          </Form.Group>
          <Form.Group className="login-form-button">
            <Button
              variant="dark"
              type="submit"
              disabled={buttonDisabled()}
            >
              {running ? <Spinner animation="border" size="sm" /> : t("Button.Enter")}
            </Button>
          </Form.Group>
        </Form>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => bindActionCreators(Object.assign({}, NotificationsActions, UsersActions), dispatch);

export default connect(null, mapDispatchToProps)(memo(Login));
