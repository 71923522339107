import React, { memo } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import "./index.css";

const MenuOptionsItem = ({ getCount, disabled, icon, id, onClick, selected, title }) => {
  return (
    <li>
      <OverlayTrigger
        placement="left"
        overlay={
          <Tooltip>
            {title}
            {getCount ? ` (${getCount()})` : null}
          </Tooltip>
        }
        trigger={(disabled && disabled()) || isMobile ? null : ["hover", "focus"]}
      >
        <button
          className={`moi-icon ${selected && selected() ? "active" : ""} ${id}`}
          type="button"
          onClick={onClick}
          disabled={disabled && disabled()}
        >
          {icon}
          {getCount && getCount() > 0 ? <div className="moi-rp-icon-count">{getCount()}</div> : null}
        </button>
      </OverlayTrigger>
    </li>
  );
};

export default memo(MenuOptionsItem);
