import ClientSchemaReduced from "./../Client/SchemaReduced";
import ContractSchemaReduced from "./../Contract/SchemaReduced";
import DriverSchemaReduced from "./../Driver/SchemaReduced";

const schema = {
  active: true,
  activeExporter: true,
  activeProduction: false,
  automobile: {
    board: {
      autoVaccum: {
        backCover: "FOP",
        license: ""
      },
      board: "",
      color: "GRAY",
      country: "BR",
      description: "",
      layout: "DEF",
      type: "CAR"
    },
    card: {
      number: ""
    },
    brand: "",
    color: "",
    model: "",
    type: "BOA"
  },
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  contract: JSON.parse(JSON.stringify(ContractSchemaReduced)),
  driver: JSON.parse(JSON.stringify(DriverSchemaReduced)),
  id: "",
  installAt: "",
  object: {
    name: ""
  },
  parameters: {
    consumptionAverage: 5.00,
    delayedLocation: "01:00:00",
    downtimeTolerance: "00:04:00",
    fixRoutePeriodic: false,
    fixRouteReport: false,
    maintenanceMotorMaxHours: 0,
    maintenanceMotorMaxMonths: 0,
    maxSpeed: 0,
    runMinHour: null,
    runMaxHour: null
  },
  pictures: [],
  scopes: [],
  serial: "",
  trackers: [],
  type: "AUT",
  uninstallAt: "",
  vessel: {
    brand: "",
    description: "",
    model: "",
    motors: 1,
    name: "",
    registrationNumber: "",
    type: ""
  },
  virtualFences: []
};

export default schema;
