import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import "./index.css";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import DateUtil from "./../../utils/Date";
import { isValidForm } from "./../../utils/Form";
import { clearObject, mergeObject } from "./../../utils/Object";
import { isEmpty, ucFirstAll } from "./../../utils/String";
import { getType } from "./../../utils/Tracker";

import PatrimonyService from "./../../services/Patrimony";
import PatrimonySchemaReduced from "./../../services/Patrimony/SchemaReduced";
import PatrimonyTestService from "./../../services/PatrimonyTest";
import PatrimonyTestSchema from "./../../services/PatrimonyTest/Schema";
import TrackerSchemaReduced from "./../../services/Tracker/SchemaReduced";
import UserService from "./../../services/User";
import UserSchemaReduced from "./../../services/User/SchemaReduced";

registerLocale("pt-BR", ptBR);

const PatrimonyTest = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const patrimonyService = new PatrimonyService();
  const patrimonyTestService = new PatrimonyTestService();
  const userService = new UserService();

  const {
    patrimonyTest: __patrimonyTest = {},
    close: __close = () => {}
  } = options;

  const [patrimonyTest, setPatrimonyTest] = useState(mergeObject(JSON.parse(JSON.stringify(PatrimonyTestSchema)), __patrimonyTest));
  const [patrimonyTestPositions, setPatrimonyTestPositions] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setPatrimonyTestPositions(getPositionsAux(patrimonyTest.positions));
    }, 100);
  }, [patrimonyTest]);

  const [patrimonySelectedOption, setPatrimonySelectedOption] = useState(patrimonyTest.patrimony.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(PatrimonySchemaReduced)), patrimonyTest.patrimony),
    label: patrimonyTest.patrimony.description,
    value: patrimonyTest.patrimony.id
  });

  const [typeOptions] = useState([
    { value: "FRE", label: t("Title.PatrimonyTest.Free") },
    { value: "INS", label: t("Title.PatrimonyTest.Installation") },
    { value: "STA", label: t("Title.PatrimonyTest.Stand") }
  ]);

  const [tagsHatchsOptions] = useState([
    { value: "HATCH_OPERATION_CLO", label: t("Title.PatrimonyTest.HATCH_OPERATION_CLO") },
    { value: "HATCH_OPERATION_OPE", label: t("Title.PatrimonyTest.HATCH_OPERATION_OPE") },
    { value: "HATCH_EPF", label: t("Title.PatrimonyTest.HATCH_EPF") }
  ]);
  const [tagsHatchsOptionsInstallation] = useState([
    { value: "HATCH_OPERATION_CLO", label: t("Title.PatrimonyTest.HATCH_OPERATION_CLO") },
    { value: "HATCH_OPERATION_OPE", label: t("Title.PatrimonyTest.HATCH_OPERATION_OPE") },
    { value: "HATCH_EPF", label: t("Title.PatrimonyTest.HATCH_EPF") }
  ]);
  const [tagsHatchsOptionsStand] = useState([]);

  const [tagsNiplesOptions] = useState([
    { value: "NIPLE_COMMUNICATION", label: t("Title.PatrimonyTest.NIPLE_COMMUNICATION") },
    { value: "NIPLE_FRAUD_BCO", label: t("Title.PatrimonyTest.NIPLE_FRAUD_BCO") },
    { value: "NIPLE_FRAUD_EPF", label: t("Title.PatrimonyTest.NIPLE_FRAUD_EPF") },
    { value: "NIPLE_FRAUD_HOG", label: t("Title.PatrimonyTest.NIPLE_FRAUD_HOG") },
    { value: "NIPLE_HATCH_COMMUNICATION", label: t("Title.PatrimonyTest.NIPLE_HATCH_COMMUNICATION") },
    { value: "NIPLE_HATCH_EPF", label: t("Title.PatrimonyTest.NIPLE_HATCH_EPF") },
    { value: "NIPLE_OPERATION_CHA", label: t("Title.PatrimonyTest.NIPLE_OPERATION_CHA") },
    { value: "NIPLE_OPERATION_CHA_WITH_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_CHA_WITH_EPF") },
    { value: "NIPLE_OPERATION_CHA_WITHOUT_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_CHA_WITHOUT_EPF") },
    { value: "NIPLE_OPERATION_DIS", label: t("Title.PatrimonyTest.NIPLE_OPERATION_DIS") },
    { value: "NIPLE_OPERATION_DIS_WITH_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_DIS_WITH_EPF") },
    { value: "NIPLE_OPERATION_DIS_WITHOUT_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_DIS_WITHOUT_EPF") },
    { value: "NIPLE_TEST_GYROSCOPE", label: t("Title.PatrimonyTest.NIPLE_TEST_GYROSCOPE") },
    { value: "NIPLE_TEST_TES", label: t("Title.PatrimonyTest.NIPLE_TEST_TES") },
    { value: "NIPLE_TEST_TPW", label: t("Title.PatrimonyTest.NIPLE_TEST_TPW") },
    { value: "NIPLE_TEST_SILICA", label: t("Title.PatrimonyTest.NIPLE_TEST_SILICA"), manual: true },
    { value: "NIPLE_TEST_PG7", label: t("Title.PatrimonyTest.NIPLE_TEST_PG7"), manual: true },
    { value: "NIPLE_TEST_PG9", label: t("Title.PatrimonyTest.NIPLE_TEST_PG9"), manual: true },
    { value: "NIPLE_TEST_3WAYS", label: t("Title.PatrimonyTest.NIPLE_TEST_3WAYS"), manual: true },
    { value: "NIPLE_TEST_4WAYS", label: t("Title.PatrimonyTest.NIPLE_TEST_4WAYS"), manual: true },
    { value: "NIPLE_TEST_RUBBER_ENGINE_SEAL", label: t("Title.PatrimonyTest.NIPLE_TEST_RUBBER_ENGINE_SEAL"), manual: true },
    { value: "NIPLE_TEST_FINISHING", label: t("Title.PatrimonyTest.NIPLE_TEST_FINISHING"), manual: true }
  ]);

  const [tagsNiplesOptionsInstallation] = useState([
    { value: "NIPLE_FRAUD_BCO", label: t("Title.PatrimonyTest.NIPLE_FRAUD_BCO") },
    { value: "NIPLE_FRAUD_EPF", label: t("Title.PatrimonyTest.NIPLE_FRAUD_EPF") },
    { value: "NIPLE_FRAUD_HOG", label: t("Title.PatrimonyTest.NIPLE_FRAUD_HOG") },
    { value: "NIPLE_OPERATION_CHA", label: t("Title.PatrimonyTest.NIPLE_OPERATION_CHA") },
    { value: "NIPLE_OPERATION_DIS", label: t("Title.PatrimonyTest.NIPLE_OPERATION_DIS") },
    { value: "NIPLE_TEST_TES", label: t("Title.PatrimonyTest.NIPLE_TEST_TES") },
    { value: "NIPLE_TEST_TPW", label: t("Title.PatrimonyTest.NIPLE_TEST_TPW") }
  ]);
  const [tagsNiplesOptionsStand] = useState([
    { value: "NIPLE_COMMUNICATION", label: t("Title.PatrimonyTest.NIPLE_COMMUNICATION") },
    { value: "NIPLE_HATCH_COMMUNICATION", label: t("Title.PatrimonyTest.NIPLE_HATCH_COMMUNICATION") },
    { value: "NIPLE_HATCH_EPF", label: t("Title.PatrimonyTest.NIPLE_HATCH_EPF") },
    { value: "NIPLE_OPERATION_CHA_WITH_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_CHA_WITH_EPF") },
    { value: "NIPLE_OPERATION_CHA_WITHOUT_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_CHA_WITHOUT_EPF") },
    { value: "NIPLE_OPERATION_DIS_WITH_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_DIS_WITH_EPF") },
    { value: "NIPLE_OPERATION_DIS_WITHOUT_EPF", label: t("Title.PatrimonyTest.NIPLE_OPERATION_DIS_WITHOUT_EPF") },
    { value: "NIPLE_FRAUD_EPF", label: t("Title.PatrimonyTest.NIPLE_FRAUD_EPF") },
    { value: "NIPLE_FRAUD_HOG", label: t("Title.PatrimonyTest.NIPLE_FRAUD_HOG") },
    { value: "NIPLE_TEST_GYROSCOPE", label: t("Title.PatrimonyTest.NIPLE_TEST_GYROSCOPE") },
    { value: "NIPLE_TEST_TES", label: t("Title.PatrimonyTest.NIPLE_TEST_TES") },
    { value: "NIPLE_TEST_TPW", label: t("Title.PatrimonyTest.NIPLE_TEST_TPW") },
    { value: "NIPLE_TEST_SILICA", label: t("Title.PatrimonyTest.NIPLE_TEST_SILICA"), manual: true },
    { value: "NIPLE_TEST_PG7", label: t("Title.PatrimonyTest.NIPLE_TEST_PG7"), manual: true },
    { value: "NIPLE_TEST_PG9", label: t("Title.PatrimonyTest.NIPLE_TEST_PG9"), manual: true },
    { value: "NIPLE_TEST_3WAYS", label: t("Title.PatrimonyTest.NIPLE_TEST_3WAYS"), manual: true },
    { value: "NIPLE_TEST_4WAYS", label: t("Title.PatrimonyTest.NIPLE_TEST_4WAYS"), manual: true },
    { value: "NIPLE_TEST_RUBBER_ENGINE_SEAL", label: t("Title.PatrimonyTest.NIPLE_TEST_RUBBER_ENGINE_SEAL"), manual: true },
    { value: "NIPLE_TEST_FINISHING", label: t("Title.PatrimonyTest.NIPLE_TEST_FINISHING"), manual: true }
  ]);

  const [tagsTrackersOptions] = useState([
    { value: "TRACKER_EPF", label: t("Title.PatrimonyTest.TRACKER_EPF") },
    { value: "TRACKER_GPS_DATE", label: t("Title.PatrimonyTest.TRACKER_GPS_DATE") }
  ]);
  const [tagsTrackersOptionsInstallation] = useState([
    { value: "TRACKER_EPF", label: t("Title.PatrimonyTest.TRACKER_EPF") }
  ]);
  const [tagsTrackersOptionsStand] = useState([
    { value: "TRACKER_EPF", label: t("Title.PatrimonyTest.TRACKER_EPF") },
    { value: "TRACKER_GPS_DATE", label: t("Title.PatrimonyTest.TRACKER_GPS_DATE") }
  ]);

  const [tagsOptions, setTagsOptions] = useState([]);
  const [tagsOptionsSelected, setTagsOptionsSelected] = useState(null);

  const [running, setRunning] = useState(false);

  const [trackerTypesOptions] = useState([
    { value: "HAT", label: t("Title.Hatch") },
    { value: "NIP", label: t("Title.Niple") },
    { value: "TRA", label: t("Title.Tracker") },
    { value: "TRA_NIP", label: `${t("Title.Tracker")}/${t("Title.Niple")}` }
  ]);

  const [patrimonyTestTrackers, setPatrimonyTestTracker] = useState(patrimonyTest.patrimony.trackers.map(item => {
    const labelType = trackerTypesOptions.find(x => x.value === item.type);
    return {
      dataAux: mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), item),
      label: `${typeof labelType !== "undefined" ? labelType.label : t("Title.Tracker")}: ${item.serial}`,
      value: item.id
    };
  }));
  const [patrimonyTestTrackerSelected, setPatrimonyTestTrackerSelected] = useState(null);

  const [userSelectedOption, setUserSelectedOption] = useState(patrimonyTest.user.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(UserSchemaReduced)), patrimonyTest.user),
    label: patrimonyTest.user.name,
    value: patrimonyTest.user.id
  });

  const buttonDisabled = () => {
    if(!running && patrimonyTest.status === "WAI") {
      return false;
    }
    return true;
  };

  const filterPatrimony = (patrimonies) => {
    return patrimonies.map(e => {
      return {
        dataAux: e,
        label: e.description,
        value: e.id
      };
    });
  };

  const filterUser = (users) => {
    return users.map(e => {
      return {
        dataAux: e,
        label: e.name,
        value: e.id
      };
    });
  };

  const getTags = (e) => {
    var values = [];
    if(e !== null) {
      switch (e.dataAux.type) {
      case "HAT":
        values = JSON.parse(JSON.stringify(tagsHatchsOptions));
        values = values.map(v => {
          v.value = `${v.value}_${e.dataAux.serial}`;
          return v;
        });
        return values;
      case "NIP":
        values = JSON.parse(JSON.stringify(tagsNiplesOptions));
        if(e.dataAux.niple.operation === "CHA") {
          values = values.filter(x => x.value !== "NIPLE_OPERATION_DIS");
        }
        else if(e.dataAux.niple.operation === "DIS") {
          values = values.filter(x => x.value !== "NIPLE_OPERATION_CHA");
        }
        values = values.map(v => {
          v.value = `${v.value}_${e.dataAux.serial}`;
          return v;
        });
        return values;
      case "TRA":
        values = JSON.parse(JSON.stringify(tagsTrackersOptions));
        values = values.map(v => {
          v.value = `${v.value}_${e.dataAux.serial}`;
          return v;
        });
        return values;
      case "TRA_NIP":
        values = JSON.parse(JSON.stringify(tagsTrackersOptions));
        values = values.map(v => {
          v.value = `${v.value}_${e.dataAux.serial}`;
          return v;
        });
        return values;
      default:
        return [];
      }
    }
    return [];
  };

  const getArrayTags = (e) => {
    return JSON.parse(JSON.stringify(e));
  };

  const addArrayTags = (positions, array, tracker) => {
    return getArrayTags(array)
      .map(add => {
        add.value = `${add.value}_${tracker.serial}`;
        return positions.push({
          manual: typeof add.manual !== "undefined" ? add.manual : false,
          tag: add.value,
          tracker: mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), tracker)
        });
      });
  }

  const getPositions = (type, patrimony, reset = false) => {
    const positions = type === "FRE" ? (reset ? [] : getArrayTags(patrimonyTest.positions)) : [];
    const { trackers } = patrimony;
    trackers.map(tracker => {
      if (tracker.type === "HAT") {
        if (type === "INS") {
          return addArrayTags(positions, tagsHatchsOptionsInstallation, tracker);
        }
        else if (type === "STA") {
          return addArrayTags(positions, tagsHatchsOptionsStand, tracker);
        }
      }
      else if (tracker.type === "NIP") {
        if (type === "INS") {
          return addArrayTags(positions, tagsNiplesOptionsInstallation, tracker);
        }
        else if (type === "STA") {
          return addArrayTags(positions, tagsNiplesOptionsStand, tracker);
        }
      }
      else if (tracker.type === "TRA" || tracker.type === "TRA_NIP") {
        if (type === "INS") {
          return addArrayTags(positions, tagsTrackersOptionsInstallation, tracker);
        }
        else if (type === "STA") {
          return addArrayTags(positions, tagsTrackersOptionsStand, tracker);
        }
      }
      return null;
    });
    return positions;
  };

  const getPositionsAux = (positions) => {
    const patrimonyTestPositions = [];
    positions.map(position => {
      const filterIndex = patrimonyTestPositions.findIndex(x => x.serial === position.tracker.serial);
      if(filterIndex !== -1) {
        return patrimonyTestPositions[filterIndex].itens.push(position);
      }
      return patrimonyTestPositions.push({
        serial: position.tracker.serial,
        title: getType(position.tracker),
        itens: [position]
      });
    });
    return patrimonyTestPositions;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.PatrimonyTest"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await patrimonyTestService.create({
            data: handleSubmitForm({ patrimonyTest }),
            token: currentUser.token
          });
        }
        else {
          response = await patrimonyTestService.update({
            id: patrimonyTest.id,
            data: handleSubmitForm({ patrimonyTest }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.PatrimonyTest"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.PatrimonyTest"),
            content: t(`Error.PatrimonyTest.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ patrimonyTest }) => {
    let newPatrimonyTest = Object.assign({}, JSON.parse(JSON.stringify(patrimonyTest)));
    newPatrimonyTest = clearObject({ data: newPatrimonyTest });
    newPatrimonyTest.id = undefined;
    return newPatrimonyTest;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const inputDisabledByState = () => {
    if(patrimonyTest.status === "WAI") {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return patrimonyTest.id === "";
  };

  const patrimonyLoadOptions = (e, c) => {
    patrimonyLoadOptionsDebounce(e, c);
  };

  const patrimonyLoadOptionsDebounce = debounce(async (e, c) => {
    const patrimonies = await patrimonyService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterPatrimony(patrimonies));
  }, 1000);

  const userLoadOptions = (e, c) => {
    userLoadOptionsDebounce(e, c);
  };

  const userLoadOptionsDebounce = debounce(async (e, c) => {
    const users = await userService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterUser(users));
  }, 1000);

  return (
    <Form id="forms-patrimony-test" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <div className="filter-date-fields">
                <Form.Group controlId="forms-patrimony-test-start-at">
                  <Form.Label>{t("Title.Date.Start")}:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      disabled={inputDisabled() || inputDisabledByState()}
                      selected={DateUtil.toDate({ date: patrimonyTest.startAt })}
                      onChange={date => {
                        const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                        const startAt = date === null ? new Date(`${todayDate} 00:00:00`) : date;
                        setPatrimonyTest(prevState => ({
                          ...prevState,
                          startAt
                        }));
                      }}
                      selectsStart
                      startDate={DateUtil.toDate({ date: patrimonyTest.startAt })}
                      endDate={DateUtil.toDate({ date: patrimonyTest.endAt })}
                      minDate={new Date()}
                      maxDate={DateUtil.toDate({ date: patrimonyTest.endAt })}
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      className="react-datepicker-form-control"
                      id="forms-patrimony-test-start-at"
                      popperPlacement="top-center"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}
                      locale="pt-BR"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="filter-date-fields">
                <Form.Group controlId="forms-patrimony-test-end-at">
                  <Form.Label>{t("Title.Date.End")}:</Form.Label>
                  <InputGroup className="mb-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                    </InputGroup.Prepend>
                    <DatePicker
                      disabled={inputDisabled() || inputDisabledByState()}
                      selected={DateUtil.toDate({ date: patrimonyTest.endAt })}
                      onChange={date => {
                        const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                        const endAt = date === null ? new Date(`${todayDate} 23:55:00`) : date;
                        setPatrimonyTest(prevState => ({
                          ...prevState,
                          endAt
                        }));
                      }}
                      selectsEnd
                      startDate={DateUtil.toDate({ date: patrimonyTest.startAt })}
                      endDate={DateUtil.toDate({ date: patrimonyTest.endAt })}
                      minDate={DateUtil.toDate({ date: patrimonyTest.startAt })}
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      showTimeSelect
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy HH:mm:ss"
                      className="react-datepicker-form-control"
                      id="forms-patrimony-test-end-at"
                      popperPlacement="top-center"
                      popperModifiers={{
                        offset: {
                          enabled: true,
                          offset: "5px, 10px"
                        },
                        preventOverflow: {
                          enabled: true,
                          escapeWithReference: false,
                          boundariesElement: "viewport"
                        }
                      }}
                      locale="pt-BR"
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-patrimony-test-installer">
                <Form.Label>{t("Title.Installer")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(patrimonyTest.user.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || inputDisabledByState()}
                  loadOptions={userLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setUserSelectedOption(e);
                      setPatrimonyTest(prevState => ({
                        ...prevState,
                        user: JSON.parse(JSON.stringify(UserSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setUserSelectedOption({ dataAux, label, value });
                      setPatrimonyTest(prevState => ({
                        ...prevState,
                        user: mergeObject(JSON.parse(JSON.stringify(UserSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Installer.Select")}
                  value={userSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(patrimonyTest.user.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Installer")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-patrimony-test-patrimony">
                <Form.Label>{t("Title.Patrimony")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(patrimonyTest.patrimony.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || inputDisabledByState()}
                  loadOptions={patrimonyLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    let patrimony = JSON.parse(JSON.stringify(PatrimonySchemaReduced));
                    if(e === null) {
                      setPatrimonySelectedOption(e);
                      setPatrimonyTest(prevState => ({
                        ...prevState,
                        patrimony
                      }));
                      setPatrimonyTestTracker([]);
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setPatrimonySelectedOption({ dataAux, label, value });
                      patrimony = mergeObject(JSON.parse(JSON.stringify(PatrimonySchemaReduced)), dataAux);
                      setPatrimonyTest(prevState => ({
                        ...prevState,
                        patrimony
                      }));
                      setPatrimonyTestTracker(dataAux.trackers.map(item => {
                        const labelType = trackerTypesOptions.find(x => x.value === item.type);
                        return {
                          dataAux: mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), item),
                          label: `${typeof labelType !== "undefined" ? labelType.label : t("Title.Tracker")}: ${item.serial}`,
                          value: item.id
                        };
                      }));
                    }


                    setPatrimonyTestTrackerSelected(null);
                    setTagsOptions([]);
                    setTagsOptionsSelected([]);

                    const oldPositions = getPositions(patrimonyTest.type, patrimony, true);
                    const oldPositionsAux = getPositionsAux(oldPositions);
                    setPatrimonyTestPositions(oldPositionsAux);
                    setPatrimonyTest(prevState => ({
                      ...prevState,
                      positions: oldPositions
                    }));
                  }}
                  placeholder={t("Title.Patrimony.Select")}
                  value={patrimonySelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(patrimonyTest.patrimony.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Patrimony")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="forms-patrimony-test-type">
                <Form.Label>{t("Label.Type")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled() || inputDisabledByState()}
                  onChange={e => {
                    const type = e.value;
                    const positions = getPositions(type, patrimonyTest.patrimony);
                    setPatrimonyTest(prevState => ({
                      ...prevState,
                      type,
                      positions
                    }));
                  }}
                  options={typeOptions}
                  value={typeOptions.find(x => x.value === patrimonyTest.type)}
                />
              </Form.Group>
            </Col>
          </Row>
          {
            patrimonyTest.type === "FRE" ? (
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-test-tracker">
                    <Form.Label>{t("Title.Tracker")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        setPatrimonyTestTrackerSelected(e);
                        setTagsOptions(getTags(e));
                        const positions = JSON.parse(JSON.stringify(patrimonyTest.positions));
                        setTagsOptionsSelected(
                          positions
                            .filter(x => x.tracker.id === e.dataAux.id)
                            .map(x => {
                              return {
                                value: x.tag,
                                label: t(`Title.PatrimonyTest.${x.tag.replace(`_${x.tracker.serial}`, "")}`)
                              };
                            })
                        );
                      }}
                      options={patrimonyTestTrackers}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      placeholder={t("Title.Tracker.Select")}
                      value={patrimonyTestTrackerSelected}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-test-tags">
                    <Form.Label>{t("Title.Tracker.Test")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled() || inputDisabledByState()}
                      isMulti={true}
                      onChange={e => {
                        const oldTags = tagsOptionsSelected;
                        const newTags = e === null ? [] : e;

                        const tagsAdd = newTags.filter(x => oldTags.findIndex(y => y.value === x.value) === -1);
                        const tagsRem = oldTags.filter(x => newTags.findIndex(y => y.value === x.value) === -1);

                        const oldPositions = patrimonyTest.positions;
                        const tracker = JSON.parse(JSON.stringify(patrimonyTestTrackerSelected.dataAux));

                        tagsAdd.map(add => {
                          return oldPositions.push({
                            tag: add.value,
                            tracker: mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), tracker)
                          });
                        });

                        tagsRem.map(rem => {
                          const index = oldPositions.findIndex(x => x.tag === rem.value);
                          if(index !== -1) {
                            oldPositions.splice(index, 1);
                          }
                          return true;
                        });

                        setTagsOptionsSelected(newTags);
                        setPatrimonyTest(prevState => ({
                          ...prevState,
                          positions: oldPositions
                        }));
                      }}
                      options={tagsOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      placeholder={t("Title.Tracker.Test.Select")}
                      value={tagsOptionsSelected}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ) : null
          }
          {
            patrimonyTestPositions.length > 0 ? (
              <ul>
                <li className="niple-box">
                  <ul>
                    {
                      patrimonyTestPositions.map((positionAux, indexAux) => {
                        return (
                          <React.Fragment key={`lps-patrimony-patrimonyTest-main-${indexAux}`}>
                            {
                              positionAux.itens.map((position, index) => {
                                return (
                                  <React.Fragment key={`lps-patrimony-patrimonyTest-title-${index}`}>
                                    {
                                      index === 0 ? (
                                        <li className="niple-box-title-full">
                                          <div>{t(positionAux.title)}:</div>
                                        </li>
                                      ) : null
                                    }
                                    <li className="grid-3-1-5 gray">
                                      <div>{t(`Title.PatrimonyTest.${position.tag.replace(`_${position.tracker.serial}`, "")}`)}:</div>
                                      {
                                        patrimonyTest.status !== "CON" ? (
                                          <div><span>{position.success ? <>{t("Title.Success")} <i className="fas fa-check" /></> : <>{t("Title.Waiting")} <i className="fas fa-spinner" /></>}</span></div>
                                        ) : (
                                          <div><span>{position.success ? <>{t("Title.Success")} <i className="fas fa-check" /></> : <>{t("Title.Fail")} <i className="fas fa-exclamation-triangle" /></>}</span></div>
                                        )
                                      }
                                    </li>
                                  </React.Fragment>
                                );
                              })
                            }
                          </React.Fragment>
                        );
                      })
                    }
                  </ul>
                </li>
              </ul>
            ) : null
          }
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(PatrimonyTest));
