export function filterByPatrimonyAndWord(exporterErrors, search) {
  let filter = exporterErrors;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.patrimony !== "undefined" && search.patrimony !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.exporterError.vehicle !== "undefined" && p.exporterError.vehicle.id === search.patrimony);
    }
    if(typeof search.niple !== "undefined" && search.niple !== "") {
      enabled = true;
      filter = search.niple ? filter : [];
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((p) => p.exporterError.vehicle.board.toLowerCase().indexOf(search.word.toLowerCase()) !== -1);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function getOperation(type) {
  switch(type) {
  case "B":
    return "Title.Niple.Fraud";
  case "D":
    return "Title.Niple.Discharge";
  case "M":
    return "Title.Niple.Maintenance";
  default:
    return "Title.Niple.Moving";
  }
}

export function sortExporterErrors(exporterErrors, asc = false) {
  if(asc) {
    return exporterErrors.sort((a, b) => a.exporterError.tracker.date.localeCompare(b.exporterError.tracker.date) || a.exporterError.createdAt.localeCompare(b.exporterError.createdAt));
  }
  return exporterErrors.sort((a, b) => b.exporterError.tracker.date.localeCompare(a.exporterError.tracker.date) || b.exporterError.createdAt.localeCompare(a.exporterError.createdAt));
}
