import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { QRCode } from "react-qrcode-logo";
import InputMask from "react-input-mask";
import {
  Button,
  Form
} from "react-bootstrap";
import "./index.css";

import { isEmpty } from "./../../utils/String";
import { Creators as QrcodesActions } from "./../../store/ducks/qrcodes";

function loadImage(url) {
  return new Promise(r => { let i = new Image(); i.onload = (() => r(i)); i.src = url; });
}

const QRCodeDownlod = ({
  props: {
    qrcodeValue
  },
  funcs: {
    disableQrcode
  }
}) => {
  const [qrcodeSize, setQrcodeSize] = useState(300);
  return (
    <section className="section-content-qrcode">
      <div className="data-qrcode">
        <div className="data-qrcode-controls">
          <Form.Group controlId="qrcode-size">
            <Form.Label>Tamanho:</Form.Label>
            <InputMask
              id="qrcode-size"
              className={`form-control ${(!isEmpty(qrcodeSize) && !isNaN(Number(qrcodeSize))) ? "is-valid" : "is-invalid"}`}
              type="number"
              placeholder={"Tamanho"}
              onChange={e => {
                const qrcodeSize = Number(e.target.value);
                setQrcodeSize(qrcodeSize);
              }}
              autoComplete="off"
              defaultValue={qrcodeSize}
            />
          </Form.Group> <Button
            variant="dark"
            type="button"
            onClick={() => { disableQrcode() }}>Fechar</Button> <Button
            variant="dark"
            type="button"
            onClick={async () => {
              let link = document.createElement("a");
              let canvas = document.getElementById(`canvas-qrcode-download-${qrcodeValue}`);
              let canvasNew = document.createElement("canvas");
              let canvasNewContext = canvasNew.getContext("2d");

              const image = await loadImage(process.env.PUBLIC_URL + "/images/logo-qrcode.png");

              canvasNew.width = qrcodeSize;
              canvasNew.height = qrcodeSize;

              var wrh = image.width / image.height;
              var newWidth = canvasNew.width;
              var newHeight = newWidth / wrh;
              if (newHeight > canvasNew.height) {
                newHeight = canvasNew.height;
                newWidth = newHeight * wrh;
              }
              var xOffset = newWidth < canvasNew.width ? ((canvasNew.width - newWidth) / 2) : 0;
              var yOffset = qrcodeSize;

              canvasNew.width = qrcodeSize;
              canvasNew.height = qrcodeSize + newHeight;
              canvasNewContext.drawImage(canvas, 0, 0, qrcodeSize, qrcodeSize);
              canvasNewContext.drawImage(image, xOffset, yOffset, newWidth, newHeight);

              link.download = `QRCode${qrcodeSize}.png`;
              link.href = canvasNew.toDataURL("image/png");
              link.click();
            }}
          >Baixar</Button>
        </div>
        <div className="data-qrcode-content">
          <QRCode
            id={`canvas-qrcode-download-${qrcodeValue}`}
            value={qrcodeValue}
            qrStyle={"square"}
            fgColor={"#000000"}
            size={qrcodeSize}
            style={{
              width: `${qrcodeSize}px`,
              height: `${qrcodeSize}px`
            }}
          />
        </div>
      </div>
      <div className="data-qrcode-bg" />
    </section>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    qrcodeValue: state.qrcodes.value
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, QrcodesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(QRCodeDownlod);

