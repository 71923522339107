import ClientSchemaReduced from "./../Client/SchemaReduced";

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  id: "",
  number: "",
  serial: ""
};

export default schema;
