import DateUtil from "./../../utils/Date";

export function getCols() {
  return [{
    width: "25%",
    type: "string",
    label: "Address"
  }, {
    width: "25%",
    type: "string",
    label: "Date"
  }, {
    width: "25%",
    type: "string",
    label: "Block"
  }, {
    width: "25%",
    type: "string",
    label: "Coordinate"
  }];
}

export function getRows(docs, t) {
  const rows = [];
  docs.map(item => {
    return rows.push([{
      id: item.id,
      value: item.gps.address
    }, {
      value: DateUtil.to({ date: new Date(item.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: item.tracker.block ? t("Active") : t("Inactive")
    }, {
      value: `${item.gps.coordinate.latitude} | ${item.gps.coordinate.longitude}`
    }]);
  });
  return rows;
}
