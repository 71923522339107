import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setPeriodFrequency: ["frequency"],
  setPeriodSpeed: ["speed"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  periodFrequency: -1,
  periodSpeed: false
};

const setPeriodFrequency = (state = INITIAL_STATE, action) => {
  const { frequency } = action;
  return {
    ...state,
    periodFrequency: frequency
  };
};

const setPeriodSpeed = (state = INITIAL_STATE, action) => {
  const { speed } = action;
  return {
    ...state,
    periodSpeed: speed
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_PERIOD_FREQUENCY]: setPeriodFrequency,
  [Types.SET_PERIOD_SPEED]: setPeriodSpeed
});
