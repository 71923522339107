import ClientSchemaReduced from "./../Client/SchemaReduced";

const schema = {
  active: true,
  baseFineAmount: "",
  businessType: "LEN",
  chargeType: "BIL",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  contractualFine: false,
  dueDate: "05",
  id: "",
  installationValue: "",
  kmTraveled: "R$ 0,80",
  loyaltyPeriod: "12",
  monthlyValue: "",
  name: "",
  sequence: "",
  technicalTime: "R$ 200,00",
  uninstallValue: ""
};

export default schema;
