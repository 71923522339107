import React from "react";
import "./index.css";

import SwitchChip from "../Switch/Chip";

const FilterChipItem = ({ checkbox = true, name, chip, onClick, selected } = {}) => {
  return (
    <li className={"filter-chip-list-item"} onClick={onClick}>
      <SwitchChip checkbox={checkbox} name={name} chip={chip} selected={selected} />
    </li>
  );
};

export default FilterChipItem;
