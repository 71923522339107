import { ApiV2 } from "../Api";

export default class Calendar {
  async findCalendar({ data, token }) {
    const response = await ApiV2.post("/calendar/find/calendar", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if (error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if (response.success) return response.data;
    return [];
  }
}
