import { createActions, createReducer } from "reduxsauce";
import { filterByClient, filterByClientAndWord, sortChips } from "./../../utils/Chip";

import ClientSchemaReduced from "../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  searchChips: ["search"],
  setChips: ["chips"],
  setChipsSelected: ["chips"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  chips: [],
  chipsSearch: [],
  chipsSearchOnlyClient: [],
  chipsSearchSelected: [],
  chipsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: -1
  },
  chipsSearchQueryEnabled: false
};

const searchChips = (state = INITIAL_STATE, action) => {
  const { search } = action;
  console.log(state);
  const { enabled: chipsSearchEnabled, results: chipsSearchResults } = filterByClientAndWord(state.chips, search);
  const { results: chipsSearchOnlyClientResults } = filterByClient(state.chips, search);
  return {
    ...state,
    chipsSearch: chipsSearchResults,
    chipsSearchOnlyClient: chipsSearchOnlyClientResults,
    chipsSearchQuery: search,
    chipsSearchQueryEnabled: chipsSearchEnabled
  };
};

const setChips = (state = INITIAL_STATE, action) => {
  let { chips } = action;
  return {
    ...state,
    chips: sortChips(chips),
    chipsSearch: INITIAL_STATE.chipsSearch,
    chipsSearchOnlyClient: sortChips(chips),
    chipsSearchSelected: INITIAL_STATE.chipsSearchSelected,
    chipsSearchQuery: INITIAL_STATE.chipsSearchQuery,
    chipsSearchQueryEnabled: INITIAL_STATE.chipsSearchQueryEnabled
  };
};

const setChipsSelected = (state = INITIAL_STATE, action) => {
  let { chips } = action;
  return {
    ...state,
    chipsSearchSelected: chips
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEARCH_CHIPS]: searchChips,
  [Types.SET_CHIPS]: setChips,
  [Types.SET_CHIPS_SELECTED]: setChipsSelected
});
