import SimpleEncryptor from "simple-encryptor";

const encDec = SimpleEncryptor(process.env.REACT_APP_APP_SECRET);

export default class Storage {
  constructor() {
    this.prefix = "@tcs_octs_";
  }

  check() {
    if(this.get("session") === null) {
      return false;
    }
    return true;
  }

  get(key) {
    key = `${this.prefix}${key}`;
    return (localStorage.getItem(key) !== null && JSON.parse(encDec.decrypt(localStorage.getItem(key))) !== null) ? JSON.parse(encDec.decrypt(localStorage.getItem(key))).value : null;
  }

  remove(key) {
    key = `${this.prefix}${key}`;
    localStorage.removeItem(key);
    const e = new CustomEvent("@tcs_octs_storage@", { detail: { key, value: false } });
    return window.dispatchEvent(e);
  }

  set(key, value) {
    key = `${this.prefix}${key}`;
    localStorage.setItem(key, encDec.encrypt(JSON.stringify({ value })));
    const e = new CustomEvent("@tcs_octs_storage@", { detail: { key, value: true } });
    return window.dispatchEvent(e);
  }
}
