import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "./../../../utils/Date";

const DEFAULT_ZINDEX = 1000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 0.65;

const Exported = ({ onClick, exPosition, exType, profile }) => {
  const { t } = useTranslation();
  const [exportedHover, setExportedHover] = useState(false);
  const [popupShow, setPopupShow] = useState(false);
  const [transhipmentChargeShow, setTranshipmentChargeShow] = useState(false);
  const [transhipmentDischargeShow, setTranshipmentDischargeShow] = useState(false);

  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(exportedHover || popupShow ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) - (popupShow ? 20 : 0) + (exportedHover ? DEFAULT_ZINDEX : 0));
  }, [exportedHover, popupShow, scale]);

  const getExportedClusterType = () => {
    const exTypes = [
      {
        id: "maintenance",
        title: () => t("Title.Niple.Maintenance"),
        icon: () => <span>M</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("Title.Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(exPosition.tracker.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              <li>
                <div>{t("Title.Coordinate")}:</div>
                <div><span>{exPosition.tracker.latitude} | {exPosition.tracker.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Operation")}:</div>
                <div><span>{exPosition.vehicle.operation}</span></div>
              </li>
              <li>
                <div>{t("Title.Response")}:</div>
                <div><span>{exPosition.response.message}</span></div>
              </li>
            </ul>
          </>
        )
      },
      {
        id: "moving",
        title: () => t("Title.Moving"),
        icon: () => <span>E</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("Title.Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(exPosition.tracker.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              <li>
                <div>{t("Title.Coordinate")}:</div>
                <div><span>{exPosition.tracker.latitude} | {exPosition.tracker.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Operation")}:</div>
                <div><span>{exPosition.vehicle.operation}</span></div>
              </li>
              <li>
                <div>{t("Title.Response")}:</div>
                <div><span>{exPosition.response.message}</span></div>
              </li>
            </ul>
          </>
        )
      },
      {
        id: "nipleFraud",
        title: () => t("Title.Niple.Fraud"),
        icon: () => <span>B</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("Title.Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(exPosition.tracker.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              <li>
                <div>{t("Title.Coordinate")}:</div>
                <div><span>{exPosition.tracker.latitude} | {exPosition.tracker.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Operation")}:</div>
                <div><span>{exPosition.vehicle.operation}</span></div>
              </li>
              <li>
                <div>{t("Title.Response")}:</div>
                <div><span>{exPosition.response.message}</span></div>
              </li>
            </ul>
          </>
        )
      },
      {
        id: "nipleDischarge",
        title: () => t("Title.Niple.Discharge"),
        icon: () => <span>D</span>,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("Title.Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(exPosition.tracker.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              <li>
                <div>{t("Title.Coordinate")}:</div>
                <div><span>{exPosition.tracker.latitude} | {exPosition.tracker.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Operation")}:</div>
                <div><span>{exPosition.vehicle.operation}</span></div>
              </li>
              <li>
                <div>{t("Title.Response")}:</div>
                <div><span>{exPosition.response.message}</span></div>
              </li>
            </ul>
          </>
        )
      },
      {
        id: "route",
        title: () => t("Title.Route"),
        icon: () => <i className="fas fa-map-marked-alt" />,
        content: () => (
          <>
            <ul>
              <li>
                <div>{t("Title.Date")}:</div>
                <div><span>{DateUtil.to({ date: new Date(exPosition.tracker.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
              </li>
              <li>
                <div>{t("Title.Coordinate")}:</div>
                <div><span>{exPosition.tracker.latitude} | {exPosition.tracker.longitude}</span></div>
              </li>
              <li>
                <div>{t("Title.Operation")}:</div>
                <div><span>{exPosition.vehicle.operation}</span></div>
              </li>
              <li>
                <div>{t("Title.Response")}:</div>
                <div><span>{exPosition.response.message}</span></div>
              </li>
            </ul>
          </>
        )
      }
    ];

    const exTypeFilter = exTypes.filter(x => x.id === exType());
    if(exTypeFilter.length > 0) {
      return exTypeFilter[0];
    }
    return false;
  };

  const exportedDetails = getExportedClusterType();
  const transform = exType() === "course" ? `rotate(${exPosition.gps.course}deg)` : "rotate(0deg)";

  const popup = (
    <div
      id={`exported-marker-popup-${exPosition.index}`}
      className="marker-exported-popup"
      onClick={e => e.stopPropagation()}
    >
      <h3 className="mep-title">{exportedDetails && exportedDetails.title()}</h3>
      <div className="mep-content">{exportedDetails && exportedDetails.content()}</div>
    </div>
  );

  return (
    <div
      className="marker-exported-all"
      onClick={e => {
        setPopupShow(!popupShow);
        setTranshipmentChargeShow(false);
        setTranshipmentDischargeShow(false);
      }}
      onMouseEnter={e => setExportedHover(true)}
      onMouseLeave={e => setExportedHover(false)}
      style={{
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-exported">
        <div className="icon" style={{
          transform,
          MozTransform: transform,
          WebkitTransform: transform }}>
          {
            exportedDetails && exType() !== "speed" && exportedDetails.icon()
          }
        </div>
        {popupShow && !transhipmentChargeShow && !transhipmentDischargeShow && popup}
      </div>
    </div>
  );
};

export default memo(Exported);
