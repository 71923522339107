import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { PinBoardBackdrop, PinBoardContainer, PinBoardContent, PinBoardContentAction, PinBoardContentKeyBoard, PinBoardContentValue } from "./styles";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHandleReset } from "../../hooks/Menu";

import { Creators as MenusActions } from "./../../store/ducks/menus";

const MAX_LENGTH = 4;

const PinBoard = React.forwardRef(({ description, handleCancel, handleExecute, onlyConfirm, ...props }, ref) => {
  const { t } = useTranslation();
  const handleResetMenu = useHandleReset();
  const dispatch = useDispatch();
  const [descriptionError, setDescriptionError] = useState(false);
  const [passwordPin, setPasswordPin] = useState("");
  const [running, setRunning] = useState(false);
  const [runningClose, setRunningClose] = useState(false);

  useEffect(() => {
    if (!onlyConfirm && isSubmit()) {
      handleSubmit();
    }
  }, [passwordPin]); //eslint-disable-line

  const handleSubmit = async () => {
    if (!running) {
      setRunning(true);
      try {
        if (typeof handleExecute !== "undefined") {
          const response = await handleExecute({ passwordPin });
          if (!response.success) {
            setDescriptionError(t("Error.User.WrongPasswordPinError"));
          }
          else {
            handleSubmitCancel();
          }
        }
      } catch(error) {
        console.log(error);
      }
    }
    setRunning(false);
    handleResetPasswordPin();
  };

  const handleSubmitOnlyConfirm = async () => {
    if (!running) {
      setRunning(true);
      try {
        if (typeof handleExecute !== "undefined") {
          const response = await handleExecute({ passwordPin });
          if (response.success) {
            handleSubmitCancel();
          }
        }
      } catch(error) {
        console.log(error);
      }
    }
    setRunning(false);
  };

  const handleSubmitCancel = async () => {
    if (!runningClose) {
      setRunning(true);
      try {
        if (typeof handleCancel !== "undefined") {
          await handleCancel();
        }
      } catch(error) {
        console.log(error);
      }
    }
    setRunningClose(false);
  };

  const isCheckedPinDisplay = (key) => {
    if ((key + 1) <= passwordPin.length) {
      return true;
    }
    return false;
  };

  const isEnableAddNumber = () => {
    if (passwordPin.length < MAX_LENGTH && !running) {
      return true;
    }
    return false;
  };

  const isEnableCancelNumber = () => {
    if (!running) {
      return true;
    }
    return false;
  };

  const isEnableDelNumber = () => {
    if (passwordPin.length > 0 && !running) {
      return true;
    }
    return false;
  };

  const isSubmit = () => {
    if (!isEnableAddNumber()) {
      return true;
    }
    return false;
  };

  const handleAddNumberPasswordPin = (number) => {
    if (isEnableAddNumber()) {
      setPasswordPin(oldPasswordPin => String(`${oldPasswordPin}${number}`));
      if (descriptionError) {
        setDescriptionError(false);
      }
    }
  };

  const handleDelNumberPasswordPin = () => {
    if (isEnableDelNumber()) {
      setPasswordPin(oldPasswordPin => String(oldPasswordPin).substring(0, oldPasswordPin.length-1));
      if (descriptionError) {
        setDescriptionError(false);
      }
    }
  };

  const handleResetPasswordPin = () => {
    if (isEnableDelNumber()) {
      setPasswordPin("");
    }
  };

  return (
    <PinBoardContainer>
      <PinBoardContent>
        <PinBoardContentValue>
          {
            (!onlyConfirm && (
              <ul>
                {
                  Array.from({ length: MAX_LENGTH }, (v, k) => (
                    <li key={`pin-display-${k}`}>
                      <input className="form-control" name={`pin-cod-${k}`} type="radio" checked={isCheckedPinDisplay(k)} readOnly/>
                    </li>
                  ))
                }
              </ul>
            ))
          }
        </PinBoardContentValue>
        <PinBoardContentAction>
          <ul>
            <li>{description}</li>
            {
              (running && (
                <li>{t("Title.Waiting")}...</li>
              ))
            }
            {
              (descriptionError && (
                <li style={{ color: "#ff0000" }}>{descriptionError}</li>
              ))
            }
          </ul>
        </PinBoardContentAction>
        <PinBoardContentKeyBoard>
          <Container>
            {
              (!onlyConfirm && (
                <Row>
                  {
                    Array.from({ length: 9 }, (v, k) => (
                      <Col xs={4} key={`pin-keyboard-${k}`}>
                        <button type="button" onClick={() => handleAddNumberPasswordPin(k + 1)} disabled={!isEnableAddNumber()}>{k + 1}</button>
                      </Col>
                    ))
                  }
                  <Col xs={4}>
                    <button type="button" onClick={handleSubmitCancel} disabled={!isEnableCancelNumber()}>
                      <i className="fas fa-times" />
                    </button>
                  </Col>
                  <Col xs={4}>
                    <button type="button" onClick={() => handleAddNumberPasswordPin(0)} disabled={!isEnableAddNumber()}>0</button>
                  </Col>
                  <Col xs={4}>
                    <button type="button" onClick={handleDelNumberPasswordPin} disabled={!isEnableDelNumber()}>
                      <i className="fa fa-delete-left" />
                    </button>
                  </Col>
                  <Col xs={12}>
                    <button type="button" onClick={() => {
                      handleResetMenu();
                      dispatch(MenusActions.menuProfilesHandleOpen("mpr-option-password-pin"));
                      dispatch(MenusActions.menuHandleClose());
                      handleSubmitCancel();
                    }}>
                      {t("Title.Pin.Config")}
                    </button>
                  </Col>
                </Row>
              ))
            }
            {
              (onlyConfirm && (
                <Row>
                  <Col xs={12}>
                    <button className="confirm" type="button" onClick={handleSubmitOnlyConfirm}>
                      {t("Title.Confirm")}
                    </button>
                  </Col>
                  <Col xs={12}>
                    <button type="button" onClick={handleSubmitCancel}>
                      {t("Title.Cancel")}
                    </button>
                  </Col>
                </Row>
              ))
            }
          </Container>
        </PinBoardContentKeyBoard>
      </PinBoardContent>
      <PinBoardBackdrop />
    </PinBoardContainer>
  );
});

export default PinBoard;