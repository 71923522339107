import React, { memo } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import "./index.css";

const MenuSingleRouteItem = ({ getCount, icon, id, onClick, selected, title, parameter }) => {
  return (
    <li>
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip>
            {title}
            {getCount ? ` (${getCount()})` : null}
          </Tooltip>
        }
      >
        <>
          {
            (selected && selected() && parameter && (
              <button
                className={`msr-rp-icon-parameter ${parameter.selected && parameter.selected() ? "active" : ""}`}
                type="button"
                onClick={parameter.onClick}>
                  {parameter.icon}
              </button>
            ))
          }
          <button
            className={`msr-rp-icon ${selected && selected() ? "active" : ""} ${id}`}
            type="button"
            onClick={onClick}>
            {icon}
            {getCount && getCount() > 0 ? <div className="msr-rp-icon-count">{getCount()}</div> : null}
          </button>
        </>
      </OverlayTrigger>
    </li>
  );
};

export default memo(MenuSingleRouteItem);
