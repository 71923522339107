import React, { memo, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isCellphone } from "./../../utils/Cellphone";
import { jsonToForm } from "./../../utils/Client";
import { isCNPJ } from "./../../utils/CNPJ";
import { isCPF } from "./../../utils/CPF";
import { isEmail } from "./../../utils/Email";
import { isEmpty } from "./../../utils/String";
import { isValidForm } from "./../../utils/Form";
import { isZipCode } from "./../../utils/ZipCode";
import { clearObject, mergeObject } from "./../../utils/Object";
import DateUtil from "./../../utils/Date";

import CityService from "./../../services/City";
import ClientService from "./../../services/Client";
import ClientSchema from "./../../services/Client/Schema";
import ZipCodeService from "./../../services/ZipCode";

const ACTIVE_KEY_ADDRESS = "1";
const ACTIVE_KEY_CONTACT = "2";
const ACTIVE_KEY_GENERAL_DATA = "0";

const Client = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const cityService = new CityService();
  const clientService = new ClientService();
  const zipCodeService = new ZipCodeService();

  const {
    client: __client = {},
    close: __close = () => {}
  } = options;

  const [client, setClient] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(ClientSchema)), __client)));

  const [activeKey, setActiveKey] = useState(ACTIVE_KEY_GENERAL_DATA);

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [citySelectedOption, setCitySelectedOption] = useState(client.address.city.id === "" ? null : {
    dataAux: {
      id: client.address.city.id,
      externalId: client.address.city.externalId,
      name: client.address.city.name.toUpperCase(),
      country: client.address.country,
      state: client.address.state
    },
    label: `${client.address.city.name.toUpperCase()} - ${client.address.state.initials.toUpperCase()}`,
    value: client.address.city.externalId
  });

  const [running, setRunning] = useState(false);

  const [typeOptions] = useState([
    { label: t("Legal"), value: "LEG" },
    { label: t("Personal"), value: "PER" }
  ]);

  const [zipCodeTimeout, setZipCodeTimeout] = useState(0);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const cityLoadOptions = (e, c) => {
    cityLoadOptionsDebounce(e, c);
  };

  const cityLoadOptionsDebounce = debounce(async (e, c) => {
    const cities = await cityService.findByName({ name: e });
    c(filterCity(cities));
  }, 1000);

  const changeActiveKey = (e) => {
    if(activeKey === e) {
      return setActiveKey("-1");
    }
    setActiveKey(e);
  };

  const filterCity = (cities) => {
    return cities.map(e => {
      return {
        dataAux: e,
        label: `${e.name.toUpperCase()} - ${e.state.initials.toUpperCase()}`,
        value: e.externalId
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        if(eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.Client"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await clientService.create({
            data: handleSubmitForm({ client }),
            token: currentUser.token
          });
        }
        else {
          response = await clientService.update({
            id: client.id,
            data: handleSubmitForm({ client }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Client"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Client"),
            content: t(`Error.Client.${response.error.type}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ chip }) => {
    let newClient = Object.assign({}, JSON.parse(JSON.stringify(client)));
    newClient = clearObject({ data: newClient });
    if(isLegal()) {
      newClient.personal = undefined;
      if(typeof newClient.legal.foundationDate !== "undefined") {
        newClient.legal.foundationDate = DateUtil.toDb({ date: newClient.legal.foundationDate });
      }
    }
    else {
      newClient.legal = undefined;
      if(typeof newClient.personal.birthDate !== "undefined") {
        newClient.personal.birthDate = DateUtil.toDb({ date: newClient.personal.birthDate });
      }
    }
    newClient.id = undefined;
    return newClient;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return client.id === "";
  };

  const isLegal = () => {
    return client.type === "LEG";
  };

  const isLegalResponsibleCPF = () => {
    if (isLegal()) {
      if (typeof client.legal.responsible !== "undefined" && isEmpty(client.legal.responsible.cnpj)) {
        return true;
      }
    }
    return false;
  };

  const isLegalResponsibleCNPJ = () => {
    if (isLegal()) {
      if (typeof client.legal.responsible !== "undefined" && isEmpty(client.legal.responsible.cpf)) {
        return true;
      }
    }
    return false;
  };

  const isPersonal = () => {
    return client.type === "PER";
  };

  return (
    <Form id="forms-client" noValidate onSubmit={handleSubmit}>
      <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-active">
                    <Form.Label>{t("Label.Active")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const active = e.value;
                        setClient(prevState => ({
                          ...prevState,
                          active
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === client.active)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-type">
                    <Form.Label>{t("Label.Type")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const type = e.value;
                        setClient(prevState => ({
                          ...prevState,
                          type
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={typeOptions}
                      value={typeOptions.find(x => x.value === client.type)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className={`${isLegal() ? "" : "hide"}`}>
                <Row>
                  <Col>
                    <Form.Group controlId="forms-client-legal-social-reason">
                      <Form.Label>{t("Label.SocialReason")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.SocialReason")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const socialReason = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              socialReason: socialReason.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={client.legal.socialReason}
                        isValid={isLegal() ? !isEmpty(client.legal.socialReason) : false}
                        isInvalid={isLegal() ? isEmpty(client.legal.socialReason) : false}
                        required={isLegal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.SocialReason")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-legal-fantasy-name">
                      <Form.Label>{t("Label.FantasyName")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.FantasyName")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const fantasyName = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              fantasyName: fantasyName.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={client.legal.fantasyName}
                        isValid={isLegal() ? !isEmpty(client.legal.fantasyName) : false}
                        isInvalid={isLegal() ? isEmpty(client.legal.fantasyName) : false}
                        required={isLegal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.FantasyName")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-legal-cnpj">
                      <Form.Label>{t("Label.CNPJ")}:</Form.Label>
                      <InputMask
                        id="forms-client-legal-cnpj"
                        className={`form-control ${isLegal() ? (!isEmpty(client.legal.cnpj) && isCNPJ(client.legal.cnpj) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Label.CNPJ")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const cnpj = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              cnpj
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        mask="99.999.999/9999-99"
                        autoComplete="off"
                        defaultValue={client.legal.cnpj}
                        required={isLegal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        { isEmpty(client.legal.cnpj) ? t("Feedback.CNPJ") : t("Feedback.Format.CNPJ") }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-legal-state-registration">
                      <Form.Label>{t("Label.StateRegistration")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.StateRegistration")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const stateRegistration = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              stateRegistration
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        defaultValue={client.legal.stateRegistration}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-personal-foundation-date">
                      <Form.Label>{t("Label.FoundationDate")}:</Form.Label>
                      <InputMask
                        id="forms-client-personal-foundation-date"
                        className={`form-control ${isLegal() && !isEmpty(client.legal.foundationDate) ? (DateUtil.isValid({ date: DateUtil.toDb({ date: client.legal.foundationDate }) }) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.FoundationDate")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const foundationDate = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              foundationDate
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        mask="99/99/9999"
                        autoComplete="off"
                        defaultValue={client.legal.foundationDate}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.Format.FoundationDate")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="forms-client-legal-responsible-name">
                      <Form.Label>{t("Label.ResponsibleName")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.ResponsibleName")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const name = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              responsible: {
                                ...prevState.legal.responsible,
                                name: name.toUpperCase()
                              }
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={client.legal.responsible.name}
                        isValid={isLegal() ? !isEmpty(client.legal.responsible.name) : false}
                        isInvalid={isLegal() ? isEmpty(client.legal.responsible.name) : false}
                        required={isLegal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.ResponsibleName")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="forms-client-legal-responsible-cnpj">
                      <Form.Label>{t("Label.ResponsibleCNPJ")}:</Form.Label>
                      <InputMask
                        id="forms-client-legal-responsible-cnpj"
                        className={`form-control ${isLegal() && isLegalResponsibleCNPJ() ? (!isEmpty(client.legal.responsible.cnpj) && isCNPJ(client.legal.responsible.cnpj) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.ResponsibleCNPJ")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const cnpj = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              responsible: {
                                ...prevState.legal.responsible,
                                cnpj
                              }
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        mask="99.999.999/9999-99"
                        autoComplete="off"
                        defaultValue={client.legal.responsible.cnpj}
                        required={isLegal() && isLegalResponsibleCNPJ() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        { isEmpty(client.legal.responsible.cnpj) ? t("Feedback.ResponsibleCNPJ") : t("Feedback.Format.ResponsibleCNPJ") }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="forms-client-legal-responsible-cpf">
                      <Form.Label>{t("Label.ResponsibleCPF")}:</Form.Label>
                      <InputMask
                        id="forms-client-legal-responsible-cpf"
                        className={`form-control ${isLegal() && isLegalResponsibleCPF() ? (!isEmpty(client.legal.responsible.cpf) && isCPF(client.legal.responsible.cpf) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.ResponsibleCPF")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const cpf = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            legal: {
                              ...prevState.legal,
                              responsible: {
                                ...prevState.legal.responsible,
                                cpf
                              }
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        mask="999.999.999-99"
                        autoComplete="off"
                        defaultValue={client.legal.responsible.cpf}
                        required={isLegal() && isLegalResponsibleCPF() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        { isEmpty(client.legal.responsible.cpf) ? t("Feedback.ResponsibleCPF") : t("Feedback.Format.ResponsibleCPF") }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className={`${isPersonal() ? "" : "hide"}`}>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-personal-name">
                      <Form.Label>{t("Label.Name")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.Name")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const name = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            personal: {
                              ...prevState.personal,
                              name: name.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={client.personal.name}
                        isValid={isPersonal() ? !isEmpty(client.personal.name) : false}
                        isInvalid={isPersonal() ? isEmpty(client.personal.name) : false}
                        required={isPersonal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-personal-cpf">
                      <Form.Label>{t("Label.CPF")}:</Form.Label>
                      <InputMask
                        id="forms-client-personal-cpf"
                        className={`form-control ${isPersonal() ? (!isEmpty(client.personal.cpf) && isCPF(client.personal.cpf) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.CPF")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const cpf = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            personal: {
                              ...prevState.personal,
                              cpf
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        mask="999.999.999-99"
                        autoComplete="off"
                        defaultValue={client.personal.cpf}
                        required={isPersonal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {
                          isEmpty(client.personal.cpf) ? t("Feedback.CPF") : t("Feedback.Format.CPF")
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-personal-general-registration">
                      <Form.Label>{t("Label.GeneralRegistration")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.GeneralRegistration")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const generalRegistration = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            personal: {
                              ...prevState.personal,
                              generalRegistration
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        defaultValue={client.personal.generalRegistration}
                        isValid={isPersonal() ? !isEmpty(client.personal.generalRegistration) : false}
                        isInvalid={isPersonal() ? isEmpty(client.personal.generalRegistration) : false}
                        required={isPersonal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.GeneralRegistration")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-client-personal-birth-date">
                      <Form.Label>{t("Label.BirthDate")}:</Form.Label>
                      <InputMask
                        id="forms-client-personal-birth-date"
                        className={`form-control ${isPersonal() ? (!isEmpty(client.personal.birthDate) && DateUtil.isValid({ date: DateUtil.toDb({ date: client.personal.birthDate }) }) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.BirthDate")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const birthDate = e.target.value;
                          setClient(prevState => ({
                            ...prevState,
                            personal: {
                              ...prevState.personal,
                              birthDate
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        mask="99/99/9999"
                        autoComplete="off"
                        defaultValue={client.personal.birthDate}
                        required={isPersonal() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {
                          isEmpty(client.personal.birthDate) ? t("Feedback.BirthDate") : t("Feedback.Format.BirthDate")
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_ADDRESS} onClick={e => changeActiveKey(ACTIVE_KEY_ADDRESS)}>{t("Title.Address")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_ADDRESS}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-address-zip-code">
                    <Form.Label>{t("Label.ZipCode")}:</Form.Label>
                    <InputMask
                      id="forms-client-address-zip-code"
                      className={`form-control ${!isEmpty(client.address.zipCode) && isZipCode(client.address.zipCode) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder={t("Placeholder.ZipCode")}
                      disabled={inputDisabled()}
                      onChange={async e => {
                        const zipCode = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            zipCode
                          }
                        }));

                        setZipCodeTimeout(clearTimeout(zipCodeTimeout));
                        setZipCodeTimeout(setTimeout(async () => {
                          const zpData = await zipCodeService.findZipCode({ zipCode });
                          if(zpData) {
                            setClient(prevState => ({
                              ...prevState,
                              address: {
                                ...prevState.address,
                                city: {
                                  externalId: zpData.dataAux.externalId,
                                  id: zpData.dataAux.id,
                                  name: zpData.dataAux.name.toUpperCase()
                                },
                                country: {
                                  id: zpData.dataAux.country.id,
                                  initials: zpData.dataAux.country.initials.toUpperCase(),
                                  name: zpData.dataAux.country.name.toUpperCase()
                                },
                                complement: zpData.complemento.toUpperCase(),
                                neighborhood: zpData.bairro.toUpperCase(),
                                state: {
                                  id: zpData.dataAux.state.id,
                                  externalId: zpData.dataAux.state.externalId,
                                  initials: zpData.dataAux.state.initials.toUpperCase(),
                                  name: zpData.dataAux.state.name.toUpperCase()
                                },
                                street: zpData.logradouro.toUpperCase()
                              }
                            }));
                            setCitySelectedOption({ dataAux: zpData.dataAux, label: `${zpData.dataAux.name.toUpperCase()} - ${zpData.dataAux.state.initials.toUpperCase()}`, value: zpData.dataAux.externalId });
                          }
                        }, 500));
                      }}
                      dataeventkey={ACTIVE_KEY_ADDRESS}
                      mask="99999-999"
                      autoComplete="off"
                      defaultValue={client.address.zipCode}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.ZipCode")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-address-city" className={`${!isEmpty(client.address.city.externalId) ? "" : "invalid"}`}>
                    <Form.Label>{t("Title.City")}:</Form.Label>
                    <AsyncSelect
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled()}
                      loadOptions={cityLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        const { dataAux, label, value } = e;
                        setCitySelectedOption({ dataAux, label, value });
                        setClient(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            city: {
                              externalId: dataAux.externalId,
                              id: dataAux.id,
                              name: dataAux.name.toUpperCase()
                            },
                            country: {
                              id: dataAux.country.id,
                              initials: dataAux.country.initials.toUpperCase(),
                              name: dataAux.country.name.toUpperCase()
                            },
                            state: {
                              id: dataAux.state.id,
                              externalId: dataAux.state.externalId,
                              initials: dataAux.state.initials.toUpperCase(),
                              name: dataAux.state.name.toUpperCase()
                            }
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_ADDRESS}
                      placeholder={t("Title.City.Select")}
                      value={citySelectedOption}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(client.address.city.externalId) ? "hide" : "show"}`} type="invalid">{t("Feedback.City")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-address-street">
                    <Form.Label>{t("Label.Street")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Street")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const street = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            street: street.toUpperCase()
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_ADDRESS}
                      autoComplete="off"
                      value={client.address.street}
                      isValid={!isEmpty(client.address.street)}
                      isInvalid={isEmpty(client.address.street)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Street")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-address-neighborhood">
                    <Form.Label>{t("Label.Neighborhood")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Neighborhood")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const neighborhood = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            neighborhood: neighborhood.toUpperCase()
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_ADDRESS}
                      autoComplete="off"
                      value={client.address.neighborhood}
                      isValid={!isEmpty(client.address.neighborhood)}
                      isInvalid={isEmpty(client.address.neighborhood)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Neighborhood")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-address-complement">
                    <Form.Label>{t("Label.Complement")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Complement")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const complement = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            complement: complement.toUpperCase()
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_ADDRESS}
                      autoComplete="off"
                      value={client.address.complement}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-address-number">
                    <Form.Label>{t("Label.Number")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Number")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const number = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          address: {
                            ...prevState.address,
                            number: number.toUpperCase()
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_ADDRESS}
                      autoComplete="off"
                      value={client.address.number}
                      isValid={!isEmpty(client.address.number)}
                      isInvalid={isEmpty(client.address.number)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Number")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_CONTACT} onClick={e => changeActiveKey(ACTIVE_KEY_CONTACT)}>{t("Title.Contact")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_CONTACT}>
            <Card.Body>
              <Row>
                <Col>
                  <Form.Group controlId="forms-client-contact-email">
                    <Form.Label>{t("Label.Email")}:</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder={t("Placeholder.Email")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const email = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          contact: {
                            ...prevState.contact,
                            email: email.toLowerCase()
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_CONTACT}
                      autoComplete="off"
                      value={client.contact.email}
                      isValid={!isEmpty(client.contact.email) && isEmail(client.contact.email)}
                      isInvalid={isEmpty(client.contact.email) || !isEmail(client.contact.email)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      { isEmpty(client.contact.email) ? t("Feedback.Email") : t("Feedback.Format.Email") }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-contact-telephone">
                    <Form.Label>{t("Label.Telephone")}:</Form.Label>
                    <InputMask
                      id="forms-client-contact-telephone"
                      className={`form-control ${!isEmpty(client.contact.telephone) ? (isCellphone(client.contact.telephone) ? "is-valid" : "is-invalid") : ""}`}
                      type="text"
                      placeholder={t("Placeholder.Telephone")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const telephone = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          contact: {
                            ...prevState.contact,
                            telephone
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_CONTACT}
                      mask="(99) 999999999"
                      autoComplete="off"
                      defaultValue={client.contact.telephone}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Format.Telephone")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-client-contact-cellphone">
                    <Form.Label>{t("Label.Cellphone")}:</Form.Label>
                    <InputMask
                      id="forms-client-contact-cellphone"
                      className={`form-control ${!isEmpty(client.contact.cellphone) ? (isCellphone(client.contact.cellphone) ? "is-valid" : "is-invalid") : ""}`}
                      type="text"
                      placeholder={t("Placeholder.Cellphone")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const cellphone = e.target.value;
                        setClient(prevState => ({
                          ...prevState,
                          contact: {
                            ...prevState.contact,
                            cellphone
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_CONTACT}
                      mask="(99) 999999999"
                      autoComplete="off"
                      defaultValue={client.contact.cellphone}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Format.Cellphone")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Client));
