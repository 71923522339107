import React from "react";
import "./index.css";

const LastNotificationListItem = ({ id, onClick, notification, selected }) => {
  return (
    <li className={`last-notification-list-item ${selected ? "active" : ""}`} onClick={onClick}>
      <ul>
        <li>
          <div><span><strong>{notification.title}</strong></span></div>
        </li>
        <li>
          <div><span>{notification.body}</span></div>
        </li>
      </ul>
    </li>
  );
};

export default LastNotificationListItem;
