import { createActions, createReducer } from "reduxsauce";
import {
  singleRouteNipleDischarge,
  singleRouteNipleFraud,
  singleRouteMaintenance,
  singleRouteMoving
} from "./../../utils/Position";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addSingleExported: ["end", "options", "patrimony", "route", "start"],
  closeMenuPositions: [],
  openMenuPositions: [],
  removeSingleExported: [],
  selectMenuSingleExported: ["id"],
  addSingleExportedMenuPositionsMarkers: ["position"],
  removeSingleExportedMenuPositionsMarkers: ["position"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  singleExported: false,
  singleExportedStartEnd: false,
  singleExportedMenu: false,
  singleExportedMenuSelected: false,
  singleExportedMenuPositionsSelected: false,
  singleExportedMenuPositionsMarkersSelected: []
};

const addSingleExported = (state = INITIAL_STATE, action) => {
  const { end, options, patrimony, route, start } = action;
  return {
    ...state,
    singleExported: {
      orderAsc: options.orderAsc,
      patrimony,
      page: options.page,
      pages: options.pages,
      route
    },
    singleExportedStartEnd: {
      end,
      start
    },
    singleExportedMenu: {
      maintenance: singleRouteMaintenance(route, true),
      moving: singleRouteMoving(route, true),
      nipleDischarge: singleRouteNipleDischarge(route, true),
      nipleFraud: singleRouteNipleFraud(route, true),
      route: []
    },
    singleExportedMenuSelected: {
      id: "route",
      value: []
    },
    singleExportedMenuPositionsMarkersSelected: []
  };
};

const addSingleExportedMenuPositionsMarkers = (state = INITIAL_STATE, action) => {
  const { position } = action;
  const singleExportedMenuPositionsMarkersSelected = state.singleExportedMenuPositionsMarkersSelected;
  if(singleExportedMenuPositionsMarkersSelected.findIndex(x => x.id === position.id) === -1) {
    singleExportedMenuPositionsMarkersSelected.push(position);
  }
  return {
    ...state,
    singleExportedMenuPositionsMarkersSelected
  };
};

const removeSingleExportedMenuPositionsMarkers = (state = INITIAL_STATE, action) => {
  const { position } = action;
  const singleExportedMenuPositionsMarkersSelected = state.singleExportedMenuPositionsMarkersSelected;
  const index = singleExportedMenuPositionsMarkersSelected.findIndex(x => x.id === position.id);
  if(index !== -1) {
    singleExportedMenuPositionsMarkersSelected.splice(index, 1);
  }
  return {
    ...state,
    singleExportedMenuPositionsMarkersSelected
  };
};

const closeMenuPositions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleExportedMenuPositionsSelected: false,
    singleExportedMenuPositionsMarkersSelected: []
  };
};

const openMenuPositions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleExportedMenuPositionsSelected: true,
    singleExportedMenuPositionsMarkersSelected: []
  };
};

const removeSingleExported = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleExported: false,
    singleExportedStartEnd: false,
    singleExportedMenu: false,
    singleExportedMenuSelected: false,
    singleExportedMenuPositionsSelected: false,
    singleExportedMenuPositionsMarkersSelected: []
  };
};

const selectMenuSingleExported = (state = INITIAL_STATE, action) => {
  const { id } = action;
  return {
    ...state,
    singleExportedMenuSelected: {
      id,
      value: state.singleExportedMenu[id]
    },
    singleExportedMenuPositionsMarkersSelected: []
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_SINGLE_EXPORTED]: addSingleExported,
  [Types.CLOSE_MENU_POSITIONS]: closeMenuPositions,
  [Types.OPEN_MENU_POSITIONS]: openMenuPositions,
  [Types.REMOVE_SINGLE_EXPORTED]: removeSingleExported,
  [Types.SELECT_MENU_SINGLE_EXPORTED]: selectMenuSingleExported,
  [Types.ADD_SINGLE_EXPORTED_MENU_POSITIONS_MARKERS]: addSingleExportedMenuPositionsMarkers,
  [Types.REMOVE_SINGLE_EXPORTED_MENU_POSITIONS_MARKERS]: removeSingleExportedMenuPositionsMarkers
});
