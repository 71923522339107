import Api from "../Api";
import DateUtil from "./../../utils/Date";

export default class PatrimonyTest {
  async conclude({ id, data, token }) {
    const response = await Api.put(`/patrimony-test/conclude/${id}`, data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async create({ data, token }) {
    const response = await Api.post("/patrimony-test", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async findAllGrid({ data, token }) {
    const response = await Api.post("/patrimony-test/find-all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return this.findAllGridTranslate({ data: response.data });
    }
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [{
      width: "20%",
      type: "string",
      label: "Title.Patrimony"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Installer"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Date.Start"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Date.End"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Status"
    }];
    data.rows = [];
    data.docs.map(item => {

      const status = [{
        key: "CON",
        value: "%{Title.Concluded}%"
      }, {
        key: "RUN",
        value: "%{Title.Running}%"
      }, {
        key: "WAI",
        value: "%{Title.Waiting}%"
      }];

      let statusNow = status.find(x => x.key === item.status);
      if(typeof statusNow === "undefined") {
        statusNow = status[2];
      }

      const itemAux = [{
        id: item.id,
        value: this.findAllGridTranslateItem({ data: item, item: "patrimony" }) !== undefined ? item.patrimony.description : ""
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "user" }) !== undefined ? item.user.name : ""
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "startAt" }) !== undefined ? DateUtil.to({ date: new Date(item.startAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : ""
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "endAt" }) !== undefined ? DateUtil.to({ date: new Date(item.endAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : ""
      }, {
        value: statusNow.value
      }];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async findById({ id, token }) {
    const response = await Api.get(`/patrimony-test/${id}`, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data;
    }
    return false;
  }

  async update({ id, data, token }) {
    const response = await Api.put(`/patrimony-test/${id}`, data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
