import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";
import { filterByPatrimonyAndWord, sortExporterErrors } from "./../../utils/Exporter";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastExporterError: ["lastExporterError"],
  updateLastExporterError: ["id", "lastExporterError"],
  searchLastExporterError: ["search"],
  selectLastExporterError: ["id"],
  setLastExporterError: ["lastExporterErrors"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  exporterErrors: [],
  exporterErrorsSearch: [],
  exporterErrorsSearchQuery: {
    patrimony: "",
    word: "",
    niple: true
  },
  exporterErrorsSearchQueryEnabled: true,
  exporterErrorSelected: false,
  exporterErrorsLoad: false
};

const format = (lastExporterError) => {
  const copy = JSON.parse(JSON.stringify(lastExporterError));
  return copy;
};

const add = (state = INITIAL_STATE, action) => {
  if(state.exporterErrorsLoad) {
    const { lastExporterError } = action;
    const index = state.exporterErrors.findIndex(x => x.id === lastExporterError.id);
    if(index !== -1) {
      if(lastExporterError.response.status) {
        return {
          ...state,
          exporterErrors: sortExporterErrors(state.exporterErrors.filter(x => x.id !== lastExporterError.id)),
          exporterErrorsSearch: sortExporterErrors(state.exporterErrorsSearch.filter(x => x.id !== lastExporterError.id)),
          exporterErrorSelected: state.exporterErrorSelected.id === lastExporterError.id ? false : state.exporterErrorSelected
        };
      }
      return update(
        state,
        {
          id: lastExporterError.id,
          lastExporterError
        }
      );
    }

    const exporterErrors = state.exporterErrors;
    exporterErrors.push({ id: lastExporterError.id, exporterError: format(lastExporterError), selected: false });

    const { exporterErrorsSearch } = search({
      ...state,
      exporterErrors
    }, { search: state.exporterErrorsSearchQuery });

    return {
      ...state,
      exporterErrors: sortExporterErrors(exporterErrors),
      exporterErrorsSearch: sortExporterErrors(exporterErrorsSearch)
    };
  }
  return state;
};

const update = (state = INITIAL_STATE, action) => {
  if(state.exporterErrorsLoad) {
    const { id, lastExporterError } = action;
    const index = state.exporterErrors.findIndex(x => x.id === id);
    if(index !== -1) {
      let exporterErrorSelected = state.exporterErrorSelected;
      const exporterErrors = state.exporterErrors.map(exporterError => {
        if((exporterError.id === id) && ((DateUtil.diff({ dateA: exporterError.exporterError.tracker.date, dateB: lastExporterError.tracker.date }) < 0) || (DateUtil.diff({ dateA: exporterError.exporterError.tracker.date, dateB: lastExporterError.tracker.date }) === 0 && DateUtil.diff({ dateA: exporterError.exporterError.createdAt, dateB: lastExporterError.createdAt }) <= 0))) {
          if(exporterError.selected) {
            exporterErrorSelected = { ...exporterError, exporterError: format(lastExporterError) };
          }
          return { ...exporterError, exporterError: format(lastExporterError) };
        }
        return { ...exporterError };
      });

      const { exporterErrorsSearch } = search({
        ...state,
        exporterErrors,
        exporterErrorSelected
      }, { search: state.exporterErrorsSearchQuery });

      return {
        ...state,
        exporterErrors: sortExporterErrors(exporterErrors),
        exporterErrorsSearch: sortExporterErrors(exporterErrorsSearch),
        exporterErrorSelected
      };
    }
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: exporterErrorsSearchEnabled, results: exporterErrorsSearchResults } = filterByPatrimonyAndWord(state.exporterErrors, search);
  return {
    ...state,
    exporterErrorsSearch: exporterErrorsSearchResults,
    exporterErrorsSearchQuery: search,
    exporterErrorsSearchQueryEnabled: exporterErrorsSearchEnabled
  };
};

const select = (state = INITIAL_STATE, action) => {
  const { id } = action;
  let exporterErrorSelected = state.exporterErrorSelected;
  const exporterErrors = state.exporterErrors.map(exporterError => {
    if(exporterError.id === id) {
      exporterErrorSelected = false;
      if(!exporterError.selected) {
        exporterErrorSelected = { ...exporterError, selected: !exporterError.selected };
      }
      return { ...exporterError, selected: !exporterError.selected };
    }
    return { ...exporterError, selected: false };
  });
  const exporterErrorsSearch = state.exporterErrorsSearch.map(exporterError => exporterError.id === id ? { ...exporterError, selected: !exporterError.selected } : { ...exporterError, selected: false });
  return {
    ...state,
    exporterErrors,
    exporterErrorsSearch,
    exporterErrorSelected
  };
};

const set = (state = INITIAL_STATE, action) => {
  if(state.exporterErrorsLoad === false) {
    const { lastExporterErrors } = action;
    const exporterErrors = lastExporterErrors.map(lastExporterError => {
      return { id: lastExporterError.id, exporterError: format(lastExporterError), selected: false };
    });

    const { exporterErrorsSearch } = search({
      ...state,
      exporterErrors
    }, { search: state.exporterErrorsSearchQuery });

    return {
      ...state,
      exporterErrors: sortExporterErrors(exporterErrors),
      exporterErrorsSearch,
      exporterErrorsLoad: true
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_EXPORTER_ERROR]: add,
  [Types.UPDATE_LAST_EXPORTER_ERROR]: update,
  [Types.SEARCH_LAST_EXPORTER_ERROR]: search,
  [Types.SELECT_LAST_EXPORTER_ERROR]: select,
  [Types.SET_LAST_EXPORTER_ERROR]: set
});
