import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { getDescriptionClient } from "./../../utils/Client";
import DateUtil from "./../../utils/Date";
import { getOperation } from "./../../utils/Exporter";
import { getDescriptionPatrimony } from "./../../utils/Patrimony";
import { getNiple, getNipleBasic } from "./../../utils/Position";
import { isScopes } from "./../../services/Auth";

const LastExporterErrorListItem = ({
  options: {
    id,
    onClick,
    exporterError,
    selected
  },
  props: {
    currentUser
  }
}) => {
  const { t } = useTranslation();

  let niples = [];
  if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
    const nipleBasic = getNipleBasic(exporterError.position, false);
    niples = nipleBasic.niples;
  }
  else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
    const nipleMaster = getNiple(exporterError.position, false);
    niples = nipleMaster.niples;
  }

  return (
    <li className={`last-exporter-error-list-item ${selected ? "active" : ""}`} onClick={onClick}>
      <ul>
        <li>
          <div>{t("Title.Client")}:</div>
          <div><span><strong>{getDescriptionClient(exporterError.position.patrimony.client)}</strong></span></div>
        </li>
        <li>
          <div>{t("Patrimony")}:</div>
          <div><span><strong>{getDescriptionPatrimony(exporterError.position.patrimony)}</strong></span></div>
        </li>
        <li>
          <div>{t("Date")}:</div>
          <div><span>{DateUtil.to({ date: new Date(exporterError.position.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
        </li>
        <li>
          <div>{t("Address")}:</div>
          <div><span>{exporterError.position.gps.address}</span></div>
        </li>
        <li>
          <div>{t("Title.Niple.Operation")}:</div>
          <div><span>{t(getOperation(exporterError.vehicle.operation))} ({exporterError.vehicle.operation})</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:basic", "read:patrimonies:description"], every: false }) && niples.map((niple, index) => {
            return (
              <li key={`lpl-niple-${index}`}>
                <div>{t("Title.Niple")}:</div>
                <div><span>{t(niple.operation)}</span></div>
              </li>
            );
          })
        }
        <li>
          <div>{t("Speed")}:</div>
          <div><span>{exporterError.position.gps.speed} Km/h</span></div>
        </li>
      </ul>
      <div className={`positions-item-ignition ${exporterError.position.tracker.ignition ? "ignition-on" : "ignition-off"}`}><i className="fas fa-key"></i></div>
    </li>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastExporterErrorListItem);
