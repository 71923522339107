export function getCount(count) {
  if(typeof count !== "undefined" && count !== false && count > 0) {
    if(count < 1000) {
      return count;
    }
    else if(count < 1000000) {
      return `${parseInt(count / 1000)}k`;
    }
    return `${parseInt((count / 1000000))}m`;
  }
  return false;
}