import io from "socket.io-client";

export default class Ws {
  constructor(props: Props) {
    this.socket = false;
    this.connected = false;
    this.callbacks = props.callbacks !== undefined ? props.callbacks : [];
    this.mounted = props.mounted !== undefined ? props.mounted : true;
    this.url = props.url !== undefined ? props.url : process.env.REACT_APP_APP_WS_URL;
  }

  connect(token, fcmToken) {
    if(this.connected === false) {
      this.connected = true;
      this.socket = io(this.url, {
        query: {
          mounted: this.mounted,
          token: fcmToken === false ? 0 : fcmToken,
          user: token === false ? 0 : token
        }
      });
      this.callbacks.map(callback => {
        return this.socket.on(callback.key, callback.action);
      });
    }
  }

  disconnect() {
    if(this.connected === true) {
      this.connected = false;
      this.socket.disconnect();
      this.socket = false;
    }
  }

  getMounted() {
    return this.mounted;
  }

  getUrl() {
    return this.url;
  }

  setMounted(mounted) {
    this.mounted = mounted;
  }

  setUrl(url) {
    this.url = url;
  }
}
