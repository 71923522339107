import React, { memo, useEffect, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "../../store/ducks/menus";

import { isScopes } from "../../services/Auth";
import Grid from "./../Grid";

import MenuCommandsItem from "../MenuCommandsItem";
import MyCommandsCommand from "../../commands/MyCommands";

import CommandForm from "./../../forms/Command";

import CommandService from "./../../services/Command";

const MenuCommands = ({
  props: { currentUser },
  funcs: { menuHandleOpen, menuCommandsHandleClose }
}) => {
  const { t } = useTranslation();
  const [selectedCommand, setSelectedCommand] = useState({ selected: false, options: false });

  useEffect(() => {
    if(selectedCommand.selected) {
      if(selectedCommand.options.scoped(currentUser) === false) changeSelectedCommand(false);
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedCommand = (item) => {
    setSelectedCommand({ selected: false, options: false });
    if (item !== false) setTimeout(() => setSelectedCommand(item), 100);
  };

  const dynamicOptions = [
    // {
    //   id: "mcm-option-patrimony",
    //   title: t("Title.Patrimony"),
    //   icon: <i className="fas fa-car" />,
    //   onClick: () => {
    //     const id = "mcm-option-patrimony";
    //     if (selectedCommand.selected === id) return setSelectedCommand(false);
    //     changeSelectedCommand({
    //       selected: id,
    //       options: {
    //         title: t("Title.Patrimony"),
    //         render: () => <PatrimonyCommand />,
    //         scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:commands:patrimonies"], every: false })
    //       }
    //     });
    //   },
    //   selected: () => selectedCommand.selected === "mcm-option-patrimony",
    //   scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:commands:patrimonies"], every: false })
    // },
    {
      id: "mcm-option-patrimony",
      title: t("Title.Patrimony"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "mcm-option-patrimony";
        if (selectedCommand.selected === id) return setSelectedCommand(false);
        changeSelectedCommand({
          selected: id,
          options: {
            api: new CommandService(),
            render: (options) => <Grid options={options} />,
            actions: [
              {
                content: ({ resetActionsForced }) => <CommandForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:commands", "create:commands:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <CommandForm options={{ command: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:commands", "update:commands:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              }
            ],
            title: t("Title.Patrimony"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:commands:commands"], every: false })
          }
        });
      },
      selected: () => selectedCommand.selected === "mcm-option-patrimony",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:commands:commands"], every: false })
    },
  ];

  const fixedOptions = [
    {
      id: "mcm-option-close",
      title: t("Title.Close"),
      icon: <i className="fas fa-times" />,
      onClick: () => {
        menuCommandsHandleClose();
        menuHandleOpen();
      },
      selected: () => true,
      scoped: () => true
    },
    {
      id: "mcm-option-my-commands",
      title: t("Title.MyCommands"),
      icon: <i className="fas fa-file" />,
      onClick: () => {
        const id = "mcm-option-my-commands";
        if (selectedCommand.selected === id) return setSelectedCommand(false);
        changeSelectedCommand({
          selected: id,
          options: {
            title: t("Title.MyCommands"),
            render: () => <MyCommandsCommand />
          }
        });
      },
      selected: () => selectedCommand.selected === "mcm-option-my-commands",
      scoped: () => true
    }
  ];

  const renderBody = () => {
    if (selectedCommand.selected) return selectedCommand.options.render(selectedCommand.options);
    return null;
  };

  const renderTitle = () => {
    if (selectedCommand.selected) return <h3>{selectedCommand.options.title}</h3>;
    return null;
  };

  return (
    <div className="menu-commands">
      <div className={`mcm-commands-options-absolute ${selectedCommand.selected ? "active" : ""}`}>
        <div className="mcm-commands-options-absolute-content">
          <div className="mcm-moa-content-title">{renderTitle()}</div>
          <div className="mcm-moa-content-body">{renderBody()}</div>
        </div>
      </div>
      <div className="mcm-commands-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if (option.scoped()) return <MenuCommandsItem key={option.id} {...option} onClick={option.onClick} />;
              return null;
            })
          }
        </ul>
      </div>
      <div className="mcm-commands-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if (option.scoped()) return <MenuCommandsItem key={option.id} {...option} onClick={option.onClick} />;
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuCommands));
