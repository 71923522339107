import React, { useEffect, useState } from "react";
import {
  Col,
  Form,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "../../store/ducks/loaders";
import { Creators as PatrimoniesActions } from "../../store/ducks/patrimonies";

import ClientSchemaReduced from "../../services/Client/SchemaReduced";

import PatrimonyService from "../../services/Patrimony";

import FilterPatrimonyItem from "../FilterPatrimonyItem";
import SwitchDefault from "../Switch/Default";

const FilterPatrimonyCommand = ({
  props: {
    currentUser,
    patrimonies,
    patrimoniesSearch,
    patrimoniesSearchOnlyClient,
    patrimoniesSearchQueryEnabled
  },
  funcs: {
    disableLoader,
    enableLoader,
    searchPatrimonies,
    setPatrimonies,
    setPatrimoniesSelected
  },
  options: {
    filterActive,
    filterMultiple,
    filterNiple,
    filterPreSelected,
    client: _client,
    setPatrimoniesSelected: setPatrimoniesSelectedForm
  }
}) => {
  const { t } = useTranslation();
  const patrimonyService = new PatrimonyService();

  const [switchAllChecked, setSwitchAllChecked] = useState(false);
  const [switchSingleChecked, setSwitchSingleChecked] = useState(false);
  const [_patrimoniesSelected, _setPatrimoniesSelected] = useState([]);

  const [client, setClient] = useState(_client ?? null);

  const [_patrimoniesSearch, _setPatrimoniesSearch] = useState({
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: filterActive ? -1 : true
  });

  useEffect(() => {
    setClient(_client);
  }, [_client]);

  useEffect(() => {
    findPatrimonies();
  }, [client]); /* eslint-disable-line */

  useEffect(() => {
    searchPatrimonies(_patrimoniesSearch);
  }, [_patrimoniesSearch]); /* eslint-disable-line */

  useEffect(() => {
    if(!switchSingleChecked) {
      if(switchAllChecked) return _setPatrimoniesSelected(patrimoniesSearchOnlyClient);
      return _setPatrimoniesSelected([]);
    }
    setSwitchSingleChecked(false);
  }, [switchAllChecked]); /* eslint-disable-line */

  useEffect(() => {
    setPatrimoniesSelected(_patrimoniesSelected);
    setPatrimoniesSelectedForm(_patrimoniesSelected);
    const allForClient = patrimonies.filter(x => x.client.id === client).length;
    const allSelectedForClient = _patrimoniesSelected.filter(x => x.client.id === client).length;
    setPatrimoniesSelectedForm(_patrimoniesSelected);
    if(_patrimoniesSelected.length > 0 && (patrimoniesSearchOnlyClient.length === _patrimoniesSelected.length)) return setSwitchAllChecked(true);
    setSwitchAllChecked(false);
  }, [_patrimoniesSelected]); /* eslint-disable-line */

  const findPatrimonies = async () => {
    enableLoader();
    const patrimonies = await patrimonyService.findAll({
      data: {
        limit: 1000,
        page: 1,
        search: ""
      },
      token: currentUser.token
    });
    const patrimoniesFilter = patrimonies.docs.filter(
      (p) => filterNiple ? (
        p.automobile && p.automobile.board && p.automobile.board.type === "ATV"
      ) : true
    );
    setPatrimonies(patrimoniesFilter.filter(x => x.client.id === client));
    if (filterPreSelected) {
      _setPatrimoniesSelected(
        filterPreSelected.length === 0 ? [] : (
          patrimoniesFilter.filter(x => filterPreSelected.findIndex(y => y === x.id) !== -1)
        )
      );
    }
    disableLoader();
  };

  const handleClickPatrimony = (e, patrimony) => {
    if (filterMultiple) return handleClickPatrimonyMultiple(e, patrimony);
    return handleClickPatrimonySingle(e, patrimony);
  };

  const handleClickPatrimonyMultiple = (e, patrimony) => {
    const patrimoniesSelected = _patrimoniesSelected.filter(x => x.id !== patrimony.id);
    if(!e) patrimoniesSelected.push(patrimony);
    setSwitchSingleChecked(true);
    _setPatrimoniesSelected(patrimoniesSelected);
  };

  const handleClickPatrimonySingle = (e, patrimony) => {
    const patrimoniesSelected = [patrimony];
    setSwitchSingleChecked(true);
    _setPatrimoniesSelected(patrimoniesSelected);
  };

  return (
    <>
      <Row>
        <Col>
          <Form.Group controlId="filter-patrimony-search-patrimony">
            <Form.Control
              type="text"
              placeholder={t("Placeholder.Search")}
              autoComplete={"off"}
              onChange={e => {
                e.persist();
                _setPatrimoniesSearch(prevState => ({
                  ...prevState,
                  word: e.target.value
                }));
              }}
              value={_patrimoniesSearch.word}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="filter-patrimony-list-all">
            {
              filterMultiple ? (
                <div className="filter-patrimony-select-all">
                  <SwitchDefault title={`${t("Title.All")} (${patrimoniesSearchQueryEnabled ? patrimoniesSearch.length : patrimonies.length})`} selected={switchAllChecked} onClick={e => {
                    setSwitchSingleChecked(false);
                    setSwitchAllChecked(!switchAllChecked);
                  }} />
                </div>
              ) : null
            }
            <div className="filter-patrimony-list-box">
              <ul>
                {
                  patrimoniesSearchQueryEnabled ? (
                    patrimoniesSearch.map(patrimony => {
                      const selected = _patrimoniesSelected.find(x => x.id === patrimony.id) ? true : false;
                      return (
                        <FilterPatrimonyItem
                          key={`filter-patrimony-list-item-${patrimony.id}`}
                          checkbox={filterMultiple}
                          name={filterMultiple ? `filter-patrimony-list-item-${patrimony.id}` : "filter-patrimony-list-item"}
                          patrimony={patrimony}
                          selected={selected}
                          onClick={e => handleClickPatrimony(selected, patrimony)}
                        />
                      );
                    })
                  ) : (
                    patrimonies.map(patrimony => {
                      const selected = _patrimoniesSelected.find(x => x.id === patrimony.id) ? true : false;
                      return (
                        <FilterPatrimonyItem
                          key={`filter-patrimony-list-item-${patrimony.id}`}
                          checkbox={filterMultiple}
                          name={filterMultiple ? `filter-patrimony-list-item-${patrimony.id}` : "filter-patrimony-list-item"}
                          patrimony={patrimony}
                          selected={selected}
                          onClick={e => handleClickPatrimony(selected, patrimony)}
                        />
                      );
                    })
                  )
                }
              </ul>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    patrimonies: state.patrimonies.patrimonies,
    patrimoniesSearch: state.patrimonies.patrimoniesSearch,
    patrimoniesSearchOnlyClient: state.patrimonies.patrimoniesSearchOnlyClient,
    patrimoniesSearchQueryEnabled: state.patrimonies.patrimoniesSearchQueryEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, PatrimoniesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(FilterPatrimonyCommand);
