import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isValidForm } from "../../utils/Form";
import { clearObject, mergeObject } from "../../utils/Object";
import { isPasswordPin } from "../../utils/Password";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { jsonToForm } from "../../utils/User";

import UserService from "../../services/User";
import UserSchema from "../../services/User/Schema";

const PasswordPin = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const userService = new UserService();

  const {
    user: __user = {}
  } = options;

  const [user, setUser] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(UserSchema)), {
    ...__user,
    passwordPin: ""
  })));

  useEffect(() => {
    const newUser = JSON.parse(JSON.stringify(currentUser));
    setUser({
      ...newUser,
      passwordPin: ""
    });
  }, [currentUser]);

  const [confirmPasswordPin, setConfirmPasswordPin] = useState("");
  const [oldPasswordPin, setOldPasswordPin] = useState(currentUser.passwordPinConfigured ? "" : "0000");
  const [oldPasswordPinChecked, setOldPasswordPinChecked] = useState(currentUser.passwordPinConfigured ? false : true);
  const [oldPasswordPinCheckedCount, setOldPasswordPinCheckedCount] = useState(0);

  const [running, setRunning] = useState(false);

  const buttonNextDisabled = () => {
    if(!running && !isEmpty(oldPasswordPin)) {
      return false;
    }
    return true;
  };

  const buttonDisabled = () => {
    if(!running && oldPasswordPinChecked) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { message, valid } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.User"),
          content: message
        });
      }
      else {
        let response = await userService.update({
          id: user.id,
          data: handleSubmitForm({ user }),
          token: currentUser.token
        });
        if(response.success) {
          addNotice({
            title: t("Title.User"),
            content: t("Success.Update")
          });
          setOldPasswordPin("");
          setOldPasswordPinChecked(false);
          setOldPasswordPinCheckedCount(0);
          setConfirmPasswordPin("");
          setUser(prevState => ({
            ...prevState,
            passwordPin: ""
          }));
        }
        else {
          addNotice({
            title: t("Title.User"),
            content: t(`Error.User.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ user }) => {
    let newUser = Object.assign({}, JSON.parse(JSON.stringify(user)));
    newUser = clearObject({ data: newUser });
    newUser.id = undefined;
    return newUser;
  };

  const inputDisabled = () => {
    if(!running || !oldPasswordPinChecked) {
      return false;
    }
    return true;
  };

  return (
    <div className="user-profiles-password-pin">
      <Form id="forms-profiles-user" noValidate onSubmit={handleSubmit}>
        <Card>
          <Card.Header>{t("Title.GeneralData")}</Card.Header>
          <Card.Body>
            <div className={`${oldPasswordPinChecked ? "hide" : ""}`}>
              <Row>
                <Col>
                  <Form.Group controlId="forms-user-old-password-pin">
                    <Form.Label>{t("Label.OldPasswordPin")}:</Form.Label>
                    <InputMask
                      id="forms-user-old-password-pin"
                      className={`form-control ${(!isEmpty(oldPasswordPin) && isPasswordPin(oldPasswordPin) && oldPasswordPinChecked) ? "is-valid" : ""} ${(isEmpty(oldPasswordPin) || !isPasswordPin(oldPasswordPin) || !oldPasswordPinChecked) ? "is-invalid" : ""}`}
                      type="text"
                      placeholder={t("Placeholder.OldPasswordPin")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const oldPasswordPin = e.target.value;
                        setOldPasswordPin(oldPasswordPin);
                      }}
                      mask="9999"
                      autoComplete="off"
                      value={oldPasswordPin}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {
                        isEmpty(oldPasswordPin) || (!isEmpty(oldPasswordPin) && !isPasswordPin(oldPasswordPin)) || (!oldPasswordPinChecked && oldPasswordPinCheckedCount === 0) ? (
                          t("Feedback.PasswordPin")
                        ) : (
                          t("Error.User.WrongPasswordPinError")
                        )
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="default-form-button">
                    <Button
                      variant="dark"
                      type="button"
                      disabled={buttonNextDisabled()}
                      onClick={async e => {
                        if(!running) {
                          setRunning(true);
                          const oldPasswordPinData = await userService.checkPasswordPin({ data: { passwordPin: oldPasswordPin }, token: currentUser.token });
                          if(oldPasswordPinData) {
                            setOldPasswordPinChecked(oldPasswordPinData.success);
                          }
                          setOldPasswordPinCheckedCount(oldPasswordPinCheckedCount + 1);
                          setRunning(false);
                        }
                      }}
                    >
                      {running ? <Spinner animation="border" size="sm" /> : t("Button.Next")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={`${oldPasswordPinChecked ? "" : "hide"}`}>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-password-pin">
                    <Form.Label>{t("Label.PasswordPin")}:</Form.Label>
                    <InputMask
                      id="forms-user-password-pin"
                      className={`form-control ${(!isEmpty(user.passwordPin) && isPasswordPin(user.passwordPin)) ? "is-valid" : ""} ${(isEmpty(user.passwordPin) || !isPasswordPin(user.passwordPin)) ? "is-invalid" : ""}`}
                      type="text"
                      placeholder={t("Placeholder.PasswordPin")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        if(oldPasswordPinChecked) {
                          const passwordPin = e.target.value;
                          setUser(prevState => ({
                            ...prevState,
                            passwordPin
                          }));
                        }
                        else {
                          setUser(prevState => ({
                            ...prevState,
                            passwordPin: ""
                          }));
                        }
                      }}
                      mask="9999"
                      autoComplete="off"
                      value={user.passwordPin}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.PasswordPin")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-confirm-password-pin">
                    <Form.Label>{t("Label.ConfirmPasswordPin")}:</Form.Label>
                    <InputMask
                      id="forms-user-confirm-password-pin"
                      className={`form-control ${(!isEmpty(confirmPasswordPin) && isPasswordPin(confirmPasswordPin) && !isEmpty(user.passwordPin) && isPasswordPin(user.passwordPin) && (confirmPasswordPin === user.passwordPin)) ? "is-valid" : ""} ${(isEmpty(confirmPasswordPin) || !isPasswordPin(confirmPasswordPin) || isEmpty(user.passwordPin) || !isPasswordPin(user.passwordPin) || (confirmPasswordPin !== user.passwordPin)) ? "is-invalid" : ""}`}
                      type="text"
                      placeholder={t("Placeholder.ConfirmPasswordPin")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        if(oldPasswordPinChecked) {
                          const confirmPasswordPin = e.target.value;
                          setConfirmPasswordPin(confirmPasswordPin);
                        }
                        else {
                          setConfirmPasswordPin("");
                        }
                      }}
                      mask="9999"
                      autoComplete="off"
                      value={confirmPasswordPin}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {
                        isEmpty(confirmPasswordPin) || isEmpty(user.passwordPin) ? (
                          t("Feedback.ConfirmPasswordPin")
                        ) : (
                          t("Feedback.Format.ConfirmPasswordPin")
                        )
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
        <Form.Group className={`default-form-button ${oldPasswordPinChecked ? "" : "hide"}`}>
          <Button
            variant="dark"
            type="submit"
            disabled={buttonDisabled()}
          >
            {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(PasswordPin));
