import ChassiSchemaReduced from "./../Chassi/SchemaReduced";
import ChipSchemaReduced from "./../Chip/SchemaReduced";
import ClientSchemaReduced from "./../Client/SchemaReduced";
import RfidSchemaReduced from "./../Rfid/SchemaReduced";

const schema = {
  active: true,
  chip: JSON.parse(JSON.stringify(ChipSchemaReduced)),
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  hatch: {
    type: "DEF"
  },
  id: "",
  name: "",
  niple: {
    chassi: JSON.parse(JSON.stringify(ChassiSchemaReduced)),
    gyroscope: true,
    groundingSensor: false,
    main: false,
    operation: "HYB",
    operationInverted: false
  },
  positionMain: false,
  rfid: JSON.parse(JSON.stringify(RfidSchemaReduced)),
  serial: "",
  type: "TRA"
};

export default schema;
