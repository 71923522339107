import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addNotice: ["notice"],
  removeNotice: ["id"],
  removingNotice: ["id"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  notices: []
};

const addNotice = (state = INITIAL_STATE, action) => {
  const { notice } = action;
  const notices = JSON.parse(JSON.stringify(state.notices));
  if (notice.refId) {
    const noticeEqual = notices.findIndex(x => x.refId === notice.refId);
    if (noticeEqual !== -1) notices.splice(noticeEqual, 1);
  }
  return {
    ...state,
    notices: [
      ...notices,
      addNoticeFormat(notice)
    ]
  };
};

const addNoticeFormat = (notice) => {
  return notice = Object.assign({
    id: Math.random().toString(36).substring(2, 11),
    refId: notice.refId ? notice.refId : undefined,
    title: undefined,
    content: undefined,
    date: DateUtil.now({ format: null }),
    removing: false
  }, notice);
};

const removeNotice = (state = INITIAL_STATE, action) => {
  const { id } = action;
  const notices = [...state.notices];
  const index = notices.findIndex(x => x.id === id);
  if (index !== -1) notices.splice(index, 1);
  return {
    ...state,
    notices
  };
};

const removingNotice = (state = INITIAL_STATE, action) => {
  const { id } = action;
  return {
    ...state,
    notices: state.notices.map(notice => notice.id === id ? { ...notice, removing: true } : { ...notice })
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_NOTICE]: addNotice,
  [Types.REMOVE_NOTICE]: removeNotice,
  [Types.REMOVING_NOTICE]: removingNotice
});
