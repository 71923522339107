import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as VirtualFenceActions } from "../../store/ducks/virtualFences";
import VirtualFenceListItem from "../VirtualFenceListItem";

const VirtualFenceList = ({
  props: { virtualFences, virtualFencesSearch, virtualFencesSearchQueryEnabled }
}) => {
  return (
    <ul className="last-virtual-fence-list">
      {
        virtualFencesSearchQueryEnabled ? (
          virtualFencesSearch.map(virtualFence => (
            <VirtualFenceListItem key={`ln-${virtualFence.id}`} {...virtualFence} onClick={() => {
              if (virtualFence.onClick) {
                virtualFence.onClick();
              }
            }} />
          ))
        ) : (
          virtualFences.map(virtualFence => (
            <VirtualFenceListItem key={`ln-${virtualFence.id}`} {...virtualFence} onClick={() => {
              if (virtualFence.onClick) {
                virtualFence.onClick();
              }
            }} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    virtualFences: state.virtualFences.virtualFences,
    virtualFencesSearch: state.virtualFences.virtualFencesSearch,
    virtualFencesSearchQueryEnabled: state.virtualFences.virtualFencesSearchQueryEnabled,
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, VirtualFenceActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualFenceList);
