import React, { memo } from "react";
import {
  Accordion,
  Card,
  Col,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "../../../../utils/Date";
import { numberToCurrency } from "../../../../utils/String";

const Supply = ({
  props: { currentUser },
  options
}) => {
  const { t } = useTranslation();
  return (
    <Card className="card-calendar">
      <Accordion.Toggle as={Card.Header} eventKey={options.eventKey} onClick={e => options.changeActiveKey(options.eventKey)}>
        <i className="fas fa-gas-pump" /> {t("Title.Supply")}: {DateUtil.to({ date: options.event.date, format: "DD/MM/YYYY", tz: "America/Sao_Paulo" })}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={options.eventKey}>
        <Card.Body>
          <Row>
            <Col>{t("Title.Patrimony")}: {options.event.patrimony.description}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Date")}: {DateUtil.to({ date: options.event.date, format: "DD/MM/YYYY", tz: "America/Sao_Paulo" })}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Price")}: R$ {numberToCurrency(options.event.price)}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Description")}: {options.event.description}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Address")}: {options.event.patrimony.description}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Fuel")}: {t(`Title.Fuel.${options.event.fuel}`)}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Liters")}: {numberToCurrency(options.event.liters)}L</Col>
          </Row>
          <Row>
            <Col>{t("Title.Hodometer")}: {options.event.hodometer}</Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Supply));
