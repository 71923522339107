import Api from "./../Api";

export async function checkToken({ token }) {
  const response = await Api.post("/user/check-token", { token }, {
    headers: {"Authorization": "bearer " + token}
  }).then(response => {
    return response.data;
  }).catch(error => {
    try {
      if(error.response.data === "Unauthorized") {
        return {
          success: false,
          error: {
            type: "UnauthorizedError",
            details: [{
              message: "UnauthorizedError"
            }]
          }
        };
      }
      return error.response.data;
    }
    catch(error) {
      return {
        success: false,
        error: {
          type: "NetworkError",
          details: [{
            message: "NetworkError"
          }]
        }
      };
    }
  });
  return response;
}

export async function signIn({ cpf, password }) {
  const response = await Api.post("/user/authenticate", { cpf, password }).then(response => {
    return response.data;
  }).catch(error => {
    try {
      if(error.response.data === "Unauthorized") {
        return {
          success: false,
          error: {
            type: "UnauthorizedError",
            details: [{
              message: "UnauthorizedError"
            }]
          }
        };
      }
      return error.response.data;
    }
    catch(error) {
      return {
        success: false,
        error: {
          type: "NetworkError",
          details: [{
            message: "NetworkError"
          }]
        }
      };
    }
  });
  return response;
}

export function isScope({ currentUser, scope }) {
  try {
    if(currentUser) {
      if(currentUser.scopes !== undefined) {
        if(currentUser.scopes.findIndex(x => x.scope === scope) !== -1) {
          return true;
        }
      }
    }
    return false;
  }
  catch(error) {
    return false;
  }
}

export function isScopes({ currentUser, scopes, every = true } = {}) {
  try {
    var hasScopes = false;
    if(currentUser) {
      if(every) {
        hasScopes = scopes.every((scope) => {
          return currentUser.scopes.findIndex(x => x.scope === scope) > -1;
        });
      }
      else {
        hasScopes = scopes.filter((scope) => {
          return currentUser.scopes.findIndex(x => x.scope === scope) > -1;
        });
        hasScopes = hasScopes.length > 0;
      }
    }
    return hasScopes;
  }
  catch(error) {
    return false;
  }
}
