import ClientSchemaReduced from "./../Client/SchemaReduced";
import DriverSchemaReduced from "./../Driver/SchemaReduced";
import ProfileSchemaReduced from "./../Profile/SchemaReduced";

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  contact: {
    cellphone: "",
    email: "",
    telephone: ""
  },
  driver: JSON.parse(JSON.stringify(DriverSchemaReduced)),
  id: "",
  cpf: "",
  firstLogin: {
    password: false,
    timezone: false,
    tutorial: false
  },
  name: "",
  password: "",
  passwordPin: "",
  passwordPinConfigured: false,
  patrimonies: [],
  profile: {
    lang: "pt",
    map: {
      cluster: true,
      markers: true,
      miniMarkers: false,
      miniMap: false,
      traffic: false,
      type: "DEF"
    },
    notification: {
      group: [],
      mode: {
        mail: false,
        push: true
      },
      warning: {
        sound: true,
        voice: false
      }
    },
    timezone: "America/Sao_Paulo"
  },
  profilePredefined: JSON.parse(JSON.stringify(ProfileSchemaReduced)),
  scopes: [{
    scope: "is:default"
  }],
  _driver: false
};

export default schema;
