import React, { memo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";
import NoticeListItem from "./../NoticeListItem";

const Notice = ({ notices, removeNotice, removingNotice }) => {
  return (
    <section className="section-notice">
      <ul className="notice-list">
        {
          notices.slice(0).reverse().map((notice, index) => {
            return (
              <NoticeListItem count={index} key={notice.id} {...notice} onClose={() => {
                removingNotice(notice.id);
                setTimeout(() => {
                  removeNotice(notice.id);
                }, 500);
              }} />
            );
          })
        }
      </ul>
    </section>
  );
};

const mapStateToProps = state => ({
  notices: state.notifications.notices
});

const mapDispatchToProps = dispatch => bindActionCreators(NotificationsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(memo(Notice));
