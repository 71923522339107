import React, { memo, useEffect, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "../../store/ducks/menus";

import { isScopes } from "../../services/Auth";
import Calendar from "../Calendar";
import Grid from "../Grid";
import MenuCalendarsItem from "../MenuCalendarsItem";

import CertificateForm from "../../forms/Certificate";
import DayoffForm from "../../forms/Dayoff";
import ExpenseForm from "../../forms/Expense";
import ExpenseTypeForm from "../../forms/ExpenseType";
import HolydayForm from "../../forms/Holyday";
import LicenseForm from "../../forms/License";
import MaintenanceScheduleForm from "../../forms/MaintenanceSchedule";
import SinisterForm from "../../forms/Sinister";
import SupplyForm from "../../forms/Supply";
import TrafficTicketForm from "../../forms/TrafficTicket";
import VacationForm from "../../forms/Vacation";

import CertificateService from "../../services/Certificate";
import DayoffService from "../../services/Dayoff";
import ExpenseService from "../../services/Expense";
import ExpenseTypeService from "../../services/ExpenseType";
import HolydayService from "../../services/Holyday";
import LicenseService from "../../services/License";
import MaintenanceScheduleService from "../../services/MaintenanceSchedule";
import SinisterService from "../../services/Sinister";
import SupplyService from "../../services/Supply";
import TrafficTicketService from "../../services/TrafficTicket";
import VacationService from "../../services/Vacation";

const MenuCalendars = ({
  props: { currentUser },
  funcs: { menuHandleOpen, menuCalendarsHandleClose }
}) => {
  const { t } = useTranslation();
  const [selectedCalendar, setSelectedCalendar] = useState({
    selected: false,
    options: false
  });

  useEffect(() => {
    if (selectedCalendar.selected) {
      if (selectedCalendar.options.scoped(currentUser) === false) changeSelectedCalendar(false);
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedCalendar = (item) => {
    setSelectedCalendar({ selected: false, options: false });
    if (item !== false) {
      setTimeout(() => {
        setSelectedCalendar(item);
      }, 100);
    }
  };

  const dynamicOptions = [
    {
      id: "mrg-option-calendar",
      title: t("Title.Calendar"),
      icon: <i className="fas fa-calendar-days" />,
      onClick: () => {
        const id = "mrg-option-calendar";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new TrafficTicketService(),
            actions: [],
            component: (options) => <Calendar options={options} />,
            title: t("Title.Calendar"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-calendar",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars"], every: false })
    },
    {
      id: "mrg-option-trafficTicket",
      title: t("Title.TrafficTicket"),
      icon: <i className="fas fa-traffic-light" />,
      onClick: () => {
        const id = "mrg-option-trafficTicket";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new TrafficTicketService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <TrafficTicketForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:trafficTickets", "create:trafficTickets:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <TrafficTicketForm options={{ trafficTicket: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:trafficTickets", "update:trafficTickets:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.TrafficTicket"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:trafficTickets"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-trafficTicket",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:trafficTickets"], every: false })
    },
    {
      id: "mrg-option-sinister",
      title: t("Title.Sinister"),
      icon: <i className="fas fa-car-crash" />,
      onClick: () => {
        const id = "mrg-option-sinister";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new SinisterService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <SinisterForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:sinisters", "create:sinisters:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <SinisterForm options={{ sinister: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:sinisters", "update:sinisters:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Sinister"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:sinisters"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-sinister",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:sinisters"], every: false })
    },
    {
      id: "mrg-option-license",
      title: t("Title.License"),
      icon: <i className="fas fa-baby-carriage" />,
      onClick: () => {
        const id = "mrg-option-license";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new LicenseService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <LicenseForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:licenses", "create:licenses:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <LicenseForm options={{ license: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:licenses", "update:licenses:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.License"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:licenses"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-license",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:licenses"], every: false })
    },
    {
      id: "mrg-option-vacation",
      title: t("Title.Vacation"),
      icon: <i className="fas fa-umbrella-beach" />,
      onClick: () => {
        const id = "mrg-option-vacation";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new VacationService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <VacationForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:vacations", "create:vacations:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <VacationForm options={{ vacation: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:vacations", "update:vacations:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Vacation"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:vacations"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-vacation",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:vacations"], every: false })
    },
    {
      id: "mrg-option-certificate",
      title: t("Title.Certificate"),
      icon: <i className="fas fa-scroll" />,
      onClick: () => {
        const id = "mrg-option-certificate";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new CertificateService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <CertificateForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:certificates", "create:certificates:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <CertificateForm options={{ certificate: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:certificates", "update:certificates:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Certificate"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:certificates"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-certificate",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:certificates"], every: false })
    },
    {
      id: "mrg-option-dayoff",
      title: t("Title.Dayoff"),
      icon: <i className="fas fa-mug-saucer" />,
      onClick: () => {
        const id = "mrg-option-dayoff";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new DayoffService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <DayoffForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:dayoffs", "create:dayoffs:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <DayoffForm options={{ dayoff: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:dayoffs", "update:dayoffs:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Dayoff"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:dayoffs"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-dayoff",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:dayoffs"], every: false })
    },
    {
      id: "mrg-option-maintenanceSchedule",
      title: t("Title.MaintenanceSchedule"),
      icon: <i className="fas fa-calendar-check" />,
      onClick: () => {
        const id = "mrg-option-maintenanceSchedule";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new MaintenanceScheduleService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <MaintenanceScheduleForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:maintenanceSchedules", "create:maintenanceSchedules:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <MaintenanceScheduleForm options={{ maintenanceSchedule: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:maintenanceSchedules", "update:maintenanceSchedules:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.MaintenanceSchedule"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:maintenanceSchedules"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-maintenanceSchedule",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:maintenanceSchedules"], every: false })
    },
    {
      id: "mrg-option-holyday",
      title: t("Title.Holyday"),
      icon: <i className="fas fa-calendar-xmark" />,
      onClick: () => {
        const id = "mrg-option-holyday";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new HolydayService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <HolydayForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:holydays", "create:holydays:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <HolydayForm options={{ holyday: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:holydays", "update:holydays:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Holyday"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:holydays"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-holyday",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:holydays"], every: false })
    },
    {
      id: "mrg-option-expenseType",
      title: t("Title.ExpenseType"),
      icon: <i className="fas fa-hand-holding-dollar" />,
      onClick: () => {
        const id = "mrg-option-expenseType";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new ExpenseTypeService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ExpenseTypeForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:expenseTypes", "create:expenseTypes:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ExpenseTypeForm options={{ expenseType: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:expenseTypes", "update:expenseTypes:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.ExpenseType"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:expenseTypes"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-expenseType",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:expenseTypes"], every: false })
    },
    {
      id: "mrg-option-expense",
      title: t("Title.Expense"),
      icon: <i className="fas fa-dollar-sign" />,
      onClick: () => {
        const id = "mrg-option-expense";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new ExpenseService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <ExpenseForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:expenses", "create:expenses:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <ExpenseForm options={{ expense: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:expenses", "update:expenses:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Expense"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:expenses"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-expense",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:expenses"], every: false })
    },
    {
      id: "mrg-option-supply",
      title: t("Title.Supply"),
      icon: <i className="fas fa-gas-pump" />,
      onClick: () => {
        const id = "mrg-option-supply";
        if (selectedCalendar.selected === id) return setSelectedCalendar(false);
        changeSelectedCalendar({
          selected: id,
          options: {
            api: new SupplyService(),
            actions: [
              {
                content: ({ resetActionsForced }) => <SupplyForm options={{ close: () => { resetActionsForced(); } }} />,
                render: ({ index, showAction }) => {
                  const checkEnabled = true;
                  return (isScopes({ currentUser, scopes: ["is:master", "create:supplys", "create:supplys:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.New")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-plus"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.New")
              },
              {
                content: ({ resetActionsForced, selectedRows }) => <SupplyForm options={{ supply: JSON.parse(JSON.stringify(selectedRows[0])), close: () => { resetActionsForced(); } }} />,
                render: ({ index, selectedRows, showAction }) => {
                  const checkEnabled = selectedRows.length === 1 ? true : false;
                  return (isScopes({ currentUser, scopes: ["is:master", "update:supplys", "update:supplys:all"], every: false }) ? (
                    <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-${id}-${(Math.random().toString(36).substr(2, 9))}`}>
                      <OverlayTrigger overlay={<Tooltip>{t("Title.Edit")}</Tooltip>}>
                        <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}><i className="fas fa-edit"></i></Button>
                      </OverlayTrigger>
                    </li>
                  ) : (
                    null
                  ));
                },
                show: false,
                title: () => t("Title.Edit")
              },
            ],
            component: (options) => <Grid options={options} />,
            title: t("Title.Supply"),
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:supplys"], every: false })
          }
        });
      },
      selected: () => selectedCalendar.selected === "mrg-option-supply",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:calendars:supplys"], every: false })
    }
  ];

  const fixedOptions = [{
    id: "mrg-option-close",
    title: t("Title.Close"),
    icon: <i className="fas fa-times" />,
    onClick: () => {
      menuCalendarsHandleClose();
      menuHandleOpen();
    },
    selected: () => true,
    scoped: () => true
  }];

  return (
    <div className="menu-calendars">
      <div className={`mrg-calendars-options-absolute ${selectedCalendar.selected ? "active" : ""}`}>
        <div className="mrg-calendars-options-absolute-content">
          <div className="mrg-roa-content-title">{selectedCalendar.selected && <h3>{selectedCalendar.options.title}</h3>}</div>
          <div className="mrg-roa-content-body">{selectedCalendar.selected && selectedCalendar.options.component(selectedCalendar.options)}</div>
        </div>
      </div>
      <div className="mrg-calendars-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if (option.scoped()) {
                return (
                  <MenuCalendarsItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="mrg-calendars-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if (option.scoped()) {
                return (
                  <MenuCalendarsItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuCalendars));
