export function isCellphone(cellphone) {
  cellphone = cellphone.replace(/_/gi, "");
  const checkMasked = cellphone.indexOf("(") !== -1;
  if((!checkMasked && (cellphone.length === 10 || cellphone.length === 11)) || (checkMasked && (cellphone.length === 13 || cellphone.length === 14))) {
    return true;
  }
  return false;
}

export function maskCellphone(cellphone) {
  const arrMasked = ["(", "", "", ") ", "", "", "", "", "", "", "", "", ""];
  const arrMaskedFinal = [];
  var j = 0;
  arrMasked.map(v => {
    if(v === "" && (j < cellphone.length)) {
      arrMaskedFinal.push(cellphone[j]);
      j++;
    }
    else {
      if(v !== "") {
        arrMaskedFinal.push(v);
      }
    }
    return j;
  });
  return arrMaskedFinal.join("");
}
