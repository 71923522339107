import Api from "../Api";
import ApiReport from "../ApiReport";
import DateUtil from "./../../utils/Date";

export default class Report {
  async findAll({ filter, limit, page, token }) {
    const response = await Api.post("/report/find-all", {
      limit,
      page,
      filter
    }, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data;
    }
    return {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    };
  }

  async findAllGrid({ data, token }) {
    const response = await Api.post("/report/find-all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return this.findAllGridTranslate({ data: response.data });
    }
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [{
      width: "20%",
      type: "string",
      label: "Title.Name"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Description"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Date.Start"
    }, {
      width: "20%",
      type: "string",
      label: "Title.Date.End"
    }, {
      width: "10%",
      type: "string",
      label: "Title.Date.TimeToGenerated"
    }, {
      width: "10%",
      type: "string",
      label: "Title.Status"
    }];
    data.rows = [];
    data.docs.map(item => {
      const active = this.findAllGridTranslateItem({ data: item, item: "active" }) !== undefined ? item.active : false;
      const canceled = this.findAllGridTranslateItem({ data: item, item: "canceled" }) !== undefined ? item.canceled : false;
      const canceledAt = this.findAllGridTranslateItem({ data: item, item: "canceledAt" }) !== undefined ? item.canceledAt : false;
      const ended = this.findAllGridTranslateItem({ data: item, item: "ended" }) !== undefined ? item.ended : false;
      const endedAt = this.findAllGridTranslateItem({ data: item, item: "endedAt" }) !== undefined ? item.endedAt : false;
      const error = this.findAllGridTranslateItem({ data: item, item: "error" }) !== undefined ? item.error : false;
      const started = this.findAllGridTranslateItem({ data: item, item: "started" }) !== undefined ? item.started : false;
      const startedAt = this.findAllGridTranslateItem({ data: item, item: "startedAt" }) !== undefined ? item.startedAt : false;

      let status = "%{Title.Reports.Status.Waiting}%";
      if (active) status = "%{Title.Reports.Status.Available}%";
      else if (canceled) {
        if (error) status = "%{Title.Reports.Status.Error}%";
        else status = "%{Title.Reports.Status.Canceled}%";
      }
      else if (!canceled && error) status = "%{Title.Reports.Status.NotFound}%";
      else if (started) {
        if (item.progress === -1) status = "%{Title.Reports.Status.Running}%";
        else status = `%{Title.Reports.Status.Running}% (${item.progress}%)`;
      }

      let timeToGenerated = "00:00:00";
      if (active) {
        if (started && ended) timeToGenerated = DateUtil.toHHMMSS(DateUtil.diff({ dateA: endedAt, dateB: startedAt, format: "seconds", tz: "America/Sao_Paulo"}));
        else timeToGenerated = DateUtil.toHHMMSS(DateUtil.diff({ dateA: item.updatedAt, dateB: item.createdAt, format: "seconds", tz: "America/Sao_Paulo"}));
      }
      else {
        if (canceled || error) {
          if (started && canceled) timeToGenerated = DateUtil.toHHMMSS(DateUtil.diff({ dateA: canceledAt, dateB: startedAt, format: "seconds", tz: "America/Sao_Paulo"}));
          else timeToGenerated = DateUtil.toHHMMSS(DateUtil.diff({ dateA: item.updatedAt, dateB: item.createdAt, format: "seconds", tz: "America/Sao_Paulo"}));
        }
        else if (started) timeToGenerated = DateUtil.toHHMMSS(DateUtil.diff({ dateA: (new Date()).toISOString(), dateB: startedAt, format: "seconds", tz: "America/Sao_Paulo"}));
        else timeToGenerated = DateUtil.toHHMMSS(DateUtil.diff({ dateA: (new Date()).toISOString(), dateB: item.createdAt, format: "seconds", tz: "America/Sao_Paulo"}));
      }

      const itemAux = [{
        value: this.findAllGridTranslateItem({ data: item, item: "name" }) !== undefined ? item.name : "-"
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "description" }) !== undefined ? item.description : "-"
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "startAt" }) !== undefined ? DateUtil.to({ date: new Date(item.startAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : "-"
      }, {
        value: this.findAllGridTranslateItem({ data: item, item: "endAt" }) !== undefined ? DateUtil.to({ date: new Date(item.endAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : "-"
      }, {
        value: timeToGenerated
      }, {
        value: status
      }];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async reportCancel({ id, token }) {
    const response = await ApiReport.post(`/report/cancel/${id}`, {}, {
      headers: {"Authorization": "Bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async reportCSV({ id, data, token }) {
    const response = await ApiReport.post(`/report/csv/${id}`, data, {
      headers: {"Authorization": "bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async reportPDF({ id, data, token }) {
    const response = await ApiReport.post(`/report/pdf/${id}`, data, {
      headers: {"Authorization": "bearer " + token},
      responseType: "blob"
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
