import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import Select from "react-select";

import { isScopes } from "./../../services/Auth";

import { Creators as RouteActions } from "../../store/ducks/route";

const FilterRoute = ({
  props: { currentUser },
  funcs: { setPeriodRoute, setPeriodRouteFix }
}) => {
  const { t } = useTranslation();
  const [route, setRoute] = useState(true);
  const [routeFix, setRouteFix] = useState(null);

  const [routeOptions] = useState([
    { value: true, label: "Com rota (somente ignições ligadas)" },
    { value: false, label: "Sem rota (ignições ligadas e desligadas)" }
  ]);

  const [routeFixOptions] = useState([
    { value: null, label: "Corrigir apenas se estiver ativo no cadastro" },
    { value: false, label: "Forçar a não corrigir rota" },
    { value: true, label: "Forçar a corrigir rota" }
  ]);

  useEffect(() => {
    setPeriodRoute(route);
  }, [route]); /* eslint-disable-line */

  useEffect(() => {
    setPeriodRouteFix(routeFix);
  }, [routeFix]); /* eslint-disable-line */

  return (
    <Card>
      <Card.Header>{t("Title.Route")}</Card.Header>
      <Card.Body>
        <Row>
          {
            isScopes({ currentUser, scopes: [
              "is:master",
              "read:patrimonies:all"
            ], every: false }) ? (
              <>
                <Col xs={12} md={6}>
                  <div className="filter-route-fields">
                    <Form.Group>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-clock" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Select
                          className="menu-outer-bottom filter-route-form-control"
                          classNamePrefix="select"
                          onChange={e => setRoute(e.value)}
                          options={routeOptions}
                          value={routeOptions.find(x => x.value === route)}
                        />
                        <div className="filter-route-form-control-shaddow" />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="filter-route-fields">
                    <Form.Group>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-clock" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <Select
                          className="menu-outer-bottom filter-route-form-control"
                          classNamePrefix="select"
                          onChange={e => setRouteFix(e.value)}
                          options={routeFixOptions}
                          value={routeFixOptions.find(x => x.value === routeFix)}
                        />
                        <div className="filter-route-form-control-shaddow" />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
              </>
            ) : (
              <Col>
                <div className="filter-route-fields">
                  <Form.Group>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text><i className="fas fa-clock" /></InputGroup.Text>
                      </InputGroup.Prepend>
                      <Select
                        className="menu-outer-bottom filter-route-form-control"
                        classNamePrefix="select"
                        onChange={e => setRoute(e.value)}
                        options={routeOptions}
                        value={routeOptions.find(x => x.value === route)}
                      />
                      <div className="filter-route-form-control-shaddow" />
                    </InputGroup>
                  </Form.Group>
                </div>
              </Col>
            )
          }
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, RouteActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterRoute));
