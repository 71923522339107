const { currencyToNumber } = require("./String");

export function jsonToForm(json) {
  const newJson = JSON.parse(JSON.stringify(json));
  return newJson;
}

export function formToJson(json) {
  const newJson = JSON.parse(JSON.stringify(json));
  if (typeof newJson.client !== "undefined") {
    newJson.clientId = newJson.client.id;
    newJson.client = undefined;
  }
  if (typeof newJson.driver !== "undefined") {
    newJson.driverId = newJson.driver.id;
    newJson.driver = undefined;
  }
  if (typeof newJson.patrimony !== "undefined") {
    newJson.patrimonyId = newJson.patrimony.id;
    newJson.patrimony = undefined;
  }
  newJson.price = currencyToNumber(newJson.price);
  return newJson;
}