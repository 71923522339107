import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "./../../utils/Date";
import { isScopes } from "./../../services/Auth";
import { getDescriptionClient } from "./../../utils/Client";
import { getDescriptionPatrimony } from "./../../utils/Patrimony";

const LastDateCorrectionListItem = ({
  options: { id, onClick, dateCorrection, selected },
  props: { currentUser }
}) => {
  const { t } = useTranslation();
  return (
    <li className={`last-date-correction-list-item ${selected ? "active" : ""}`} onClick={onClick}>
      <ul>
        <li>
          <div><span><strong>{getDescriptionClient(dateCorrection.patrimony.client)}</strong></span></div>
        </li>
        <li>
          <div><span><strong>{getDescriptionPatrimony(dateCorrection.patrimony)}</strong></span></div>
        </li>
        {
          [dateCorrection.tracker.serial].map((serial, index) => {
            const type = dateCorrection.patrimony.trackers.find(x => x.serial === serial);
            const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
            const trackerTypes = [
              { value: "HAT", label: t("Title.Hatch") },
              { value: "NIP", label: t("Title.Niple") },
              { value: "TRA", label: t("Title.Tracker") },
              { value: "TRA_NIP", label: `${t("Title.Tracker")}/${t("Title.Niple")}` }
            ];
            const selectedType = trackerTypes.find(x => x.value === type.type);
            if(type && (type.type !== "TRA" && type.type !== "TRA_NIP")) {
              return null;
            }
            return (
              <li className="niple-box" key={`lps-main-description-${index}`}>
                <ul>
                  <li className="niple-box-title">
                    <div>{selectedType ? selectedType.label : t("Title.Tracker")}:</div>
                  </li>
                  <li>
                    <div>{t("Title.Serial")}:</div>
                    <div><span>{serial}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Date")} ({t("GPS")}):</div>
                    <div><span>{DateUtil.toDateCorrection({ date: new Date(dateCorrection.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Date")} ({t("DateCorrection")}):</div>
                    <div><span>{DateUtil.to({ date: new Date(dateCorrection.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                  </li>
                  <li>
                    <div>{t("Title.Chip")}:</div>
                    <div><span>{chip ? type.chip.line : ""}</span></div>
                  </li>
                </ul>
              </li>
            );
          })
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof dateCorrection.trackersBackup !== "undefined" ? (
          	 dateCorrection.trackersBackup.map((trackerBackup, index) => {
              const type = dateCorrection.patrimony.trackers.find(x => x.serial === trackerBackup.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              if(type && (trackerBackup.serial === dateCorrection.tracker.serial)) {
                return null;
              }
              return (
                <li className="niple-box" key={`lps-tracker-backup-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Tracker.Help")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Serial")}:</div>
                      <div><span>{trackerBackup.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Date")} ({t("GPS")}):</div>
                      <div><span>{DateUtil.toDateCorrection({ date: new Date(trackerBackup.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Date")} ({t("DateCorrection")}):</div>
                      <div><span>{DateUtil.to({ date: new Date(trackerBackup.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Chip")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
      </ul>
    </li>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastDateCorrectionListItem);
