import DateUtil from "../../utils/Date";

export function getCols() {
  return [{
    width: "20%",
    type: "string",
    label: "Address"
  }, {
    width: "20%",
    type: "string",
    label: "From"
  }, {
    width: "20%",
    type: "string",
    label: "To"
  }, {
    width: "20%",
    type: "string",
    label: "TotalAccounted"
  }, {
    width: "20%",
    type: "string",
    label: "Coordinate"
  }];
}

export function getRows(docs, t) {
  const rows = [];
  docs.map(item => {
    return rows.push([{
      id: item.id,
      value: item.gps.address
    }, {
      value: DateUtil.to({ date: new Date(item.pump.dateStart), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: DateUtil.to({ date: new Date(item.pump.dateEnd), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})
    }, {
      value: DateUtil.toHHMMSS(item.pump.all.seconds)
    }, {
      value: `${item.gps.coordinate.latitude} | ${item.gps.coordinate.longitude}`
    }]);
  });
  return rows;
}
