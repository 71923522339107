import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";
import { filterByClientAndWord, sortDateCorrections } from "./../../utils/DateCorrection";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastDateCorrection: ["lastDateCorrection"],
  updateLastDateCorrection: ["id", "lastDateCorrection"],
  searchLastDateCorrection: ["search"],
  setLastDateCorrection: ["lastDateCorrections"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  dateCorrections: [],
  dateCorrectionsSearch: [],
  dateCorrectionsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    niple: true,
    patrimony: "",
    word: ""
  },
  dateCorrectionsSearchQueryEnabled: true,
  dateCorrectionsLoad: false
};

const format = (lastDateCorrection) => {
  const copy = JSON.parse(JSON.stringify(lastDateCorrection));
  if(typeof copy.patrimony === "undefined") {
    copy.patrimony = {
      automobile: {
        board: {
          autoVaccum: {
            license: copy.tracker.serial
          },
          board: copy.tracker.serial,
          color: "GRAY",
          country: "BR",
          description: "SC - CHAPECÓ",
          layout: "DEF",
          type: "CAR"
        },
        brand: "TPX",
        card: {
          number: copy.tracker.serial
        },
        color: "WHITE",
        model: "OCTSAT",
        type: "BOA"
      },
      object: {
        name: copy.tracker.serial
      },
      type: "AUT",
      vessel: {
        name: copy.tracker.serial,
        registrationNumber: copy.tracker.serial
      }
    };
  }
  return copy;
};

const add = (state = INITIAL_STATE, action) => {
  if(state.dateCorrectionsLoad) {
    const { lastDateCorrection } = action;
    const index = state.dateCorrections.findIndex(x => x.id === lastDateCorrection.tracker.serial);
    if(index !== -1) {
      return update(
        state,
        {
          id: lastDateCorrection.tracker.serial,
          lastDateCorrection
        }
      );
    }

    const dateCorrections = state.dateCorrections.filter(x => x.dateCorrection.tracker.dateCorrection === true);
    if(lastDateCorrection.tracker.dateCorrection) {
      dateCorrections.push({ id: lastDateCorrection.tracker.serial, dateCorrection: format(lastDateCorrection), selected: false });
    }

    const { dateCorrectionsSearch } = search({
      ...state,
      dateCorrections
    }, { search: state.dateCorrectionsSearchQuery });

    return {
      ...state,
      dateCorrections: sortDateCorrections(dateCorrections),
      dateCorrectionsSearch: sortDateCorrections(dateCorrectionsSearch)
    };
  }
  return state;
};

const update = (state = INITIAL_STATE, action) => {
  if(state.dateCorrectionsLoad) {
    const { id, lastDateCorrection } = action;
    const index = state.dateCorrections.findIndex(x => x.id === id);
    if(index !== -1) {
      let dateCorrections = state.dateCorrections.map(dateCorrection => {
        if((dateCorrection.id === id) && ((DateUtil.diff({ dateA: dateCorrection.dateCorrection.gps.date, dateB: lastDateCorrection.gps.date }) < 0) || (DateUtil.diff({ dateA: dateCorrection.dateCorrection.gps.date, dateB: lastDateCorrection.gps.date }) === 0 && DateUtil.diff({ dateA: dateCorrection.dateCorrection.createdAt, dateB: lastDateCorrection.createdAt }) <= 0))) {
          return { ...dateCorrection, dateCorrection: format(lastDateCorrection) };
        }
        return { ...dateCorrection };
      });
      dateCorrections = dateCorrections.filter(x => x.dateCorrection.tracker.dateCorrection === true);

      const { dateCorrectionsSearch } = search({
        ...state,
        dateCorrections
      }, { search: state.dateCorrectionsSearchQuery });

      return {
        ...state,
        dateCorrections: sortDateCorrections(dateCorrections),
        dateCorrectionsSearch: sortDateCorrections(dateCorrectionsSearch)
      };
    }
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: dateCorrectionsSearchEnabled, results: dateCorrectionsSearchResults } = filterByClientAndWord(state.dateCorrections, search);
  return {
    ...state,
    dateCorrectionsSearch: dateCorrectionsSearchResults,
    dateCorrectionsSearchQuery: search,
    dateCorrectionsSearchQueryEnabled: dateCorrectionsSearchEnabled
  };
};

const set = (state = INITIAL_STATE, action) => {
  if(state.dateCorrectionsLoad === false) {
    const { lastDateCorrections } = action;
    const dateCorrections = lastDateCorrections.map(lastDateCorrection => {
      return { id: lastDateCorrection.tracker.serial, dateCorrection: format(lastDateCorrection), selected: false };
    });

    const { dateCorrectionsSearch } = search({
      ...state,
      dateCorrections
    }, { search: state.dateCorrectionsSearchQuery });

    return {
      ...state,
      dateCorrections: sortDateCorrections(dateCorrections),
      dateCorrectionsSearch: sortDateCorrections(dateCorrectionsSearch),
      dateCorrectionsLoad: true
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_DATE_CORRECTION]: add,
  [Types.UPDATE_LAST_DATE_CORRECTION]: update,
  [Types.SEARCH_LAST_DATE_CORRECTION]: search,
  [Types.SET_LAST_DATE_CORRECTION]: set
});
