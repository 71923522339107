import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import { jsonToForm, formToJson } from "../../utils/Dayoff";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { isValidForm } from "../../utils/Form";
import { clearObject, mergeObject } from "../../utils/Object";
import DateUtil from "../../utils/Date";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";
import DriverService from "../../services/Driver";
import DriverSchemaReduced from "../../services/Driver/SchemaReduced";
import DayoffService from "../../services/Dayoff";
import DayoffSchema from "../../services/Dayoff/Schema";

const ACTIVE_KEY_DETAIL_DATA = "1";
const ACTIVE_KEY_GENERAL_DATA = "0";

registerLocale("pt-BR", ptBR);

const Dayoff = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const driverService = new DriverService();
  const dayoffService = new DayoffService();

  const {
    dayoff: __dayoff = {},
    close: __close = () => {}
  } = options;

  const [dayoff, setDayoff] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(DayoffSchema)), __dayoff)));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:dayoffs:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setDayoff(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeKey, setActiveKey] = useState(ACTIVE_KEY_GENERAL_DATA);

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(dayoff.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dayoff.client),
    label: dayoff.client.type === "LEG" ? (`${dayoff.client.legal.socialReason} | CNPJ: ${maskCNPJ(dayoff.client.legal.cnpj)}`) : (`${dayoff.client.personal.name} | CPF: ${maskCPF(dayoff.client.personal.cpf)}`),
    value: dayoff.client.id
  });

  const [driverSelectedOption, setDriverSelectedOption] = useState(dayoff.driver.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), dayoff.driver),
    label: dayoff.driver.name,
    value: dayoff.driver.id
  });

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if (!running) return false;
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const changeActiveKey = (e) => {
    if (activeKey === e) return setActiveKey("-1");
    setActiveKey(e);
  };

  const driverLoadOptions = (e, c) => {
    driverLoadOptionsDebounce(e, c);
  };

  const driverLoadOptionsDebounce = debounce(async (e, c) => {
    const drivers = await driverService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterDriver(drivers));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : `${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`,
        value: e.id
      };
    });
  };

  const filterDriver = (drivers) => {
    return drivers.map(e => {
      return {
        dataAux: e,
        label: e.name,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if (!valid) {
        if (!message) {
          message = t("Error.Fields.Empty");
        }
        if (eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.Dayoff"),
          content: message
        });
      }
      else {
        let response = false;
        if (isCreate()) {
          response = await dayoffService.create({
            data: handleSubmitForm({ dayoff }),
            token: currentUser.token
          });
        }
        else {
          response = await dayoffService.update({
            id: dayoff.id,
            data: handleSubmitForm({ dayoff }),
            token: currentUser.token
          });
        }

        if (response.success) {
          addNotice({
            title: t("Title.Dayoff"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Dayoff"),
            content: t(`Error.Dayoff.${response.error.type}.${ucFirstAll(response.error.details.path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ dayoff }) => {
    let newDayoff = Object.assign({}, JSON.parse(JSON.stringify(dayoff)));
    newDayoff = clearObject({ data: newDayoff });
    newDayoff.id = undefined;
    return formToJson(newDayoff);
  };

  const inputDisabled = () => {
    if (!running) return false;
    return true;
  };

  const isCreate = () => {
    return dayoff.id === "";
  };

  return (
    <Form id="forms-dayoff" noValidate onSubmit={handleSubmit}>
      <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-dayoff-active">
                    <Form.Label>{t("Label.Active")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const active = e.value;
                        setDayoff(prevState => ({
                          ...prevState,
                          active
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === dayoff.active)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-dayoff-client">
                    <Form.Label>{t("Title.Client")}:</Form.Label>
                    <AsyncSelect
                      className={`menu-outer-bottom ${!isEmpty(dayoff.client.id) ? "is-valid" : "is-invalid"}`}
                      classNamePrefix="select"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled() || !isScopes({
                        currentUser,
                        scopes: [
                          "is:master",
                          "read:dayoffs:all"
                        ],
                        every: false
                      })}
                      loadOptions={clientLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if(e === null) {
                          setClientSelectedOption(e);
                          setDayoff(prevState => ({
                            ...prevState,
                            client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setClientSelectedOption({ dataAux, label, value });
                          setDayoff(prevState => ({
                            ...prevState,
                            client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      placeholder={t("Title.Client.Select")}
                      value={clientSelectedOption}
                      required={true}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(dayoff.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forms-dayoff-driver">
                    <Form.Label>{t("Title.Driver")}:</Form.Label>
                    <AsyncSelect
                      className={`menu-outer-bottom ${!isEmpty(dayoff.driver.id) ? "is-valid" : "is-invalid"}`}
                      classNamePrefix="select"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled()}
                      loadOptions={driverLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if (e === null) {
                          setDriverSelectedOption(e);
                          setDayoff(prevState => ({
                            ...prevState,
                            driver: JSON.parse(JSON.stringify(DriverSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setDriverSelectedOption({ dataAux, label, value });
                          setDayoff(prevState => ({
                            ...prevState,
                            driver: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      placeholder={t("Title.Driver.Select")}
                      value={driverSelectedOption}
                      required={true}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(dayoff.driver.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Driver")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_DETAIL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_DETAIL_DATA)}>{t("Title.DetailData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_DETAIL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <div className="filter-date-fields">
                    <Form.Group controlId="forms-dayoff-start-at">
                      <Form.Label>{t("Title.Date.Start")}:</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <DatePicker
                          disabled={inputDisabled()}
                          selected={DateUtil.toDate({ date: dayoff.dateStart })}
                          onChange={date => {
                            const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                            const dateStart = date === null ? new Date(`${todayDate} 00:00:00`) : date;
                            setDayoff(prevState => ({
                              ...prevState,
                              dateStart
                            }));
                          }}
                          selectsStart
                          startDate={DateUtil.toDate({ date: dayoff.dateStart })}
                          endDate={DateUtil.toDate({ date: dayoff.dateEnd })}
                          minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-01`)}
                          maxDate={DateUtil.toDate({ date: dayoff.dateEnd })}
                          timeFormat="HH:mm"
                          timeIntervals={5}
                          showTimeSelect
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          className="react-datepicker-form-control"
                          id="forms-dayoff-start-at"
                          popperPlacement="top-center"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 10px"
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport"
                            }
                          }}
                          locale="pt-BR"
                          dataeventkey={ACTIVE_KEY_DETAIL_DATA}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="filter-date-fields">
                    <Form.Group controlId="forms-dayoff-end-at">
                      <Form.Label>{t("Title.Date.End")}:</Form.Label>
                      <InputGroup className="mb-3">
                        <InputGroup.Prepend>
                          <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                        </InputGroup.Prepend>
                        <DatePicker
                          disabled={inputDisabled()}
                          selected={DateUtil.toDate({ date: dayoff.dateEnd })}
                          onChange={date => {
                            const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
                            const dateEnd = date === null ? new Date(`${todayDate} 23:55:00`) : date;
                            setDayoff(prevState => ({
                              ...prevState,
                              dateEnd
                            }));
                          }}
                          selectsEnd
                          startDate={DateUtil.toDate({ date: dayoff.dateStart })}
                          endDate={DateUtil.toDate({ date: dayoff.dateEnd })}
                          minDate={DateUtil.toDate({ date: dayoff.dateStart })}
                          timeFormat="HH:mm"
                          timeIntervals={5}
                          showTimeSelect
                          showMonthDropdown
                          showYearDropdown
                          dateFormat="dd/MM/yyyy HH:mm:ss"
                          className="react-datepicker-form-control"
                          id="forms-dayoff-end-at"
                          popperPlacement="top-center"
                          popperModifiers={{
                            offset: {
                              enabled: true,
                              offset: "5px, 10px"
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false,
                              boundariesElement: "viewport"
                            }
                          }}
                          locale="pt-BR"
                          dataeventkey={ACTIVE_KEY_DETAIL_DATA}
                        />
                      </InputGroup>
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forms-dayoff-description">
                    <Form.Label>{t("Label.Description")}:</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder={t("Label.Description")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const description = e.target.value;
                        setDayoff(prevState => ({
                          ...prevState,
                          description
                        }));
                      }}
                      autoComplete="off"
                      value={dayoff.description}
                      isValid={!isEmpty(dayoff.description)}
                      isInvalid={isEmpty(dayoff.description)}
                      dataeventkey={ACTIVE_KEY_DETAIL_DATA}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Description")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Dayoff));
