import React, { memo } from "react";
import {
  Col,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import PatrimonyMap from "./../PatrimonyMap";

const Patrimony = ({
  props: {
    currentUser,
    patrimoniesSearchSelected
  },
  funcs: {
    addNotice,
    enableLoader,
    disableLoader
  }
}) => {
  const { t } = useTranslation(); //eslint-disable-line
  return (
    <div className="patrimony-simulators">
      <Row>
        <Col xs={12}>
          <PatrimonyMap trackerOptions={{ hatch: false, niple: "6274275", tracker: "8017566", rs232: true }} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    patrimoniesSearchSelected: state.patrimonies.patrimoniesSearchSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Patrimony));
