import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as UsersActions } from "./../../store/ducks/users";

import { mergeObject } from "./../../utils/Object";
import { isScopes } from "./../../services/Auth";

import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

import UserService from "./../../services/User";

import FilterUserItem from "./../FilterUserItem";
import SwitchDefault from "./../Switch/Default";

const FilterUser = ({
  props: { currentUser, users, usersSearch, usersSearchOnlyClient, usersSearchQueryEnabled },
  funcs: { disableLoader, enableLoader, searchUsers, setUsers, setUsersSelected },
  options: { filterMultiple }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const userService = new UserService();

  const [switchAllChecked, setSwitchAllChecked] = useState(false);
  const [switchSingleChecked, setSwitchSingleChecked] = useState(false);
  const [_usersSelected, _setUsersSelected] = useState([]);

  const [_usersSearch, _setUsersSearch] = useState({
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: -1
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") },
    { value: -1, label: t("All") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(_usersSearch.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), _usersSearch.client),
    label: _usersSearch.client.type === "LEG" ? _usersSearch.client.legal.socialReason : _usersSearch.client.personal.name,
    value: _usersSearch.client.id
  });

  useEffect(() => {
    findUsers();
  }, []); /* eslint-disable-line */

  useEffect(() => {
    searchUsers(_usersSearch);
  }, [_usersSearch]); /* eslint-disable-line */

  useEffect(() => {
    if(!switchSingleChecked) {
      if(switchAllChecked) {
        return _setUsersSelected(usersSearchOnlyClient);
      }
      return _setUsersSelected([]);
    }
    setSwitchSingleChecked(false);
  }, [switchAllChecked]); /* eslint-disable-line */

  useEffect(() => {
    setUsersSelected(_usersSelected);
    if(_usersSelected.length > 0 && (usersSearchOnlyClient.length === _usersSelected.length)) {
      return setSwitchAllChecked(true);
    }
    setSwitchAllChecked(false);
  }, [_usersSelected]); /* eslint-disable-line */

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? e.legal.socialReason : e.personal.name,
        value: e.id
      };
    });
  };

  const findUsers = async () => {
    enableLoader();
    const users = await userService.findAll({
      data: {
        limit: 1000,
        page: 1,
        search: ""
      },
      token: currentUser.token
    });
    setUsers(users.docs);
    disableLoader();
  };

  const handleClickUser = (e, user) => {
    if(filterMultiple) {
      return handleClickUserMultiple(e, user);
    }
    return handleClickUserSingle(e, user);
  };

  const handleClickUserMultiple = (e, user) => {
    const usersSelected = _usersSelected.filter(x => x.id !== user.id);
    if(!e) {
      usersSelected.push(user);
    }
    setSwitchSingleChecked(true);
    _setUsersSelected(usersSelected);
  };

  const handleClickUserSingle = (e, user) => {
    const usersSelected = [user];
    setSwitchSingleChecked(true);
    _setUsersSelected(usersSelected);
  };

  return (
    <Card>
      <Card.Header>{t("Title.User")}</Card.Header>
      <Card.Body>
        <Row>
          {
            isScopes({ currentUser, scopes: [
              "is:master",
              "read:users:all"
            ], every: false }) ? (
                <>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-user-search-client">
                      <AsyncSelect
                        className="menu-outer-bottom menu-outer-zindex"
                        classNamePrefix="select"
                        cacheOptions
                        defaultOptions
                        isClearable
                        loadOptions={clientLoadOptions}
                        loadingMessage={() => t("React.Select.Wait")}
                        noOptionsMessage={() => t("React.Select.NoOptions")}
                        onChange={e => {
                          if(e === null) {
                            setClientSelectedOption(e);
                            _setUsersSearch(prevState => ({
                              ...prevState,
                              client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                            }));
                          }
                          else {
                            const { dataAux, label, value } = e;
                            setClientSelectedOption({ dataAux, label, value });
                            _setUsersSearch(prevState => ({
                              ...prevState,
                              client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                            }));
                          }
                          setSwitchAllChecked(true);
                          setTimeout(() => {
                            setSwitchAllChecked(false);
                          }, 100);
                        }}
                        placeholder={t("React.Select.SelectClient")}
                        value={clientSelectedOption}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="filter-user-search-user">
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.Search")}
                        autoComplete={"off"}
                        onChange={e => {
                          e.persist();
                          _setUsersSearch(prevState => ({
                            ...prevState,
                            word: e.target.value
                          }));
                        }}
                        value={_usersSearch.word}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Group controlId="forms-chassi-active">
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        options={activeOptions}
                        onChange={e => {
                          if(e === null) {
                            _setUsersSearch(prevState => ({
                              ...prevState,
                              active: -1
                            }));
                          }
                          else {
                            _setUsersSearch(prevState => ({
                              ...prevState,
                              active: e.value
                            }));
                          }
                          setSwitchAllChecked(true);
                          setTimeout(() => {
                            setSwitchAllChecked(false);
                          }, 100);
                        }}
                        value={activeOptions.find(x => x.value === _usersSearch.active)}
                      />
                    </Form.Group>
                  </Col>
                </>
              ) : (
                <Col>
                  <Form.Group controlId="filter-user-search-user">
                    <Form.Control
                      type="text"
                      placeholder={t("Placeholder.Search")}
                      autoComplete={"off"}
                      onChange={e => {
                        e.persist();
                        _setUsersSearch(prevState => ({
                          ...prevState,
                          word: e.target.value
                        }));
                      }}
                      value={_usersSearch.word}
                    />
                  </Form.Group>
                </Col>
              )
          }
        </Row>
        <Row>
          <Col>
            <div className="filter-user-list-all">
              {
                filterMultiple ? (
                  <div className="filter-user-select-all">
                    <SwitchDefault title={`${t("Title.All")} (${usersSearchQueryEnabled ? usersSearch.length : users.length})`} selected={switchAllChecked} onClick={e => {
                      setSwitchSingleChecked(false);
                      setSwitchAllChecked(!switchAllChecked);
                    }} />
                  </div>
                ) : null
              }
              <div className="filter-user-list-box">
                <ul>
                  {
                    usersSearchQueryEnabled ? (
                      usersSearch.map(user => {
                        const selected = _usersSelected.find(x => x.id === user.id) ? true : false;
                        return (
                          <FilterUserItem
                            key={`filter-user-list-item-${user.id}`}
                            checkbox={filterMultiple}
                            name={filterMultiple ? `filter-user-list-item-${user.id}` : "filter-user-list-item"}
                            user={user}
                            selected={selected}
                            onClick={e => handleClickUser(selected, user)}
                          />
                        );
                      })
                    ) : (
                      users.map(user => {
                        const selected = _usersSelected.find(x => x.id === user.id) ? true : false;
                        return (
                          <FilterUserItem
                            key={`filter-user-list-item-${user.id}`}
                            checkbox={filterMultiple}
                            name={filterMultiple ? `filter-user-list-item-${user.id}` : "filter-user-list-item"}
                            user={user}
                            selected={selected}
                            onClick={e => handleClickUser(selected, user)}
                          />
                        );
                      })
                    )
                  }
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    users: state.users.users,
    usersSearch: state.users.usersSearch,
    usersSearchOnlyClient: state.users.usersSearchOnlyClient,
    usersSearchQueryEnabled: state.users.usersSearchQueryEnabled
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, UsersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterUser));
