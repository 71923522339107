import React, { useState, useEffect } from "react";
import {
  Form
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LastDelayedLocationActions } from "./../../store/ducks/lastDelayedLocations";

const LastDelayedLocationSearch = ({
  props: { lastPositionsSearchQuery, lastPositionSelected },
  funcs: { searchLastDelayedLocation }
}) => {
  const { t } = useTranslation();
  const [delayedLocationsSearch, setDelayedLocationsSearch] = useState({
    client: lastPositionsSearchQuery.client,
    niple: lastPositionsSearchQuery.niple,
    patrimony: lastPositionSelected ? lastPositionSelected.position.patrimony.id : "",
    word: ""
  });

  useEffect(() => {
    searchLastDelayedLocation(delayedLocationsSearch);
  }, [delayedLocationsSearch, searchLastDelayedLocation]);

  useEffect(() => {
    setDelayedLocationsSearch(prevState => ({
      ...prevState,
      client: lastPositionsSearchQuery.client,
      niple: lastPositionsSearchQuery.niple,
      patrimony: lastPositionSelected ? lastPositionSelected.position.patrimony.id : ""
    }));
  }, [lastPositionsSearchQuery, lastPositionSelected]);

  return (
    <form onSubmit={e => e.preventDefault()}>
      <Form.Group controlId="delayed-locations-item-form-search">
        <Form.Control
          type="text"
          placeholder={t("Placeholder.Search")}
          autoComplete={"off"}
          onChange={e => setDelayedLocationsSearch(prevState => ({
            ...prevState,
            word: e.target.value
          }))}
          value={delayedLocationsSearch.word}
          disabled={false}
        />
      </Form.Group>
    </form>
  );
};

const mapStateToProps = state => ({
  props: {
    lastPositionsSearchQuery: state.lastPositions.positionsSearchQuery,
    lastPositionSelected: state.lastPositions.positionSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastDelayedLocationActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastDelayedLocationSearch);
