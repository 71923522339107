import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Nav,
  Row,
  Spinner,
  Tab
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { maskCNPJ } from "./../../utils/CNPJ";
import { maskCPF } from "./../../utils/CPF";
import { isValidForm } from "./../../utils/Form";
import { clearObject, mergeObject } from "./../../utils/Object";
import { getProfiles } from "./../../utils/Profile";
import { isEmpty, ucFirstAll } from "./../../utils/String";

import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";
import ProfileService from "./../../services/Profile";
import ProfileSchema from "./../../services/Profile/Schema";
import ProfileScopes from "./../../services/Profile/Scopes";

import SwitchDefault from "./../../components/Switch/Default";

const Profile = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const profileService = new ProfileService();
  const clientService = new ClientService();

  const {
    profile: __profile = {},
    close: __close = () => {}
  } = options;

  const [profile, setProfile] = useState(mergeObject(JSON.parse(JSON.stringify(ProfileSchema)), __profile));
  const [profileScopes] = useState(JSON.parse(JSON.stringify(ProfileScopes)));
  const [profileScopesSelected, setProfileScopesSelected] = useState(getProfiles(JSON.parse(JSON.stringify(ProfileScopes)), JSON.parse(JSON.stringify(profile.scopes))));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:profiles:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setProfile(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  useEffect(() => {
    const scopes = [];
    profileScopesSelected.map(profile => {
      return profile.scopes.map(scope => scopes.push(scope.scope));
    });
    const uniqueScopes = [...new Set(scopes)];
    setProfile({
      ...profile,
      scopes: uniqueScopes.map(scope => {
        return {
          scope
        };
      })
    });
  }, [profileScopesSelected]); /* eslint-disable-line */

  const [clientSelectedOption, setClientSelectedOption] = useState(profile.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), profile.client),
    label: profile.client.type === "LEG" ? (`${profile.client.legal.socialReason} | CNPJ: ${maskCNPJ(profile.client.legal.cnpj)}`) : (`${profile.client.personal.name} | CPF: ${maskCPF(profile.client.personal.cpf)}`),
    value: profile.client.id
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : (`${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`),
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Profile"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await profileService.create({
            data: handleSubmitForm({ profile }),
            token: currentUser.token
          });
        }
        else {
          response = await profileService.update({
            id: profile.id,
            data: handleSubmitForm({ profile }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Profile"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Profile"),
            content: t(`Error.Profile.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ profile }) => {
    let newProfile = Object.assign({}, JSON.parse(JSON.stringify(profile)));
    newProfile = clearObject({ data: newProfile });
    newProfile.id = undefined;
    return newProfile;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return profile.id === "";
  };

  const isMaster = (scope = false) => {
    if(scope) {
      return scope === "access:users:master";
    }
    return profileScopesSelected.findIndex(x => x.id === "access:users:master") !== -1 ? true : false;
  };

  const isMasterScope = (scope) => {
    return scope === "access:users:read";
  };

  return (
    <Form id="forms-profile" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-profile-active">
                <Form.Label>{t("Label.Active")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  options={activeOptions}
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const active = e.value;
                    setProfile(prevState => ({
                      ...prevState,
                      active
                    }));
                  }}
                  value={activeOptions.find(x => x.value === profile.active)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-user-client">
                <Form.Label>{t("Title.Client")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(profile.client.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || !isScopes({
                    currentUser,
                    scopes: [
                      "is:master",
                      "read:profiles:all"
                    ],
                    every: false
                  })}
                  loadOptions={clientLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setClientSelectedOption(e);
                      setProfile(prevState => ({
                        ...prevState,
                        client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setClientSelectedOption({ dataAux, label, value });
                      setProfile(prevState => ({
                        ...prevState,
                        client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Client.Select")}
                  value={clientSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(profile.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="forms-profile-name">
                <Form.Label>{t("Label.Name")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Name")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const name = e.target.value;
                    setProfile(prevState => ({
                      ...prevState,
                      name: name.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={profile.name}
                  isValid={!isEmpty(profile.name)}
                  isInvalid={isEmpty(profile.name)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Tab.Container defaultActiveKey={profileScopes.default}>
                <Row>
                  <Col xs={3}>
                    <Nav variant="pills" className="flex-column">
                      {
                        profileScopes.types.map((type, index) => {
                          return (
                            <Nav.Item key={`profile-item-${index}`}>
                              <Nav.Link eventKey={type.id}>{type.name} <div><i className={type.icon} /></div></Nav.Link>
                            </Nav.Item>
                          );
                        })
                      }
                    </Nav>
                  </Col>
                  <Col xs={9}>
                    <Tab.Content>
                      {
                        profileScopes.types.map((type, tindex) => {
                          return (
                            <Tab.Pane eventKey={type.id} key={`profile-pane-${tindex}`}>
                              <Accordion defaultActiveKey={type.default}>
                                {
                                  type.items.map((item, index) => {
                                    return (
                                      <Card key={`profile-group-${index}`}>
                                        <Accordion.Toggle as={Card.Header} eventKey={item.id}>{item.name}</Accordion.Toggle>
                                        <Accordion.Collapse eventKey={item.id}>
                                          <Card.Body>
                                            <Row>
                                              {
                                                item.items.map((subitem, subindex) => {
                                                  const index = profileScopesSelected.findIndex(x => x.id === subitem.id);
                                                  const selected = index !== -1 ? true : false;
                                                  return (
                                                    <Col className="card-item-margin" xs={12} md={4} key={`profile-group-item-${subindex}`}>
                                                      <SwitchDefault disabled={(!isMaster() || (isMaster() && isMasterScope(subitem.checkId))) ? false : true} name={subitem.id} selected={(!isMaster() || (isMaster() && isMasterScope(subitem.checkId))) ? selected : true} title={subitem.name} onClick={e => {
                                                        if(!isMaster() || (isMaster() && isMasterScope(subitem.checkId))) {
                                                          let profileScopesSelectedAux = JSON.parse(JSON.stringify(profileScopesSelected));
                                                          if(!selected) {
                                                            if(isMaster(subitem.id)) {
                                                              profileScopesSelectedAux = [];
                                                            }
                                                            else {
                                                              profileScopesSelectedAux = profileScopesSelectedAux.filter(x => x.checkId !== subitem.checkId);
                                                            }
                                                            profileScopesSelectedAux.push(subitem);
                                                          }
                                                          else {
                                                            profileScopesSelectedAux.splice(index, 1);
                                                          }
                                                          setProfileScopesSelected(profileScopesSelectedAux);
                                                        }
                                                      }} />
                                                    </Col>
                                                  );
                                                })
                                              }
                                            </Row>
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      </Card>
                                    );
                                  })
                                }
                              </Accordion>
                            </Tab.Pane>
                          );
                        })
                      }
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Profile));
