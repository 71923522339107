import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isValidForm } from "./../../utils/Form";
import { clearObject, mergeObject } from "./../../utils/Object";
import { isEmpty, ucFirstAll } from "./../../utils/String";
import { jsonToForm } from "./../../utils/User";

import UserService from "./../../services/User";
import UserSchema from "./../../services/User/Schema";

const Password = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const userService = new UserService();

  const {
    user: __user = {}
  } = options;

  const [user, setUser] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(UserSchema)), {
    ...__user,
    password: ""
  })));

  useEffect(() => {
    const newUser = JSON.parse(JSON.stringify(currentUser));
    setUser({
      ...newUser,
      password: ""
    });
  }, [currentUser]);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [oldPasswordChecked, setOldPasswordChecked] = useState(false);
  const [oldPasswordCheckedCount, setOldPasswordCheckedCount] = useState(0);

  const [running, setRunning] = useState(false);

  const buttonNextDisabled = () => {
    if(!running && !isEmpty(oldPassword)) {
      return false;
    }
    return true;
  };

  const buttonDisabled = () => {
    if(!running && oldPasswordChecked) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { message, valid } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.User"),
          content: message
        });
      }
      else {
        let response = await userService.update({
          id: user.id,
          data: handleSubmitForm({ user }),
          token: currentUser.token
        });
        if(response.success) {
          addNotice({
            title: t("Title.User"),
            content: t("Success.Update")
          });
          setOldPassword("");
          setOldPasswordChecked(false);
          setOldPasswordCheckedCount(0);
          setConfirmPassword("");
          setUser(prevState => ({
            ...prevState,
            password: ""
          }));
        }
        else {
          addNotice({
            title: t("Title.User"),
            content: t(`Error.User.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ user }) => {
    let newUser = Object.assign({}, JSON.parse(JSON.stringify(user)));
    newUser = clearObject({ data: newUser });
    newUser.id = undefined;
    return newUser;
  };

  const inputDisabled = () => {
    if(!running || !oldPasswordChecked) {
      return false;
    }
    return true;
  };

  return (
    <div className="user-profiles-password">
      <Form id="forms-profiles-user" noValidate onSubmit={handleSubmit}>
        <Card>
          <Card.Header>{t("Title.GeneralData")}</Card.Header>
          <Card.Body>
            <div className={`${oldPasswordChecked ? "hide" : ""}`}>
              <Row>
                <Col>
                  <Form.Group controlId="forms-user-old-password">
                    <Form.Label>{t("Label.OldPassword")}:</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("Placeholder.OldPassword")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const oldPassword = e.target.value;
                        setOldPassword(oldPassword);
                      }}
                      autoComplete="off"
                      value={oldPassword}
                      isValid={!isEmpty(oldPassword) && oldPasswordChecked}
                      isInvalid={isEmpty(oldPassword) || !oldPasswordChecked}
                    />
                    <Form.Control.Feedback type="invalid">
                      {
                        isEmpty(oldPassword) || (!oldPasswordChecked && oldPasswordCheckedCount === 0) ? (
                          t("Feedback.Password")
                        ) : (
                          t("Error.User.WrongPasswordError")
                        )
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="default-form-button">
                    <Button
                      variant="dark"
                      type="button"
                      disabled={buttonNextDisabled()}
                      onClick={async e => {
                        if(!running) {
                          setRunning(true);
                          const oldPasswordData = await userService.checkPassword({ data: { password: oldPassword }, token: currentUser.token });
                          if(oldPasswordData) {
                            setOldPasswordChecked(oldPasswordData.success);
                          }
                          setOldPasswordCheckedCount(oldPasswordCheckedCount + 1);
                          setRunning(false);
                        }
                      }}
                    >
                      {running ? <Spinner animation="border" size="sm" /> : t("Button.Next")}
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
            <div className={`${oldPasswordChecked ? "" : "hide"}`}>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-password">
                    <Form.Label>{t("Label.Password")}:</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("Placeholder.Password")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        if(oldPasswordChecked) {
                          const password = e.target.value;
                          setUser(prevState => ({
                            ...prevState,
                            password
                          }));
                        }
                        else {
                          setUser(prevState => ({
                            ...prevState,
                            password: ""
                          }));
                        }
                      }}
                      autoComplete="off"
                      value={user.password}
                      isValid={!isEmpty(user.password)}
                      isInvalid={isEmpty(user.password)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Password")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-confirm-password">
                    <Form.Label>{t("Label.ConfirmPassword")}:</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder={t("Placeholder.ConfirmPassword")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        if(oldPasswordChecked) {
                          const confirmPassword = e.target.value;
                          setConfirmPassword(confirmPassword);
                        }
                        else {
                          setConfirmPassword("");
                        }
                      }}
                      autoComplete="off"
                      value={confirmPassword}
                      isValid={!isEmpty(confirmPassword) && !isEmpty(user.password) && (confirmPassword === user.password)}
                      isInvalid={isEmpty(confirmPassword) || isEmpty(user.password) || (confirmPassword !== user.password)}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">
                      {
                        isEmpty(confirmPassword) || isEmpty(user.password) ? (
                          t("Feedback.ConfirmPassword")
                        ) : (
                          t("Feedback.Format.ConfirmPassword")
                        )
                      }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </div>
          </Card.Body>
        </Card>
        <Form.Group className={`default-form-button ${oldPasswordChecked ? "" : "hide"}`}>
          <Button
            variant="dark"
            type="submit"
            disabled={buttonDisabled()}
          >
            {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Password));
