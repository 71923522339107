import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import Grid from "./../Grid";
import { sortPositionsPolyline } from "./../../utils/Position";

import { getCols as getColsTranshipment, getRows as getRowsTranshipment } from "./../../services/Transhipment/Transhipment";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as TranshipmentsActions } from "./../../store/ducks/transhipments";
import { Creators as UsersActions } from "./../../store/ducks/users";

const MenuSingleTranshipmentPositions = ({
  props: { currentUser, lastPositionSelected, singleTranshipment, singleTranshipmentMenu, singleTranshipmentMenuSelected, singleTranshipmentMenuPositionsSelected },
  funcs: { addSingleTranshipment, addSingleTranshipmentMenuPositionsMarkers, closeMenuPositions, disableLoader, enableLoader, menuHandleClose, menuHandleOpen, openMenuPositions, removeSingleTranshipment, removeSingleTranshipmentMenuPositionsMarkers, selectMenuSingleTranshipment }
}) => {
  const { t } = useTranslation();

  const [grid, setGrid] = useState(null);
  const [idSelected, setIdSelected] = useState(false);

  const [gridTypes] = useState([{
    id: "transhipment",
    getCols: () => getColsTranshipment(),
    getRows: ({ docs, t }) => getRowsTranshipment(docs, t)
  }]);

  const getCols = ({ singleTranshipmentMenuSelected }) => {
    const findGetCols = gridTypes.find(x => x.id === singleTranshipmentMenuSelected.id);
    if(typeof findGetCols !== "undefined") {
      return findGetCols.getCols();
    }
    return [];
  };

  const getDocs = ({ singleTranshipment, singleTranshipmentMenuSelected }) => {
    if(singleTranshipmentMenuSelected.id === "transhipment") {
      return sortPositionsPolyline(singleTranshipment.transhipment);
    }
    return sortPositionsPolyline(singleTranshipmentMenuSelected.value);
  };

  const getRows = ({ docs, singleTranshipmentMenuSelected }) => {
    const findGetRows = gridTypes.find(x => x.id === singleTranshipmentMenuSelected.id);
    if(typeof findGetRows !== "undefined") {
      return findGetRows.getRows({ docs, t });
    }
    return [];
  };

  useEffect(() => {
    setIdSelected(singleTranshipmentMenuSelected.id);
    setGrid(null);

    const cols = getCols({ singleTranshipmentMenuSelected });
    const docs = getDocs({ singleTranshipment, singleTranshipmentMenuSelected });
    const rows = getRows({ docs, singleTranshipmentMenuSelected });

    setTimeout(() => {
      setGrid(<Grid options={{
        actions: [],
        api: false,
        cols,
        docs,
        rows,
        title: "Teste",
        onSelect: (e) => addSingleTranshipmentMenuPositionsMarkers(e),
        onDeselect: (e) => removeSingleTranshipmentMenuPositionsMarkers(e),
        onSearch: (e) => {
          const docs = getDocs({ singleTranshipment, singleTranshipmentMenuSelected });
          const rows = getRows({ docs, singleTranshipmentMenuSelected });
          const docsSelected = [];
          const rowsSelected = [];
          var col, confirm, row, value;
          for(var i = 0; i < rows.length; i++) {
            row = rows[i];
            confirm = false;
            for(var j = 0; j < row.length; j++) {
              col = row[j];
              value = col.value.toString();
              if(value.toLowerCase().indexOf(e.toString().toLowerCase()) !== -1) {
                confirm = true;
              }
              if((j + 1) === row.length) {
        				if(confirm) {
                  docsSelected.push(docs[i]);
                  rowsSelected.push(rows[i]);
                }
              }
            }
          }
          return {
            docs: docsSelected,
            rows: rowsSelected
          };
        }
      }} />);
    }, 100);
  }, [idSelected, singleTranshipmentMenuSelected]); /* eslint-disable-line */

  return (
    <div className="menu-single-transhipment-positions">{grid}</div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    lastPositionSelected: state.lastPositions.positionSelected,
    singleTranshipment: state.transhipments.singleTranshipment,
    singleTranshipmentMenu: state.transhipments.singleTranshipmentMenu,
    singleTranshipmentMenuSelected: state.transhipments.singleTranshipmentMenuSelected,
    singleTranshipmentMenuPositionsSelected: state.transhipments.singleTranshipmentMenuPositionsSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, MenusActions, TranshipmentsActions, UsersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuSingleTranshipmentPositions));
