import React, { memo } from "react";
import "./index.css";

const SwitchPatrimony = ({ checkbox = true, name, patrimony, selected } = {}) => {

  const getBoardType = (patrimony) => {
    switch (patrimony.type) {
    case "AUT":
      if (patrimony.automobile.type === "BOA") {
        return patrimony.automobile.board.type;
      }
      return "CAR";
    case "OBJ":
      return "OBJ";
    case "VES":
      return "VES";
    default:
      return "CAR";
    }
  };

  /**
   * Marker data content
   */
  const markerDataContent = () => {
    switch (patrimony.type) {
    case "AUT":
      return markerAutomobile();
    case "OBJ":
      return markerObject();
    case "VES":
      return markerVessel();
    default:
      return markerAutomobile();
    }
  };

  const markerAutomobile = () => {
    switch (patrimony.automobile.type) {
    case "BOA":
      return markerAutomobileBoard();
    case "CAR":
      return markerAutomobileCard();
    default:
      return markerAutomobileBoard();
    }
  };

  const markerAutomobileBoard = () => {
    const board = patrimony.automobile.board;
    if(board.layout === "MER") {
      return (
        <>
          <div className="marker-data-top">
            <div className="marker-data-top-mercosul" />
            <div className="marker-data-top-country" />
            <div className="marker-data-top-flag" />
          </div>
          <div className="marker-data-bottom">
            {
              (board.type !== "MOT") ? (
                <div className="marker-data-bottom-board">{board.board}</div>
              ) : (
                <div className="marker-data-bottom-board">
                  <div className="marker-board-top">{board.board.substr(0, 3)}</div>
                  <div className="marker-board-bottom">{board.board.substr(3, 4)}</div>
                </div>
              )
            }
          </div>
        </>
      );
    }
    else {
      return (
        <>
          <div className="marker-screw top" />
          <div className="marker-screw bottom" />
          <div className="marker-description">
            <div className="marker-description-all">{board.description}</div>
          </div>
          <div className="marker-board">
            {
              (board.type !== "MOT") ? (
                <div className="marker-board-all">{board.board}</div>
              ) : (
                <>
                  <div className="marker-board-top">{board.board.substr(0, 3)}</div>
                  <div className="marker-board-bottom">{board.board.substr(4, 4)}</div>
                </>
              )
            }
          </div>
        </>
      );
    }
  };

  const markerAutomobileCard = () => {
    return (
      <div className="marker-mini-board-all">{patrimony.automobile.card.number}</div>
    );
  };

  const markerObject = () => {
    return (
      <div className="marker-mini-board-all">{patrimony.object.name}</div>
    );
  };

  const markerVessel = () => {
    const { description, name, registrationNumber } = patrimony.vessel;
    return (
      <div className="marker-mini-board-all">
        <div className="marker-vessel-icon" />
        <ul>
          <li><div>{description}</div></li>
          <li><div>{name}</div></li>
          <li><div>{registrationNumber}</div></li>
        </ul>
      </div>
    );
  };

  return (
    <div
      className="marker-all marker-all-switch"
      board-color={(patrimony.automobile && patrimony.automobile.board && patrimony.automobile.board.color) || ""}
      board-country={(patrimony.automobile && patrimony.automobile.board && patrimony.automobile.board.country) || ""}
      board-layout={(patrimony.automobile && patrimony.automobile.board && patrimony.automobile.board.layout) || ""}
      board-type={getBoardType(patrimony || "")}
      mini-markers={"false"}
    >
      <input
        className="marker-data-checkbox"
        type={`${checkbox ? "checkbox" : "radio"}`}
        checked={selected}
        name={name}
        readOnly={true}
      />
      <div className="marker-data">
        <div className="marker-data-content">
          { markerDataContent() }
        </div>
      </div>
    </div>
  );
};

export default memo(SwitchPatrimony);
