import React, { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.css";

const DEFAULT_ZINDEX = 5000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 1;

const ExportedCluster = ({ count, size, onClick, exType }) => {
  const { t } = useTranslation();
  const [exportedHover, setExportedHover] = useState(false);
  const [scale, setScale] = useState(SCALE_NORMAL);
  const [sizeBox] = useState("30px");
  const [sizeBorder] = useState("40px");
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(exportedHover ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) + (exportedHover ? DEFAULT_ZINDEX : 0));
  }, [exportedHover, scale]);

  const getExportedClusterType = () => {
    const exTypes = [
      {
        id: "maintenance",
        title: t("Title.Niple.Maintenance"),
        icon: <span>M</span>
      },
      {
        id: "moving",
        title: t("Title.Moving"),
        icon: <span>E</span>
      },
      {
        id: "nipleDischarge",
        title: t("Title.Niple.Discharge"),
        icon: <span>D</span>
      },
      {
        id: "nipleFraud",
        title: t("Title.Niple.Fraud"),
        icon: <span>B</span>
      },
      {
        id: "route",
        title: t("Title.Route"),
        icon: <i className="fas fa-map-marked-alt" />
      }
    ];

    const exTypeFilter = exTypes.filter(x => x.id === exType());
    if(exTypeFilter.length > 0) {
      return exTypeFilter[0];
    }
    return false;
  };

  const exportedDetails = getExportedClusterType();
  return (
    <div className="marker-exported-cluster-all"
      onClick={onClick}
      onMouseEnter={e => setExportedHover(true)}
      onMouseLeave={e => setExportedHover(false)}
      style={{
        width: sizeBorder,
        height: sizeBorder,
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div
        className="marker-exported-cluster"
        style={{ width: sizeBox, height: sizeBox }}
      >
        <div className="icon">{exportedDetails.icon}</div>
        <div className="count">{count}</div>
      </div>
    </div>
  );
};

export default memo(ExportedCluster);
