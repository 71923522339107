import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as ExportedsActions } from "./../../store/ducks/exporteds";
import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as RoutesActions } from "./../../store/ducks/routes";
import { Creators as TranshipmentsActions } from "./../../store/ducks/transhipments";
import LastPositionListItem from "./../LastPositionListItem";
import LastPositionSelectedItem from "./../LastPositionSelectedItem";

import PositionService from "./../../services/Position";

const LastPositionList = ({
  props: {
    currentUser,
    databaseTest,
    lastPositions,
    lastPositionsSearch,
    lastPositionsSearchQueryEnabled,
    lastPositionSelected,
    singleExported,
    singleExportedStartEnd,
    singleRoute,
    singleRouteStartEnd
  },
  funcs: {
    addSingleExported,
    addSingleRoute,
    disableLoader,
    enableLoader,
    menuHandleClose,
    removeSingleExported,
    removeSingleRoute,
    removeSingleTranshipment,
    selectLastPosition
  }
}) => {
  const handleExported = async (lastPositionSelected, endDate, startDate) => {
    enableLoader();
    const patrimony = lastPositionSelected.position.patrimony.id;
    const response = databaseTest ? await PositionService.findExportedRouteTest({
      endDate,
      page: 1,
      patrimony,
      startDate,
      token: currentUser.token
    }) : await PositionService.findExportedRoute({
      endDate,
      page: 1,
      patrimony,
      startDate,
      token: currentUser.token
    });
    const { docs, page, pages } = response.positions;
    addSingleExported(endDate, { page, pages }, lastPositionSelected, docs, startDate);
    menuHandleClose();
    disableLoader();
  };

  const handleRoute = async (lastPositionSelected, endDate, startDate, orderAsc) => {
    enableLoader();
    const patrimony = lastPositionSelected.position.patrimony;
    const downtimeTolerance = typeof patrimony.parameters !== "undefined" ? patrimony.parameters.downtimeTolerance : undefined;
    const speedTolerance = typeof patrimony.parameters !== "undefined" ? patrimony.parameters.speedTolerance : undefined;
    const response = databaseTest ? await PositionService.findRouteTest({
      endDate,
      orderAsc,
      page: 1,
      patrimony: patrimony.id,
      startDate,
      token: currentUser.token
    }) : await PositionService.findRoute({
      endDate,
      orderAsc,
      page: 1,
      patrimony: patrimony.id,
      startDate,
      token: currentUser.token
    });
    const { docs, page, pages } = response.positions;
    addSingleRoute(endDate, { orderAsc, page, pages }, lastPositionSelected, docs, startDate, { downtimeTolerance, speedTolerance });
    menuHandleClose();
    disableLoader();
  };

  if(lastPositionSelected) {
    return (
      <LastPositionSelectedItem key={lastPositionSelected.id} options={{
        ...lastPositionSelected,
        hasBack: () => {
          const indexNow = lastPositionsSearchQueryEnabled ? lastPositionsSearch.findIndex(x => x.id === lastPositionSelected.id) : lastPositions.findIndex(x => x.id === lastPositionSelected.id);
          if (indexNow > 0) {
            return true;
          }
          return false;
        },
        hasNext: () => {
          const indexNow = lastPositionsSearchQueryEnabled ? lastPositionsSearch.findIndex(x => x.id === lastPositionSelected.id) : lastPositions.findIndex(x => x.id === lastPositionSelected.id);
          const indexAll = lastPositionsSearchQueryEnabled ? (lastPositionsSearch.length - 1) : (lastPositions.length - 1);
          if (indexNow < indexAll) {
            return true;
          }
          return false;
        },
        onClick: () => {
          removeSingleExported();
          removeSingleRoute();
          removeSingleTranshipment();
          selectLastPosition(lastPositionSelected.id);
        },
        onClickBack: async () => {
          const indexNow = lastPositionsSearchQueryEnabled ? lastPositionsSearch.findIndex(x => x.id === lastPositionSelected.id) : lastPositions.findIndex(x => x.id === lastPositionSelected.id);
          if (indexNow > 0) {
            removeSingleExported();
            removeSingleRoute();
            removeSingleTranshipment();
            const newPositionSelected = lastPositionsSearchQueryEnabled ? lastPositionsSearch[indexNow - 1] : lastPositions[indexNow - 1];
            selectLastPosition(newPositionSelected.id);
            if (singleExported) {
              const { end, start } = singleExportedStartEnd;
              await handleExported(newPositionSelected, end, start);
            }
            else if (singleRoute) {
              const { orderAsc } = singleRoute;
              const { end, start } = singleRouteStartEnd;
              await handleRoute(newPositionSelected, end, start, orderAsc);
            }
          }
        },
        onClickNext: async () => {
          const indexNow = lastPositionsSearchQueryEnabled ? lastPositionsSearch.findIndex(x => x.id === lastPositionSelected.id) : lastPositions.findIndex(x => x.id === lastPositionSelected.id);
          const indexAll = lastPositionsSearchQueryEnabled ? (lastPositionsSearch.length - 1) : (lastPositions.length - 1);
          if (indexNow < indexAll) {
            removeSingleExported();
            removeSingleRoute();
            removeSingleTranshipment();
            const newPositionSelected = lastPositionsSearchQueryEnabled ? lastPositionsSearch[indexNow + 1] : lastPositions[indexNow + 1];
            selectLastPosition(newPositionSelected.id);
            if (singleExported) {
              const { end, start } = singleExportedStartEnd;
              await handleExported(newPositionSelected, end, start);
            }
            else if (singleRoute) {
              const { orderAsc } = singleRoute;
              const { end, start } = singleRouteStartEnd;
              await handleRoute(newPositionSelected, end, start, orderAsc);
            }
          }
        }
      }} />
    );
  }
  return (
    <ul className="last-position-list">
      {
        lastPositionsSearchQueryEnabled ? (
          lastPositionsSearch.map(lastPosition => (
            <LastPositionListItem key={`lp-${lastPosition.id}`} options={{...lastPosition, onClick: () => {
              removeSingleExported();
              removeSingleRoute();
              removeSingleTranshipment();
              selectLastPosition(lastPosition.id);
            }}} />
          ))
        ) : (
          lastPositions.map(lastPosition => (
            <LastPositionListItem key={`lp-${lastPosition.id}`} options={{...lastPosition, onClick: () => {
              removeSingleExported();
              removeSingleRoute();
              removeSingleTranshipment();
              selectLastPosition(lastPosition.id);
            }}} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositions: state.lastPositions.positions,
    lastPositionsSearch: state.lastPositions.positionsSearch,
    lastPositionsSearchQueryEnabled: state.lastPositions.positionsSearchQueryEnabled,
    lastPositionSelected: state.lastPositions.positionSelected,
    singleExported: state.exporteds.singleExported,
    singleExportedStartEnd: state.exporteds.singleExportedStartEnd,
    singleRoute: state.routes.singleRoute,
    singleRouteStartEnd: state.routes.singleRouteStartEnd,
    singleTranshipment: state.transhipments.singleTranshipment
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, ExportedsActions, LastPositionActions, LoadersActions, MenusActions, RoutesActions, TranshipmentsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionList);
