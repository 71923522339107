import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as LastDateCorrectionActions } from "./../../store/ducks/lastDateCorrections";
import LastDateCorrectionListItem from "./../LastDateCorrectionListItem";

const LastDateCorrectionList = ({
  props: { lastDateCorrections, lastDateCorrectionsSearch, lastDateCorrectionsSearchQueryEnabled }
}) => {
  return (
    <ul className="last-date-correction-list">
      {
        lastDateCorrectionsSearchQueryEnabled ? (
          lastDateCorrectionsSearch.map(lastDateCorrection => (
            <LastDateCorrectionListItem key={`ln-${lastDateCorrection.id}`} options={{ ...lastDateCorrection, onClick: () => {
            }}} />
          ))
        ) : (
          lastDateCorrections.map(lastDateCorrection => (
            <LastDateCorrectionListItem key={`ln-${lastDateCorrection.id}`} options={{...lastDateCorrection, onClick: () => {
            }}} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    lastDateCorrections: state.lastDateCorrections.dateCorrections,
    lastDateCorrectionsSearch: state.lastDateCorrections.dateCorrectionsSearch,
    lastDateCorrectionsSearchQueryEnabled: state.lastDateCorrections.dateCorrectionsSearchQueryEnabled,
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastDateCorrectionActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastDateCorrectionList);
