import Storage from "./../services/Storage";
import throttle from "lodash.throttle";
import { createStore } from "redux";
import reducers from "./ducks";
const store = createStore(reducers);

const newStorage = new Storage();

store.subscribe(throttle(() => {
  newStorage.set("session", store.getState().users.currentUser);
}, 1000));

export default store;
