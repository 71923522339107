import React, { memo, useEffect, useState } from "react";
import "./index.css";

const DEFAULT_ZINDEX = 1000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 0.65;

const Route = ({ onClick, position }) => {
  const [routeHover, setRouteHover] = useState(false);
  const [popupShow, setPopupShow] = useState(true);

  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  useEffect(() => {
    setScale(routeHover || popupShow ? SCALE_HOVER : SCALE_NORMAL);
    setZIndex(Math.round(scale * DEFAULT_ZINDEX) - (popupShow ? 20 : 0) + (routeHover ? DEFAULT_ZINDEX : 0));
  }, [routeHover, popupShow, scale]);

  const transform = `rotate(${position.gps.course}deg)`;
  const popup = (
    <div
      id={`route-marker-popup-${position.id}`}
      className="marker-route-popup"
      onClick={e => e.stopPropagation()}
    >
      <h3 className="mep-title">Marker</h3>
      <div className="mep-content">
        <button onClick={() => onClick(2)}>Remover</button>
      </div>
    </div>
  );

  return (
    <div
      className="marker-route-all"
      onClick={e => {
        setPopupShow(!popupShow);
        onClick(popupShow ? 0 : 1);
      }}
      onMouseEnter={e => setRouteHover(true)}
      onMouseLeave={e => setRouteHover(false)}
      style={{
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-route">
        <div className="icon" style={{
          transform,
          MozTransform: transform,
          WebkitTransform: transform }}>
          <i className="fas fa-route" />
        </div>
        {popupShow && popup}
      </div>
    </div>
  );
};

export default memo(Route);
