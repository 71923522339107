import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as LastExporterErrorActions } from "./../../store/ducks/lastExporterErrors";
import LastExporterErrorListItem from "./../LastExporterErrorListItem";
import LastExporterErrorSelectedItem from "./../LastExporterErrorSelectedItem";

const LastExporterErrorList = ({
  props: {
    lastExporterErrors,
    lastExporterErrorsSearch,
    lastExporterErrorsSearchQueryEnabled,
    lastExporterErrorSelected
  },
  funcs: {
    selectLastExporterError
  }
}) => {
  if(lastExporterErrorSelected) {
    return (
      <LastExporterErrorSelectedItem key={lastExporterErrorSelected.id} options={{
        ...lastExporterErrorSelected,
        onClick: () => {
          selectLastExporterError(lastExporterErrorSelected.id);
        }
      }} />
    );
  }
  return (
    <ul className="last-exporter-error">
      {
        lastExporterErrorsSearchQueryEnabled ? (
          lastExporterErrorsSearch.map(lastExporterError => (
            <LastExporterErrorListItem key={`lee-${lastExporterError.id}`} options={{...lastExporterError, onClick: () => {
              selectLastExporterError(lastExporterError.id);
            }}} />
          ))
        ) : (
          lastExporterErrors.map(lastExporterError => (
            <LastExporterErrorListItem key={`lee-${lastExporterError.id}`} options={{...lastExporterError, onClick: () => {
              selectLastExporterError(lastExporterError.id);
            }}} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    lastExporterErrors: state.lastExporterErrors.exporterErrors,
    lastExporterErrorsSearch: state.lastExporterErrors.exporterErrorsSearch,
    lastExporterErrorsSearchQueryEnabled: state.lastExporterErrors.exporterErrorsSearchQueryEnabled,
    lastExporterErrorSelected: state.lastExporterErrors.exporterErrorSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastExporterErrorActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastExporterErrorList);
