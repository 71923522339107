const schema = {
  active: true,
  address: {
    city: {
      id: "",
      externalId: "",
      name: ""
    },
    complement: "",
    country: {
      id: "",
      initials: "",
      name: ""
    },
    neighborhood: "",
    number: "",
    state: {
      id: "",
      externalId: "",
      initials: "",
      name: ""
    },
    street: "",
    zipCode: ""
  },
  contact: {
    cellphone: "",
    email: "",
    telephone: ""
  },
  id: "",
  legal: {
    cnpj: "",
    fantasyName: "",
    foundationDate: "",
    responsible: {
      cnpj: "",
      cpf: "",
      name: ""
    },
    socialReason: "",
    stateRegistration: ""
  },
  personal: {
    birthDate: "",
    cpf: "",
    generalRegistration: "",
    name: ""
  },
  scopes: [],
  type: "LEG"
};

export default schema;
