import React from "react";
import "./index.css";

import SwitchPatrimony from "./../Switch/Patrimony";

const FilterPatrimonyItem = ({ checkbox = true, name, patrimony, onClick, selected } = {}) => {
  return (
    <li className={"filter-patrimony-list-item"} onClick={onClick}>
      <SwitchPatrimony checkbox={checkbox} name={name} patrimony={patrimony} selected={selected} />
    </li>
  );
};

export default FilterPatrimonyItem;
