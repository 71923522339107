import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import PatrimonyMapMenuPositionsTransmissionQueueItem from "./../PatrimonyMapMenuPositionsTransmissionQueueItem";

const PatrimonyMapMenuPositionsTransmissionQueue = ({
  options,
  props: {
    currentUser,
    routes
  }
}) => {
  const {
    routeId: __routeId = "",
  } = options;

  const [routeId] = useState(__routeId);

  const getRoute = () => {
    const route = routes.find(x => x.id === routeId);
    if(route) {
      return route;
    }
    return false;
  };

  const getRoutePositionsTransmissionQueue = () => {
    const simulatorRoute = getRoute();
    if(simulatorRoute) {
      const positions = JSON.parse(JSON.stringify(simulatorRoute.routePositionsTransmissionQueue));
      return positions;
    }
    return [];
  };

  return (
    <ul className="patrimony-map-menu-positions">
      {
        getRoutePositionsTransmissionQueue().map(routePosition => (
          <PatrimonyMapMenuPositionsTransmissionQueueItem key={`rptq-${routePosition.id}`} options={{...routePosition, onClick: () => {
            // removeSingleRoute();
            // removeSingleTranshipment();
            // selectLastPosition(lastPosition.id);
          }}} />
        ))
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    routes: state.simulatorsMaps.routes
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonyMapMenuPositionsTransmissionQueue);
