import { format, isValid, strip } from "@fnando/cpf";

export function isCPF(cpf) {
  return isValid(cpf);
}

export function maskCPF(cpf) {
  return format(cpf);
}

export function unmaskCPF(cpf) {
  return strip(cpf);
}
