import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  disableDatabaseMounted: [],
  disableDatabaseRefresh: [],
  disableDatabaseTest: [],
  enableDatabaseMounted: [],
  enableDatabaseRefresh: [],
  enableDatabaseTest: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  databaseMounted: true,
  databaseRefresh: false,
  databaseTest: false
};

const disableDatabaseMounted = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    databaseMounted: false
  };
};

const disableDatabaseRefresh = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    databaseRefresh: false
  };
};

const disableDatabaseTest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    databaseTest: false
  };
};

const enableDatabaseMounted = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    databaseMounted: true
  };
};

const enableDatabaseRefresh = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    databaseRefresh: true
  };
};

const enableDatabaseTest = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    databaseTest: true
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.DISABLE_DATABASE_MOUNTED]: disableDatabaseMounted,
  [Types.DISABLE_DATABASE_REFRESH]: disableDatabaseRefresh,
  [Types.DISABLE_DATABASE_TEST]: disableDatabaseTest,
  [Types.ENABLE_DATABASE_MOUNTED]: enableDatabaseMounted,
  [Types.ENABLE_DATABASE_REFRESH]: enableDatabaseRefresh,
  [Types.ENABLE_DATABASE_TEST]: enableDatabaseTest
});
