import ClientSchemaReduced from "./../Client/SchemaReduced";

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  id: "",
  name: "",
  scopes: []
};

export default schema;
