import React from "react";
import "./index.css";

const SwitchDefault = ({ checkbox = true, disabled = false, name, onClick, selected, title = false, small = false } = {}) => {
  return (
    <div className={`div-total-switch ${small ? "small" : ""}`}>
      { title && <div className="div-description-switch">{title}:</div> }
      <input
        type={`${checkbox ? "checkbox" : "radio"}`}
        checked={selected}
        disabled={disabled}
        name={name}
        onClick={e => onClick(e)}
        readOnly={true}
      />
      <div className="div-content-switch">
        <div className="div-indicator-switch"></div>
      </div>
    </div>
  );
};

export default SwitchDefault;
