import ClientSchemaReduced from "./../Client/SchemaReduced";

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  global: false,
  id: "",
  location: {
    type: "Point",
    coordinates: []
  },
  name: "",
  range: 0,
  type: "SUP"
};

export default schema;
