import ChassiSchemaReduced from "./../Chassi/SchemaReduced";

const schemaReduced = {
  hatch: {
    type: "DEF"
  },
  id: "",
  niple: {
    chassi: JSON.parse(JSON.stringify(ChassiSchemaReduced)),
    gyroscope: true,
    groundingSensor: false,
    main: false,
    operation: "HYB",
    operationInverted: false
  },
  positionMain: false,
  serial: "",
  type: "TRA"
};

export default schemaReduced;
