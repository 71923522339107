import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";
import { filterByClientAndWord, sortDelayedLocations } from "./../../utils/DelayedLocation";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastDelayedLocation: ["lastDelayedLocation"],
  updateLastDelayedLocation: ["id", "lastDelayedLocation"],
  searchLastDelayedLocation: ["search"],
  setLastDelayedLocation: ["lastDelayedLocations"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  delayedLocations: [],
  delayedLocationsSearch: [],
  delayedLocationsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    niple: true,
    patrimony: "",
    word: ""
  },
  delayedLocationsSearchQueryEnabled: true,
  delayedLocationsLoad: false
};

const format = (lastDelayedLocation) => {
  const copy = JSON.parse(JSON.stringify(lastDelayedLocation));
  if(typeof copy.patrimony === "undefined") {
    copy.patrimony = {
      automobile: {
        board: {
          autoVaccum: {
            license: copy.tracker.serial
          },
          board: copy.tracker.serial,
          color: "GRAY",
          country: "BR",
          description: "SC - CHAPECÓ",
          layout: "DEF",
          type: "CAR"
        },
        brand: "TPX",
        card: {
          number: copy.tracker.serial
        },
        color: "WHITE",
        model: "OCTSAT",
        type: "BOA"
      },
      object: {
        name: copy.tracker.serial
      },
      type: "AUT",
      vessel: {
        name: copy.tracker.serial,
        registrationNumber: copy.tracker.serial
      }
    };
  }
  return copy;
};

const add = (state = INITIAL_STATE, action) => {
  const { lastDelayedLocation } = action;
  if(state.delayedLocationsLoad && lastDelayedLocation !== null) {
    const index = state.delayedLocations.findIndex(x => x.id === lastDelayedLocation.tracker.serial);
    if(index !== -1) {
      return update(
        state,
        {
          id: lastDelayedLocation.tracker.serial,
          lastDelayedLocation
        }
      );
    }

    const delayedLocations = state.delayedLocations.filter(x => x.delayedLocation.tracker.delayedLocation === true);
    if(lastDelayedLocation.tracker.delayedLocation) {
      delayedLocations.push({ id: lastDelayedLocation.tracker.serial, delayedLocation: format(lastDelayedLocation), selected: false });
    }

    const { delayedLocationsSearch } = search({
      ...state,
      delayedLocations
    }, { search: state.delayedLocationsSearchQuery });

    return {
      ...state,
      delayedLocations: sortDelayedLocations(delayedLocations),
      delayedLocationsSearch: sortDelayedLocations(delayedLocationsSearch)
    };
  }
  return state;
};

const update = (state = INITIAL_STATE, action) => {
  const { id, lastDelayedLocation } = action;
  if(state.delayedLocationsLoad && (id !== null && lastDelayedLocation !== null)) {
    const index = state.delayedLocations.findIndex(x => x.id === id);
    if(index !== -1) {
      let delayedLocations = state.delayedLocations.map(delayedLocation => {
        if((delayedLocation.id === id) && ((DateUtil.diff({ dateA: delayedLocation.delayedLocation.gps.date, dateB: lastDelayedLocation.gps.date }) < 0) || (DateUtil.diff({ dateA: delayedLocation.delayedLocation.gps.date, dateB: lastDelayedLocation.gps.date }) === 0 && DateUtil.diff({ dateA: delayedLocation.delayedLocation.createdAt, dateB: lastDelayedLocation.createdAt }) <= 0))) {
          return { ...delayedLocation, delayedLocation: format(lastDelayedLocation) };
        }
        return { ...delayedLocation };
      });
      delayedLocations = delayedLocations.filter(x => x.delayedLocation.tracker.delayedLocation === true);

      const { delayedLocationsSearch } = search({
        ...state,
        delayedLocations
      }, { search: state.delayedLocationsSearchQuery });

      return {
        ...state,
        delayedLocations: sortDelayedLocations(delayedLocations),
        delayedLocationsSearch: sortDelayedLocations(delayedLocationsSearch)
      };
    }
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: delayedLocationsSearchEnabled, results: delayedLocationsSearchResults } = filterByClientAndWord(state.delayedLocations, search);
  return {
    ...state,
    delayedLocationsSearch: delayedLocationsSearchResults,
    delayedLocationsSearchQuery: search,
    delayedLocationsSearchQueryEnabled: delayedLocationsSearchEnabled
  };
};

const set = (state = INITIAL_STATE, action) => {
  const { lastDelayedLocations } = action;
  if(state.delayedLocationsLoad === false && lastDelayedLocations !== null) {
    const delayedLocations = lastDelayedLocations.map(lastDelayedLocation => {
      return { id: lastDelayedLocation.tracker.serial, delayedLocation: format(lastDelayedLocation), selected: false };
    });

    const { delayedLocationsSearch } = search({
      ...state,
      delayedLocations
    }, { search: state.delayedLocationsSearchQuery });

    return {
      ...state,
      delayedLocations: sortDelayedLocations(delayedLocations),
      delayedLocationsSearch: sortDelayedLocations(delayedLocationsSearch),
      delayedLocationsLoad: true
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_DELAYED_LOCATION]: add,
  [Types.UPDATE_LAST_DELAYED_LOCATION]: update,
  [Types.SEARCH_LAST_DELAYED_LOCATION]: search,
  [Types.SET_LAST_DELAYED_LOCATION]: set
});
