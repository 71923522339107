import React, { memo } from "react";
import {
  Toast
} from "react-bootstrap";
import "./index.css";

import DateUtil from "./../../utils/Date";

const NoticeListItem = ({ content, count, id, onClose, removing, title }) => {
  const renderTimer = ({ onClose, id }) => {
    setTimeout(() => {
      DateUtil.timeRing({ id: `toast-timering-${id}`, timer: 15000, cb: () => {
        onClose();
      }});
    }, 100);
    return null;
  };

  return (
    <li className={`notice-list-item ${removing ? "" : "opening"}`}>
      <Toast onClose={onClose} show={true} key={id}>
        <Toast.Header>
          <strong>{title}</strong>
          <div id={`toast-timering-${id}`} className="clock-ociosidade">
            <svg className="clock-circular" viewBox="25 25 50 50">
              <circle className="clock-path" style={{ stroke: "rgba(255,255,255,1)", strokeDasharray: "0 130" }} cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10" data-clock=""></circle>
              <circle className="clock-path-shadow" style={{ stroke: "rgba(255,255,255,.4)" }} cx="50" cy="50" r="20" fill="none" strokeWidth="4" strokeMiterlimit="10"></circle>
            </svg>
          </div>
        </Toast.Header>
        <Toast.Body>{content}</Toast.Body>
        {renderTimer({ onClose, id })}
      </Toast>
    </li>
  );
};

export default memo(NoticeListItem);
