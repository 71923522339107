import ClientSchemaReduced from "./../Client/SchemaReduced";
import WorkingWeekSchemaReduced from "./../WorkingWeek/SchemaReduced";

const schema = {
  active: true,
  id: "",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  name: "",
  workingWeek: JSON.parse(JSON.stringify(WorkingWeekSchemaReduced))
};

export default schema;
