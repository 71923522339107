import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input";
import Select from "react-select";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { maskCPF } from "./../../utils/CPF";
import { maskCNPJ } from "./../../utils/CNPJ";
import { isValidForm } from "./../../utils/Form";
import { isEmpty, ucFirstAll } from "./../../utils/String";
import { clearObject, mergeObject } from "./../../utils/Object";

import ContractService from "./../../services/Contract";
import ContractSchema from "./../../services/Contract/Schema";
import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

const Contract = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const contractService = new ContractService();
  const clientService = new ClientService();

  const {
    contract: __contract = {},
    close: __close = () => {}
  } = options;

  const [contract, setContract] = useState(mergeObject(JSON.parse(JSON.stringify(ContractSchema)), __contract));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:contracts:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setContract(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [clientSelectedOption, setClientSelectedOption] = useState(contract.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), contract.client),
    label: contract.client.type === "LEG" ? (`${contract.client.legal.socialReason} | CNPJ: ${maskCNPJ(contract.client.legal.cnpj)}`) : (`${contract.client.personal.name} | CPF: ${maskCPF(contract.client.personal.cpf)}`),
    value: contract.client.id
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [businessTypeOptions] = useState([
    { value: "LEN", label: t("Title.Lending") },
    { value: "OWN", label: t("Title.Own") },
    { value: "SAL", label: t("Title.Sale") }
  ]);

  const [chargeTypeOptions] = useState([
    { value: "BIL", label: t("Title.Billet") },
    { value: "CAR", label: t("Title.Card") },
    { value: "CHE", label: t("Title.Check") }
  ]);

  const [contractualFineOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : (`${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`),
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Contract"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await contractService.create({
            data: handleSubmitForm({ contract }),
            token: currentUser.token
          });
        }
        else {
          response = await contractService.update({
            id: contract.id,
            data: handleSubmitForm({ contract }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Contract"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Contract"),
            content: t(`Error.Contract.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ contract }) => {
    let newContract = Object.assign({}, JSON.parse(JSON.stringify(contract)));
    newContract = clearObject({ data: newContract });
    newContract.id = undefined;
    return newContract;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return contract.id === "";
  };

  return (
    <Form id="forms-contract" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-active">
                <Form.Label>{t("Label.Active")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  options={activeOptions}
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const active = e.value;
                    setContract(prevState => ({
                      ...prevState,
                      active
                    }));
                  }}
                  value={activeOptions.find(x => x.value === contract.active)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-user-client">
                <Form.Label>{t("Title.Client")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(contract.client.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || !isScopes({
                    currentUser,
                    scopes: [
                      "is:master",
                      "read:contracts:all"
                    ],
                    every: false
                  })}
                  loadOptions={clientLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setClientSelectedOption(e);
                      setContract(prevState => ({
                        ...prevState,
                        client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setClientSelectedOption({ dataAux, label, value });
                      setContract(prevState => ({
                        ...prevState,
                        client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Client.Select")}
                  value={clientSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(contract.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-business-type">
                <Form.Label>{t("Label.BusinessType")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const businessType = e.value;
                    setContract(prevState => ({
                      ...prevState,
                      businessType
                    }));
                  }}
                  options={businessTypeOptions}
                  value={businessTypeOptions.find(x => x.value === contract.businessType)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-name">
                <Form.Label>{t("Label.Name")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Name")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const name = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      name: name.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={contract.name}
                  isValid={!isEmpty(contract.name)}
                  isInvalid={isEmpty(contract.name)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Form.Group controlId="forms-contract-number">
                <Form.Label>{t("Label.ContractNumber")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.ContractNumber")}
                  disabled={true}
                  autoComplete="off"
                  value={contract.sequence}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-monthly-value">
                <Form.Label>{t("Label.MonthlyValue")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-monthly-value"
                  className={`form-control ${!isEmpty(contract.monthlyValue) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.MonthlyValue")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.monthlyValue}
                  onChangeEvent={e => {
                    const monthlyValue = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      monthlyValue
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.MonthlyValue")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-year-value">
                <Form.Label>{t("Label.YearValue")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-year-value"
                  className={`form-control ${!isEmpty(contract.yearValue) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.YearValue")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.yearValue}
                  onChangeEvent={e => {
                    const yearValue = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      yearValue
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.YearValue")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-due-date">
                <Form.Label>{t("Label.DueDate")}:</Form.Label>
                <InputMask
                  id="forms-contract-due-date"
                  className={`form-control ${!isEmpty(contract.dueDate) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.DueDate")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    let dueDate = e.target.value;
                    if(dueDate < 10) {
                      dueDate = `0${dueDate}`;
                    }
                    setContract(prevState => ({
                      ...prevState,
                      dueDate
                    }));
                  }}
                  mask="99"
                  autoComplete="off"
                  defaultValue={contract.dueDate}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.DueDate")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-installation-value">
                <Form.Label>{t("Label.InstallationValue")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-installation-value"
                  className={`form-control ${!isEmpty(contract.installationValue) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.InstallationValue")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.installationValue}
                  onChangeEvent={e => {
                    const installationValue = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      installationValue
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.InstallationValue")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-charge-type">
                <Form.Label>{t("Label.ChargeType")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const chargeType = e.value;
                    setContract(prevState => ({
                      ...prevState,
                      chargeType
                    }));
                  }}
                  options={chargeTypeOptions}
                  value={chargeTypeOptions.find(x => x.value === contract.chargeType)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-contractual-fine">
                <Form.Label>{t("Label.ContractualFine")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const contractualFine = e.value;
                    setContract(prevState => ({
                      ...prevState,
                      contractualFine
                    }));
                  }}
                  options={contractualFineOptions}
                  value={contractualFineOptions.find(x => x.value === contract.contractualFine)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-loyalty-period">
                <Form.Label>{t("Label.LoyaltyPeriod")}:</Form.Label>
                <InputMask
                  id="forms-contract-loyalty-period"
                  className={`form-control ${!isEmpty(contract.loyaltyPeriod) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.LoyaltyPeriod")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    let loyaltyPeriod = e.target.value;
                    if(loyaltyPeriod < 10) {
                      loyaltyPeriod = `0${loyaltyPeriod}`;
                    }
                    setContract(prevState => ({
                      ...prevState,
                      loyaltyPeriod
                    }));
                  }}
                  mask="99"
                  autoComplete="off"
                  defaultValue={contract.loyaltyPeriod}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.LoyaltyPeriod")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-uninstall-value">
                <Form.Label>{t("Label.UninstallValue")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-uninstall-value"
                  className={`form-control ${!isEmpty(contract.uninstallValue) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.UninstallValue")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.uninstallValue}
                  onChangeEvent={e => {
                    const uninstallValue = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      uninstallValue
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.UninstallValue")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="forms-contract-base-fine-amount">
                <Form.Label>{t("Label.BaseFineAmount")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-base-fine-amount"
                  className={`form-control ${!isEmpty(contract.baseFineAmount) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.BaseFineAmount")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.baseFineAmount}
                  onChangeEvent={e => {
                    const baseFineAmount = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      baseFineAmount
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.BaseFineAmount")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-km-traveled">
                <Form.Label>{t("Label.KmTraveled")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-km-traveled"
                  className={`form-control ${!isEmpty(contract.kmTraveled) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.KmTraveled")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.kmTraveled}
                  onChangeEvent={e => {
                    const kmTraveled = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      kmTraveled
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.KmTraveled")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-contract-technical-time">
                <Form.Label>{t("Label.TechnicalTime")}:</Form.Label>
                <CurrencyInput
                  id="forms-contract-technical-time"
                  className={`form-control ${!isEmpty(contract.technicalTime) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.TechnicalTime")}
                  disabled={inputDisabled()}
                  decimalSeparator=","
                  thousandSeparator="."
                  precision="2"
                  prefix="R$ "
                  value={contract.technicalTime}
                  onChangeEvent={e => {
                    const technicalTime = e.target.value;
                    setContract(prevState => ({
                      ...prevState,
                      technicalTime
                    }));
                  }}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.TechnicalTime")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Contract));
