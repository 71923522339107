import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setPeriodSpeeding: ["speeding"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  speeding: 100
};

const setPeriodSpeeding = (state = INITIAL_STATE, action) => {
  const { speeding } = action;
  return {
    ...state,
    speeding
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_PERIOD_SPEEDING]: setPeriodSpeeding
});
