import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "./../../utils/Date";
import { getDescriptionClient } from "./../../utils/Client";
import { getDescriptionPatrimony } from "./../../utils/Patrimony";
import { getBattery, getHatchStatus, getHatchType, getJammer, getNiple, getNipleBasic, getNipleFraud, getNipleOperation, getReason, getValidMoving } from "./../../utils/Position";
import { isScopes } from "./../../services/Auth";

import LastExporterErrorSelectedItemCommandItem from "./../LastExporterErrorSelectedItemCommandItem";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";

const LastExporterErrorSelectedItem = ({
  options: {
    id,
    onClick,
    exporterError,
    selected
  },
  props: {
    currentUser
  },
  funcs: {
    disableLoader,
    enableLoader
  }
}) => {
  const { t } = useTranslation();

  let hatchs = [];
  let niples = [];
  if(isScopes({ currentUser, scopes: ["read:patrimonies:basic"], every: false })) {
    const nipleBasic = getNipleBasic(exporterError.position, false);
    niples = nipleBasic.niples;
  }
  else if(isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })) {
    const nipleMaster = getNiple(exporterError.position, false);
    hatchs = nipleMaster.hatchs;
    niples = nipleMaster.niples;
  }

  const getTrackerChip = (position, serial) => {
    const tracker = position.patrimony.trackers.find(x => x.serial === serial);
    return {
      line: typeof tracker.chip !== "undefined" ? tracker.chip.line : "",
      serial: typeof tracker.chip !== "undefined" ? tracker.chip.serial : ""
    };
  };

  return (
    <div className="last-position-selected">
      <div className="commands">
        <LastExporterErrorSelectedItemCommandItem options={{ exporterError }} />
      </div>
      <ul className="ul-last-position-selected" onClick={onClick}>
        <li>
          <div>{t("Title.Reason")}:</div>
          <div><span><strong>{t(getReason(exporterError.position))} ({exporterError.position.gps.reason})</strong></span></div>
        </li>
        <li>
          <div>{t("Title.Client")}:</div>
          <div><span><strong>{getDescriptionClient(exporterError.position.patrimony.client)}</strong></span></div>
        </li>
        <li>
          <div>{t("Patrimony")}:</div>
          <div><span><strong>{getDescriptionPatrimony(exporterError.position.patrimony)}</strong></span></div>
        </li>
        <li>
          <div>{t("Driver")}:</div>
          <div><span>{typeof exporterError.position.patrimony.driver !== "undefined" ? exporterError.position.patrimony.driver.name : t("NoDriver")}</span></div>
        </li>
        <li>
          <div>{t("Tracker")}:</div>
          <div><span>{exporterError.position.tracker.serial}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof exporterError.position.patrimony.trackers !== "undefined" ? (
            exporterError.position.patrimony.trackers.filter(x =>
              x.type === "NIP" &&
              x.serial === exporterError.position.tracker.serial &&
              typeof x.niple !== "undefined" &&
              typeof x.niple.chassi !== "undefined" &&
              typeof x.niple.chassi.serial !== "undefined"
            ).map((v, i) => {
              return (
                <li key={`niple-serial-number-${i}`}>
                  <div>{t("SerialNumber")}:</div>
                  <div><span>{v.niple.chassi.serial}</span></div>
                </li>
              );
            })
          ) : null
        }
        <li>
          <div>{t("Date")} ({t("GPS")}):</div>
          <div><span>{DateUtil.to({ date: new Date(exporterError.position.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Date")} ({t("Enter")}):</div>
              <div><span>{DateUtil.to({ date: new Date(exporterError.position.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("Date")} ({t("DateCorrection")}):</div>
              <div><span>{exporterError.position.tracker.dateCorrection ? t("Active") : t("Inactive")}</span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Coordinate")}:</div>
          <div><span>{exporterError.position.gps.coordinate.latitude} | {exporterError.position.gps.coordinate.longitude}</span></div>
        </li>
        <li>
          <div>{t("Course")}:</div>
          <div><span>{exporterError.position.gps.course}°</span></div>
        </li>
        <li>
          <div>{t("Ignition")}:</div>
          <div><span>{exporterError.position.tracker.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
        </li>
        <li>
          <div>{t("Speed")}:</div>
          <div><span>{exporterError.position.gps.speed} Km/h</span></div>
        </li>
        <li>
          <div>{t("Anchor")}:</div>
          <div><span>{exporterError.position.tracker.anchor ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Block")}:</div>
          <div><span>{exporterError.position.tracker.block ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Battery")}:</div>
          <div><span>{getBattery(exporterError.position) ? t("BatteryLow") : t("BatteryHigh")}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li>
              <div>{t("DelayedLocation")}:</div>
              <div><span>{exporterError.position.tracker.delayedLocation ? t("Active") : t("Inactive")}</span></div>
            </li>
          ) : null
        }
        <li>
          <div>{t("Jammer")}:</div>
          <div><span>{getJammer(exporterError.position) ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Panic")}:</div>
          <div><span>{exporterError.position.tracker.panic ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Title.Niple.Maintenance")}:</div>
          <div><span>{exporterError.position.tracker.maintenance ? t("Active") : t("Inactive")}</span></div>
        </li>
        <li>
          <div>{t("Moving")}:</div>
          <div><span>{exporterError.position.tracker.moving ? t("Active") : t("Inactive")}</span></div>
        </li>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:basic", "read:patrimonies:description"], every: false }) && niples.map((niple, index) => {
            return (
              <li key={`lps-niple-op-${index}`}>
                <div>{t("Title.Niple")}:</div>
                <div><span>{t(niple.operation)}</span></div>
              </li>
            );
          })
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && hatchs.map((hatch, index) => {
            return (
              <li key={`lps-hatch-op-${index}`}>
                <div>{t("Title.Hatch")}:</div>
                <div><span>{t(hatch.operation)}</span></div>
              </li>
            );
          })
        }
        <div className="address">
          { exporterError.position.gps.address }
        </div>
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) ? (
            <li className="niple-box">
              <ul>
                <li className="niple-box-title">
                  <div>{t("Title.Chip")}:</div>
                </li>
                <li>
                  <div>{t("Title.Line")}:</div>
                  <div><span>{getTrackerChip(exporterError.position, exporterError.position.tracker.serial).line}</span></div>
                </li>
                <li>
                  <div>{t("Title.Serial")}:</div>
                  <div><span>{getTrackerChip(exporterError.position, exporterError.position.tracker.serial).serial}</span></div>
                </li>
              </ul>
            </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof exporterError.position.blockerNetwork !== "undefined" ? (
            exporterError.position.blockerNetwork.blockers.map((blocker, index) => {
              return (
                <li className="niple-box" key={`lps-blocker-description-${index}`}>
                  <ul>
                    <li><div>Blocker {index + 1}:</div></li>
                    <li><div>Id: <span>{blocker.id}</span></div></li>
                    <li><div>Connection: <span>{blocker.connection ? "ON" : "OFF"}</span></div></li>
                    <li><div>Status: <span>{blocker.status ? "ON" : "OFF"}</span></div></li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof exporterError.position.command !== "undefined" ? (
            <li className="niple-box" key={`lps-command-description-${exporterError.position.id}`}>
	          <ul>
	            <li><div>Command:</div></li>
	            <li><div>String: <span>{exporterError.position.command.string}</span></div></li>
	            <li><div>Sender: <span>{exporterError.position.command.sender}</span></div></li>
	          </ul>
	        </li>
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof exporterError.position.nipleNetwork !== "undefined" ? (
            exporterError.position.nipleNetwork.niples.map((niple, index) => {
              const type = exporterError.position.patrimony.trackers.find(x => x.serial === niple.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              const externalPowerReason = [];
              if (niple.fraud === "EPF" || exporterError.position.tracker.charging) externalPowerReason.push("Niple");
              if (getBattery(exporterError.position)) externalPowerReason.push("Rastreador");
              return (
                <li className="niple-box" key={`lps-niple-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Niple")} {(index + 1)}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Serial")}:</div>
                      <div><span>{niple.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(niple.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Coordinate")}:</div>
                      <div><span>{niple.gps.coordinate.latitude} | {niple.gps.coordinate.longitude}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.ExternalPower")}:</div>
                      <div><span>{(niple.fraud === "EPF" || exporterError.position.tracker.charging || getBattery(exporterError.position)) ? t("Title.Inactive") : t("Title.Active")}</span></div>
                    </li>
                    {
                      (externalPowerReason && externalPowerReason.length > 0 && (
                        <li>
                          <div>{t("Title.Niple.ExternalPowerReason")}:</div>
                          <div><span>{externalPowerReason.join(", ")}</span></div>
                        </li>
                      ))
                    }
                    <li>
                      <div>{t("Title.Niple.Fraud")}:</div>
                      <div><span>{t(getNipleFraud(exporterError.position, niple))}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Operation")}:</div>
                      <div><span>{t(getNipleOperation(exporterError.position, niple))}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Charge")}:</div>
                      <div><span>{!niple.sensors.charge ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Discharge")}:</div>
                      <div><span>{!niple.sensors.discharge ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Center")}:</div>
                      <div><span>{!niple.sensors.defaultPosition ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Head")}:</div>
                      <div><span>{!niple.sensors.defaultHead ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Niple.Gyroscope")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Gyroscope.X")}:</div>
                      <div><span>{niple.gyroscope.x}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Gyroscope.Y")}:</div>
                      <div><span>{niple.gyroscope.y}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Gyroscope.Z")}:</div>
                      <div><span>{niple.gyroscope.z}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Niple.Chip")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Chip.Line")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Niple.Chip.Serial")}:</div>
                      <div><span>{chip ? chip.serial : ""}</span></div>
                    </li>
                    {
                      typeof niple.chassi !== "undefined" ? (
                        <>
                          <li className="niple-box-title-full">
                            <div>{t("Title.Chassi")}:</div>
                          </li>
                          <li>
                            <div>{t("Title.Chassi.Id")}:</div>
                            <div><span>{niple.chassi.id}</span></div>
                          </li>
                          <li>
                            <div>{t("Title.Chassi.Serial")}:</div>
                            <div><span>{niple.chassi.serial}</span></div>
                          </li>
                        </>
                      ) : null
                    }
                    {
                      typeof niple.rfid !== "undefined" ? (
                        <>
                          <li className="niple-box-title-full">
                            <div>{t("Title.Rfid")}:</div>
                          </li>
                          <li>
                            <div>{t("Title.Rfid.Id")}:</div>
                            <div><span>{niple.rfid.id}</span></div>
                          </li>
                          <li>
                            <div>{t("Title.Rfid.Number")}:</div>
                            <div><span>{niple.rfid.number}</span></div>
                          </li>
                          <li>
                            <div>{t("Title.Rfid.Serial")}:</div>
                            <div><span>{niple.rfid.serial}</span></div>
                          </li>
                        </>
                      ) : null
                    }
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof exporterError.position.nipleNetwork !== "undefined" ? (
            exporterError.position.nipleNetwork.hatchs.map((hatch, index) => {
              const type = exporterError.position.patrimony.trackers.find(x => x.serial === hatch.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              const externalPowerReason = [];
              if (hatch.externalPower) externalPowerReason.push("Escotilha");
              if (getBattery(exporterError.position)) externalPowerReason.push("Rastreador");
              return (
                <li className="niple-box" key={`lps-hatch-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Hatch")} {(index + 1)}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Type")}:</div>
                      <div><span>{t(getHatchType(type, true))}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Serial")}:</div>
                      <div><span>{hatch.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(hatch.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Status")}:</div>
                      <div><span>{getHatchStatus(hatch) && !getValidMoving(exporterError.position) ? t("Title.Hatch.Open") : t("Title.Hatch.Closed")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.ExternalPower")}:</div>
                      <div><span>{(hatch.externalPower || getBattery(exporterError.position)) ? t("Title.Inactive") : t("Title.Active")}</span></div>
                    </li>
                    {
                      (externalPowerReason && externalPowerReason.length > 0 && (
                        <li>
                          <div>{t("Title.Hatch.ExternalPowerReason")}:</div>
                          <div><span>{externalPowerReason.join(", ")}</span></div>
                        </li>
                      ))
                    }
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Distance")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Distance.Bigger")}:</div>
                      <div><span>{hatch.sensors.greaterDistance}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Distance.Smaller")}:</div>
                      <div><span>{hatch.sensors.shortestDistance}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Distance.Current")}:</div>
                      <div><span>{hatch.sensors.distance}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Niple")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Serial")}:</div>
                      <div><span>{hatch.niple.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Connection")}:</div>
                      <div><span>{hatch.niple.connection ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.CRC")}:</div>
                      <div><span>{hatch.niple.crc ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Coordinates")}:</div>
                      <div><span>{hatch.niple.last.coordinate.latitude} | {hatch.niple.last.coordinate.longitude}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Niple.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(hatch.niple.last.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Gyroscope")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Gyroscope.X")}:</div>
                      <div><span>{hatch.gyroscope.x}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Gyroscope.Y")}:</div>
                      <div><span>{hatch.gyroscope.y}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Gyroscope.Z")}:</div>
                      <div><span>{hatch.gyroscope.z}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Hatch.Chip")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Chip.Line")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Hatch.Chip.Serial")}:</div>
                      <div><span>{chip ? chip.serial : ""}</span></div>
                    </li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
        {
          isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false }) && typeof exporterError.position.trackersBackup !== "undefined" ? (
          	 exporterError.position.trackersBackup.map((trackerBackup, index) => {
              const type = exporterError.position.patrimony.trackers.find(x => x.serial === trackerBackup.serial);
              const chip = typeof type !== "undefined" ? (typeof type.chip !== "undefined" ? type.chip : false) : false;
              return (
                <li className="niple-box" key={`lps-tracker-backup-description-${index}`}>
                  <ul>
                    <li className="niple-box-title">
                      <div>{t("Title.Tracker.Help")} {(index + 1)}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Serial")}:</div>
                      <div><span>{trackerBackup.serial}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Date")}:</div>
                      <div><span>{DateUtil.to({ date: new Date(trackerBackup.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Coordinate")}:</div>
                      <div><span>{trackerBackup.coordinate.latitude} | {trackerBackup.coordinate.longitude}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Ignition")}:</div>
                      <div><span>{trackerBackup.ignition ? t("IgnitionOn") : t("IgnitionOff")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Input1")}:</div>
                      <div><span>{trackerBackup.input1 ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Input2")}:</div>
                      <div><span>{trackerBackup.input2 ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Jammer")}:</div>
                      <div><span>{trackerBackup.jammer ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Battery")}:</div>
                      <div><span>{trackerBackup.battery ? t("Title.Tracker.Help.BatteryLow") : t("Title.Tracker.Help.BatteryHigh")}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Moving")}:</div>
                      <div><span>{trackerBackup.moving ? t("Title.Active") : t("Title.Inactive")}</span></div>
                    </li>
                    <li className="niple-box-title-full">
                      <div>{t("Title.Tracker.Help.Chip")}:</div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Chip.Line")}:</div>
                      <div><span>{chip ? chip.line : ""}</span></div>
                    </li>
                    <li>
                      <div>{t("Title.Tracker.Help.Chip.Serial")}:</div>
                      <div><span>{chip ? chip.serial : ""}</span></div>
                    </li>
                  </ul>
                </li>
              );
            })
          ) : null
        }
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastExporterErrorSelectedItem);
