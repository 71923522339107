import React, { memo, useState } from "react";
import {
  Button,
  Card,
  Form,
  Spinner
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { getDefaultOptionsUser } from "./../../utils/Report";
import { mergeObject } from "./../../utils/Object";

import Item from "./Item";
import Option from "./../Option";

import UserService from "./../../services/User";

const User = ({
  props: { currentUser, usersSearchSelected, periodDateEnd, periodDateStart },
  funcs: { addNotice, enableLoader, disableLoader }
}) => {
  const { t } = useTranslation();
  const userService = new UserService();
  const [running, setRunning] = useState(false);
  const [selectedReport, setSelectedReport] = useState({
    selected: false,
    options: false,
    values: false
  });

  const buttonDisabled = () => {
    if(usersSearchSelected.length > 0) {
      return false;
    }
    return true;
  };

  const changeSelectedReport = (item) => {
    setSelectedReport({
      selected: false,
      options: false,
      values: false
    });
    if(item !== false) {
      setTimeout(() => {
        const options = getDefaultOptionsUser();
        item.options = mergeObject(options, item.options);
        setSelectedReport(item);
      }, 100);
    }
  };

  const getDateSelected = () => {
    return {
      endDate: periodDateEnd,
      startDate: periodDateStart
    };
  };

  const getUsersSearchSelected = () => {
    const users = [];
    usersSearchSelected.map(item => {
      return users.push(item.id);
    });
    return users;
  };

  const getReport = async (e) => {
    if(!running) {
      setRunning(true);
      enableLoader();
      const { endDate, startDate } = getDateSelected();

      let endAt = undefined;
      if(selectedReport.options.dateEnd) {
        endAt = endDate;
      }

      let startAt = undefined;
      if(selectedReport.options.dateStart) {
        startAt = startDate;
      }

      const response = await userService.report({ endAt: endAt, id: selectedReport.values.id, users: getUsersSearchSelected(), startAt: startAt, token: currentUser.token });
      if(response.success) {
        addNotice({
          title: selectedReport.values.title,
          content: t("Success.Report.Request")
        });
      }
      else {
        addNotice({
          title: selectedReport.values.title,
          content: t("Error.Report.Request")
        });
      }
      changeSelectedReport(false);
      setTimeout(() => {
        disableLoader();
        setRunning(false);
      }, 100);
    }
  };

  const reports = [
    {
      id: "reports-user-access",
      title: t("Title.Access"),
      icon: <i className="fas fa-user" />,
      onClick: () => {
        const id = "reports-user-access";
        if(selectedReport.selected === id) {
          return setSelectedReport(false);
        }
        changeSelectedReport({
          selected: id,
          options: {
            dateEnd: true,
            dateStart: true,
            filterMultiple: true
          },
          values: {
            id: "user-access",
            title: t("Title.Access")
          }
        });
      },
      selected: () => selectedReport.selected === "reports-user-access",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "reports:users:access"], every: false })
    }
  ];

  return (
    <div className="user-reports">
      <Card>
        <Card.Header>{t("Title.Reports")}</Card.Header>
        <Card.Body>
          <div className="user-reports-all">
            {
              <ul className="user-reports-lists">
                {
                  reports.map(report => {
                    return report.scoped() ? (
                      <Item key={report.id} {...report} onClick={report.onClick} />
                    ) : null;
                  })
                }
              </ul>
            }
          </div>
        </Card.Body>
      </Card>
      <div className="user-reports-options">
        {
          selectedReport.selected ? (
            <>
              <Option {...selectedReport} />
              <Form.Group className="default-form-button">
                <Button
                  variant="dark"
                  type="button"
                  disabled={buttonDisabled()}
                  onClick={getReport}
                >
                  {running ? <Spinner animation="border" size="sm" /> : t("Button.Generate")}
                </Button>
              </Form.Group>
            </>
          ) : (
            <h3 className="user-reports-not-selected">{t("Title.Reports.NotSelected")}</h3>
          )
        }
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    usersSearchSelected: state.users.usersSearchSelected,
    periodDateEnd: state.period.periodDateEnd,
    periodDateStart: state.period.periodDateStart
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(User));
