import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import "./index.css";

import { Creators as LoadersActions } from "../../store/ducks/loaders";
import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { mergeObject } from "../../utils/Object";

import CommandService from "../../services/Command";
import CommandSchema from "../../services/Command/Schema";

const Cancel = ({
  options,
  props: {
    currentUser
  },
  funcs: {
    addNotice,
    disableLoader,
    enableLoader
  }
}) => {
  const { t } = useTranslation();
  const commandService = new CommandService();

  const {
    close: __close = () => {},
    command: __command = {}
  } = options;

  const [command] = useState(mergeObject(JSON.parse(JSON.stringify(CommandSchema)), __command));
  const [running, setRunning] = useState(false);

  useEffect(() => {
    cancelCommand();
  }, []); /* eslint-disable-line */

  const cancelCommand = async () => {
    if(!running) {
      setRunning(true);
      enableLoader();
      const response = await commandService.commandCancel({ id: command.id, token: currentUser.token });
      if(response.success) {
        addNotice({
          title: t("Title.MyCommands.Cancel"),
          content: t("Success.Command.Cancel.Request")
        });
      }
      else {
        addNotice({
          title: t("Title.MyCommands.Cancel"),
          content: t("Error.Command.Cancel.Request")
        });
      }
      setTimeout(() => {
        disableLoader();
        setRunning(false);
        __close();
      }, 100);
    }
  };

  return (
    <div className="user-commands-cancel-content-data" />
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Cancel));
