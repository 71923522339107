import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as LastDelayedLocationActions } from "./../../store/ducks/lastDelayedLocations";
import LastDelayedLocationListItem from "./../LastDelayedLocationListItem";

const LastDelayedLocationList = ({
  props: { lastDelayedLocations, lastDelayedLocationsSearch, lastDelayedLocationsSearchQueryEnabled }
}) => {
  return (
    <ul className="last-delayed-location-list">
      {
        lastDelayedLocationsSearchQueryEnabled ? (
          lastDelayedLocationsSearch.map(lastDelayedLocation => (
            <LastDelayedLocationListItem key={`ln-${lastDelayedLocation.id}`} options={{ ...lastDelayedLocation, onClick: () => {
            }}} />
          ))
        ) : (
          lastDelayedLocations.map(lastDelayedLocation => (
            <LastDelayedLocationListItem key={`ln-${lastDelayedLocation.id}`} options={{...lastDelayedLocation, onClick: () => {
            }}} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    lastDelayedLocations: state.lastDelayedLocations.delayedLocations,
    lastDelayedLocationsSearch: state.lastDelayedLocations.delayedLocationsSearch,
    lastDelayedLocationsSearchQueryEnabled: state.lastDelayedLocations.delayedLocationsSearchQueryEnabled,
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastDelayedLocationActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastDelayedLocationList);
