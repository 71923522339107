import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  disableLoader: [],
  enableLoader: []
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  loader: false
};

const disable = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loader: false
  };
};

const enable = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    loader: true
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.DISABLE_LOADER]: disable,
  [Types.ENABLE_LOADER]: enable
});
