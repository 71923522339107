import ClientSchemaReduced from "./../Client/SchemaReduced";

const schemaReduced = {
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  description: "",
  id: "",
  trackers: []
};

export default schemaReduced;
