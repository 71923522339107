import { ApiV2 } from "../Api";

export default class ExpenseType {
  async create({ data, token }) {
    const response = await ApiV2.post("/expenseType", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if (error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async findAllGrid({ data, token }) {
    const response = await ApiV2.post("/expenseType/find/all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if (error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if (response.success) return this.findAllGridTranslate({ data: response.data });
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [{
      width: "50%",
      type: "string",
      label: "Title.Client"
    }, {
      width: "50%",
      type: "string",
      label: "Title.Name"
    }];
    data.rows = [];
    data.docs.map(item => {
      const itemAux = [
        {
          value: this.findAllGridTranslateItem({ data: item, item: "client" }) !== undefined ? (item.client.type === "LEG" ? item.client.legal.socialReason : item.client.personal.name) : ""
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "name" }) !== undefined ? item.name : ""
        }
      ];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async findAutocomplete({ data, token }) {
    const response = await ApiV2.post("/expenseType/find/autocomplete", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }

  async update({ id, data, token }) {
    const response = await ApiV2.put(`/expenseType/${id}`, data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if (error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
