import { createActions, createReducer } from "reduxsauce";
import { mergeObject } from "./../../utils/Object";
import { filterByClient, filterByClientAndWord, sortUsers } from "./../../utils/User";
import Storage from "./../../services/Storage";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

const newStorage = new Storage();

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  searchUsers: ["search"],
  setCurrentToken: ["currentToken"],
  setCurrentUser: ["currentUser"],
  setCurrentUserProfileMapMiniMarkers: ["miniMarkers"],
  setCurrentUserProfileMapTraffic: ["traffic"],
  setCurrentUserProfileMapType: ["mType"],
  setUsers: ["users"],
  setUsersSelected: ["users"],
  updateCurrentUser: ["currentUser"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  currentToken: false,
  currentUser: newStorage.get("session") || false,
  users: [],
  usersSearch: [],
  usersSearchOnlyClient: [],
  usersSearchSelected: [],
  usersSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: "",
    active: -1
  },
  usersSearchQueryEnabled: false
};

const formatCurrentUserProfile = (profile) => {
  const copy = JSON.parse(JSON.stringify(profile));
  return mergeObject({
    map: {
      miniMarkers: false,
      traffic: false,
      type: "DEF"
    }
  }, copy);
};

const searchUsers = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: usersSearchEnabled, results: usersSearchResults } = filterByClientAndWord(state.users, search);
  const { results: usersSearchOnlyClientResults } = filterByClient(state.users, search);
  return {
    ...state,
    usersSearch: usersSearchResults,
    usersSearchOnlyClient: usersSearchOnlyClientResults,
    usersSearchQuery: search,
    usersSearchQueryEnabled: usersSearchEnabled
  };
};

const setCurrentToken = (state = INITIAL_STATE, action) => {
  const { currentToken } = action;
  return {
    ...state,
    currentToken
  };
};

const setCurrentUser = (state = INITIAL_STATE, action) => {
  const { currentUser } = action;
  const currentUserProfile = currentUser ? formatCurrentUserProfile(currentUser.profile) : false;
  if(currentUserProfile) {
    currentUser.profile = currentUserProfile;
  }
  return {
    ...state,
    currentUser
  };
};

const setCurrentUserProfile = (state = INITIAL_STATE, action) => {
  const { profile } = action;
  return {
    ...state,
    currentUser: {
      ...state.currentUser,
      profile
    }
  };
};

const setCurrentUserProfileMap = (state = INITIAL_STATE, action) => {
  const { map } = action;
  const profile = state.currentUser.profile;
  profile.map = map;
  return setCurrentUserProfile(state, { profile });
};

const setCurrentUserProfileMapMiniMarkers = (state = INITIAL_STATE, action) => {
  const { miniMarkers } = action;
  const map = state.currentUser.profile.map;
  map.miniMarkers = miniMarkers;
  return setCurrentUserProfileMap(state, { map });
};

const setCurrentUserProfileMapTraffic = (state = INITIAL_STATE, action) => {
  const { traffic } = action;
  const map = state.currentUser.profile.map;
  map.traffic = traffic;
  return setCurrentUserProfileMap(state, { map });
};

const setCurrentUserProfileMapType = (state = INITIAL_STATE, action) => {
  const { mType } = action;
  const map = state.currentUser.profile.map;
  map.type = mType;
  return setCurrentUserProfileMap(state, { map });
};

const setUsers = (state = INITIAL_STATE, action) => {
  let { users } = action;
  return {
    ...state,
    users: sortUsers(users),
    usersSearch: INITIAL_STATE.usersSearch,
    usersSearchOnlyClient: sortUsers(users),
    usersSearchSelected: INITIAL_STATE.usersSearchSelected,
    usersSearchQuery: INITIAL_STATE.usersSearchQuery,
    usersSearchQueryEnabled: INITIAL_STATE.usersSearchQueryEnabled
  };
};

const setUsersSelected = (state = INITIAL_STATE, action) => {
  let { users } = action;
  return {
    ...state,
    usersSearchSelected: users
  };
};

const updateCurrentUser = (state = INITIAL_STATE, action) => {
  return setCurrentUser(state, action);
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SEARCH_USERS]: searchUsers,
  [Types.SET_CURRENT_TOKEN]: setCurrentToken,
  [Types.SET_CURRENT_USER]: setCurrentUser,
  [Types.SET_CURRENT_USER_PROFILE_MAP_MINI_MARKERS]: setCurrentUserProfileMapMiniMarkers,
  [Types.SET_CURRENT_USER_PROFILE_MAP_TRAFFIC]: setCurrentUserProfileMapTraffic,
  [Types.SET_CURRENT_USER_PROFILE_MAP_TYPE]: setCurrentUserProfileMapType,
  [Types.SET_USERS]: setUsers,
  [Types.SET_USERS_SELECTED]: setUsersSelected,
  [Types.UPDATE_CURRENT_USER]: updateCurrentUser,
});
