import React from "react";
import "./index.css";

const Loader = () => {
  return (
    <section className="section-content-loader">
      <div className="data-loader">
        <div className="spinner-border spinner-border-lg"></div>
      </div>
      <div className="data-loader-bg" />
    </section>
  );
};

export default Loader;
