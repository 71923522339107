import ClientSchemaReduced from "../Client/SchemaReduced";
import PatrimonySchemaReduced from "../Patrimony/SchemaReduced";

const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
const date = new Date(`${todayDate} 00:00:00`);

const schema = {
  active: true,
  address: "",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  date,
  description: "",
  fuel: "GAS",
  id: "",
  hodometer: "",
  liters: 0,
  patrimony: JSON.parse(JSON.stringify(PatrimonySchemaReduced)),
  price: 0
};

export default schema;
