export function filterByClientAndWord(delayedLocations, search) {
  let filter = delayedLocations;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.delayedLocation.patrimony.client !== "undefined" && p.delayedLocation.patrimony.client.id === search.client.id);
    }
    if(typeof search.niple !== "undefined" && search.niple !== "") {
      enabled = true;
      filter = filter.filter(
        (p) => search.niple ? (
          p.delayedLocation.patrimony.automobile && p.delayedLocation.patrimony.automobile.board && p.delayedLocation.patrimony.automobile.board.type === "ATV"
        ) : (
          (p.delayedLocation.patrimony.automobile && p.delayedLocation.patrimony.automobile.board && p.delayedLocation.patrimony.automobile.board.type !== "ATV") ||
          (p.delayedLocation.patrimony.automobile && p.delayedLocation.patrimony.automobile.card) ||
          (p.delayedLocation.patrimony.object) ||
          (p.delayedLocation.patrimony.vessel)
        ));
    }
    if(typeof search.patrimony !== "undefined" && search.patrimony !== "") {
      enabled = true;
      filter = filter.filter((n) => typeof n.delayedLocation.patrimony !== "undefined" && typeof n.delayedLocation.patrimony.id !== "undefined" && n.delayedLocation.patrimony.id === search.patrimony);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((p) => p.delayedLocation.tracker.serial.toLowerCase().indexOf(search.word.toLowerCase()) !== -1 || p.delayedLocation.patrimony.automobile.board.board.toLowerCase().indexOf(search.word.toLowerCase()) !== -1);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function sortDelayedLocations(delayedLocations, asc = false) {
  if(asc) {
    return delayedLocations.sort((a, b) => a.delayedLocation.gps.date.localeCompare(b.delayedLocation.gps.date) || a.delayedLocation.createdAt.localeCompare(b.delayedLocation.createdAt));
  }
  return delayedLocations.sort((a, b) => b.delayedLocation.gps.date.localeCompare(a.delayedLocation.gps.date) || b.delayedLocation.createdAt.localeCompare(a.delayedLocation.createdAt));
}

export function sortDelayedLocationsPolyline(delayedLocationsPolyline, asc = false) {
  if(asc) {
    return delayedLocationsPolyline.sort((a, b) => a.gps.date.localeCompare(b.gps.date) || a.createdAt.localeCompare(b.createdAt));
  }
  return delayedLocationsPolyline.sort((a, b) => b.gps.date.localeCompare(a.gps.date) || b.createdAt.localeCompare(a.createdAt));
}
