import React from "react";
import Timezone from "moment-timezone";

class DateUtil extends React.Component {
  add({ add, date, tz = "UTC", format = "days" } = {}) {
    return new Date(Timezone(new Date(date))
      .tz(tz)
      .add(add, format)
      .format());
  }

  diff({ dateA, dateB, tz = "UTC", format = "milliseconds" } = {}) {
    return Timezone(new Date(dateA))
      .tz(tz)
      .diff(Timezone(new Date(dateB)).tz(tz), format);
  }

  endDate({ format = "YYYY-MM-DD HH:mm:ss", tz = "UTC" } = {}) {
    return this.to({ date: new Date(`${(this.now({ tz: "America/Sao_Paulo" }).split(" "))[0]} 23:55:00`), format, tz });
  }

  isValid({ date } = {}) {
    var d = new Date(date);
    if(isNaN(d.getTime())) {
      return false;
    }
    return true;
  }

  isTime(time) {
    time = time.replace(/_/gi, "");
    time = time.replace(/:/gi, "");
    if(time.length === 6) {
      return true;
    }
    return false;
  }

  now({ format = "YYYY-MM-DD HH:mm:ss", tz = "UTC" } = {}) {
    return Timezone(new Date())
      .tz(tz)
      .format(format);
  }

  secondsToAll({ seconds }) {
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const months = days / 30;
    const years = months / 12;
    return {
      seconds: parseInt(seconds),
      minutes: parseInt(minutes),
      hours: parseInt(hours),
      days: parseInt(days),
      months: parseInt(months),
      years: parseInt(years)
    };
  }

  startDate({ format = "YYYY-MM-DD HH:mm:ss", tz = "UTC" } = {}) {
    return this.to({ date: new Date(`${(this.now({ tz: "America/Sao_Paulo" }).split(" "))[0]} 00:00:00`), format, tz });
  }

  subtract({ subtract, date, tz = "UTC", format = "days" } = {}) {
    return new Date(Timezone(new Date(date))
      .tz(tz)
      .subtract(subtract, format)
      .format());
  }

  to({ date, format = "YYYY-MM-DD HH:mm:ss", tz = "UTC" } = {}) {
    return Timezone(new Date(date))
      .tz(tz)
      .format(format);
  }

  toDateCorrection({ date, format = "YYYY-MM-DD HH:mm:ss", tz = "UTC" } = {}) {
    const cDateDays = 137;
    const initialYear = 2001;
    let iDate = new Date(Date.now());
    let nDate = new Date(Date.now());

    iDate.setYear(initialYear);

    const diffYear = this.diff({ dateA: nDate.getTime(), dateB: iDate.getTime(), format: "days" }) - cDateDays;
    const newDate = this.subtract({ subtract: diffYear, date: date.getTime() });
    return this.to({ date: newDate.getTime(), format, tz });
  }

  toDb({ date } = {}) {
    let dt = date.split(" ");
    dt[0] = dt[0].split("/")[2] + "-" + dt[0].split("/")[1] + "-" + dt[0].split("/")[0];
    return dt.join(" ");
  }

  toDate({ date } = {}) {
    try {
      if(this.isValid({ date })) {
        return new Date(date);
      }
    } catch(error) {
    }
    return date;
  }

  toHHMMSS(secs) {
    const secNum = parseInt(secs, 10);
    const hours   = Math.floor(secNum / 3600);
    const minutes = Math.floor(secNum / 60) % 60;
    const seconds = secNum % 60;
    return [hours, minutes, seconds].map(v => v < 10 ? "0" + v : v).join(":");
  }

  toStr({ date, format = "YYYY-MM-DD", tz = "UTC" } = {}) {
    const newDate = Timezone(new Date(date))
      .tz(tz)
      .format(format).split("/");

    const dateDay = newDate[0];
    let dateMonth = newDate[1];
    const dateYear = newDate[2];

    switch(dateMonth) {
    case "01":
      dateMonth = "janeiro";
      break;
    case "02":
      dateMonth = "fevereiro";
      break;
    case "03":
      dateMonth = "março";
      break;
    case "04":
      dateMonth = "abril";
      break;
    case "05":
      dateMonth = "maio";
      break;
    case "06":
      dateMonth = "junho";
      break;
    case "07":
      dateMonth = "julho";
      break;
    case "08":
      dateMonth = "agosto";
      break;
    case "09":
      dateMonth = "setembro";
      break;
    case "10":
      dateMonth = "outubro";
      break;
    case "11":
      dateMonth = "novembro";
      break;
    case "12":
      dateMonth = "dezembro";
      break;
    default:
      dateMonth = "janeiro";
      break;
    }
    return `${dateDay} de ${dateMonth} de ${dateYear}`;
  }

  timeToSeconds(time) {
    if (typeof time !== "undefined" && time !== null) {
      const timeArr = time.split(":");
      if (timeArr.length === 3) {
        const hours = Number(timeArr[0]) * (60 * 60);
        const minutes = Number(timeArr[1]) * 60;
        const seconds = Number(timeArr[2]);
        return hours + minutes + seconds;
      }
    }
    return 0;
  }

  timeAgo({ elm }) {
    var templates = {
      prefix: "",
      suffix: " ago",
      seconds: "less than a minute",
      minute: "about a minute",
      minutes: "%d minutes",
      hour: "about an hour",
      hours: "about %d hours",
      day: "a day",
      days: "%d days",
      month: "about a month",
      months: "%d months",
      year: "about a year",
      years: "%d years"
    };
    var template = function(t, n) {
      return templates[t] && templates[t].replace(/%d/i, Math.abs(Math.round(n)));
    };

    var timer = function(time) {
      if (!time)
        return;
      time = time.replace(/\.\d+/, "");
      time = time.replace(/-/, "/").replace(/-/, "/");
      time = time.replace(/T/, " ").replace(/Z/, " UTC");
      time = time.replace(/([\\+\\-]\d\d)\\:?(\d\d)/, " $1$2");
      time = new Date(time * 1000 || time);

      var now = new Date();
      var seconds = ((now.getTime() - time) * .001) >> 0;
      var minutes = seconds / 60;
      var hours = minutes / 60;
      var days = hours / 24;
      var years = days / 365;

      return templates.prefix + (
        (seconds < 45 && template("seconds", seconds)) ||
        (seconds < 90 && template("minute", 1)) ||
        (minutes < 45 && template("minutes", minutes)) ||
        (minutes < 90 && template("hour", 1)) ||
        (hours < 24 && template("hours", hours)) ||
        (hours < 42 && template("day", 1)) ||
        (days < 30 && template("days", days)) ||
        (days < 45 && template("month", 1)) ||
        (days < 365 && template("months", days / 30)) ||
        (years < 1.5 && template("year", 1)) ||
        template("years", years)
      ) + templates.suffix;
    };

    if(elm !== undefined && elm !== "" && elm !== null && elm !== false) {
      elm.innerHTML = timer(elm.getAttribute("title"));
      const obj = this;
      setTimeout(() => {
        obj.timeAgo({ elm });
      }, 60000);
    }
  }

  timeRing({ id, timer, cb }) {
    const elm = document.getElementById(id);
    if(elm !== undefined && elm !== "" && elm !== null && elm !== false) {
      if(elm.getAttribute("renderized") !== "true") {
        elm.setAttribute("renderized", true);
        this.timeRingInterval({ id, ch: elm.children[0].children[0], timer, count: 0, width: 0, cb });
      }
    }
  }

  timeRingInterval({ id, ch, timer, count, width, cb }) {
    const elm = document.getElementById(id);
    if(elm !== undefined && elm !== "" && elm !== null && elm !== false) {
      width = (((count * 100) / timer) / 100) * 130;
      ch.style.strokeDasharray = `${width} 130`;
      count = count + 100;
      if(count >= timer) {
        return cb();
      }
      setTimeout(() => {
        this.timeRingInterval({ id, ch, timer, count, width, cb });
      }, 100);
    }
  }

  uniqid(a = "", b = false) {
    var c = Date.now()/1000;
    var d = c.toString(16).split(".").join("");
    while(d.length < 14){
      d += "0";
    }
    var e = "";
    if(b){
      e = ".";
      var f = Math.round(Math.random()*100000000);
      e += f;
    }
    return a + d + e;
  }
}

export default new DateUtil();
