import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as LastNotificationActions } from "./../../store/ducks/lastNotifications";
import LastNotificationListItem from "./../LastNotificationListItem";

const LastNotificationList = ({
  props: { lastNotifications, lastNotificationsSearch, lastNotificationsSearchQueryEnabled }
}) => {
  return (
    <ul className="last-notification-list">
      {
        lastNotificationsSearchQueryEnabled ? (
          lastNotificationsSearch.map(lastNotification => (
            <LastNotificationListItem key={`ln-${lastNotification.id}`} {...lastNotification} onClick={() => {
            }} />
          ))
        ) : (
          lastNotifications.map(lastNotification => (
            <LastNotificationListItem key={`ln-${lastNotification.id}`} {...lastNotification} onClick={() => {
            }} />
          ))
        )
      }
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    lastNotifications: state.lastNotifications.notifications,
    lastNotificationsSearch: state.lastNotifications.notificationsSearch,
    lastNotificationsSearchQueryEnabled: state.lastNotifications.notificationsSearchQueryEnabled,
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastNotificationActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastNotificationList);
