import axios from "axios";

const DefaultApi = axios.create({
  baseURL: process.env.REACT_APP_APP_API_URL,
  timeout: 0
});

const DefaultApiV2 = axios.create({
  baseURL: process.env.REACT_APP_APP_API_URL_V2,
  timeout: 0
});

export default DefaultApi;
export {
  DefaultApi as Api,
  DefaultApiV2 as ApiV2
};
