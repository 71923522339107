import React, { memo } from "react";
import {
  Accordion,
  Card,
  Col,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "../../../../utils/Date";

const Dayoff = ({
  props: { currentUser },
  options
}) => {
  const { t } = useTranslation();
  return (
    <Card className="card-calendar">
      <Accordion.Toggle as={Card.Header} eventKey={options.eventKey} onClick={e => options.changeActiveKey(options.eventKey)}>
        <i className="fas fa-mug-saucer" /> {t("Title.Dayoff")}: {DateUtil.to({ date: options.event.dateStart, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })} até {DateUtil.to({ date: options.event.dateEnd, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={options.eventKey}>
        <Card.Body>
          <Row>
            <Col>{t("Title.Driver")}: {options.event.driver.name}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Date.Start")}: {DateUtil.to({ date: options.event.dateStart, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Date.End")}: {DateUtil.to({ date: options.event.dateEnd, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Description")}: {options.event.description}</Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Dayoff));
