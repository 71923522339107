import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { isScopes } from "./../../services/Auth";

import { Creators as MenusActions } from "./../../store/ducks/menus";

import MenuProfilesItem from "./../MenuProfilesItem";

import NotificationProfile from "./../../profiles/Notification";
import PasswordProfile from "./../../profiles/Password";
import PasswordPinProfile from "./../../profiles/PasswordPin";
import UserProfile from "./../../profiles/User";

const MenuProfiles = ({
  props: {
    currentUser,
    profiles
  },
  funcs: {
    menuHandleOpen,
    menuProfilesHandleClose
  }
}) => {
  const { t } = useTranslation();
  const [selectedProfile, setSelectedProfile] = useState({
    selected: false,
    options: false
  });

  useEffect(() => {
    if (profiles.selected !== false) {
      const option = dynamicOptions.find(opt => opt.id === profiles.selected);
      if (option) {
        option.onClick();
      }
    }
  }, []); //eslint-disable-line

  useEffect(() => {
    if (selectedProfile.selected) {
      if (selectedProfile.options.scoped(currentUser) === false) {
        changeSelectedProfile(false);
      }
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedProfile = (item) => {
    setSelectedProfile({
      selected: false,
      options: false
    });
    if (item !== false) {
      setTimeout(() => {
        setSelectedProfile(item);
      }, 100);
    }
  };

  const dynamicOptions = [
    {
      id: "mpr-option-user",
      title: t("Title.User"),
      icon: <i className="fas fa-user-edit" />,
      onClick: () => {
        const id = "mpr-option-user";
        if (selectedProfile.selected === id) {
          return setSelectedProfile(false);
        }
        changeSelectedProfile({
          selected: id,
          options: {
            title: t("Title.User"),
            render: () => <UserProfile options={{ user: JSON.parse(JSON.stringify(currentUser)) }} />,
            scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
          }
        });
      },
      selected: () => selectedProfile.selected === "mpr-option-user",
      scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
    },
    {
      id: "mpr-option-password",
      title: t("Title.Password"),
      icon: <i className="fas fa-key" />,
      onClick: () => {
        const id = "mpr-option-password";
        if (selectedProfile.selected === id) {
          return setSelectedProfile(false);
        }
        changeSelectedProfile({
          selected: id,
          options: {
            title: t("Title.Password"),
            render: () => <PasswordProfile options={{ user: JSON.parse(JSON.stringify(currentUser)) }} />,
            scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
          }
        });
      },
      selected: () => selectedProfile.selected === "mpr-option-password",
      scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
    },
    {
      id: "mpr-option-password-pin",
      title: t("Title.PasswordPin"),
      icon: <i className="fa-solid fa-lock" />,
      onClick: () => {
        const id = "mpr-option-password-pin";
        if (selectedProfile.selected === id) {
          return setSelectedProfile(false);
        }
        changeSelectedProfile({
          selected: id,
          options: {
            title: t("Title.PasswordPin"),
            render: () => <PasswordPinProfile options={{ user: JSON.parse(JSON.stringify(currentUser)) }} />,
            scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
          }
        });
      },
      selected: () => selectedProfile.selected === "mpr-option-password-pin",
      scoped: () => !isScopes({ currentUser, scopes: ["is:guest"], every: false })
    },
    {
      id: "mpr-option-notification",
      title: t("Title.Notifications"),
      icon: <i className="fas fa-bell" />,
      onClick: () => {
        const id = "mpr-option-notification";
        if (selectedProfile.selected === id) {
          return setSelectedProfile(false);
        }
        changeSelectedProfile({
          selected: id,
          options: {
            title: t("Title.Notifications"),
            render: () => <NotificationProfile options={{ user: JSON.parse(JSON.stringify(currentUser)) }} />,
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:notifications"], every: false })
          }
        });
      },
      selected: () => selectedProfile.selected === "mpr-option-notification",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:notifications"], every: false })
    }
  ];

  const fixedOptions = [{
    id: "mpr-option-close",
    title: t("Title.Close"),
    icon: <i className="fas fa-times" />,
    onClick: () => {
      menuProfilesHandleClose();
      menuHandleOpen();
    },
    selected: () => true,
    scoped: () => true
  }];

  const renderBody = () => {
    if (selectedProfile.selected) {
      return selectedProfile.options.render();
    }
    return null;
  };

  const renderTitle = () => {
    if (selectedProfile.selected) {
      return <h3>{selectedProfile.options.title}</h3>;
    }
    return null;
  };

  return (
    <div className="menu-profiles">
      <div className={`mpr-profiles-options-absolute ${selectedProfile.selected ? "active" : ""}`}>
        <div className="mpr-profiles-options-absolute-content">
          <div className="mpr-roa-content-title">{renderTitle()}</div>
          <div className="mpr-roa-content-body">{renderBody()}</div>
        </div>
      </div>
      <div className="mpr-profiles-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if (option.scoped()) {
                return (
                  <MenuProfilesItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="mpr-profiles-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if (option.scoped()) {
                return (
                  <MenuProfilesItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    profiles: state.menus.profiles
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuProfiles));
