import Api from "../Api";

export default class City {
  async findByExternalId({ externalId }) {
    const response = await Api.post("/city/find-by-external-id", {
      externalId
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success && response.data.docs.length === 1) {
      return response.data.docs[0];
    }
    return false;
  }

  async findByName({ name }) {
    const response = await Api.post("/city/find-by-name", {
      name
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }

  async findByStateExternalId({ externalId }) {
    const response = await Api.post("/city/find-by-state-external-id", {
      externalId
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data.docs;
    }
    return [];
  }
}
