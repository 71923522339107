import { createActions, createReducer } from "reduxsauce";
import DateUtil from "./../../utils/Date";
import { filterByClientAndPatrimonyAndWord, sortNotifications } from "./../../utils/Notification";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastNotification: ["lastNotification"],
  checkExpiredLastNotifications: [],
  searchLastNotification: ["search"],
  setLastNotification: ["lastNotifications"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  notifications: [],
  notificationsSearch: [],
  notificationsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    patrimony: "",
    word: ""
  },
  notificationsSearchQueryEnabled: false,
  notificationSelected: false,
  notificationsLoad: false
};

const add = (state = INITIAL_STATE, action) => {
  if(state.notificationsLoad) {
    const { lastNotification } = action;
    const notifications = state.notifications;
    notifications.push({ id: lastNotification.id, notification: lastNotification, selected: false });

    const { notificationsSearch } = search({
      ...state,
      notifications
    }, { search: state.notificationsSearchQuery });

    return {
      ...state,
      notifications: sortNotifications(notifications),
      notificationsSearch: sortNotifications(notificationsSearch)
    };
  }
  return state;
};

const checkExpiredLastNotifications = (state = INITIAL_STATE, action) => {
  if(state.notificationsLoad) {
    const notifications = state.notifications.filter(notification => {
      const dateA = notification.notification.date;
      const dateB = (new Date()).toISOString();
      const diff = DateUtil.diff({ dateA, dateB, format: "hours" });
      return diff > -1;
    });

    const { notificationsSearch } = search({
      ...state,
      notifications
    }, { search: state.notificationsSearchQuery });

    return {
      ...state,
      notifications: sortNotifications(notifications),
      notificationsSearch: sortNotifications(notificationsSearch)
    };
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: notificationsSearchEnabled, results: notificationsSearchResults } = filterByClientAndPatrimonyAndWord(state.notifications, search);
  return {
    ...state,
    notificationsSearch: notificationsSearchResults,
    notificationsSearchQuery: search,
    notificationsSearchQueryEnabled: notificationsSearchEnabled
  };
};

const set = (state = INITIAL_STATE, action) => {
  if(state.notificationsLoad === false) {
    const { lastNotifications } = action;
    const notifications = lastNotifications.map(lastNotification => {
      return { id: lastNotification.id, notification: lastNotification, selected: false };
    });
    return {
      ...state,
      notifications: sortNotifications(notifications),
      notificationsLoad: true
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_NOTIFICATION]: add,
  [Types.CHECK_EXPIRED_LAST_NOTIFICATIONS]: checkExpiredLastNotifications,
  [Types.SEARCH_LAST_NOTIFICATION]: search,
  [Types.SET_LAST_NOTIFICATION]: set
});
