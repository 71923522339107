import { maskCellphone } from "./Cellphone";

export function jsonToForm(json) {
  if(typeof json.contact !== "undefined") {
    if(typeof json.contact.cellphone !== "undefined" && json.contact.cellphone !== "") {
      json.contact.cellphone = maskCellphone(json.contact.cellphone);
    }
    if(typeof json.contact.telephone !== "undefined" && json.contact.telephone !== "") {
      json.contact.telephone = maskCellphone(json.contact.telephone);
    }
  }
  return json;
}

export function filterByClient(users, search) {
  let filter = users;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((u) => typeof u.client !== "undefined" && u.client.id === search.client.id);
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((u) => typeof u.active !== "undefined" && u.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function filterByClientAndWord(users, search) {
  let filter = users;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((u) => typeof u.client !== "undefined" && u.client.id === search.client.id);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter(
        (u) =>
          (u.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1)
      );
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((u) => typeof u.active !== "undefined" && u.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function sortUsers(users, asc = true) {
  if(asc) {
    return users.sort((a, b) => {
      const nameA = a.name;
      const nameB = b.name;
      if(nameA > nameB) {
        return 1;
      }
      else if(nameA < nameB) {
        return -1;
      }
      return 0;
    });
  }
  return users.sort((a, b) => {
    const nameA = a.name;
    const nameB = b.name;
    if(nameA > nameB) {
      return -1;
    }
    else if(nameA < nameB) {
      return 1;
    }
    return 0;
  });
}
