import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addSingleTranshipment: ["end", "patrimony", "transhipment", "start"],
  closeMenuPositions: [],
  openMenuPositions: [],
  removeSingleTranshipment: [],
  selectMenuSingleTranshipment: ["id"],
  addSingleTranshipmentMenuPositionsMarkers: ["position"],
  removeSingleTranshipmentMenuPositionsMarkers: ["position"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  singleTranshipment: false,
  singleTranshipmentStartEnd: false,
  singleTranshipmentMenu: false,
  singleTranshipmentMenuSelected: false,
  singleTranshipmentMenuPositionsSelected: false,
  singleTranshipmentMenuPositionsMarkersSelected: []
};

const addSingleTranshipment = (state = INITIAL_STATE, action) => {
  const { end, patrimony, transhipment, start } = action;
  return {
    ...state,
    singleTranshipment: {
      patrimony,
      transhipment
    },
    singleTranshipmentStartEnd: {
      end,
      start
    },
    singleTranshipmentMenu: {
      transhipment: transhipment
    },
    singleTranshipmentMenuSelected: {
      id: "transhipment",
      value: transhipment
    },
    singleTranshipmentMenuPositionsMarkersSelected: []
  };
};

const addSingleTranshipmentMenuPositionsMarkers = (state = INITIAL_STATE, action) => {
  const { position } = action;
  const singleTranshipmentMenuPositionsMarkersSelected = state.singleTranshipmentMenuPositionsMarkersSelected;
  if(singleTranshipmentMenuPositionsMarkersSelected.findIndex(x => x.id === position.id) === -1) {
    singleTranshipmentMenuPositionsMarkersSelected.push(position);
  }
  return {
    ...state,
    singleTranshipmentMenuPositionsMarkersSelected
  };
};

const removeSingleTranshipmentMenuPositionsMarkers = (state = INITIAL_STATE, action) => {
  const { position } = action;
  const singleTranshipmentMenuPositionsMarkersSelected = state.singleTranshipmentMenuPositionsMarkersSelected;
  const index = singleTranshipmentMenuPositionsMarkersSelected.findIndex(x => x.id === position.id);
  if(index !== -1) {
    singleTranshipmentMenuPositionsMarkersSelected.splice(index, 1);
  }
  return {
    ...state,
    singleTranshipmentMenuPositionsMarkersSelected
  };
};

const closeMenuPositions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleTranshipmentMenuPositionsSelected: false,
    singleTranshipmentMenuPositionsMarkersSelected: []
  };
};

const openMenuPositions = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleTranshipmentMenuPositionsSelected: true,
    singleTranshipmentMenuPositionsMarkersSelected: []
  };
};

const removeSingleTranshipment = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    singleTranshipment: false,
    singleTranshipmentStartEnd: false,
    singleTranshipmentMenu: false,
    singleTranshipmentMenuSelected: false,
    singleTranshipmentMenuPositionsSelected: false,
    singleTranshipmentMenuPositionsMarkersSelected: []
  };
};

const selectMenuSingleTranshipment = (state = INITIAL_STATE, action) => {
  const { id } = action;
  return {
    ...state,
    singleTranshipmentMenuSelected: {
      id,
      value: state.singleTranshipmentMenu[id]
    },
    singleTranshipmentMenuPositionsMarkersSelected: []
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_SINGLE_TRANSHIPMENT]: addSingleTranshipment,
  [Types.CLOSE_MENU_POSITIONS]: closeMenuPositions,
  [Types.OPEN_MENU_POSITIONS]: openMenuPositions,
  [Types.REMOVE_SINGLE_TRANSHIPMENT]: removeSingleTranshipment,
  [Types.SELECT_MENU_SINGLE_TRANSHIPMENT]: selectMenuSingleTranshipment,
  [Types.ADD_SINGLE_TRANSHIPMENT_MENU_POSITIONS_MARKERS]: addSingleTranshipmentMenuPositionsMarkers,
  [Types.REMOVE_SINGLE_TRANSHIPMENT_MENU_POSITIONS_MARKERS]: removeSingleTranshipmentMenuPositionsMarkers
});
