import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  disableQrcode: [],
  enableQrcode: ["value"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  qrcode: false,
  value: null
};

const disable = (state = INITIAL_STATE, action) => {
  return {
    ...state,
    qrcode: false,
    value: null
  };
};

const enable = (state = INITIAL_STATE, action) => {
  const { value } = action;
  return {
    ...state,
    qrcode: true,
    value
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.DISABLE_QRCODE]: disable,
  [Types.ENABLE_QRCODE]: enable
});
