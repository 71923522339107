export function getType(tracker) {
  let title = "";
  if(tracker) {
    switch(tracker.type) {
    case "HAT":
      title = "Title.Hatch";
      break;
    case "NIP":
      title = "Title.Niple";
      break;
    case "TRA":
      title = "Title.Tracker";
      break;
    case "TRA_NIP":
      title = "Title.TrackerNiple";
      break;
    default:
      title = "Title.Tracker";
      break;
    }
  }
  return title;
}
