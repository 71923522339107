import ClientSchemaReduced from "./../Client/SchemaReduced";
import UserSchemaReduced from "./../User/SchemaReduced";

const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
const endAt = new Date(`${todayDate} 23:55:00`);
const startAt = new Date(`${todayDate} 00:00:00`);

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  endAt,
  error: false,
  file: {
    key: "",
    size: ""
  },
  freqAt: -1,
  id: "",
  originalId: "",
  patrimonies: [],
  routeAt: false,
  speedAt: false,
  startAt,
  user: JSON.parse(JSON.stringify(UserSchemaReduced)),
  users: [],
};

export default schema;
