export function isBoard(board, mercosul = false) {
  let er = /^(([a-zA-Z]{3}-[0-9]{4}))$/;
  if(mercosul) {
    er = /^(([a-zA-Z]{3}[0-9]{1}[a-zA-Z]{1}[0-9]{2}))$/;
  }
  if(er.test(board)) {
    return true;
  }
  return false;
}

export function isBoardDescription(description) {
  description = description.replace(/_/g, "");
  let er = /^(([a-zA-Z]{2} - [a-zA-Z]{1,15}))$/;
  if(er.test(description)) {
    return true;
  }
  return false;
}
