import React, { memo, useEffect, useState } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./index.css";

const DEFAULT_ZINDEX = 1000;
const SCALE_HOVER = 1;
const SCALE_NORMAL = 0.65;

const Polygon = ({ color, edit, index, onClick, total, virtualFence }) => {
  const { t } = useTranslation();

  const [polygonHover, setPolygonHover] = useState(false);
  const [popupShow, setPopupShow] = useState(false);

  const [scale, setScale] = useState(SCALE_NORMAL);
  const [zIndex, setZIndex] = useState(DEFAULT_ZINDEX);

  const [indexSelected, setIndexSelected] = useState(index);
  const [indexOptions, setIndexOptions] = useState([]);

  useEffect(() => {
    if (edit === -1) {
      setScale(polygonHover || popupShow ? SCALE_HOVER : SCALE_NORMAL);
      setZIndex(Math.round(scale * DEFAULT_ZINDEX) - (popupShow ? 20 : 0) + (polygonHover ? DEFAULT_ZINDEX : 0));
    }
  }, [polygonHover, popupShow, scale]); /* eslint-disable-line */

  useEffect(() => {
    if (edit !== index) {
      setPopupShow(false);
      setPolygonHover(false);
      setScale(SCALE_NORMAL);
      setZIndex(Math.round(SCALE_NORMAL * DEFAULT_ZINDEX));
    }
    else {
      setPopupShow(true);
      setPolygonHover(true);
      setScale(SCALE_HOVER);
      setZIndex(Math.round(SCALE_HOVER * DEFAULT_ZINDEX) - 20 + DEFAULT_ZINDEX);
    }
  }, [edit]); /* eslint-disable-line */

  useEffect(() => {
    if (typeof total !== "undefined" && !isNaN(total)) {
      const idx = [];
      for (var i = 0; i < total; i++) {
        idx.push({ value: i, label: (i + 1) });
      }
      setIndexOptions(idx);
    }
  }, [total]);

  useEffect(() => {
    setIndexSelected(index);
  }, [index]);

  const popup = (
    <div
      id={`polygon-marker-popup-${index}`}
      className="marker-polygon-popup"
      onClick={e => e.stopPropagation()}
    >
      <h3 className="mep-title">{t("Title.Point")}: {(index + 1)}/{total}</h3>
      <div className="mep-content">
        <ul>
          <li>
            <div>{t("Title.Name")}:</div>
            <div>{virtualFence.name}</div>
          </li>
          <li>
            <div>{t("Title.Actions")}:</div>
            <div>
              <div>
                <OverlayTrigger overlay={<Tooltip>{edit !== index ? t("Button.Edit") : t("Button.Cancel")}</Tooltip>}>
                  <Button
                    className="mep-content-button"
                    variant="dark"
                    type="button"
                    onClick={() => {
                      onClick(2, index);
                      setIndexSelected(index);
                    }}
                  >
                    {
                      edit !== index ? <i className="fas fa-edit" /> : <i className="fas fa-times" />
                    }
                  </Button>
                </OverlayTrigger>
              </div>
              <div>
                <OverlayTrigger overlay={<Tooltip>{t("Button.Remove")}</Tooltip>}>
                  <Button
                    className="mep-content-button"
                    variant="dark"
                    type="button"
                    onClick={() => edit === index ? onClick(3, index) : false}
                    disabled={edit === index ? false : true}
                  >
                    <i className="fas fa-trash-alt" />
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </li>
          <li>
            <div>{t("Title.Order")}:</div>
            <div>
              <div>
                <Select
                  className="mep-content-select menu-outer-top"
                  classNamePrefix="select"
                  isDisabled={edit === index ? false : true}
                  onChange={e => setIndexSelected(e.value)}
                  options={indexOptions}
                  value={indexOptions.find(x => x.value === indexSelected)}
                />
              </div>
              <div>
                <OverlayTrigger overlay={<Tooltip>{t("Button.Save")}</Tooltip>}>
                  <Button
                    className="mep-content-button"
                    variant="dark"
                    type="button"
                    onClick={() => edit === index ? onClick(4, index, indexSelected) : false}
                    disabled={edit === index ? false : true}
                  >
                    <i className="fas fa-check" />
                  </Button>
                </OverlayTrigger>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );

  return (
    <div
      className="marker-polygon-all"
      onClick={e => {
        if (edit === -1) {
          setPopupShow(!popupShow);
          onClick(popupShow ? 0 : 1);
        }
      }}
      onMouseEnter={e => setPolygonHover(true)}
      onMouseLeave={e => setPolygonHover(false)}
      style={{
        background: `${color}`,
        transform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        MozTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        WebkitTransform: `translateX(-50%) translateY(-50%) scale(${scale} , ${scale})`,
        zIndex: zIndex
      }}
    >
      <div className="marker-polygon">
        <div
          className="icon"
          style={{
            color: `${color}`
          }}
        >{(index + 1)}</div>
        {popupShow && popup}
      </div>
    </div>
  );
};

export default memo(Polygon);
