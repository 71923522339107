import DateUtil from "./Date";

export function getDescriptionClient(client) {
  if(typeof client === "undefined") {
	  return "";
  }
  switch (client.type) {
  case "LEG":
    return getDescriptionClientLegal(client);
  case "PER":
    return getDescriptionClientPersonal(client);
  default:
    return getDescriptionClientLegal(client);
  }
}

export function getDescriptionClientLegal(client) {
  return client.legal.socialReason;
}

export function getDescriptionClientPersonal(client) {
  return client.personal.name;
}

export function jsonToForm(json) {
  if(typeof json.legal !== "undefined") {
    if(typeof json.legal.foundationDate !== "undefined" && json.legal.foundationDate !== "") {
      json.legal.foundationDate = DateUtil.to({ date: json.legal.foundationDate, format: "DD/MM/YYYY" });
    }
  }
  if(typeof json.personal !== "undefined") {
    if(typeof json.personal.birthDate !== "undefined" && json.personal.birthDate !== "") {
      json.personal.birthDate = DateUtil.to({ date: json.personal.birthDate, format: "DD/MM/YYYY" });
    }
  }
  return json;
}
