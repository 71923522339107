import React, { useEffect, useRef, useState } from "react";
import {
  Form,
  InputGroup,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import { isScopes } from "./../../services/Auth";

import { Creators as ExportedsActions } from "./../../store/ducks/exporteds";
import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as UsersActions } from "./../../store/ducks/users";
import MenuSingleExportedItem from "./../MenuSingleExportedItem";
import PositionService from "./../../services/Position";

registerLocale("pt-BR", ptBR);

const MenuSingleExported = ({
  props: {
    currentUser,
    databaseTest,
    lastPositionSelected,
    singleExported,
    singleExportedStartEnd,
    singleExportedMenu,
    singleExportedMenuSelected,
    singleExportedMenuPositionsSelected
  },
  funcs: {
    addSingleExported,
    closeMenuPositions,
    disableLoader,
    enableLoader,
    menuHandleClose,
    menuHandleOpen,
    openMenuPositions,
    removeSingleExported,
    selectMenuSingleExported
  }
}) => {
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);

  const { t } = useTranslation();
  const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
  const [datePickerOpen, setDatePickerOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date(`${todayDate} 00:00:00`));
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [endDate, setEndDate] = useState(new Date(`${todayDate} 23:55:00`));
  const [endDateFocus, setEndDateFocus] = useState(false);
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(1);
  const [orderAsc, setOrderAsc] = useState(false);

  useEffect(() => {
    if (singleExportedStartEnd) {
      const startAux = new Date(singleExportedStartEnd.start);
      if (startAux.getTime() !== startDate.getTime()) {
        setStartDate(startAux);
      }
      const endAux = new Date(singleExportedStartEnd.end);
      if (endAux.getTime() !== endDate.getTime()) {
        setEndDate(endAux);
      }
      const orderAscAux = singleExportedStartEnd.orderAsc;
      if (orderAscAux !== orderAsc) {
        setOrderAsc(orderAscAux);
      }
    }
  }, []); /* eslint-disable-line */

  const handleSearchRoute = async (page, orderAsc) => {
    enableLoader();
    const patrimony = lastPositionSelected.position.patrimony.id;
    const response = databaseTest ? await PositionService.findExportedRouteTest({
      endDate,
      orderAsc,
      page,
      patrimony,
      startDate,
      token: currentUser.token
    }) : await PositionService.findExportedRoute({
      endDate,
      orderAsc,
      page,
      patrimony,
      startDate,
      token: currentUser.token
    });
    const { docs, page: pageReq, pages: pagesReq } = response.positions;
    setPage(pageReq);
    setPages(pagesReq);
    addSingleExported(endDate, { orderAsc, page: pageReq, pages: pagesReq }, lastPositionSelected, docs, startDate);
    menuHandleClose();
    disableLoader();
  };

  const dynamicOptions = [
    {
      id: "mse-option-route",
      title: t("Title.Route"),
      icon: <i className="fas fa-route" />,
      onClick: () => selectMenuSingleExported("route"),
      selected: () => singleExportedMenuSelected && singleExportedMenuSelected.id === "route",
      scoped: () => true
    },
    {
      id: "mse-option-maintenance",
      title: t("Title.Niple.Maintenance"),
      icon: <span>M</span>,
      getCount: () => singleExportedMenu.maintenance.length,
      onClick: () => selectMenuSingleExported("maintenance"),
      selected: () => singleExportedMenuSelected && singleExportedMenuSelected.id === "maintenance",
      scoped: () => true
    },
    {
      id: "mse-option-moving",
      title: t("Title.Moving"),
      icon: <span>E</span>,
      getCount: () => singleExportedMenu.moving.length,
      onClick: () => selectMenuSingleExported("moving"),
      selected: () => singleExportedMenuSelected && singleExportedMenuSelected.id === "moving",
      scoped: () => true
    },
    {
      id: "mse-option-niple-discharge",
      title: t("Title.Niple.Discharge"),
      icon: <span>D</span>,
      getCount: () => singleExportedMenu.nipleDischarge.length,
      onClick: () => selectMenuSingleExported("nipleDischarge"),
      selected: () => singleExportedMenuSelected && singleExportedMenuSelected.id === "nipleDischarge",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    },
    {
      id: "mse-option-niple-fraud",
      title: t("Title.Niple.Fraud"),
      icon: <span>B</span>,
      getCount: () => singleExportedMenu.nipleFraud.length,
      onClick: () => selectMenuSingleExported("nipleFraud"),
      selected: () => singleExportedMenuSelected && singleExportedMenuSelected.id === "nipleFraud",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "read:patrimonies:description"], every: false })
    }
  ];

  const fixedOptions = [
    {
      id: "mse-option-close",
      title: t("Title.Close"),
      icon: <i className="fas fa-times" />,
      onClick: () => {
        removeSingleExported();
        menuHandleOpen();
      },
      selected: () => true
    },
    {
      id: "mse-option-date",
      title: t("Title.Calendar"),
      icon: <i className="far fa-calendar-alt" />,
      onClick: () => setDatePickerOpen(!datePickerOpen),
      selected: () => datePickerOpen
    }
  ];

  return (
    <div className="menu-single-exported">
      <div className={`mse-route-options-absolute ${datePickerOpen ? "active" : ""}`}>
        <div className="mse-rpa-fields">
          <form onSubmit={e => e.preventDefault()}>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={startDate}
                  onCalendarOpen={() => setStartDateFocus(true)}
                  onCalendarClose={() => setStartDateFocus(false)}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                  ref={startDateRef}
                />
                {
                  (startDateFocus && (
                    <div className="react-datepicker-close-button">
                      <button type="button" onClick={() => {
                        if (startDateRef.current !== null && typeof startDateRef.current.setOpen !== "undefined") {
                          startDateRef.current.setOpen(false);
                        }
                      }}><i className="fa fa-times" /></button>
                    </div>
                  ))
                }
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={endDate}
                  onCalendarOpen={() => setEndDateFocus(true)}
                  onCalendarClose={() => setEndDateFocus(false)}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                  ref={endDateRef}
                />
                {
                  (endDateFocus && (
                    <div className="react-datepicker-close-button">
                      <button type="button" onClick={() => {
                        if (endDateRef.current !== null && typeof endDateRef.current.setOpen !== "undefined") {
                          endDateRef.current.setOpen(false);
                        }
                      }}><i className="fa fa-times" /></button>
                    </div>
                  ))
                }
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
          </form>
        </div>
        <div className="mse-rpa-close">
          <button type="button" onClick={e => setDatePickerOpen(!datePickerOpen)}><i className="far fa-times-circle" /></button>
        </div>
        <div className="mse-rpa-search">
          <button type="button" onClick={() => handleSearchRoute(page, orderAsc)}><i className="fas fa-search" /></button>
        </div>
        <div className="mse-rpa-pagination">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Back")}</Tooltip>}
            trigger={isMobile || (page <= 1) ? null : ["hover", "focus"]}
          >
            <button type="button"
              disabled={page <= 1}
              onClick={() => {
                if (page > 1) {
                  const newPage = page - 1;
                  setPage(newPage);
                  handleSearchRoute(newPage, orderAsc);
                }
              }}
            >
              <i className="fas fa-chevron-left" />
            </button>
          </OverlayTrigger>
          <p>{t("Title.Page")} {singleExported.page} {t("Title.Of")} {singleExported.pages}</p>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{!orderAsc ? t("Title.Sort.TimeAsc") : t("Title.Sort.TimeDesc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <button type="button"
              onClick={e => {
                setOrderAsc(!orderAsc);
                handleSearchRoute(page, !orderAsc);
              }}
            >
              {
                (!orderAsc && (
                  <i className="fas fa-hourglass-start" />
                ))
              }
              {
                (orderAsc && (
                  <i className="fas fa-hourglass-end" />
                ))
              }
            </button>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Next")}</Tooltip>}
            trigger={isMobile || (page >= pages) ? null : ["hover", "focus"]}
          >
            <button type="button"
              disabled={page >= pages}
              onClick={e => {
                if (page < pages) {
                  const newPage = page + 1;
                  setPage(newPage);
                  handleSearchRoute(newPage, orderAsc);
                }
              }}
            >
              <i className="fas fa-chevron-right" />
            </button>
          </OverlayTrigger>
        </div>
      </div>
      <div className="mse-route-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              return (
                <MenuSingleExportedItem key={option.id} {...option} onClick={option.onClick} />
              );
            })
          }
        </ul>
      </div>
      <div className="mse-route-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuSingleExportedItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionSelected: state.lastPositions.positionSelected,
    singleExported: state.exporteds.singleExported,
    singleExportedStartEnd: state.exporteds.singleExportedStartEnd,
    singleExportedMenu: state.exporteds.singleExportedMenu,
    singleExportedMenuSelected: state.exporteds.singleExportedMenuSelected,
    singleExportedMenuPositionsSelected: state.exporteds.singleExportedMenuPositionsSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, ExportedsActions, LoadersActions, MenusActions, UsersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSingleExported);
