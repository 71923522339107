export function getProfiles(profileScopes, userScopes) {
  let profileScopesSelected = [];
  profileScopes.types.map(type => {
    return type.items.map(item => {
      return item.items.map(subitem => {
        const filterScopes = subitem.scopes.filter(x => userScopes.findIndex(y => y.scope === x.scope) !== -1);
        if(filterScopes.length === subitem.scopes.length) {
          profileScopesSelected = profileScopesSelected.filter(x => x.checkId !== subitem.checkId);
          return profileScopesSelected.push(subitem);
        }
        return false;
      });
    });
  });
  return profileScopesSelected;
}
