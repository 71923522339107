import React, { memo, useState } from "react";
import {
  Form,
  InputGroup
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";
import "./index.css";

import { Creators as NotificationsActions } from "./../../../store/ducks/notifications";
import { Creators as LoadersActions } from "./../../../store/ducks/loaders";

import { jsonToForm } from "./../../../utils/Client";
import { mergeObject } from "./../../../utils/Object";

import ClientService from "./../../../services/Client";
import ClientSchema from "./../../../services/Client/Schema";

registerLocale("pt-BR", ptBR);

const Contract = ({
  options,
  props: { currentUser },
  funcs: { enableLoader, disableLoader }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();

  const {
    client: __client = {}
  } = options;

  const [client] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(ClientSchema)), __client)));
  const [contract, setContract] = useState(false);
  const [loader, setLoader] = useState(false);

  const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
  const [date, setDate] = useState(new Date(`${todayDate} 00:00:00`));

  const getContract = async () => {
    enableLoader();
    const response = await clientService.contract({ data: { date }, id: client.id, token: currentUser.token });
    setContract(window.URL.createObjectURL(response));
    setTimeout(() => {
      disableLoader();
    }, 100);
  };

  if(contract === false && loader === false) {
    setLoader(true);
    setTimeout(() => {
      getContract();
    }, 100);
  }

  return (
    <div>
      <div className={`cc-route-options-absolute active`}>
        <div className="cc-rpa-fields">
          <form onSubmit={e => e.preventDefault()}>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={date}
                  onChange={date => setDate(date)}
                  selectsStart
                  date={date}
                  minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                />
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
          </form>
        </div>
        <div className="cc-rpa-search">
          <button type="button" onClick={getContract}><i className="fas fa-search" /></button>
        </div>
      </div>
      {contract && <iframe className="iframe-fluid" src={contract} title={t("Title.Contract")} />}
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Contract));
