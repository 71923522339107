import React, { useState, useEffect } from "react";
import {
  Form,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import "./index.css";

import { Creators as LastPositionActions } from "./../../store/ducks/lastPositions";

import { mergeObject } from "./../../utils/Object";
import { isScopes } from "./../../services/Auth";

import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

const LastPositionSearch = ({
  props: { currentUser, databaseTest, lastPositionSelected, lastPositionsSortAsc, lastPositionsSortDate, lastPositionsSortDischarge, lastPositionsSortName },
  funcs: { searchLastPosition, setLastPositionSort }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const [positionsSearch, setPositionsSearch] = useState({
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    events: [],
    word: ""
  });

  const [clientSelectedOption, setClientSelectedOption] = useState(positionsSearch.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), positionsSearch.client),
    label: positionsSearch.client.type === "LEG" ? positionsSearch.client.legal.socialReason : positionsSearch.client.personal.name,
    value: positionsSearch.client.id
  });

  useEffect(() => {
    searchLastPosition(positionsSearch);
  }, [positionsSearch, searchLastPosition]);

  useEffect(() => {
    resetClient();
    resetSearch();
  }, [databaseTest]);

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? e.legal.socialReason : e.personal.name,
        value: e.id
      };
    });
  };

  const resetClient = () => {
    setClientSelectedOption(null);
    setPositionsSearch(prevState => ({
      ...prevState,
      client: JSON.parse(JSON.stringify(ClientSchemaReduced))
    }));
  };

  const resetSearch = () => {
    setPositionsSearch({
      client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
      events: [],
      word: ""
    });
  };

  const hasEvent = (type) => positionsSearch.events.findIndex(x => x === type) !== -1;

  const handleEvent = (type) => {
    const positionsSearchAux = {...positionsSearch};
    let index = positionsSearchAux.events.findIndex(x => x === type);
    if (index === -1) {
      positionsSearchAux.events.push(type);
    }
    else {
      positionsSearchAux.events.splice(index, 1);
    }
    setPositionsSearch(prevState => ({
      ...prevState,
      events: positionsSearchAux.events
    }));
  };

  return (
    <form onSubmit={e => e.preventDefault()}>
      {
        isScopes({ currentUser, scopes: [
          "is:master",
          "read:patrimonies:all"
        ], every: false }) ? (
            <Form.Group controlId="positions-item-form-search-client">
              <AsyncSelect
                className="menu-outer-bottom"
                classNamePrefix="select"
                cacheOptions
                defaultOptions
                isClearable
                loadOptions={clientLoadOptions}
                loadingMessage={() => t("React.Select.Wait")}
                noOptionsMessage={() => t("React.Select.NoOptions")}
                onChange={e => {
                  if(e === null) {
                    resetClient();
                  }
                  else {
                    const { dataAux, label, value } = e;
                    setClientSelectedOption({ dataAux, label, value });
                    setPositionsSearch(prevState => ({
                      ...prevState,
                      client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                    }));
                  }
                }}
                placeholder={t("React.Select.SelectClient")}
                value={clientSelectedOption}
              />
            </Form.Group>
          ) : null
      }
      <Form.Group controlId="positions-item-form-search">
        <Form.Control
          type="text"
          placeholder={t("Placeholder.Search")}
          autoComplete={"off"}
          onChange={e => setPositionsSearch(prevState => ({
            ...prevState,
            word: e.target.value
          }))}
          value={positionsSearch.word}
          disabled={lastPositionSelected ? true : false}
        />
      </Form.Group>
      <Form.Group>
        <ul className="last-position-patrimony-type">
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.NameAsc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={lastPositionsSortAsc && lastPositionsSortName ? "active" : ""} onClick={() => setLastPositionSort(true, false, false, true)}>
              <i className="fas fa-sort-alpha-down" />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.NameDesc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={!lastPositionsSortAsc && lastPositionsSortName ? "active" : ""} onClick={() => setLastPositionSort(false, false, false, true)}>
              <i className="fas fa-sort-alpha-down-alt" />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.TimeAsc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={lastPositionsSortAsc && lastPositionsSortDate ? "active" : ""} onClick={() => setLastPositionSort(true, true, false, false)}>
              <i className="fas fa-hourglass-end" />
            </li>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip>{t("Title.Sort.TimeDesc")}</Tooltip>}
            trigger={isMobile ? null : ["hover", "focus"]}
          >
            <li className={!lastPositionsSortAsc && lastPositionsSortDate ? "active" : ""} onClick={() => setLastPositionSort(false, true, false, false)}>
              <i className="fas fa-hourglass-start" />
            </li>
          </OverlayTrigger>
          {
            isScopes({ currentUser, scopes: [
              "is:master",
              "read:patrimonies:all"
            ], every: false }) ? (
              <>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("Title.Sort.DischargeAsc")}</Tooltip>}
                  trigger={isMobile ? null : ["hover", "focus"]}
                >
                  <li className={lastPositionsSortAsc && lastPositionsSortDischarge ? "active" : ""} onClick={() => setLastPositionSort(true, false, true, false)}>
                    <i className="fas fa-long-arrow-alt-up"></i>
                    D
                  </li>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip>{t("Title.Sort.DischargeDesc")}</Tooltip>}
                  trigger={isMobile ? null : ["hover", "focus"]}
                >
                  <li className={!lastPositionsSortAsc && lastPositionsSortDischarge ? "active" : ""} onClick={() => setLastPositionSort(false, false, true, false)}>
                    <i className="fas fa-long-arrow-alt-down"></i>
                    D
                  </li>
                </OverlayTrigger>
              </>
            ) : null
          }
        </ul>
      </Form.Group>
      {
        isScopes({ currentUser, scopes: [
          "is:master",
          "read:patrimonies:all"
        ], every: false }) ? (
          <Form.Group>
            <ul className="last-position-patrimony-event-type">
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.IgnitionOn")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("ignitionOn") ? "active" : ""} onClick={() => handleEvent("ignitionOn")}>
                  <div className="icon"><i className="fas fa-key" style={{ color: "#087d06" }} /></div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.IgnitionOff")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("ignitionOff") ? "active" : ""} onClick={() => handleEvent("ignitionOff")}>
                  <div className="icon"><i className="fas fa-key" style={{ color: "#ff0000" }} /></div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.Battery")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("battery") ? "active" : ""} onClick={() => handleEvent("battery")}>
                  <div className="icon"><i className="fas fa-battery-empty" /></div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.Maintenance")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("maintenance") ? "active" : ""} onClick={() => handleEvent("maintenance")}>
                  <div className="icon">M</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.EspRestarted")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("espRestarted") ? "active" : ""} onClick={() => handleEvent("espRestarted")}>
                  <div className="icon">R</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.NipleFraud")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("nipleFraud") ? "active" : ""} onClick={() => handleEvent("nipleFraud")}>
                  <div className="icon">B</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.NipleClosed")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("nipleClosed") ? "active" : ""} onClick={() => handleEvent("nipleClosed")}>
                  <div className="icon">F</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.NipleDischarge")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("nipleDischarge") ? "active" : ""} onClick={() => handleEvent("nipleDischarge")}>
                  <div className="icon">D</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.NipleDischargeTest")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("nipleDischargeTest") ? "active" : ""} onClick={() => handleEvent("nipleDischargeTest")}>
                  <div className="icon" style={{ color: "#999999" }}>D</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.NipleCharge")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("nipleCharge") ? "active" : ""} onClick={() => handleEvent("nipleCharge")}>
                  <div className="icon">C</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.NipleChargeTest")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("nipleChargeTest") ? "active" : ""} onClick={() => handleEvent("nipleChargeTest")}>
                  <div className="icon" style={{ color: "#999999" }}>C</div>
                </li>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip>{t("Title.Filter.HatchOpen")}</Tooltip>}
                trigger={isMobile ? null : ["hover", "focus"]}
              >
                <li className={hasEvent("hatchOpen") ? "active" : ""} onClick={() => handleEvent("hatchOpen")}>
                  <div className="icon">A</div>
                </li>
              </OverlayTrigger>
            </ul>
          </Form.Group>
        ) : null
      }
    </form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionSelected: state.lastPositions.positionSelected,
    lastPositionsSortAsc: state.lastPositions.positionsSortAsc,
    lastPositionsSortDate: state.lastPositions.positionsSortDate,
    lastPositionsSortDischarge: state.lastPositions.positionsSortDischarge,
    lastPositionsSortName: state.lastPositions.positionsSortName
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LastPositionActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LastPositionSearch);
