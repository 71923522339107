import DateUtil from "./Date";

export function jsonToForm(json) {
  if(typeof json.installAt !== "undefined" && json.installAt !== "") {
    json.installAt = DateUtil.to({ date: json.installAt, format: "DD/MM/YYYY" });
  }
  if(typeof json.uninstallAt !== "undefined" && json.uninstallAt !== "") {
    json.uninstallAt = DateUtil.to({ date: json.uninstallAt, format: "DD/MM/YYYY" });
  }
  return json;
}

export function isPatrimonyId(id) {
  return id.match(/^[0-9a-fA-F]{24}$/);
}

export function isAutomobile(patrimony) {
  if (typeof patrimony === "undefined") {
    return false;
  }
  return patrimony.type === "AUT";
};

export function isAutomobileBoardAutoVaccum(patrimony) {
  if (isAutomobile(patrimony)) {
    if (isAutomobileBoard(patrimony)) {
      return patrimony.automobile.board.type === "ATV";
    }
    return false;
  }
  return false;
};

export function isAutomobileBoardTruck(patrimony) {
  if (isAutomobile(patrimony)) {
    if (isAutomobileBoard(patrimony)) {
      return patrimony.automobile.board.type === "TRU";
    }
    return false;
  }
  return false;
};

export function isAutomobileBoard(patrimony) {
  if (isAutomobile(patrimony)) {
    return patrimony.automobile.type === "BOA";
  }
  return false;
};

export function isVessel(patrimony) {
  if (typeof patrimony === "undefined") {
    return false;
  }
  return patrimony.type === "VES";
};

export function filterByClient(patrimonies, search) {
  let filter = patrimonies;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.client !== "undefined" && p.client.id === search.client.id);
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((p) => typeof p.active !== "undefined" && p.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function filterByClientAndWord(patrimonies, search) {
  let filter = patrimonies;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.client !== "undefined" && p.client.id === search.client.id);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter(
        (p) =>
          (p.automobile && p.automobile.board && p.automobile.board.board.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
          (p.automobile && p.automobile.card && p.automobile.card.number.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
          (p.object && p.object.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1) ||
          (p.vessel && p.vessel.name.toLowerCase().indexOf(search.word.toLowerCase()) !== -1)
      );
    }
    if(typeof search.active !== "undefined" && search.active !== -1) {
      enabled = true;
      filter = filter.filter((p) => typeof p.active !== "undefined" && p.active === search.active);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function getDescriptionPatrimony(patrimony) {
  switch (patrimony.type) {
  case "AUT":
    return getDescriptionPatrimonyAutomobile(patrimony);
  case "OBJ":
    return getDescriptionPatrimonyObject(patrimony);
  case "VES":
    return getDescriptionPatrimonyVessel(patrimony);
  default:
    return getDescriptionPatrimonyAutomobile(patrimony);
  }
}

export function getDescriptionPatrimonyAutomobile(patrimony) {
  switch (patrimony.automobile.type) {
  case "BOA":
    return getDescriptionPatrimonyAutomobileBoard(patrimony);
  case "CAR":
    return getDescriptionPatrimonyAutomobileCard(patrimony);
  default:
    return getDescriptionPatrimonyAutomobileBoard(patrimony);
  }
}

export function getDescriptionPatrimonyAutomobileBoard(patrimony) {
  return patrimony.automobile.board.board;
}

export function getDescriptionPatrimonyAutomobileCard(patrimony) {
  return patrimony.automobile.card.number;
}

export function getDescriptionPatrimonyObject(patrimony) {
  return patrimony.object.name;
}

export function getDescriptionPatrimonyVessel(patrimony) {
  return patrimony.vessel.name;
}

export function sortPatrimonies(patrimonies, asc = true) {
  if(asc) {
    return patrimonies.sort((a, b) => {
      const nameA = getDescriptionPatrimony(a);
      const nameB = getDescriptionPatrimony(b);
      if(nameA > nameB) {
        return 1;
      }
      else if(nameA < nameB) {
        return -1;
      }
      return 0;
    });
  }
  return patrimonies.sort((a, b) => {
    const nameA = getDescriptionPatrimony(a);
    const nameB = getDescriptionPatrimony(b);
    if(nameA > nameB) {
      return -1;
    }
    else if(nameA < nameB) {
      return 1;
    }
    return 0;
  });
}
