import { createActions, createReducer } from "reduxsauce";
import { filterByWord } from "./../../utils/PatrimonyTest";

import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  addLastPatrimonyTest: ["lastPatrimonyTest"],
  updateLastPatrimonyTest: ["id", "lastPatrimonyTest"],
  searchLastPatrimonyTest: ["search"],
  selectLastPatrimonyTest: ["id"],
  setLastPatrimonyTest: ["lastPatrimonyTests"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  patrimonyTests: [],
  patrimonyTestsSearch: [],
  patrimonyTestsSearchQuery: {
    client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
    word: ""
  },
  patrimonyTestsSearchQueryEnabled: false,
  patrimonyTestSelected: false,
  patrimonyTestsLoad: false
};

const format = (lastPatrimonyTest) => {
  const copy = JSON.parse(JSON.stringify(lastPatrimonyTest));
  return copy;
};

const add = (state = INITIAL_STATE, action) => {
  if(state.patrimonyTestsLoad) {
    const { lastPatrimonyTest } = action;
    const index = state.patrimonyTests.findIndex(x => x.id === lastPatrimonyTest.patrimony.id);
    if(index !== -1) {
      return update(
        state,
        {
          id: lastPatrimonyTest.patrimony.id,
          lastPatrimonyTest
        }
      );
    }

    const patrimonyTests = state.patrimonyTests;
    patrimonyTests.push({ id: lastPatrimonyTest.patrimony.id, patrimonyTest: format(lastPatrimonyTest), selected: false });

    const { patrimonyTestsSearch } = search({
      ...state,
      patrimonyTests
    }, { search: state.patrimonyTestsSearchQuery });

    return {
      ...state,
      patrimonyTests: patrimonyTests,
      patrimonyTestsSearch: patrimonyTestsSearch
    };
  }
  return state;
};

const update = (state = INITIAL_STATE, action) => {
  if(state.patrimonyTestsLoad) {
    const { id, lastPatrimonyTest } = action;
    const index = state.patrimonyTests.findIndex(x => x.id === id);
    if(index !== -1) {
      let patrimonyTestSelected = state.patrimonyTestSelected;
      const patrimonyTests = state.patrimonyTests.map(patrimonyTest => {
        if(patrimonyTest.id === id) {
          if(patrimonyTest.selected) {
            patrimonyTestSelected = { ...patrimonyTest, patrimonyTest: format(lastPatrimonyTest) };
          }
          return { ...patrimonyTest, patrimonyTest: format(lastPatrimonyTest) };
        }
        return { ...patrimonyTest };
      });

      const { patrimonyTestsSearch } = search({
        ...state,
        patrimonyTests,
        patrimonyTestSelected
      }, { search: state.patrimonyTestsSearchQuery });

      return {
        ...state,
        patrimonyTests: patrimonyTests,
        patrimonyTestsSearch: patrimonyTestsSearch,
        patrimonyTestSelected
      };
    }
  }
  return state;
};

const search = (state = INITIAL_STATE, action) => {
  const { search } = action;
  const { enabled: patrimonyTestsSearchEnabled, results: patrimonyTestsSearchResults } = filterByWord(state.patrimonyTests, search);
  return {
    ...state,
    patrimonyTestsSearch: patrimonyTestsSearchResults,
    patrimonyTestsSearchQuery: search,
    patrimonyTestsSearchQueryEnabled: patrimonyTestsSearchEnabled
  };
};

const select = (state = INITIAL_STATE, action) => {
  const { id } = action;
  let patrimonyTestSelected = state.patrimonyTestSelected;
  const patrimonyTests = state.patrimonyTests.map(patrimonyTest => {
    if(patrimonyTest.id === id) {
      patrimonyTestSelected = false;
      if(!patrimonyTest.selected) {
        patrimonyTestSelected = { ...patrimonyTest, selected: !patrimonyTest.selected };
      }
      return { ...patrimonyTest, selected: !patrimonyTest.selected };
    }
    return { ...patrimonyTest, selected: false };
  });
  const patrimonyTestsSearch = state.patrimonyTestsSearch.map(patrimonyTest => patrimonyTest.id === id ? { ...patrimonyTest, selected: !patrimonyTest.selected } : { ...patrimonyTest, selected: false });
  return {
    ...state,
    patrimonyTests,
    patrimonyTestsSearch,
    patrimonyTestSelected
  };
};

const set = (state = INITIAL_STATE, action) => {
  if(state.patrimonyTestsLoad === false) {
    const { lastPatrimonyTests } = action;
    const patrimonyTests = lastPatrimonyTests.map(lastPatrimonyTest => {
      return { id: lastPatrimonyTest.patrimony.id, patrimonyTest: format(lastPatrimonyTest), selected: false };
    });
    return {
      ...state,
      patrimonyTests: patrimonyTests,
      patrimonyTestsLoad: true
    };
  }
  return state;
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.ADD_LAST_PATRIMONY_TEST]: add,
  [Types.UPDATE_LAST_PATRIMONY_TEST]: update,
  [Types.SEARCH_LAST_PATRIMONY_TEST]: search,
  [Types.SELECT_LAST_PATRIMONY_TEST]: select,
  [Types.SET_LAST_PATRIMONY_TEST]: set
});
