import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setPeriodDateEnd: ["dateEnd"],
  setPeriodDateStart: ["dateStart"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  periodDateStart: false,
  periodDateEnd: false
};

const setPeriodDateEnd = (state = INITIAL_STATE, action) => {
  const { dateEnd } = action;
  return {
    ...state,
    periodDateEnd: dateEnd
  };
};

const setPeriodDateStart = (state = INITIAL_STATE, action) => {
  const { dateStart } = action;
  return {
    ...state,
    periodDateStart: dateStart
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_PERIOD_DATE_END]: setPeriodDateEnd,
  [Types.SET_PERIOD_DATE_START]: setPeriodDateStart
});
