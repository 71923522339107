import React, { useEffect, useRef, useState } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import "./index.css";

import GoogleMapReact from "google-map-react";

import PointMarker from "../../../components/Marker/Point";

const SupportPointMap = ({
  locationOptions
}) => {
  const { t } = useTranslation();

  const {
    point: __point = {
      type: "Point",
      coordinates: []
    },
    range: __range = 0,
    setSupportPoint: __setSupportPoint = () => {},
    supportPoint: __supportPoint = false
  } = locationOptions;

  const __default_center__ = { lat: -14.2800653, lng: -46.9647527 };
  const __default_zoom__ = 4.78;
  const __default_min_zoom__ = 3;
  const __default_max_zoom__ = 19;

  const mapRef = useRef();
  const mapsRef = useRef();

  const [bounds, setBounds] = useState(null); /* eslint-disable-line */
  const [mapReady, setMapReady] = useState(false);

  const [sP, setSP] = useState(__supportPoint);
  const [sPCenter, setSPCenter] = useState(__point);
  const [sPCircle, setSPCircle] = useState(false);
  const [sPRange, setSPRange] = useState(__range);

  const [add, setAdd] = useState(false);
  const [addAux, setAddAux] = useState(false);
  const [edit, setEdit] = useState(-1);
  const [draggable, setDraggable] = useState(true);

  const [options] = useState({
    mapTypeId: "DEF",
    minZoom: __default_min_zoom__,
    maxZoom: __default_max_zoom__,
    restriction: null,
    styles: [{
      stylers: [{
        saturation: -10
      }]
    }]
  });
  const [zoom, setZoom] = useState(12); /* eslint-disable-line */

  useEffect(() => {
    if (mapReady) {
      const color = getCircleColor();
      setSPCircle(new mapsRef.current.Circle({
        strokeColor: color,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: color,
        fillOpacity: 0.35,
        map: mapRef.current,
        center: getCircleCenter() ? getCircleCenter() : __default_center__,
        radius: sPRange
      }));
      reloadBounds();
    }
  }, [mapReady]); /* eslint-disable-line */

  useEffect(() => {
    if (mapReady) {
      sPCircle.setCenter(getCircleCenter());
      sPCircle.setRadius(sPRange);
      const sp = JSON.parse(JSON.stringify(__supportPoint));
      sp.location = JSON.parse(JSON.stringify(sPCenter));
      if (!sp.location.coordinates) sp.location.coordinates = [];
      __setSupportPoint(sp);
      reloadBounds();
    }
  }, [sPCenter, sPRange]); /* eslint-disable-line */

  useEffect(() => {
    setSP(__supportPoint);
  }, [__supportPoint]);

  useEffect(() => {
    setSPRange(__range);
  }, [__range]);

  useEffect(() => {
    if (mapReady) {
      const color = getCircleColor();
      sPCircle.setOptions({ strokeColor: color, fillColor: color });
    }
  }, [sP]); /* eslint-disable-line */

  const addPoint = () => {
    if (mapReady) {
      setEdit(-1);
      setAdd(!add);
      setAddAux(false);
    }
  };

  const addPointAux = (lat, lng) => {
    if (mapReady) {
      if (!isNaN(lat) && !isNaN(lng)) {
        setAddAux(new mapsRef.current.LatLng(lat, lng));
        setEdit(getCircleCenter() ? 0 : -1);
        setAdd(false);
      }
    }
  };

  const addPointReset = () => {
    if (mapReady) {
      setEdit(-1);
      setAdd(false);
      setAddAux(false);
    }
  };

  const addCircleCenter = (oldIndex, newIndex) => {
    if (mapReady) {
      const point = JSON.parse(JSON.stringify(sPCenter));
      point.coordinates = [addAux.lng(), addAux.lat()];
      setSPCenter(point);
      addPointReset();
    }
  };

  const editCircleCenter = (index) => {
    if (mapReady) {
      if (edit === index) setEdit(-1);
      else setEdit(index);
    }
  };

  const getMapTypeId = ({ type }) => {
    switch(type) {
    case "SAT":
      return "hybrid";
    default:
      return "roadmap";
    }
  };

  const getBounds = () => {
    if (mapReady) {
      const pointsBounds = new mapsRef.current.LatLngBounds();
      if (getCircleCenter()) {
        pointsBounds.extend(getCircleCenter());
        return pointsBounds;
      }
      else {
        mapRef.current.setCenter(__default_center__);
        mapRef.current.setZoom(__default_zoom__);
      }
    }
    return false;
  };

  const getCircleColor = () => {
    let color = "#820404";
    if (mapReady) {
      switch(sP.type) {
        case "CHE":
          color = "#820404";
          break;
        case "COL":
          color = "#008c23";
          break;
        case "SUP":
          color = "#0453A2";
          break;
        default:
          color = "#820404";
          break;
      }
    }
    return color;
  };

  const getCircleCenter = () => {
    if (mapReady) {
      const coords = sPCenter.coordinates;
      if (coords.length === 2) return new mapsRef.current.LatLng(coords[1], coords[0]);
    }
    return false;
  };

  const removeCircleCenter = (index) => {
    if (mapReady) {
      const center = JSON.parse(JSON.stringify(sPCenter));
      center.coordinates = [];
      setSPCenter(center);
      setEdit(-1);
    }
  };

  const reloadBounds = () => {
    const bounds = getBounds();
    if (bounds) {
      mapRef.current.fitBounds(bounds);
    }
  };

  const setCircleCenter = (index, lat, lng) => {
    if (mapReady) {
      const center = JSON.parse(JSON.stringify(sPCenter));
      center.coordinates = [lng, lat];
      setSPCenter(center);
    }
  };

  const setOrderCircleCenter = (oldIndex, newIndex, centerAux = false) => {
    if (mapReady) {
      const center = centerAux !== false ? JSON.parse(JSON.stringify(centerAux)) : JSON.parse(JSON.stringify(sPCenter));
      center.coordinates = center.coordinates ? JSON.parse(JSON.stringify(center.coordinates)) : [];
      setSPCenter(center);
    }
  };

  return (
    <div className="support-point-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_APP_GOOGLE_MAP_KEY,
          libraries: ["places", "geometry"]
        }}
        defaultCenter={__default_center__}
        defaultZoom={__default_zoom__}
        draggable={draggable}
        options={{
          disableDefaultUI: true,
          mapTypeId: getMapTypeId({ type: options.mapTypeId }),
          minZoom: options.minZoom,
          maxZoom: options.maxZoom,
          restriction: options.restriction,
          styles: options.styles
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          setMapReady(true);
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        onClick={(e) => {
          if (add) {
            const { lat, lng } = e;
            addPointAux(lat, lng);
          }
        }}
        onChildMouseDown={(childKey, childProps, mouse) => {
          if (edit === childProps.index) {
            setDraggable(false);
            if (!isNaN(mouse.lat) && !isNaN(mouse.lng)) {
              if (addAux) setAddAux(new mapsRef.current.LatLng(mouse.lat, mouse.lng));
              else setCircleCenter(childProps.index, mouse.lat, mouse.lng);
            }
          }
        }}
        onChildMouseUp={(childKey, childProps, mouse) => {
          setDraggable(true);
        }}
        onChildMouseMove={(childKey, childProps, mouse) => {
          if (edit === childProps.index) {
            setDraggable(false);
            if (!isNaN(mouse.lat) && !isNaN(mouse.lng)) {
              if (addAux) setAddAux(new mapsRef.current.LatLng(mouse.lat, mouse.lng));
              else setCircleCenter(childProps.index, mouse.lat, mouse.lng);
            }
          }
        }}
        yesIWantToUseGoogleMapApiInternals>
          {
            (mapReady && (
              (getCircleCenter() && (
                <PointMarker
                  color={getCircleColor()}
                  edit={edit}
                  index={0}
                  key={`support-point-point-0`}
                  lat={getCircleCenter().lat()}
                  lng={getCircleCenter().lng()}
                  onClick={(e, i, n) => {
                    addPointReset();
                    switch(e) {
                      case 2:
                        editCircleCenter(i);
                      break;
                      case 3:
                        removeCircleCenter(i);
                        break;
                      case 4:
                        setOrderCircleCenter(i, n);
                        break;
                      default:
                        break;
                    }
                  }}
                  total={getCircleCenter() ? 1 : 0}
                  supportPoint={sP}
                />
              ))
            ))
          }
          {
            ((mapReady && addAux !== false) && (
              <PointMarker
                color={getCircleColor()}
                edit={edit}
                index={getCircleCenter() ? 0 : -1}
                key={`support-point-point-${getCircleCenter() ? 0 : -1}`}
                lat={addAux.lat()}
                lng={addAux.lng()}
                onClick={(e, i, n) => {
                  switch(e) {
                    case 2:
                    case 3:
                      addPointReset();
                    break;
                    case 4:
                      addCircleCenter(i, n);
                      break;
                    default:
                      break;
                  }
                }}
                total={getCircleCenter() ? 1 : 0}
                supportPoint={sP}
              />
            ))
          }
      </GoogleMapReact>
      <div className="support-point-map-menu">
        <ul>
          <li>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>{add ? t("Title.Cancel"): t("Title.AddPoint")}</Tooltip>}
              trigger={isMobile ? null : ["hover", "focus"]}
            >
              <button
                className={`sp-button ${add ? "active" : ""}`}
                type="button"
                onClick={addPoint}
                disabled={getCircleCenter() ? true : false}
              >
                <div className="sp-icon">
                  <i className="fas fa-map-marker-alt" />
                </div>
              </button>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SupportPointMap);
