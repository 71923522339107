import React, { memo, useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  InputGroup,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import Select from "react-select";

import { Creators as FrequencyActions } from "../../store/ducks/frequency";

const FilterFrequency = ({
  props: { currentUser },
  funcs: { setPeriodFrequency, setPeriodSpeed }
}) => {
  const { t } = useTranslation();
  const [frequency, setFrequency] = useState(-1);
  const [speed, setSpeed] = useState(false);

  const [frequencyOptions] = useState([
    { value: -1, label: "Todas as posições" },
    { value: 5, label: "a cada 5 min." },
    { value: 15, label: "a cada 15 min." },
    { value: 30, label: "a cada 30 min." },
    { value: 60, label: "a cada 1 hora" },
  ]);

  const [speedOptions] = useState([
    { value: true, label: "Incluir velocidade independente da frequência" },
    { value: false, label: "Não incluir velocidade fora da frequência" }
  ]);

  useEffect(() => {
    setPeriodFrequency(frequency);
  }, [frequency]); /* eslint-disable-line */

  useEffect(() => {
    setPeriodSpeed(speed);
  }, [speed]); /* eslint-disable-line */

  return (
    <Card>
      <Card.Header>{t("Title.Frequency")}</Card.Header>
      <Card.Body>
        <Row>
          <Col>
            <div className="filter-frequency-fields">
              <Form.Group>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text><i className="fas fa-clock" /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    className="menu-outer-bottom filter-frequency-form-control"
                    classNamePrefix="select"
                    onChange={e => setFrequency(e.value)}
                    options={frequencyOptions}
                    value={frequencyOptions.find(x => x.value === frequency)}
                  />
                  <div className="filter-frequency-form-control-shaddow" />
                </InputGroup>
              </Form.Group>
            </div>
          </Col>
          <Col>
            <div className="filter-frequency-fields">
              <Form.Group>
                <InputGroup className="mb-3">
                  <InputGroup.Prepend>
                    <InputGroup.Text><i className="fas fa-gauge" /></InputGroup.Text>
                  </InputGroup.Prepend>
                  <Select
                    className="menu-outer-bottom filter-frequency-form-control"
                    classNamePrefix="select"
                    onChange={e => setSpeed(e.value)}
                    options={speedOptions}
                    value={speedOptions.find(x => x.value === speed)}
                  />
                  <div className="filter-frequency-form-control-shaddow" />
                </InputGroup>
              </Form.Group>
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, FrequencyActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(FilterFrequency));
