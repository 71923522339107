import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { maskCNPJ } from "./../../utils/CNPJ";
import { maskCPF } from "./../../utils/CPF";
import { isValidForm } from "./../../utils/Form";
import { clearObject, mergeObject } from "./../../utils/Object";
import { isEmpty, ucFirstAll } from "./../../utils/String";

import ChipService from "./../../services/Chip";
import ChipSchema from "./../../services/Chip/Schema";
import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";

const Chip = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const chipService = new ChipService();
  const clientService = new ClientService();

  const {
    chip: __chip = {},
    close: __close = () => {}
  } = options;

  const [chip, setChip] = useState(mergeObject(JSON.parse(JSON.stringify(ChipSchema)), __chip));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:chips:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setChip(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [clientSelectedOption, setClientSelectedOption] = useState(chip.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), chip.client),
    label: chip.client.type === "LEG" ? (`${chip.client.legal.socialReason} | CNPJ: ${maskCNPJ(chip.client.legal.cnpj)}`) : (`${chip.client.personal.name} | CPF: ${maskCPF(chip.client.personal.cpf)}`),
    value: chip.client.id
  });

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : (`${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`),
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Chip"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await chipService.create({
            data: handleSubmitForm({ chip }),
            token: currentUser.token
          });
        }
        else {
          response = await chipService.update({
            id: chip.id,
            data: handleSubmitForm({ chip }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Chip"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Chip"),
            content: t(`Error.Chip.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ chip }) => {
    let newChip = Object.assign({}, JSON.parse(JSON.stringify(chip)));
    newChip = clearObject({ data: newChip });
    newChip.id = undefined;
    return newChip;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return chip.id === "";
  };

  return (
    <Form id="forms-chip" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-chip-active">
                <Form.Label>{t("Label.Active")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  options={activeOptions}
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const active = e.value;
                    setChip(prevState => ({
                      ...prevState,
                      active
                    }));
                  }}
                  value={activeOptions.find(x => x.value === chip.active)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-user-client">
                <Form.Label>{t("Title.Client")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(chip.client.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || !isScopes({
                    currentUser,
                    scopes: [
                      "is:master",
                      "read:chips:all"
                    ],
                    every: false
                  })}
                  loadOptions={clientLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setClientSelectedOption(e);
                      setChip(prevState => ({
                        ...prevState,
                        client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setClientSelectedOption({ dataAux, label, value });
                      setChip(prevState => ({
                        ...prevState,
                        client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Client.Select")}
                  value={clientSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(chip.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-chip-line">
                <Form.Label>{t("Label.Line")}:</Form.Label>
                <InputMask
                  id="forms-chip-line"
                  className={`form-control ${!isEmpty(chip.line) ? "is-valid" : "is-invalid"}`}
                  type="text"
                  placeholder={t("Placeholder.Line")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const line = e.target.value;
                    setChip(prevState => ({
                      ...prevState,
                      line: line.toUpperCase()
                    }));
                  }}
                  mask="99999999999999999999"
                  autoComplete="off"
                  value={chip.line}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Line")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-chip-serial">
                <Form.Label>{t("Label.Serial")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Serial")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const serial = e.target.value;
                    setChip(prevState => ({
                      ...prevState,
                      serial: serial.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={chip.serial}
                  isValid={!isEmpty(chip.serial)}
                  isInvalid={isEmpty(chip.serial)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Serial")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="forms-chip-personal-serial">
                <Form.Label>{t("Label.PersonalSerial")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.PersonalSerial")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const personalSerial = e.target.value;
                    setChip(prevState => ({
                      ...prevState,
                      personalSerial: personalSerial.toUpperCase()
                    }));
                  }}
                  autoComplete="off"
                  value={chip.personalSerial}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Chip));
