import React, { useState } from "react";
import {
  OverlayTrigger,
  Tabs,
  Tab,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "./../../store/ducks/menus";

import PatrimonyMapMenuPositions from "./../PatrimonyMapMenuPositions";
import PatrimonyMapMenuPositionsTransmissionQueue from "./../PatrimonyMapMenuPositionsTransmissionQueue";
import PatrimonyMapMenuPositionsPlayerAction from "./../PatrimonyMapMenuPositionsPlayerAction";

import { getCount } from "./../../utils/Generic";
import { sortPositions } from "./../../utils/Position";

const PatrimonyMapMenu = ({
  options,
  props: {
    currentUser,
    routes
  }
}) => {
  const { t } = useTranslation();

  const {
    routeId: __routeId = "",
  } = options;

  const [actionType, setActionType] = useState("positions");
  const [toggled, setToggled] = useState(false);
  const [routeId] = useState(__routeId);

  const getRoute = () => {
    const route = routes.find(x => x.id === routeId);
    if(route) {
      return route;
    }
    return false;
  };

  const getRoutePositions = () => {
    const simulatorRoute = getRoute();
    if(simulatorRoute) {
      const positions = JSON.parse(JSON.stringify(simulatorRoute.routePositions));
      return sortPositions(positions.filter(x => x.id !== "sp-cursor"));
    }
    return [];
  };

  const getRoutePositionsTransmissionQueue = () => {
    const simulatorRoute = getRoute();
    if(simulatorRoute) {
      const positions = JSON.parse(JSON.stringify(simulatorRoute.routePositionsTransmissionQueue));
      return positions;
    }
    return [];
  };

  const handleMenuClick = (e) => {
    e.preventDefault();
    setToggled(toggled ? false : true);
  };

  const handleActionType = (e) => {
    setActionType(e);
  };

  const arrMenus = [
    {
      count: getRoutePositions().length,
      id: "positions",
      icon: <i className="fas fa-map-marker-alt" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${actionType === id ? "" : "hide"}`} eventKey="positions" title={<span>{t("Title.Positions")}</span>} key={key}>
            <div className={"menu-positions-item-patrimony-type"}>
              <PatrimonyMapMenuPositionsPlayerAction options={{ routeId }} />
            </div>
            <div className={"menu-positions-item-box"}>
              <PatrimonyMapMenuPositions options={{ routeId }} />
            </div>
          </Tab>
        );
      },
      title: t("Title.Positions"),
      scope: true
    },
    {
      count: getRoutePositionsTransmissionQueue().length,
      id: "positionsTransmissionQueue",
      icon: <i className="fas fa-upload" />,
      render: (id, key) => {
        return (
          <Tab tabClassName={`${actionType === id ? "" : "hide"}`} eventKey="positionsTransmissionQueue" title={<span>{t("Title.Positions")}</span>} key={key}>
            <div className={"menu-positions-item-box full-size"}>
              <PatrimonyMapMenuPositionsTransmissionQueue options={{ routeId }} />
            </div>
          </Tab>
        );
      },
      title: t("Title.PositionsQueue"),
      scope: true
    }
  ];

  return (
    <>
      <aside className={`patrimony-map-aside-menu ${toggled ? "toggled" : ""}`}>
        <div className="patrimony-map-menu-itens">
          <ul>
            <li>
              <div className="menu-icon active" onClick={handleMenuClick}>
                {
                  toggled ? (
                    <i className="fas fa-times" />
                  ) : (
                    <i className="fas fa-bars" />
                  )
                }
              </div>
            </li>
            {
              toggled && arrMenus.map((m, i) => {
                if(m.scope) {
                  return (
                    <li key={`menu-icon-${i}`}>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>{m.title}</Tooltip>}
                        trigger={isMobile ? null : ["hover", "focus"]}
                      >
                        <div className={`menu-icon ${actionType === m.id ? "active" : ""}`} onClick={e => handleActionType(m.id)}>
                          {m.icon}
                          {getCount(m.count) !== false ? <div className="menu-icon-count">{getCount(m.count)}</div> : null}
                        </div>
                      </OverlayTrigger>
                    </li>
                  );
                }
                return null;
              })
            }
          </ul>
        </div>
        <div className="patrimony-map-menu-content">
          <Tabs defaultActiveKey="positions" activeKey={actionType} onSelect={e => handleActionType(e)}>
            {
              arrMenus.map((m, i) => {
                if(m.scope) {
                  return m.render(m.id, `menu-content-${i}`);
                }
                return null;
              })
            }
          </Tabs>
        </div>
      </aside>
      <div className="patrimony-map-aside-menu-backdrop" onClick={handleMenuClick}></div>
    </>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    routes: state.simulatorsMaps.routes
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(MenusActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonyMapMenu);
