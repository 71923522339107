import React, { memo } from "react";
import {
  Accordion,
  Card,
  Col,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "../../../../utils/Date";

const MaintenanceSchedule = ({
  props: { currentUser },
  options
}) => {
  const { t } = useTranslation();

  const status = (item) => {
    const items = [
      { value: "AGENDAR", label: t("Title.Status.Schedule") },
      { value: "AGENDADO", label: t("Title.Status.Scheduled") },
      { value: "CONCLUIDA", label: t("Title.Status.Completed") },
      { value: "EM OBSERVAÇÃO", label: t("Title.Status.UnderObservation") }
    ];
    const selectedItem = items.find(x => x.value === item);
    if (selectedItem) return selectedItem.label;
    return items[0].label;
  };

  return (
    <Card className="card-calendar">
      <Accordion.Toggle as={Card.Header} eventKey={options.eventKey} onClick={e => options.changeActiveKey(options.eventKey)}>
        <i className="fas fa-calendar-check" /> {t("Title.MaintenanceSchedule")}: {DateUtil.to({ date: options.event.dateStart, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })} até {DateUtil.to({ date: options.event.dateEnd, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })}
      </Accordion.Toggle>
      <Accordion.Collapse eventKey={options.eventKey}>
        <Card.Body>
          <Row>
            <Col>{t("Title.Patrimony")}: {options.event.patrimony.description}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Date.Start")}: {DateUtil.to({ date: options.event.dateStart, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Date.End")}: {DateUtil.to({ date: options.event.dateEnd, format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo" })}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Description")}: {options.event.description}</Col>
          </Row>
          <Row>
            <Col>{t("Title.Status")}: {status(options.event.status)}</Col>
          </Row>
        </Card.Body>
      </Accordion.Collapse>
    </Card>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MaintenanceSchedule));
