import React, { memo } from "react";
import {
  Button,
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";

import Grid from "./../../components/Grid";

import Cancel from "./../Cancel";

import CommandHistoryService from "./../../services/CommandHistory";

const MyCommands = ({
  props: { currentUser, usersSearchSelected, periodDateEnd, periodDateStart },
  funcs: { enableLoader, disableLoader }
}) => {
  const { t } = useTranslation();
  return (
    <Grid options={{
      api: new CommandHistoryService(),
      actions: [
        // {
        //   content: ({ resetActionsForced, selectedRows }) => <Cancel options={{ close: () => { resetActionsForced(); }, command: JSON.parse(JSON.stringify(selectedRows[0])) }} />,
        //   render: ({ index, selectedRows, showAction }) => {
        //     let checkEnabled = false;
        //     if(selectedRows.length === 1) {
        //       const command = JSON.parse(JSON.stringify(selectedRows[0]));
        //       checkEnabled = selectedRows.length === 1;
        //       if (command.active || command.canceled || command.error) checkEnabled = false;
        //     }
        //     return (
        //       <li className={`${checkEnabled ? "active" : ""}`} key={`grid-action-item-pdf-landscape-${(Math.random().toString(36).substring(2, 11))}`}>
        //         <OverlayTrigger overlay={<Tooltip>{t("Title.MyCommands.Cancel")}</Tooltip>}>
        //           <Button variant="light" type="button" disabled={!checkEnabled} onClick={e => showAction({ index })}>
        //             <i className="fa-solid fa-ban" />
        //           </Button>
        //         </OverlayTrigger>
        //       </li>
        //     );
        //   },
        //   show: false,
        //   title: () => t("Title.MyCommands.Cancel")
        // }
      ],
      title: t("Title.MyCommands")
    }} />
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MyCommands));
