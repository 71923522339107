import React, { memo, useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import { Creators as MenusActions } from "./../../store/ducks/menus";

import { isScopes } from "./../../services/Auth";
import MenuSimulatorsItem from "./../MenuSimulatorsItem";

import PatrimonySimulator from "./../../simulators/Patrimony";

const MenuSimulators = ({
  props: { currentUser },
  funcs: { menuHandleOpen, menuSimulatorsHandleClose }
}) => {
  const { t } = useTranslation();
  const [selectedSimulator, setSelectedSimulator] = useState({
    selected: false,
    options: false
  });

  useEffect(() => {
    if(selectedSimulator.selected) {
      if(selectedSimulator.options.scoped(currentUser) === false) {
        changeSelectedSimulator(false);
      }
    }
  }, [currentUser]); /* eslint-disable-line */

  const changeSelectedSimulator = (item) => {
    setSelectedSimulator({
      selected: false,
      options: false
    });
    if(item !== false) {
      setTimeout(() => {
        setSelectedSimulator(item);
      }, 100);
    }
  };

  const dynamicOptions = [
    {
      id: "mrg-option-simulator-patrimony",
      title: t("Title.Simulator.Patrimony"),
      icon: <i className="fas fa-car" />,
      onClick: () => {
        const id = "mrg-option-simulator-patrimony";
        if(selectedSimulator.selected === id) {
          return setSelectedSimulator(false);
        }
        changeSelectedSimulator({
          selected: id,
          options: {
            title: t("Title.Simulator.Patrimony"),
            render: () => <PatrimonySimulator />,
            scoped: (currentUser) => isScopes({ currentUser, scopes: ["is:master", "menu:simulators:patrimonies"], every: false })
          }
        });
      },
      selected: () => selectedSimulator.selected === "mrg-option-simulator-patrimony",
      scoped: () => isScopes({ currentUser, scopes: ["is:master", "menu:simulators:patrimony"], every: false })
    }
  ];

  const fixedOptions = [{
    id: "mrg-option-close",
    title: t("Title.Close"),
    icon: <i className="fas fa-times" />,
    onClick: () => {
      menuSimulatorsHandleClose();
      menuHandleOpen();
    },
    selected: () => true,
    scoped: () => true
  }];

  const renderBody = () => {
    if(selectedSimulator.selected) {
      return selectedSimulator.options.render();
    }
    return null;
  };

  const renderTitle = () => {
    if(selectedSimulator.selected) {
      return <h3>{selectedSimulator.options.title}</h3>;
    }
    return null;
  };

  return (
    <div className="menu-simulators">
      <div className={`mst-simulators-options-absolute ${selectedSimulator.selected ? "active" : ""}`}>
        <div className="mst-simulators-options-absolute-content">
          <div className="mrg-roa-content-title">{renderTitle()}</div>
          <div className="mrg-roa-content-body">{renderBody()}</div>
        </div>
      </div>
      <div className="mst-simulators-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuSimulatorsItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
      <div className="mst-simulators-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuSimulatorsItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, MenusActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(MenuSimulators));
