import ClientSchemaReduced from "../Client/SchemaReduced";
import PatrimonySchemaReduced from "../Patrimony/SchemaReduced";

const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
const dateEnd = new Date(`${todayDate} 23:55:00`);
const dateStart = new Date(`${todayDate} 00:00:00`);

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  dateEnd,
  dateStart,
  description: "",
  id: "",
  patrimony: JSON.parse(JSON.stringify(PatrimonySchemaReduced)),
  status: "EM OBSERVAÇÃO"
};

export default schema;
