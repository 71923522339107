import ClientSchemaReduced from "./../Client/SchemaReduced";
import UserSchemaReduced from "./../User/SchemaReduced";

const schema = {
  active: true,
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  cron: "0 * * * * *",
  id: "",
  name: "",
  patrimonies: [],
  repeat: true,
  type: "BACT",
  user: JSON.parse(JSON.stringify(UserSchemaReduced)),
};

export default schema;
