import React, { memo, useState } from "react";
import {
  Card,
  Col,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

const Slider = ({
  props: {
    currentUser
  },
  options
}) => {
  const { t } = useTranslation();

  const {
    pictures: __pictures = []
  } = options;

  const [pictures] = useState(__pictures);
  const [pictureIndex, setPictureIndex] = useState(-1);

  return (
    <div className="slider">
      <div className="slider-min-pictures">
        <Row>
          {
            pictures.map((img, index) => (
              <Col className="slider-picture" xs={12} md={3} key={`slider-picture-${index}`}>
                <Card onClick={() => setPictureIndex(index)}>
                  <Card.Img variant="top" src={img.url} />
                </Card>
              </Col>
            ))
          }
          {
            pictures.length === 0 && <Col className="slider-picture">
              <p>{t("Title.Photos.NoOptions")}</p>
            </Col>
          }
        </Row>
      </div>
      {
        pictureIndex !== -1 && pictures.length !== 0 ? (
          <div className="slider-max-pictures">
            {
              pictureIndex > 0 ? (
                <div className="slider-max-picture-prev" onClick={() => setPictureIndex(pictureIndex - 1)}>
                  <i class="fas fa-chevron-left" />
                </div>
              ) : null
            }
            {
              pictureIndex < (pictures.length - 1) ? (
                <div className="slider-max-picture-next" onClick={() => setPictureIndex(pictureIndex + 1)}>
                  <i class="fas fa-chevron-right" />
                </div>
              ) : null
            }
            <div className="slider-max-picture-close" onClick={() => setPictureIndex(-1)}>
              <img src={process.env.PUBLIC_URL + "/images/menu-icon-close.png"} alt="" />
            </div>
            <div className="slider-max-picture">
              <img src={pictures[pictureIndex].url} alt="" />
            </div>
            <div className="slider-max-list-pictures">
              {
                pictures.map((img, index) => (
                  <div className={`slider-max-list-pictures-picture ${pictureIndex === index ? "active" : ""}`} key={`slider-max-list-pictures-${index}`}>
                    <Card onClick={() => setPictureIndex(index)}>
                      <Card.Img variant="top" src={img.url} />
                    </Card>
                  </div>
                ))
              }
            </div>
            <div className="slider-max-pictures-bg" />
          </div>
        ) : null
      }
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Slider));
