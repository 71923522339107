import { ApiV2 } from "../Api";
import DateUtil from "./../../utils/Date";

import { getCommadName } from "../../utils/Command";

export default class CommandHistory {
  async create({ data, token }) {
    const response = await ApiV2.post("/commandHistory", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }

  async findAll({ data, token }) {
    const response = await ApiV2.post("/commandHistory/find/all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return response.data;
    }
    return {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    };
  }

  async findAllGrid({ data, token }) {
    const response = await ApiV2.post("/commandHistory/find/all", data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    if(response.success) {
      return this.findAllGridTranslate({ data: response.data });
    }
    return this.findAllGridTranslate({ data: {
      docs: [],
      limit: 1,
      page: 1,
      pages: false
    }});
  }

  findAllGridTranslate({ data }) {
    data.cols = [
      {
        width: "20%",
        type: "string",
        label: "Title.Client"
      },
      {
        width: "15%",
        type: "string",
        label: "Title.Date.Start"
      },
      {
        width: "15%",
        type: "string",
        label: "Title.Date.End"
      },
      {
        width: "10%",
        type: "string",
        label: "Title.Command"
      },
      {
        width: "15%",
        type: "string",
        label: "Title.Patrimony"
      },
      {
        width: "10%",
        type: "string",
        label: "Title.Status"
      },
      {
        width: "15%",
        type: "string",
        label: "Title.Reason"
      }
    ];
    data.rows = [];
    data.docs.map(item => {
      const active = this.findAllGridTranslateItem({ data: item, item: "active" }) !== undefined ? item.active : false;
      const canceled = this.findAllGridTranslateItem({ data: item, item: "canceled" }) !== undefined ? item.canceled : false;
      const error = this.findAllGridTranslateItem({ data: item, item: "error" }) !== undefined ? item.error : false;
      const started = this.findAllGridTranslateItem({ data: item, item: "started" }) !== undefined ? item.started : false;

      let status = "%{Title.Commands.Status.Waiting}%";
      if (active) status = "%{Title.Commands.Status.Available}%";
      else if (canceled) {
        if (error) status = "%{Title.Commands.Status.Error}%";
        else status = "%{Title.Commands.Status.Canceled}%";
      }
      else if (!canceled && error) status = "%{Title.Commands.Status.NotFound}%";
      else if (started) {
        if (item.progress === -1) status = "%{Title.Commands.Status.Running}%";
        else status = `%{Title.Commands.Status.Running}%`;
      }

      const commandHistoryItem = this.findAllGridTranslateItem({ data: item, item: "type" }) ? (getCommadName(item.type) ? getCommadName(item.type).label : item.type) : "";
      const itemAux = [
        {
          value: this.findAllGridTranslateItem({ data: item, item: "client" }) !== undefined ? (item.client.type === "LEG" ? item.client.legal.socialReason : item.client.personal.name) : ""
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "createdAt" }) !== undefined ? DateUtil.to({ date: new Date(item.createdAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : ""
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "updatedAt" }) !== undefined ? DateUtil.to({ date: new Date(item.updatedAt), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"}) : ""
        },
        {
          value: commandHistoryItem
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "patrimony.description" }) !== undefined ? item.patrimony.description : ""
        },
        {
          value: status
        },
        {
          value: this.findAllGridTranslateItem({ data: item, item: "reason" }) !== undefined ? item.reason : ""
        }
      ];
      return data.rows.push(itemAux);
    });
    return data;
  }

  findAllGridTranslateItem({ data, item }) {
    try {
      var itemAux = item.split(".");
      var dataAux = data;
      itemAux.map(it => {
        return dataAux = dataAux[it];
      });
      return dataAux;
    }
    catch (e) {
      return false;
    }
  }

  async update({ id, data, token }) {
    const response = await ApiV2.put(`/commandHistory/${id}`, data, {
      headers: {"Authorization": "bearer " + token}
    }).then(response => {
      return response.data;
    }).catch(error => {
      try {
        if(error.response.data === "Unauthorized") {
          return {
            success: false,
            error: {
              type: "UnauthorizedError",
              details: [{
                message: "UnauthorizedError"
              }]
            }
          };
        }
        return error.response.data;
      }
      catch(error) {
        return {
          success: false,
          error: {
            type: "NetworkError",
            details: [{
              message: "NetworkError"
            }]
          }
        };
      }
    });
    return response;
  }
}
