import React, { memo } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { isMobile } from "react-device-detect";
import "./index.css";

const LastPositionActionItem = ({ disabled, icon, id, onClick, selected, title }) => {
  return (
    <li>
      <OverlayTrigger
        placement="left"
        overlay={<Tooltip>{title}</Tooltip>}
        trigger={(disabled && disabled()) || isMobile ? null : ["hover", "focus"]}
      >
        <button
          className={`lpai-button ${selected && selected() ? "active" : ""} ${id}`}
          type="button"
          onClick={onClick}
          disabled={disabled && disabled()}
        >
          <div className="lpai-icon">{icon}</div>
          <div className="lpai-description">{title}</div>
        </button>
      </OverlayTrigger>
    </li>
  );
};

export default memo(LastPositionActionItem);
