import React, { memo, useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isScopes } from "./../../services/Auth";
import { maskCPF } from "./../../utils/CPF";
import { maskCNPJ } from "./../../utils/CNPJ";
import { isValidForm } from "./../../utils/Form";
import { isEmpty, ucFirstAll } from "./../../utils/String";
import { clearObject, mergeObject } from "./../../utils/Object";

import ChassiService from "./../../services/Chassi";
import ChassiSchemaReduced from "./../../services/Chassi/SchemaReduced";
import ChipService from "./../../services/Chip";
import ChipSchemaReduced from "./../../services/Chip/SchemaReduced";
import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";
import RfidService from "./../../services/Rfid";
import RfidSchemaReduced from "./../../services/Rfid/SchemaReduced";
import TrackerService from "./../../services/Tracker";
import TrackerSchema from "./../../services/Tracker/Schema";

const Tracker = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const chassiService = new ChassiService();
  const chipService = new ChipService();
  const clientService = new ClientService();
  const rfidService = new RfidService();
  const trackerService = new TrackerService();

  const {
    tracker: __tracker = {},
    close: __close = () => {}
  } = options;

  const [tracker, setTracker] = useState(mergeObject(JSON.parse(JSON.stringify(TrackerSchema)), __tracker));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:trackers:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setTracker(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [chassiSelectedOption, setChassiSelectedOption] = useState(tracker.niple.chassi.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ChassiSchemaReduced)), tracker.niple.chassi),
    label: `${tracker.niple.chassi.serial}`,
    value: tracker.niple.chassi.id
  });

  const [chipSelectedOption, setChipSelectedOption] = useState(tracker.chip.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ChipSchemaReduced)), tracker.chip),
    label: `${tracker.chip.line} - ${tracker.chip.serial}`,
    value: tracker.chip.id
  });

  const [clientSelectedOption, setClientSelectedOption] = useState(tracker.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), tracker.client),
    label: tracker.client.type === "LEG" ? (`${tracker.client.legal.socialReason} | CNPJ: ${maskCNPJ(tracker.client.legal.cnpj)}`) : (`${tracker.client.personal.name} | CPF: ${maskCPF(tracker.client.personal.cpf)}`),
    value: tracker.client.id
  });

  const [rfidSelectedOption, setRfidSelectedOption] = useState(tracker.rfid.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(RfidSchemaReduced)), tracker.rfid),
    label: `Nº ${tracker.rfid.number} | ${tracker.rfid.serial}`,
    value: tracker.rfid.id
  });

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const chassiLoadOptions = (e, c) => {
    chassiLoadOptionsDebounce(e, c);
  };

  const chassiLoadOptionsDebounce = debounce(async (e, c) => {
    const chassis = await chassiService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterChassi(chassis));
  }, 1000);

  const filterChassi = (chassis) => {
    return chassis.map(e => {
      return {
        dataAux: e,
        label: `${e.serial}`,
        value: e.id
      };
    });
  };

  const chipLoadOptions = (e, c) => {
    chipLoadOptionsDebounce(e, c);
  };

  const chipLoadOptionsDebounce = debounce(async (e, c) => {
    const chips = await chipService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterChip(chips));
  }, 1000);

  const filterChip = (chips) => {
    return chips.map(e => {
      return {
        dataAux: e,
        label: `${e.line} - ${e.serial}`,
        value: e.id
      };
    });
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? `${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}` : (`${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`),
        value: e.id
      };
    });
  };

  const rfidLoadOptions = (e, c) => {
    rfidLoadOptionsDebounce(e, c);
  };

  const rfidLoadOptionsDebounce = debounce(async (e, c) => {
    const rfids = await rfidService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterRfid(rfids));
  }, 1000);

  const filterRfid = (rfids) => {
    return rfids.map(e => {
      return {
        dataAux: e,
        label: `Nº ${e.number} | ${e.serial}`,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { valid, message } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        addNotice({
          title: t("Title.Tracker"),
          content: message
        });
      }
      else {
        let response = false;
        if(isCreate()) {
          response = await trackerService.create({
            data: handleSubmitForm({ tracker }),
            token: currentUser.token
          });
        }
        else {
          response = await trackerService.update({
            id: tracker.id,
            data: handleSubmitForm({ tracker }),
            token: currentUser.token
          });
        }

        if(response.success) {
          addNotice({
            title: t("Title.Tracker"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.Tracker"),
            content: t(`Error.Tracker.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ tracker }) => {
    let newTracker = Object.assign({}, JSON.parse(JSON.stringify(tracker)));
    newTracker = clearObject({ data: newTracker });
    newTracker.id = undefined;
    if(!isHatch()) {
      newTracker.hatch = undefined;
    }
    if(!isNiple()) {
      newTracker.niple = undefined;
    }
    return newTracker;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const isCreate = () => {
    return tracker.id === "";
  };

  const isHatch = () => {
    if(tracker.type === "HAT") {
      return true;
    }
    return false;
  };

  const isNiple = () => {
    if(tracker.type === "NIP") {
      return true;
    }
    return false;
  };

  const [typesOptions] = useState([
    { value: "HAT", label: t("Title.Hatch") },
    { value: "NIP", label: t("Title.Niple") },
    { value: "TRA", label: t("Title.Tracker") },
    { value: "TRA_NIP", label: `${t("Title.Tracker")}/${t("Title.Niple")}` }
  ]);

  const [hatchTypesOptions] = useState([
    { value: "DEF", label: t("Title.Hatch.Type.Default") },
    { value: "BCO", label: t("Title.Hatch.Type.BackCover") }
  ]);

  const [nipleGroundingSensorOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [nipleGyroscopeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [nipleMainOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [nipleOperationOptions] = useState([
    { value: "CHA", label: t("Title.Niple.Charge") },
    { value: "DIS", label: t("Title.Niple.Discharge") },
    { value: "HYB", label: t("Title.Niple.Hybrid") }
  ]);

  const [nipleOperationInvertedOptions] = useState([
    { value: false, label: t("Inactive") },
    { value: true, label: t("Active") }
  ]);

  const [positionMainOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  return (
    <Form id="forms-tracker" noValidate onSubmit={handleSubmit}>
      <Card>
        <Card.Header>{t("Title.GeneralData")}</Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-active">
                <Form.Label>{t("Label.Active")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const active = e.value;
                    setTracker(prevState => ({
                      ...prevState,
                      active
                    }));
                  }}
                  options={activeOptions}
                  value={activeOptions.find(x => x.value === tracker.active)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-user-client">
                <Form.Label>{t("Title.Client")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${!isEmpty(tracker.client.id) ? "is-valid" : "is-invalid"}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isDisabled={inputDisabled() || !isScopes({
                    currentUser,
                    scopes: [
                      "is:master",
                      "read:trackers:all"
                    ],
                    every: false
                  })}
                  loadOptions={clientLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setClientSelectedOption(e);
                      setTracker(prevState => ({
                        ...prevState,
                        client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setClientSelectedOption({ dataAux, label, value });
                      setTracker(prevState => ({
                        ...prevState,
                        client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Client.Select")}
                  value={clientSelectedOption}
                  required={true}
                />
                <Form.Control.Feedback className={`${!isEmpty(tracker.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-chip">
                <Form.Label>{t("Title.Chip")}:</Form.Label>
                <AsyncSelect
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  isDisabled={inputDisabled()}
                  loadOptions={chipLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setChipSelectedOption(e);
                      setTracker(prevState => ({
                        ...prevState,
                        chip: JSON.parse(JSON.stringify(ChipSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setChipSelectedOption({ dataAux, label, value });
                      setTracker(prevState => ({
                        ...prevState,
                        chip: mergeObject(JSON.parse(JSON.stringify(ChipSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Chip.Select")}
                  value={chipSelectedOption}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-rfid">
                <Form.Label>{t("Title.Rfid")}:</Form.Label>
                <AsyncSelect
                  className={`menu-outer-bottom ${tracker.active && isNiple() ? (!isEmpty(tracker.rfid.id) ? "is-valid" : "is-invalid") : ""}`}
                  classNamePrefix="select"
                  cacheOptions
                  defaultOptions
                  isClearable
                  isDisabled={inputDisabled()}
                  loadOptions={rfidLoadOptions}
                  loadingMessage={() => t("React.Select.Wait")}
                  noOptionsMessage={() => t("React.Select.NoOptions")}
                  onChange={e => {
                    if(e === null) {
                      setRfidSelectedOption(e);
                      setTracker(prevState => ({
                        ...prevState,
                        rfid: JSON.parse(JSON.stringify(RfidSchemaReduced))
                      }));
                    }
                    else {
                      const { dataAux, label, value } = e;
                      setRfidSelectedOption({ dataAux, label, value });
                      setTracker(prevState => ({
                        ...prevState,
                        rfid: mergeObject(JSON.parse(JSON.stringify(RfidSchemaReduced)), dataAux)
                      }));
                    }
                  }}
                  placeholder={t("Title.Rfid.Select")}
                  value={rfidSelectedOption}
                  required={tracker.active && isNiple() ? true : false}
                />
              </Form.Group>
              <Form.Control.Feedback className={`${tracker.active && isNiple() ? (!isEmpty(tracker.rfid.id) ? "hide" : "show") : "hide"}`} type="invalid">{t("Feedback.Rfid")}</Form.Control.Feedback>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-serial">
                <Form.Label>{t("Label.Serial")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Serial")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const serial = e.target.value;
                    setTracker(prevState => ({
                      ...prevState,
                      serial
                    }));
                  }}
                  autoComplete="off"
                  value={tracker.serial}
                  isValid={!isEmpty(tracker.serial)}
                  isInvalid={isEmpty(tracker.serial)}
                  required={true}
                />
                <Form.Control.Feedback type="invalid">{t("Feedback.Serial")}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-name">
                <Form.Label>{t("Label.Name")}:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Placeholder.Name")}
                  disabled={inputDisabled()}
                  onChange={e => {
                    const name = e.target.value;
                    setTracker(prevState => ({
                      ...prevState,
                      name: ucFirstAll(name)
                    }));
                  }}
                  autoComplete="off"
                  value={tracker.name}
                  required={false}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-type">
                <Form.Label>{t("Label.Type")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={inputDisabled()}
                  onChange={e => {
                    const type = e.value;
                    setTracker(prevState => ({
                      ...prevState,
                      type
                    }));
                  }}
                  options={typesOptions}
                  value={typesOptions.find(x => x.value === tracker.type)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group controlId="forms-tracker-position-main">
                <Form.Label>{t("Label.Tracker.Main")}:</Form.Label>
                <Select
                  className="menu-outer-bottom"
                  classNamePrefix="select"
                  isDisabled={true}
                  options={positionMainOptions}
                  value={positionMainOptions.find(x => x.value === tracker.positionMain)}
                />
              </Form.Group>
            </Col>
          </Row>
          <div className={`${isHatch() ? "" : "hide"}`}>
            <Row>
              <Col>
                <Form.Group controlId="forms-tracker-hatch-type">
                  <Form.Label>{t("Title.Hatch.Type")}:</Form.Label>
                  <Select
                    className="menu-outer-bottom"
                    classNamePrefix="select"
                    isDisabled={inputDisabled()}
                    onChange={e => {
                      const type = e.value;
                      setTracker(prevState => ({
                        ...prevState,
                        hatch: {
                          ...prevState.hatch,
                          type
                        }
                      }));
                    }}
                    options={hatchTypesOptions}
                    value={hatchTypesOptions.find(x => x.value === tracker.hatch.type)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
          <div className={`${isNiple() ? "" : "hide"}`}>
            <Row>
              <Col>
                <Form.Group controlId="forms-tracker-niple.chassi">
                  <Form.Label>{t("Title.Chassi")}:</Form.Label>
                  <AsyncSelect
                    className={"menu-outer-bottom"}
                    classNamePrefix="select"
                    cacheOptions
                    defaultOptions
                    isClearable
                    isDisabled={inputDisabled()}
                    loadOptions={chassiLoadOptions}
                    loadingMessage={() => t("React.Select.Wait")}
                    noOptionsMessage={() => t("React.Select.NoOptions")}
                    onChange={e => {
                      if(e === null) {
                        setChassiSelectedOption(e);
                        setTracker(prevState => ({
                          ...prevState,
                          niple: {
                            ...prevState.niple,
                            chassi: JSON.parse(JSON.stringify(ChassiSchemaReduced))
                          }
                        }));
                      }
                      else {
                        const { dataAux, label, value } = e;
                        setChassiSelectedOption({ dataAux, label, value });
                        setTracker(prevState => ({
                          ...prevState,
                          niple: {
                            ...prevState.niple,
                            chassi: mergeObject(JSON.parse(JSON.stringify(ChassiSchemaReduced)), dataAux)
                          }
                        }));
                      }
                    }}
                    placeholder={t("Title.Chassi.Select")}
                    value={chassiSelectedOption}
                    required={false}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="forms-tracker-niple-main">
                  <Form.Label>{t("Label.Niple.Main")}:</Form.Label>
                  <Select
                    className="menu-outer-bottom"
                    classNamePrefix="select"
                    isDisabled={inputDisabled()}
                    onChange={e => {
                      const main = e.value;
                      setTracker(prevState => ({
                        ...prevState,
                        niple: {
                          ...prevState.niple,
                          main
                        }
                      }));
                    }}
                    options={nipleMainOptions}
                    value={nipleMainOptions.find(x => x.value === tracker.niple.main)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="forms-tracker-niple-operation">
                  <Form.Label>{t("Label.Niple.Operations")}:</Form.Label>
                  <Select
                    className="menu-outer-bottom"
                    classNamePrefix="select"
                    isDisabled={inputDisabled()}
                    onChange={e => {
                      const operation = e.value;
                      setTracker(prevState => ({
                        ...prevState,
                        niple: {
                          ...prevState.niple,
                          operation
                        }
                      }));
                    }}
                    options={nipleOperationOptions}
                    value={nipleOperationOptions.find(x => x.value === tracker.niple.operation)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group controlId="forms-tracker-niple-operation-inverted">
                  <Form.Label>{t("Label.Niple.OperationInverted")}:</Form.Label>
                  <Select
                    className="menu-outer-bottom"
                    classNamePrefix="select"
                    isDisabled={inputDisabled()}
                    onChange={e => {
                      const operationInverted = e.value;
                      setTracker(prevState => ({
                        ...prevState,
                        niple: {
                          ...prevState.niple,
                          operationInverted
                        }
                      }));
                    }}
                    options={nipleOperationInvertedOptions}
                    value={nipleOperationInvertedOptions.find(x => x.value === tracker.niple.operationInverted)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <Form.Group controlId="forms-tracker-niple-gyroscope">
                  <Form.Label>{t("Label.Niple.Gyroscope")}:</Form.Label>
                  <Select
                    className="menu-outer-top"
                    classNamePrefix="select"
                    isDisabled={inputDisabled()}
                    onChange={e => {
                      const gyroscope = e.value;
                      setTracker(prevState => ({
                        ...prevState,
                        niple: {
                          ...prevState.niple,
                          gyroscope
                        }
                      }));
                    }}
                    options={nipleGyroscopeOptions}
                    value={nipleGyroscopeOptions.find(x => x.value === tracker.niple.gyroscope)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} md={6}>
                <Form.Group controlId="forms-tracker-niple-grounding-sensor">
                  <Form.Label>{t("Label.Niple.GroundingSensor")}:</Form.Label>
                  <Select
                    className="menu-outer-top"
                    classNamePrefix="select"
                    isDisabled={inputDisabled()}
                    onChange={e => {
                      const groundingSensor = e.value;
                      setTracker(prevState => ({
                        ...prevState,
                        niple: {
                          ...prevState.niple,
                          groundingSensor
                        }
                      }));
                    }}
                    options={nipleGroundingSensorOptions}
                    value={nipleGroundingSensorOptions.find(x => x.value === tracker.niple.groundingSensor)}
                  />
                </Form.Group>
              </Col>
            </Row>
          </div>
        </Card.Body>
      </Card>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Tracker));
