import React, { useState } from "react";
import {
  Form,
  InputGroup
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

import { Creators as LoadersActions } from "./../../store/ducks/loaders";
import { Creators as MenusActions } from "./../../store/ducks/menus";
import { Creators as TranshipmentsActions } from "./../../store/ducks/transhipments";
import { Creators as UsersActions } from "./../../store/ducks/users";
import MenuSingleTranshipmentItem from "./../MenuSingleTranshipmentItem";
import PositionService from "./../../services/Position";

registerLocale("pt-BR", ptBR);

const MenuSingleTranshipment = ({
  props: { currentUser, databaseTest, lastPositionSelected, singleTranshipment, singleTranshipmentMenu, singleTranshipmentMenuSelected, singleTranshipmentMenuPositionsSelected },
  funcs: { addSingleTranshipment, closeMenuPositions, disableLoader, enableLoader, menuHandleClose, menuHandleOpen, openMenuPositions, removeSingleTranshipment, selectMenuSingleTranshipment }
}) => {
  const { t } = useTranslation();
  const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
  const [datePickerOpen, setDatePickerOpen] = useState(true);
  const [startDate, setStartDate] = useState(new Date(`${todayDate} 00:00:00`));
  const [endDate, setEndDate] = useState(new Date(`${todayDate} 23:55:00`));

  const handleSearchTranshipment = async () => {
    enableLoader();
    const patrimony = lastPositionSelected.position.patrimony.id;
    const response = databaseTest ? await PositionService.findTranshipmentTest({
      endDate,
      patrimony,
      startDate,
      token: currentUser.token
    }) : await PositionService.findTranshipment({
      endDate,
      patrimony,
      startDate,
      token: currentUser.token
    });
    addSingleTranshipment(endDate, lastPositionSelected, response, startDate);
    menuHandleClose();
    disableLoader();
  };

  const dynamicOptions = [{
    id: "msr-option-transhipment",
    title: t("Title.Transhipment"),
    icon: <i className="fas fa-exchange-alt" />,
    getCount: () => singleTranshipmentMenu.transhipment.length,
    onClick: () => selectMenuSingleTranshipment("transhipment"),
    selected: () => singleTranshipmentMenuSelected && singleTranshipmentMenuSelected.id === "transhipment",
    scoped: () => true
  }];

  const fixedOptions = [{
    id: "msr-option-close",
    title: t("Title.Close"),
    icon: <i className="fas fa-times" />,
    onClick: () => {
      removeSingleTranshipment();
      menuHandleOpen();
    },
    selected: () => true
  }, {
    id: "msr-option-date",
    title: t("Title.Calendar"),
    icon: <i className="far fa-calendar-alt" />,
    onClick: () => setDatePickerOpen(!datePickerOpen),
    selected: () => datePickerOpen
  }, {
    id: "msr-option-positions",
    title: t("Title.Positions"),
    icon: <i className="fas fa-clipboard-list" />,
    onClick: () => {
      if(singleTranshipmentMenuPositionsSelected) {
        return closeMenuPositions();
      }
      return openMenuPositions();
    },
    selected: () => singleTranshipmentMenuPositionsSelected
  }];

  return (
    <div className="menu-single-transhipment">
      <div className={`msr-transhipment-options-absolute ${datePickerOpen ? "active" : ""}`}>
        <div className="mst-rpa-fields">
          <form onSubmit={e => e.preventDefault()}>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={startDate}
                  onChange={date => setStartDate(date)}
                  selectsStart
                  startDate={startDate}
                  endDate={endDate}
                  minDate={new Date(`${process.env.REACT_APP_APP_START_YEAR}-01-02`)}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                />
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text><i className="fas fa-calendar-minus" /></InputGroup.Text>
                </InputGroup.Prepend>
                <DatePicker
                  selected={endDate}
                  onChange={date => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  maxDate={new Date()}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  showTimeSelect
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy HH:mm:ss"
                  className="react-datepicker-form-control"
                  popperPlacement="top-center"
                  popperModifiers={{
                    offset: {
                      enabled: true,
                      offset: "5px, 10px"
                    },
                    preventOverflow: {
                      enabled: true,
                      escapeWithReference: false,
                      boundariesElement: "viewport"
                    }
                  }}
                  locale="pt-BR"
                />
                <div className="react-datepicker-form-control-shaddow" />
              </InputGroup>
            </Form.Group>
          </form>
        </div>
        <div className="mst-rpa-close">
          <button type="button" onClick={e => setDatePickerOpen(!datePickerOpen)}><i className="far fa-times-circle" /></button>
        </div>
        <div className="mst-rpa-search">
          <button type="button" onClick={handleSearchTranshipment}><i className="fas fa-search" /></button>
        </div>
      </div>
      <div className="msr-transhipment-options-fixed">
        <ul>
          {
            fixedOptions.map(option => {
              return (
                <MenuSingleTranshipmentItem key={option.id} {...option} onClick={option.onClick} />
              );
            })
          }
        </ul>
      </div>
      <div className="msr-transhipment-options-dynamic">
        <ul>
          {
            dynamicOptions.map(option => {
              if(option.scoped()) {
                return (
                  <MenuSingleTranshipmentItem key={option.id} {...option} onClick={option.onClick} />
                );
              }
              return null;
            })
          }
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    databaseTest: state.databaseTest.databaseTest,
    lastPositionSelected: state.lastPositions.positionSelected,
    singleTranshipment: state.transhipments.singleTranshipment,
    singleTranshipmentMenu: state.transhipments.singleTranshipmentMenu,
    singleTranshipmentMenuSelected: state.transhipments.singleTranshipmentMenuSelected,
    singleTranshipmentMenuPositionsSelected: state.transhipments.singleTranshipmentMenuPositionsSelected
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, LoadersActions, MenusActions, TranshipmentsActions, UsersActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuSingleTranshipment);
