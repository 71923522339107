import ClientSchemaReduced from "./../Client/SchemaReduced";
import DriverSchemaReduced from "./../Driver/SchemaReduced";
import PatrimonySchemaReduced from "./../Patrimony/SchemaReduced";

const todayDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()+1}-${(new Date()).getDate()}`;
const date = new Date(`${todayDate} 00:00:00`);

const schema = {
  active: true,
  address: "",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  code: "",
  date,
  description: "",
  driver: JSON.parse(JSON.stringify(DriverSchemaReduced)),
  gravity: "L",
  id: "",
  patrimony: JSON.parse(JSON.stringify(PatrimonySchemaReduced)),
  price: 0,
  speed: 0
};

export default schema;
