import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import { registerServiceWorker } from "./serviceWorker";

window.onload = () => {
  //setTimeout(() => {
    document.body.setAttribute("data-load", "true");
  //}, 5000);
};

window.onunload = () => {
  //document.body.setAttribute("data-load", "false");
};

ReactDOM.render(<App />, document.getElementById("root"));

registerServiceWorker();
