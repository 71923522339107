import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import { Creators as NotificationsActions } from "../../store/ducks/notifications";

import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import { jsonToForm, formToJson } from "../../utils/ExpenseType";
import { isEmpty, ucFirstAll } from "../../utils/String";
import { isValidForm } from "../../utils/Form";
import { clearObject, mergeObject } from "../../utils/Object";

import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";
import ExpenseTypeService from "../../services/ExpenseType";
import ExpenseTypeSchema from "../../services/ExpenseType/Schema";

const ACTIVE_KEY_GENERAL_DATA = "0";

const ExpenseType = ({
  options,
  props: { currentUser },
  funcs: { addNotice }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const expenseTypeService = new ExpenseTypeService();

  const {
    expenseType: __expenseType = {},
    close: __close = () => {}
  } = options;

  const [expenseType, setExpenseType] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(ExpenseTypeSchema)), __expenseType)));

  useEffect(() => {
    setTimeout(() => {
      if(!isScopes({ currentUser, scopes: [
        "is:master",
        "read:expenseTypes:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setExpenseType(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeKey, setActiveKey] = useState(ACTIVE_KEY_GENERAL_DATA);

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [clientSelectedOption, setClientSelectedOption] = useState(expenseType.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), expenseType.client),
    label: expenseType.client.type === "LEG" ? (`${expenseType.client.legal.socialReason} | CNPJ: ${maskCNPJ(expenseType.client.legal.cnpj)}`) : (`${expenseType.client.personal.name} | CPF: ${maskCPF(expenseType.client.personal.cpf)}`),
    value: expenseType.client.id
  });

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if (!running) return false;
    return true;
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const changeActiveKey = (e) => {
    if (activeKey === e) return setActiveKey("-1");
    setActiveKey(e);
  };

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : `${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if (!valid) {
        if (!message) {
          message = t("Error.Fields.Empty");
        }
        if (eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.ExpenseType"),
          content: message
        });
      }
      else {
        let response = false;
        if (isCreate()) {
          response = await expenseTypeService.create({
            data: handleSubmitForm({ expenseType }),
            token: currentUser.token
          });
        }
        else {
          response = await expenseTypeService.update({
            id: expenseType.id,
            data: handleSubmitForm({ expenseType }),
            token: currentUser.token
          });
        }

        if (response.success) {
          addNotice({
            title: t("Title.ExpenseType"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          addNotice({
            title: t("Title.ExpenseType"),
            content: t(`Error.ExpenseType.${response.error.type}.${ucFirstAll(response.error.details.path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ expenseType }) => {
    let newExpenseType = Object.assign({}, JSON.parse(JSON.stringify(expenseType)));
    newExpenseType = clearObject({ data: newExpenseType });
    newExpenseType.id = undefined;
    return formToJson(newExpenseType);
  };

  const inputDisabled = () => {
    if (!running) return false;
    return true;
  };

  const isCreate = () => {
    return expenseType.id === "";
  };

  return (
    <Form id="forms-expense-type" noValidate onSubmit={handleSubmit}>
      <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-expense-type-active">
                    <Form.Label>{t("Label.Active")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const active = e.value;
                        setExpenseType(prevState => ({
                          ...prevState,
                          active
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === expenseType.active)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-expense-type-client">
                    <Form.Label>{t("Title.Client")}:</Form.Label>
                    <AsyncSelect
                      className={`menu-outer-bottom ${!isEmpty(expenseType.client.id) ? "is-valid" : "is-invalid"}`}
                      classNamePrefix="select"
                      menuPlacement="auto"
                      menuPosition="fixed"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled() || !isScopes({
                        currentUser,
                        scopes: [
                          "is:master",
                          "read:expenseTypes:all"
                        ],
                        every: false
                      })}
                      loadOptions={clientLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if(e === null) {
                          setClientSelectedOption(e);
                          setExpenseType(prevState => ({
                            ...prevState,
                            client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setClientSelectedOption({ dataAux, label, value });
                          setExpenseType(prevState => ({
                            ...prevState,
                            client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      placeholder={t("Title.Client.Select")}
                      value={clientSelectedOption}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      required={true}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(expenseType.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group controlId="forms-expense-type-name">
                    <Form.Label>{t("Label.Name")}:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("Label.Name")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const name = e.target.value;
                        setExpenseType(prevState => ({
                          ...prevState,
                          name
                        }));
                      }}
                      autoComplete="off"
                      value={expenseType.name}
                      isValid={!isEmpty(expenseType.name)}
                      isInvalid={isEmpty(expenseType.name)}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      required={true}
                    />
                    <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(ExpenseType));
