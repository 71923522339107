import React, { Component, Suspense } from "react";
import { Provider } from "react-redux";
import "./index.css";

import "./../../i18n";
import store from "./../../store";

import MainHome from "./../../components/Main/Home";

export default class Home extends Component {
  render() {
    return (
      <Suspense fallback={"pt-BR"}>
        <Provider store={store}>
          <MainHome />
        </Provider>
      </Suspense>
    );
  }
}
