import React, { useEffect, useRef, useState } from "react";
import {
  OverlayTrigger,
  Tooltip
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import "./index.css";

import GoogleMapReact from "google-map-react";

import PolygonMarker from "./../../../components/Marker/Polygon";

const VirtualFenceMap = ({
  locationOptions
}) => {
  const { t } = useTranslation();

  const {
    polygon: __polygon = {
      type: "Polygon",
      coordinates: [[]]
    },
    setVirtualFence: __setVirtualFence = () => {},
    virtualFence: __virtualFence = false
  } = locationOptions;

  const __default_center__ = { lat: -14.2800653, lng: -46.9647527 };
  const __default_zoom__ = 4.78;
  const __default_min_zoom__ = 3;
  const __default_max_zoom__ = 19;

  const mapRef = useRef();
  const mapsRef = useRef();

  const [bounds, setBounds] = useState(null); /* eslint-disable-line */
  const [mapReady, setMapReady] = useState(false);

  const [vF, setVF] = useState(__virtualFence);
  const [vFPath, setVFPath] = useState(__polygon);
  const [vFPolygon, setVFPolygon] = useState(false);

  const [add, setAdd] = useState(false);
  const [addAux, setAddAux] = useState(false);
  const [edit, setEdit] = useState(-1);
  const [draggable, setDraggable] = useState(true);

  const [options] = useState({
    mapTypeId: "DEF",
    minZoom: __default_min_zoom__,
    maxZoom: __default_max_zoom__,
    restriction: null,
    styles: [{
      stylers: [{
        saturation: -10
      }]
    }]
  });
  const [zoom, setZoom] = useState(12); /* eslint-disable-line */

  useEffect(() => {
    if (mapReady) {
      const color = getPolygonColor();
      setVFPolygon(new mapsRef.current.Polygon({
        paths: getPolygonPath(),
        strokeColor: color,
        strokeOpacity: 0.5,
        strokeWeight: 1,
        fillColor: color,
        fillOpacity: 0.35,
        map: mapRef.current
      }));
      reloadBounds();
    }
  }, [mapReady]); /* eslint-disable-line */

  useEffect(() => {
    if (mapReady) {
      vFPolygon.setPath(getPolygonPath());
      const vf = JSON.parse(JSON.stringify(__virtualFence));
      vf.location = JSON.parse(JSON.stringify(vFPath));
      __setVirtualFence(vf);
      reloadBounds();
    }
  }, [vFPath]); /* eslint-disable-line */

  useEffect(() => {
    setVF(__virtualFence);
  }, [__virtualFence]);

  useEffect(() => {
    if (mapReady) {
      const color = getPolygonColor();
      vFPolygon.setOptions({ strokeColor: color, fillColor: color });
    }
  }, [vF]); /* eslint-disable-line */

  const addPoint = () => {
    if (mapReady) {
      setEdit(-1);
      setAdd(!add);
      setAddAux(false);
    }
  };

  const addPointAux = (lat, lng) => {
    if (mapReady) {
      if (!isNaN(lat) && !isNaN(lng)) {
        setAddAux(new mapsRef.current.LatLng(lat, lng));
        setEdit(((getPolygonPath().length === 0 ? 1 : getPolygonPath().length) - 1));
        setAdd(false);
      }
    }
  };

  const addPointReset = () => {
    if (mapReady) {
      setEdit(-1);
      setAdd(false);
      setAddAux(false);
    }
  };

  const addPolygonPath = (oldIndex, newIndex) => {
    if (mapReady) {
      const path = JSON.parse(JSON.stringify(vFPath));
      if (oldIndex === 0 && newIndex === 0) {
        path.coordinates[0].push([addAux.lng(), addAux.lat()]);
        path.coordinates[0].push([addAux.lng(), addAux.lat()]);
      }
      else {
        path.coordinates[0].splice(oldIndex, 0, [addAux.lng(), addAux.lat()]);
      }
      if (oldIndex !== newIndex) {
        setOrderPolygonPath(oldIndex, newIndex, path);
      }
      else {
        setVFPath(path);
      }
      addPointReset();
    }
  };

  const editPolygonPath = (index) => {
    if (mapReady) {
      if (edit === index) {
        setEdit(-1);
      }
      else {
        setEdit(index);
      }
    }
  };

  const getMapTypeId = ({ type }) => {
    switch(type) {
    case "SAT":
      return "hybrid";
    default:
      return "roadmap";
    }
  };

  const getBounds = () => {
    if (mapReady) {
      const pointsBounds = new mapsRef.current.LatLngBounds();
      if (getPolygonPath().length > 0) {
        getPolygonPath().map(rs => pointsBounds.extend(rs));
        return pointsBounds;
      }
      else {
        mapRef.current.setCenter(__default_center__);
        mapRef.current.setZoom(__default_zoom__);
      }
    }
    return false;
  };

  const getPolygonColor = () => {
    let color = "#820404";
    if (mapReady) {
      switch(vF.type) {
        case "DEF":
          color = "#820404";
          break;
        case "RES":
          color = "#008c23";
          break;
        case "RIS":
          color = "#000000";
          break;
        case "WAI":
          color = "#0453A2";
          break;
        default:
          color = "#820404";
          break;
      }
    }
    return color;
  };

  const getPolygonPath = () => {
    if (mapReady) {
      const coords = vFPath.coordinates[0];
      return coords.map(latLng => new mapsRef.current.LatLng(latLng[1], latLng[0]));
    }
    return [];
  };

  const removePolygonPath = (index) => {
    if (mapReady) {
      const path = JSON.parse(JSON.stringify(vFPath));
      path.coordinates[0].splice(index, 1);
      if (index === 0 && path.coordinates[0].length > 1) {
        path.coordinates[0][path.coordinates[0].length-1] = path.coordinates[0][0];
      }
      else if(index === 0 && path.coordinates[0].length === 1) {
        path.coordinates[0] = [];
      }
      setVFPath(path);
      setEdit(-1);
    }
  };

  const reloadBounds = () => {
    const bounds = getBounds();
    if (bounds) {
      mapRef.current.fitBounds(bounds);
    }
  };

  const setPolygonPath = (index, lat, lng) => {
    if (mapReady) {
      const path = JSON.parse(JSON.stringify(vFPath));
      path.coordinates[0][index] = [lng, lat];
      if (index === 0) {
        path.coordinates[0][path.coordinates[0].length-1] = [lng, lat];
      }
      setVFPath(path);
    }
  };

  const setOrderPolygonPath = (oldIndex, newIndex, pathAux = false) => {
    if (mapReady) {
      const path = pathAux !== false ? JSON.parse(JSON.stringify(pathAux)) : JSON.parse(JSON.stringify(vFPath));
      const oldValues = path.coordinates[0][oldIndex];
      path.coordinates[0].splice(oldIndex, 1);
      path.coordinates[0].splice(newIndex, 0, oldValues);
      path.coordinates[0][path.coordinates[0].length-1] = JSON.parse(JSON.stringify(path.coordinates[0][0]));
      setVFPath(path);
    }
  };

  return (
    <div className="virtual-fence-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_APP_GOOGLE_MAP_KEY,
          libraries: ["places", "geometry"]
        }}
        defaultCenter={__default_center__}
        defaultZoom={__default_zoom__}
        draggable={draggable}
        options={{
          disableDefaultUI: true,
          mapTypeId: getMapTypeId({ type: options.mapTypeId }),
          minZoom: options.minZoom,
          maxZoom: options.maxZoom,
          restriction: options.restriction,
          styles: options.styles
        }}
        onGoogleApiLoaded={({ map, maps }) => {
          mapRef.current = map;
          mapsRef.current = maps;
          setMapReady(true);
        }}
        onChange={({ zoom, bounds }) => {
          setZoom(zoom);
          setBounds([
            bounds.nw.lng,
            bounds.se.lat,
            bounds.se.lng,
            bounds.nw.lat
          ]);
        }}
        onClick={(e) => {
          if (add) {
            const { lat, lng } = e;
            addPointAux(lat, lng);
          }
        }}
        onChildMouseDown={(childKey, childProps, mouse) => {
          if (edit === childProps.index) {
            setDraggable(false);
            if (!isNaN(mouse.lat) && !isNaN(mouse.lng)) {
              if (addAux) {
                setAddAux(new mapsRef.current.LatLng(mouse.lat, mouse.lng));
              }
              else {
                setPolygonPath(childProps.index, mouse.lat, mouse.lng);
              }
            }
          }
        }}
        onChildMouseUp={(childKey, childProps, mouse) => {
          setDraggable(true);
        }}
        onChildMouseMove={(childKey, childProps, mouse) => {
          if (edit === childProps.index) {
            setDraggable(false);
            if (!isNaN(mouse.lat) && !isNaN(mouse.lng)) {
              if (addAux) {
                setAddAux(new mapsRef.current.LatLng(mouse.lat, mouse.lng));
              }
              else {
                setPolygonPath(childProps.index, mouse.lat, mouse.lng);
              }
            }
          }
        }}
        yesIWantToUseGoogleMapApiInternals>
        {
          mapReady ? (
            getPolygonPath()
              .map((item, index) => {
                if ((index + 1) < getPolygonPath().length) {
                  return (
                    <PolygonMarker
                      color={getPolygonColor()}
                      edit={edit}
                      index={index}
                      key={`virtual-fence-point-${index}`}
                      lat={item.lat()}
                      lng={item.lng()}
                      onClick={(e, i, n) => {
                        addPointReset();
                        switch(e) {
                          case 2:
                            editPolygonPath(i);
                          break;
                          case 3:
                            removePolygonPath(i);
                            break;
                          case 4:
                            setOrderPolygonPath(i, n);
                            break;
                          default:
                            break;
                        }
                      }}
                      total={(getPolygonPath().length - 1)}
                      virtualFence={vF}
                    />
                  );
                }
                return null;
              })
          ) : null
        }
        {
          mapReady && addAux !== false ? (
            <PolygonMarker
              color={getPolygonColor()}
              edit={edit}
              index={((getPolygonPath().length === 0 ? 1 : getPolygonPath().length) - 1)}
              key={`virtual-fence-point-${((getPolygonPath().length === 0 ? 1 : getPolygonPath().length) - 1)}`}
              lat={addAux.lat()}
              lng={addAux.lng()}
              onClick={(e, i, n) => {
                switch(e) {
                  case 2:
                  case 3:
                    addPointReset();
                  break;
                  case 4:
                    addPolygonPath(i, n);
                    break;
                  default:
                    break;
                }
              }}
              total={(getPolygonPath().length === 0 ? 1 : getPolygonPath().length)}
              virtualFence={vF}
            />
          ) : null
        }
      </GoogleMapReact>
      <div className="virtual-fence-map-menu">
        <ul>
          <li>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>{add ? t("Title.Cancel"): t("Title.AddPoint")}</Tooltip>}
              trigger={isMobile ? null : ["hover", "focus"]}
            >
              <button
                className={`vf-button ${add ? "active" : ""}`}
                type="button"
                onClick={addPoint}
              >
                <div className="vf-icon">
                  <i className="fas fa-map-marker-alt" />
                </div>
              </button>
            </OverlayTrigger>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(VirtualFenceMap);
