import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as SimulatorsMapsActions } from "./../../store/ducks/simulatorsMaps";

import PatrimonyMapMenuPositionsActionItem from "./../PatrimonyMapMenuPositionsActionItem";

const PatrimonyMapMenuPositionsAction = ({
  options,
  props: {
    currentUser,
    routes
  },
  funcs: {
    setRouteParams
  }
}) => {
  const actions = [];
  return (
    <div className={"patrimony-map-menu-positions-action"}>
      <ul>
        {
          actions.map(action => {
            if(action.scoped()) {
              return (
                <PatrimonyMapMenuPositionsActionItem key={action.id} {...action} onClick={action.onClick} />
              );
            }
            return null;
          })
        }
      </ul>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    routes: state.simulatorsMaps.routes
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, SimulatorsMapsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonyMapMenuPositionsAction);
