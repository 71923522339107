import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import "./index.css";

import { Creators as SimulatorsMapsActions } from "./../../store/ducks/simulatorsMaps";

const PLAYER_PAUSE = 0;
const PLAYER_PLAY = 1;
const PLAYER_STOP = -1;

const PatrimonyMapMenuPositionsPlayerAction = ({
  options,
  props: {
    currentUser,
    routes
  },
  funcs: {
    setRouteParams
  }
}) => {
  const {
    routeId: __routeId = "",
  } = options;

  const [routeId] = useState(__routeId);

  const getRoute = () => {
    const route = routes.find(x => x.id === routeId);
    if(route) {
      return route;
    }
    return false;
  };

  const getRouteStatus = () => {
    const simulatorRoute = getRoute();
    if(simulatorRoute) {
      return simulatorRoute.routeParams.status;
    }
    return 0;
  };

  const handleTogglePlayPause = (e) => {
    if(getRouteStatus() === PLAYER_PAUSE || getRouteStatus() === PLAYER_STOP) {
      return handlePlay(e);
    }
    return handlePause(e);
  };

  const handlePause = (e) => {
    e.preventDefault();
    setRouteParams(routeId, "status", PLAYER_PAUSE);
  };

  const handlePlay = (e) => {
    e.preventDefault();
    setRouteParams(routeId, "status", PLAYER_PLAY);
  };

  const handleStop = (e) => {
    e.preventDefault();
    setRouteParams(routeId, "status", PLAYER_STOP);
  };

  return (
    <ul className="patrimony-map-menu-positions-player-action">
      <li className={(getRouteStatus() === PLAYER_PAUSE) || (getRouteStatus() === PLAYER_PLAY) ? "active" : ""} onClick={handleTogglePlayPause}>
        {
          getRouteStatus() === PLAYER_PLAY ? (
            <i className="fas fa-pause" />
          ) : (
            <i className="fas fa-play" />
          )
        }
      </li>
      <li className={getRouteStatus() === PLAYER_STOP ? "active" : ""} onClick={handleStop}>
        <i className="fas fa-stop" />
      </li>
    </ul>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser,
    routes: state.simulatorsMaps.routes
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, SimulatorsMapsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonyMapMenuPositionsPlayerAction);
