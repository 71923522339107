export function filterByClientAndWord(dateCorrections, search) {
  let filter = dateCorrections;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.client !== "undefined" && search.client.id !== "") {
      enabled = true;
      filter = filter.filter((p) => typeof p.dateCorrection.patrimony.client !== "undefined" && p.dateCorrection.patrimony.client.id === search.client.id);
    }
    if(typeof search.niple !== "undefined" && search.niple !== "") {
      enabled = true;
      filter = filter.filter(
        (p) => search.niple ? (
          p.dateCorrection.patrimony.automobile && p.dateCorrection.patrimony.automobile.board && p.dateCorrection.patrimony.automobile.board.type === "ATV"
        ) : (
          (p.dateCorrection.patrimony.automobile && p.dateCorrection.patrimony.automobile.board && p.dateCorrection.patrimony.automobile.board.type !== "ATV") ||
          (p.dateCorrection.patrimony.automobile && p.dateCorrection.patrimony.automobile.card) ||
          (p.dateCorrection.patrimony.object) ||
          (p.dateCorrection.patrimony.vessel)
        ));
    }
    if(typeof search.patrimony !== "undefined" && search.patrimony !== "") {
      enabled = true;
      filter = filter.filter((n) => typeof n.dateCorrection.patrimony !== "undefined" && typeof n.dateCorrection.patrimony.id !== "undefined" && n.dateCorrection.patrimony.id === search.patrimony);
    }
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((p) => p.dateCorrection.tracker.serial.toLowerCase().indexOf(search.word.toLowerCase()) !== -1 || p.dateCorrection.patrimony.automobile.board.board.toLowerCase().indexOf(search.word.toLowerCase()) !== -1);
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function sortDateCorrections(dateCorrections, asc = false) {
  if(asc) {
    return dateCorrections.sort((a, b) => {
      if (a.dateCorrection.createdAt && b.dateCorrection.createdAt) return a.dateCorrection.gps.date.localeCompare(b.dateCorrection.gps.date) || a.dateCorrection.createdAt.localeCompare(b.dateCorrection.createdAt);
      return a.dateCorrection.gps.date.localeCompare(b.dateCorrection.gps.date);
    });
  }
  return dateCorrections.sort((a, b) => {
    if (a.dateCorrection.createdAt && b.dateCorrection.createdAt) return b.dateCorrection.gps.date.localeCompare(a.dateCorrection.gps.date) || b.dateCorrection.createdAt.localeCompare(a.dateCorrection.createdAt);
    return b.dateCorrection.gps.date.localeCompare(a.dateCorrection.gps.date);
  });
}

export function sortDateCorrectionsPolyline(dateCorrectionsPolyline, asc = false) {
  if(asc) {
    return dateCorrectionsPolyline.sort((a, b) => {
      if (a.createdAt && b.createdAt) return a.gps.date.localeCompare(b.gps.date) || a.createdAt.localeCompare(b.createdAt);
      return a.gps.date.localeCompare(b.gps.date);
    });
  }
  return dateCorrectionsPolyline.sort((a, b) => {
    if (a.createdAt && b.createdAt) return b.gps.date.localeCompare(a.gps.date) || b.createdAt.localeCompare(a.createdAt);
    return b.gps.date.localeCompare(a.gps.date);
  });
}
