import styled from "styled-components";

export const PinBoardContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PinBoardBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.5);
  z-index: 1;
`;

export const PinBoardContent = styled.div`
  padding: 15px;
  position: relative;
  background: #fff;
  margin: auto;
  z-index: 2;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const PinBoardContentAction = styled.div`
  margin: 20px auto;
  width: 100%;

  &,
  & > ul {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  & > ul > li {
    display: block;
  }
  & > ul > li > i {
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    color: #333;
    width: 42px;
    height: 42px;
    font-size: 20px;
    line-height: 1;
    margin: auto auto 10px auto;
    align-items: center;
    justify-content: center;
    display: flex;
  }
`;

export const PinBoardContentKeyBoard = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  div {
    padding: 5px;
    max-width: 300px;
  }
  .col-4 {
    padding: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  button {
    width: 100%;
    height: 50px;
    text-align: center;
    background: #f2f2f2;
    border: 1px solid #d2d2d2;
    border-radius: 4px;
    color: #555;
    font-size: 18px;
    line-height: 1;
    cursor: pointer;
    &:hover {
      background: #d2d2d2;
    }
    &:disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }
  button.confirm {
    background: #28a745;
    border: 1px solid #1d7b32;
    color: #fff;
    &:hover {
      background: #1d7b32;
    }
  }
`;

export const PinBoardContentValue = styled.div`
  padding: 15px;
  width: 100%;
  max-width: 100px;

  &,
  & > ul,
  & > ul > li {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &,
  & > ul > li {
    margin: 0 10px;
    flex-direction: column;
  }
  & > ul {
    flex-direction: row;
  }
  & > ul > li > input {
    display: block;
    color: #000;
    width: 15px;
    height: 15px;
    position: relative;
  }

  & > ul > li > input:after,
  & > ul > li > input:checked:after,
  & > ul > li > input:checked:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  & > ul > li > input:after,
  & > ul > li > input:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 1px solid #222;
    content: "";
    background-color: #f2f2f2;
    z-index: 1;
  }
  & > ul > li > input:checked:before {
    width: 9px;
    height: 9px;
    border-radius: 9px;
    border: 0;
    content: "";
    background-color: #333;
    z-index: 2;
  }
`;