import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import "./index.css";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";

import { isCellphone } from "./../../utils/Cellphone";
import { isEmail } from "./../../utils/Email";
import { isValidForm } from "./../../utils/Form";
import { clearObject, mergeObject } from "./../../utils/Object";
import { isEmpty, ucFirstAll } from "./../../utils/String";
import { jsonToForm } from "./../../utils/User";

import UserService from "./../../services/User";
import UserSchema from "./../../services/User/Schema";

const ACTIVE_KEY_GENERAL_DATA = "0";
const ACTIVE_KEY_NOTIFICATIONS = "1";

const User = ({
  options,
  props: {
    currentUser
  },
  funcs: {
    addNotice
  }
}) => {
  const { t } = useTranslation();
  const userService = new UserService();

  const {
    user: __user = {}
  } = options;

  const [user, setUser] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(UserSchema)), {
    ...__user,
    password: ""
  })));

  useEffect(() => {
    const newUser = JSON.parse(JSON.stringify(currentUser));
    setUser({
      ...newUser,
      password: ""
    });
  }, [currentUser]);

  const [activeKey, setActiveKey] = useState("0");

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  const changeActiveKey = (e) => {
    if(activeKey === e) {
      return setActiveKey("-1");
    }
    setActiveKey(e);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if(!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if(!valid) {
        if(!message) {
          message = t("Error.Fields.Empty");
        }
        if(eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.User"),
          content: message
        });
      }
      else {
        let response = await userService.update({
          id: user.id,
          data: handleSubmitForm({ user }),
          token: currentUser.token
        });

        if(response.success) {
          addNotice({
            title: t("Title.User"),
            content: t("Success.Update")
          });
        }
        else {
          addNotice({
            title: t("Title.User"),
            content: t(`Error.User.${response.error.type}.${ucFirstAll(response.error.details[0].path)}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ user }) => {
    let newUser = Object.assign({}, JSON.parse(JSON.stringify(user)));
    newUser = clearObject({ data: newUser });
    newUser.id = undefined;
    return newUser;
  };

  const inputDisabled = () => {
    if(!running) {
      return false;
    }
    return true;
  };

  return (
    <div className="user-profiles">
      <Form id="forms-profiles-user" noValidate onSubmit={handleSubmit}>
        <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
            <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
              <Card.Body>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-profiles-user-name">
                      <Form.Label>{t("Label.Name")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.Name")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const name = e.target.value;
                          setUser(prevState => ({
                            ...prevState,
                            name: name.toUpperCase()
                          }));
                        }}
                        autoComplete="off"
                        value={user.name}
                        isValid={!isEmpty(user.name)}
                        isInvalid={isEmpty(user.name)}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.Name")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-profiles-user-cpf">
                      <Form.Label>{t("Label.CPF")}:</Form.Label>
                      <InputMask
                        id="forms-profiles-user-cpf"
                        className={"form-control"}
                        type="text"
                        placeholder={t("Placeholder.CPF")}
                        disabled={true}
                        mask="999.999.999-99"
                        autoComplete="off"
                        defaultValue={user.cpf}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        required={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="forms-user-contact-email">
                      <Form.Label>{t("Label.Email")}:</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={t("Placeholder.Email")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const email = e.target.value;
                          setUser(prevState => ({
                            ...prevState,
                            contact: {
                              ...prevState.contact,
                              email: email.toLowerCase()
                            }
                          }));
                        }}
                        autoComplete="off"
                        value={user.contact.email}
                        isValid={!isEmpty(user.contact.email) && isEmail(user.contact.email)}
                        isInvalid={isEmpty(user.contact.email) || !isEmail(user.contact.email)}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        required={true}
                      />
                      <Form.Control.Feedback type="invalid">
                        {
                          isEmpty(user.contact.email) ? (
                            t("Feedback.Email")
                          ) : (
                            t("Feedback.Format.Email")
                          )
                        }
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-user-contact-telephone">
                      <Form.Label>{t("Label.Telephone")}:</Form.Label>
                      <InputMask
                        id="forms-user-contact-telephone"
                        className={`form-control ${!isEmpty(user.contact.telephone) ? (isCellphone(user.contact.telephone) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.Telephone")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const telephone = e.target.value;
                          setUser(prevState => ({
                            ...prevState,
                            contact: {
                              ...prevState.contact,
                              telephone
                            }
                          }));
                        }}
                        mask="(99) 999999999"
                        autoComplete="off"
                        defaultValue={user.contact.telephone}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.Format.Telephone")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-user-contact-cellphone">
                      <Form.Label>{t("Label.Cellphone")}:</Form.Label>
                      <InputMask
                        id="forms-user-contact-cellphone"
                        className={`form-control ${!isEmpty(user.contact.cellphone) ? (isCellphone(user.contact.cellphone) ? "is-valid" : "is-invalid") : ""}`}
                        type="text"
                        placeholder={t("Placeholder.Cellphone")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const cellphone = e.target.value;
                          setUser(prevState => ({
                            ...prevState,
                            contact: {
                              ...prevState.contact,
                              cellphone
                            }
                          }));
                        }}
                        mask="(99) 999999999"
                        autoComplete="off"
                        defaultValue={user.contact.cellphone}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.Format.Cellphone")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_NOTIFICATIONS} onClick={e => changeActiveKey(ACTIVE_KEY_NOTIFICATIONS)}>{t("Title.Notifications")}</Accordion.Toggle>
            <Accordion.Collapse eventKey={ACTIVE_KEY_NOTIFICATIONS}>
              <Card.Body>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-user-profile-notification-mode-push">
                      <Form.Label>{t("Label.Notifications.Push")}:</Form.Label>
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const active = e.value;
                          setUser(prevState => ({
                            ...prevState,
                            profile: {
                              ...prevState.profile,
                              notification: {
                                ...prevState.profile.notification,
                                mode: {
                                  ...prevState.profile.notification.mode,
                                  push: active
                                }
                              }
                            }
                          }));
                        }}
                        options={activeOptions}
                        value={activeOptions.find(x => x.value === user.profile.notification.mode.push)}
                        dataeventkey={ACTIVE_KEY_NOTIFICATIONS}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-user-profile-notification-mode-mail">
                      <Form.Label>{t("Label.Notifications.Mail")}:</Form.Label>
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const active = e.value;
                          setUser(prevState => ({
                            ...prevState,
                            profile: {
                              ...prevState.profile,
                              notification: {
                                ...prevState.profile.notification,
                                mode: {
                                  ...prevState.profile.notification.mode,
                                  mail: active
                                }
                              }
                            }
                          }));
                        }}
                        options={activeOptions}
                        value={activeOptions.find(x => x.value === user.profile.notification.mode.mail)}
                        dataeventkey={ACTIVE_KEY_NOTIFICATIONS}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-user-profile-notification-warning-sound">
                      <Form.Label>{t("Label.Notifications.Sound")}:</Form.Label>
                      <Select
                        className="menu-outer-top"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const active = e.value;
                          setUser(prevState => ({
                            ...prevState,
                            profile: {
                              ...prevState.profile,
                              notification: {
                                ...prevState.profile.notification,
                                warning: {
                                  ...prevState.profile.notification.warning,
                                  sound: active
                                }
                              }
                            }
                          }));
                        }}
                        options={activeOptions}
                        value={activeOptions.find(x => x.value === user.profile.notification.warning.sound)}
                        dataeventkey={ACTIVE_KEY_NOTIFICATIONS}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-user-profile-notification-warning-voice">
                      <Form.Label>{t("Label.Notifications.Voice")}:</Form.Label>
                      <Select
                        className="menu-outer-top"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const active = e.value;
                          setUser(prevState => ({
                            ...prevState,
                            profile: {
                              ...prevState.profile,
                              notification: {
                                ...prevState.profile.notification,
                                warning: {
                                  ...prevState.profile.notification.warning,
                                  voice: active
                                }
                              }
                            }
                          }));
                        }}
                        options={activeOptions}
                        value={activeOptions.find(x => x.value === user.profile.notification.warning.voice)}
                        dataeventkey={ACTIVE_KEY_NOTIFICATIONS}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Form.Group className="default-form-button">
          <Button
            variant="dark"
            type="submit"
            disabled={buttonDisabled()}
          >
            {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
          </Button>
        </Form.Group>
      </Form>
    </div>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(User));
