import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Form,
  Row,
  Spinner,
} from "react-bootstrap";
import InputMask from "react-input-mask";
import CurrencyInput from "react-currency-input";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import { QRCode } from "react-qrcode-logo";

import { Creators as NotificationsActions } from "./../../store/ducks/notifications";
import { Creators as QrcodesActions } from "./../../store/ducks/qrcodes";

import { isScopes } from "./../../services/Auth";
import { isBoard } from "./../../utils/Board";
import { maskCPF } from "./../../utils/CPF";
import { maskCNPJ } from "./../../utils/CNPJ";
import Date from "../../utils/Date";
import { isValidForm } from "./../../utils/Form";
import { isEmpty, ucFirstAll } from "./../../utils/String";
import { clearObject, mergeObject } from "./../../utils/Object";
import { jsonToForm } from "./../../utils/Patrimony";
import DateUtil from "./../../utils/Date";

import SliderComponent from "./../../components/Slider";

import ClientService from "./../../services/Client";
import ClientSchemaReduced from "./../../services/Client/SchemaReduced";
import ContractService from "./../../services/Contract";
import ContractSchemaReduced from "./../../services/Contract/SchemaReduced";
import DriverService from "./../../services/Driver";
import DriverSchemaReduced from "./../../services/Driver/SchemaReduced";
import PatrimonyService from "./../../services/Patrimony";
import PatrimonySchema from "./../../services/Patrimony/Schema";
import TrackerService from "./../../services/Tracker";
import TrackerSchemaReduced from "./../../services/Tracker/SchemaReduced";
import VirtualFenceService from "./../../services/VirtualFence";
import VirtualFenceSchemaReduced from "./../../services/VirtualFence/SchemaReduced";

const ACTIVE_KEY_GENERAL_DATA = "0";
const ACTIVE_KEY_PARAMETERS = "1";
const ACTIVE_KEY_PHOTOS = "2";
const ACTIVE_KEY_QRCODE = "3";

const Patrimony = ({
  options,
  props: { currentUser },
  funcs: { addNotice, enableQrcode }
}) => {
  const { t } = useTranslation();
  const clientService = new ClientService();
  const contractService = new ContractService();
  const driverService = new DriverService();
  const patrimonyService = new PatrimonyService();
  const trackerService = new TrackerService();
  const virtualFenceService = new VirtualFenceService();

  const {
    patrimony: __patrimony = {},
    close: __close = () => {}
  } = options;

  const [patrimony, setPatrimony] = useState(jsonToForm(mergeObject(JSON.parse(JSON.stringify(PatrimonySchema)), __patrimony)));
  const [pictures] = useState(patrimony.pictures.sort((a, b) => a.tag.localeCompare(b.tag)));

  useEffect(() => {
    setTimeout(() => {
      if (!isScopes({ currentUser, scopes: [
        "is:master",
        "read:patrimonies:all"
      ], every: false })) {
        setClientSelectedOption({
          dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client),
          label: currentUser.client.type === "LEG" ? (`${currentUser.client.legal.socialReason} | CNPJ: ${maskCNPJ(currentUser.client.legal.cnpj)}`) : (`${currentUser.client.personal.name} | CPF: ${maskCPF(currentUser.client.personal.cpf)}`),
          value: currentUser.client.id
        });
        setPatrimony(prevState => ({
          ...prevState,
          client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
        }));
      }
    }, 1000);
  }, []); /* eslint-disable-line */

  const [activeKey, setActiveKey] = useState(ACTIVE_KEY_GENERAL_DATA);

  const [activeOptions] = useState([
    { value: true, label: t("Active") },
    { value: false, label: t("Inactive") }
  ]);

  const [automobileTypesOptions] = useState([
    { value: "BOA", label: t("Board") },
    { value: "CAR", label: t("Card") }
  ]);

  const [automobileBoardTypesOptions] = useState([
    { value: "ATV", label: t("AutoVacuum") },
    { value: "CAR", label: t("Car") },
    { value: "MOT", label: t("Motorcycle") },
    { value: "TRU", label: t("Truck") }
  ]);

  const [automobileBoardLayoutsOptions] = useState([
    { value: "DEF", label: t("Default") },
    { value: "MER", label: t("Mercosul") }
  ]);

  const [automobileBoardCountriesOptions] = useState([
    { value: "AR", label: t("AR (Republica Argentina)") },
    { value: "BR", label: t("BR (Brasil)") },
    { value: "PY", label: t("PY (Republica del Paraguay)") },
    { value: "UY", label: t("UY (Uruguay)") },
    { value: "VE", label: t("VE (Republica Bolivariana de Venezuela)") }
  ]);

  const [automobileBoardColorOptions] = useState([
    { value: "BLACK", label: t("Black") },
    { value: "BLUE", label: t("Blue") },
    { value: "GRAY", label: t("Gray") },
    { value: "GREEN", label: t("Green") },
    { value: "RED", label: t("Red") },
    { value: "WHITE", label: t("White") },
    { value: "WHITE_RED", label: t("WhiteRed") }
  ]);

  const [automobileBoardAutoVaccumBackCoverOptions] = useState([
    { value: "FOP", label: t("FullOpening") },
    { value: "HOP", label: t("HorizontalOpening") },
    { value: "VOP", label: t("VerticalOpening") }
  ]);

  const [typesOptions] = useState([
    { value: "AUT", label: t("Automobile") },
    { value: "OBJ", label: t("Object") },
    { value: "VES", label: t("Vessel") }
  ]);

  const [trackerTypesOptions] = useState([
    { value: "HAT", label: t("Title.Hatch") },
    { value: "NIP", label: t("Title.Niple") },
    { value: "TRA", label: t("Title.Tracker") },
    { value: "TRA_NIP", label: `${t("Title.Tracker")}/${t("Title.Niple")}` }
  ]);

  const [vesselTypesOptions] = useState([
    { value: "JET", label: t("VesselType.JET") },
    { value: "SPE", label: t("VesselType.SPE") }
  ]);

  const [trackersSelectedOptions, setTrackersSelectedOptions] = useState(patrimony.trackers.map((item, i) => {
    if (i === 0) {
      if (typeof patrimony.trackers.find(x => x.positionMain === true) === "undefined") {
        item.positionMain = true;
      }
    }
    const labelType = trackerTypesOptions.find(x => x.value === item.type);
    return {
      dataAux: mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), item),
      label: `${typeof labelType !== "undefined" ? labelType.label : t("Title.Tracker")}: ${item.serial}`,
      value: item.id
    };
  }));

  const [virtualFencesSelectedOptions, setVirtualFencesSelectedOptions] = useState(patrimony.virtualFences.map((item, i) => {
    return {
      dataAux: mergeObject(JSON.parse(JSON.stringify(VirtualFenceSchemaReduced)), item),
      label: `${t("Title.VirtualFence")}: ${item.name}`,
      value: item.id
    };
  }));

  const [clientSelectedOption, setClientSelectedOption] = useState(patrimony.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), patrimony.client),
    label: patrimony.client.type === "LEG" ? (`${patrimony.client.legal.socialReason} | CNPJ: ${maskCNPJ(patrimony.client.legal.cnpj)}`) : (`${patrimony.client.personal.name} | CPF: ${maskCPF(patrimony.client.personal.cpf)}`),
    value: patrimony.client.id
  });

  const [contractSelectedOption, setContractSelectedOption] = useState(patrimony.contract.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ContractSchemaReduced)), patrimony.contract),
    label: `Nº ${patrimony.contract.sequence} | ${patrimony.contract.name}`,
    value: patrimony.contract.id
  });

  const [driverSelectedOption, setDriverSelectedOption] = useState(patrimony.driver.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), patrimony.driver),
    label: patrimony.driver.name,
    value: patrimony.driver.id
  });

  const [running, setRunning] = useState(false);

  const buttonDisabled = () => {
    if (!running) {
      return false;
    }
    return true;
  };

  const changeActiveKey = (e) => {
    if (activeKey === e) {
      return setActiveKey("-1");
    }
    setActiveKey(e);
  };

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : (`${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`),
        value: e.id
      };
    });
  };

  const contractLoadOptions = (e, c) => {
    contractLoadOptionsDebounce(e, c);
  };

  const contractLoadOptionsDebounce = debounce(async (e, c) => {
    const contracts = await contractService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterContract(contracts));
  }, 1000);

  const filterContract = (contracts) => {
    return contracts.map(e => {
      return {
        dataAux: e,
        label: `Nº ${e.sequence} | ${e.name}`,
        value: e.id
      };
    });
  };

  const driverLoadOptions = (e, c) => {
    driverLoadOptionsDebounce(e, c);
  };

  const driverLoadOptionsDebounce = debounce(async (e, c) => {
    const drivers = await driverService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterDriver(drivers));
  }, 1000);

  const filterDriver = (drivers) => {
    return drivers.map(e => {
      return {
        dataAux: e,
        label: e.name,
        value: e.id
      };
    });
  };

  const trackerLoadOptions = (e, c) => {
    trackerLoadOptionsDebounce(e, c);
  };

  const trackerLoadOptionsDebounce = debounce(async (e, c) => {
    const trackers = await trackerService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterTracker(trackers));
  }, 1000);

  const filterTracker = (trackers) => {
    return trackers.map(e => {
      const labelType = trackerTypesOptions.find(x => x.value === e.type);
      return {
        dataAux: e,
        label: `${typeof labelType !== "undefined" ? labelType.label : t("Title.Tracker")}: ${e.serial}`,
        value: e.id
      };
    });
  };

  const virtualFenceLoadOptions = (e, c) => {
    virtualFenceLoadOptionsDebounce(e, c);
  };

  const virtualFenceLoadOptionsDebounce = debounce(async (e, c) => {
    const virtualFences = await virtualFenceService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterVirtualFence(virtualFences));
  }, 1000);

  const filterVirtualFence = (virtualFences) => {
    return virtualFences.map(e => {
      return {
        dataAux: e,
        label: `${t("Title.VirtualFence")}: ${e.name}`,
        value: e.id
      };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!running) {
      setRunning(true);
      let { eventKey, message, valid } = isValidForm(e);
      if (!valid) {
        if (!message) {
          message = t("Error.Fields.Empty");
        }
        if (eventKey !== -1) {
          setActiveKey(eventKey);
        }
        addNotice({
          title: t("Title.Patrimony"),
          content: message
        });
      }
      else {
        let response = false;
        if (isCreate()) {
          response = await patrimonyService.create({
            data: handleSubmitForm({ patrimony }),
            token: currentUser.token
          });
        }
        else {
          response = await patrimonyService.update({
            id: patrimony.id,
            data: handleSubmitForm({ patrimony }),
            token: currentUser.token
          });
        }

        if (response.success) {
          addNotice({
            title: t("Title.Patrimony"),
            content: isCreate() ? t("Success.Insert") : t("Success.Update")
          });
          setTimeout(() => {
            __close();
          }, 200);
        }
        else {
          let error = ucFirstAll(response.error.details[0].path);
          if (error.indexOf("niple.serial") !== -1) {
            error = "Trackers.niple.serial";
          }
          addNotice({
            title: t("Title.Patrimony"),
            content: t(`Error.Patrimony.${response.error.type}.${error}`)
          });
        }
      }
    }
    setRunning(false);
  };

  const handleSubmitForm = ({ patrimony }) => {
    let newPatrimony = Object.assign({}, JSON.parse(JSON.stringify(patrimony)));
    newPatrimony = clearObject({ data: newPatrimony });
    newPatrimony.id = undefined;
    if (!isAutomobile()) {
      newPatrimony.automobile = undefined;
    }
    if (!isObject()) {
      newPatrimony.object = undefined;
    }
    if (!isVessel()) {
      newPatrimony.vessel = undefined;
    }
    if (isAutomobile()) {
      if (!isAutomobileBoard()) {
        newPatrimony.automobile.board = undefined;
      }
      if (isAutomobileBoard() && !isAutomobileBoardAutoVaccum()) {
        newPatrimony.automobile.board.autoVaccum = undefined;
      }
      if (!isAutomobileCard()) {
        newPatrimony.automobile.card = undefined;
      }
    }
    if (typeof newPatrimony.installAt !== "undefined") {
      newPatrimony.installAt = DateUtil.toDb({ date: newPatrimony.installAt });
    }
    if (typeof newPatrimony.uninstallAt !== "undefined") {
      newPatrimony.uninstallAt = DateUtil.toDb({ date: newPatrimony.uninstallAt });
    }
    if (typeof newPatrimony.parameters !== "undefined" && typeof newPatrimony.parameters.maxSpeed !== "undefined") {
      const maxSpeed = `${newPatrimony.parameters.maxSpeed}`;
      newPatrimony.parameters.maxSpeed = Number(maxSpeed.replace(/_/gi, ""));
    }
    if (typeof newPatrimony.parameters !== "undefined" && typeof newPatrimony.parameters.maintenanceMotorMaxHours !== "undefined") {
      const maintenanceMotorMaxHours = `${newPatrimony.parameters.maintenanceMotorMaxHours}`;
      newPatrimony.parameters.maintenanceMotorMaxHours = Number(maintenanceMotorMaxHours.replace(/_/gi, ""));
    }
    if (typeof newPatrimony.parameters !== "undefined" && typeof newPatrimony.parameters.maintenanceMotorMaxMonths !== "undefined") {
      const maintenanceMotorMaxMonths = `${newPatrimony.parameters.maintenanceMotorMaxMonths}`;
      newPatrimony.parameters.maintenanceMotorMaxMonths = Number(maintenanceMotorMaxMonths.replace(/_/gi, ""));
    }
    if (typeof newPatrimony.trackers !== "undefined") {
      newPatrimony.trackers.map(tracker => {
        if (tracker.type !== "NIP" && tracker.type !== "TRA_NIP") {
          tracker.niple = undefined;
        }
        if (tracker.type !== "HAT") {
          tracker.hatch = undefined;
        }
        return tracker;
      });
    }
    return newPatrimony;
  };

  const inputDisabled = () => {
    if (!running) {
      return false;
    }
    return true;
  };

  const isAutomobile = () => {
    return patrimony.type === "AUT";
  };

  const isAutomobileBoardAutoVaccum = () => {
    if (isAutomobile()) {
      if (isAutomobileBoard()) {
        return patrimony.automobile.board.type === "ATV";
      }
      return false;
    }
    return false;
  };

  const isAutomobileBoard = () => {
    if (isAutomobile()) {
      return patrimony.automobile.type === "BOA";
    }
    return false;
  };

  const isAutomobileBoardDef = () => {
    if (isAutomobile()) {
      if (isAutomobileBoard()) {
        return patrimony.automobile.board.layout === "DEF";
      }
      return false;
    }
    return false;
  };

  const isAutomobileBoardMer = () => {
    if (isAutomobile()) {
      if (isAutomobileBoard()) {
        return patrimony.automobile.board.layout === "MER";
      }
      return false;
    }
    return false;
  };

  const isAutomobileCard = () => {
    if (isAutomobile()) {
      return patrimony.automobile.type === "CAR";
    }
    return false;
  };

  const isCreate = () => {
    return patrimony.id === "";
  };

  const isObject = () => {
    return patrimony.type === "OBJ";
  };

  const isVessel = () => {
    return patrimony.type === "VES";
  };

  return (
    <Form id="forms-patrimony" noValidate onSubmit={handleSubmit}>
      <Accordion defaultActiveKey={ACTIVE_KEY_GENERAL_DATA} activeKey={activeKey}>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_GENERAL_DATA} onClick={e => changeActiveKey(ACTIVE_KEY_GENERAL_DATA)}>{t("Title.GeneralData")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_GENERAL_DATA}>
            <Card.Body>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-active">
                    <Form.Label>{t("Label.Active")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const active = e.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          active
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === patrimony.active)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-client">
                    <Form.Label>{t("Title.Client")}:</Form.Label>
                    <AsyncSelect
                      className={`menu-outer-bottom ${!isEmpty(patrimony.client.id) ? "is-valid" : "is-invalid"}`}
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isDisabled={inputDisabled() || !isScopes({
                        currentUser,
                        scopes: [
                          "is:master",
                          "read:patrimonies:all"
                        ],
                        every: false
                      })}
                      loadOptions={clientLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if (e === null) {
                          setClientSelectedOption(e);
                          setPatrimony(prevState => ({
                            ...prevState,
                            client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setClientSelectedOption({ dataAux, label, value });
                          setPatrimony(prevState => ({
                            ...prevState,
                            client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      placeholder={t("Title.Client.Select")}
                      value={clientSelectedOption}
                      required={true}
                    />
                    <Form.Control.Feedback className={`${!isEmpty(patrimony.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-user-contract">
                    <Form.Label>{t("Title.Contract")}:</Form.Label>
                    <AsyncSelect
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isClearable
                      isDisabled={inputDisabled()}
                      loadOptions={contractLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if (e === null) {
                          setContractSelectedOption(e);
                          setPatrimony(prevState => ({
                            ...prevState,
                            contract: JSON.parse(JSON.stringify(ContractSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setContractSelectedOption({ dataAux, label, value });
                          setPatrimony(prevState => ({
                            ...prevState,
                            contract: mergeObject(JSON.parse(JSON.stringify(ContractSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      placeholder={t("Title.Contract.Select")}
                      value={contractSelectedOption}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-driver">
                    <Form.Label>{t("Title.Driver")}:</Form.Label>
                    <AsyncSelect
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isClearable
                      isDisabled={inputDisabled()}
                      loadOptions={driverLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if (e === null) {
                          setDriverSelectedOption(e);
                          setPatrimony(prevState => ({
                            ...prevState,
                            driver: JSON.parse(JSON.stringify(DriverSchemaReduced))
                          }));
                        }
                        else {
                          const { dataAux, label, value } = e;
                          setDriverSelectedOption({ dataAux, label, value });
                          setPatrimony(prevState => ({
                            ...prevState,
                            driver: mergeObject(JSON.parse(JSON.stringify(DriverSchemaReduced)), dataAux)
                          }));
                        }
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      placeholder={t("Title.Driver.Select")}
                      value={driverSelectedOption}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-tracker">
                    <Form.Label>{t("Title.Tracker")}:</Form.Label>
                    <AsyncSelect
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      cacheOptions
                      defaultOptions
                      isClearable
                      isDisabled={inputDisabled()}
                      isMulti={true}
                      loadOptions={trackerLoadOptions}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      onChange={e => {
                        if (e === null) {
                          setTrackersSelectedOptions(e);
                          setPatrimony(prevState => ({
                            ...prevState,
                            trackers: []
                          }));
                        }
                        else {
                          setTrackersSelectedOptions(e.map((x, i) => {
                            if (i === 0) {
                              if (typeof e.find(x => x.dataAux.positionMain === true) === "undefined") {
                                x.dataAux.positionMain = true;
                              }
                            }
                            const labelType = trackerTypesOptions.find(v => v.value === x.dataAux.type);
                            return {
                              dataAux: mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), x.dataAux),
                              label: `${typeof labelType !== "undefined" ? labelType.label : t("Title.Tracker")}: ${x.dataAux.serial}`,
                              value: x.dataAux.id
                            };
                          }));
                          setPatrimony(prevState => ({
                            ...prevState,
                            trackers: e.map((x, i) => {
                              if (i === 0) {
                                if (typeof e.find(x => x.dataAux.positionMain === true) === "undefined") {
                                  x.dataAux.positionMain = true;
                                }
                              }
                              return mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), x.dataAux);
                            })
                          }));
                        }
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      placeholder={t("Title.Tracker.Select")}
                      value={trackersSelectedOptions}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-tracker-main">
                    <Form.Label>{t("Title.Tracker.Main")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const trackers = trackersSelectedOptions;
                        const selected = trackers.findIndex(x => x.value === e.value);
                        if (selected !== -1) {
                          trackers.map(tracker => {
                            return tracker.dataAux.positionMain = false;
                          });
                          trackers[selected].dataAux.positionMain = true;
                        }
                        setTrackersSelectedOptions(trackers);
                        setPatrimony(prevState => ({
                          ...prevState,
                          trackers: trackers.map(x => {
                            return mergeObject(JSON.parse(JSON.stringify(TrackerSchemaReduced)), x.dataAux);
                          })
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={trackersSelectedOptions !== null ? trackersSelectedOptions : []}
                      loadingMessage={() => t("React.Select.Wait")}
                      noOptionsMessage={() => t("React.Select.NoOptions")}
                      placeholder={t("Title.Tracker.Main.Select")}
                      value={trackersSelectedOptions !== null ? trackersSelectedOptions.find(x => x.dataAux.positionMain === true) : null}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {
                (isScopes({ currentUser, scopes: [
                  "is:master",
                  "read:patrimonies:all"
                ], every: false })) ? (
                  <Row>
                    <Col xs={12}>
                      <Form.Group controlId="forms-patrimony-virtual-fence">
                        <Form.Label>{t("Title.VirtualFence.Operation")}:</Form.Label>
                        <AsyncSelect
                          className="menu-outer-bottom"
                          classNamePrefix="select"
                          cacheOptions
                          defaultOptions
                          isClearable
                          isDisabled={inputDisabled()}
                          isMulti={true}
                          loadOptions={virtualFenceLoadOptions}
                          loadingMessage={() => t("React.Select.Wait")}
                          noOptionsMessage={() => t("React.Select.NoOptions")}
                          onChange={e => {
                            if (e === null) {
                              setVirtualFencesSelectedOptions(e);
                              setPatrimony(prevState => ({
                                ...prevState,
                                virtualFences: []
                              }));
                            }
                            else {
                              setVirtualFencesSelectedOptions(e.map((x, i) => {
                                return {
                                  dataAux: mergeObject(JSON.parse(JSON.stringify(VirtualFenceSchemaReduced)), x.dataAux),
                                  label: `${t("Title.VirtualFence.Operation")}: ${x.dataAux.name}`,
                                  value: x.dataAux.id
                                };
                              }));
                              setPatrimony(prevState => ({
                                ...prevState,
                                virtualFences: e.map((x, i) => {
                                  return mergeObject(JSON.parse(JSON.stringify(VirtualFenceSchemaReduced)), x.dataAux);
                                })
                              }));
                            }
                          }}
                          dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                          placeholder={t("Title.VirtualFence.Operation.Select")}
                          value={virtualFencesSelectedOptions}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                ) : null
              }
              <Row>
                <Col>
                  <Form.Group controlId="forms-patrimony-type">
                    <Form.Label>{t("Label.Type")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const type = e.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          type
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      options={typesOptions}
                      value={typesOptions.find(x => x.value === patrimony.type)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className={`${isAutomobile() ? "" : "hide"}`}>
                <Row>
                  <Col>
                    <Form.Group controlId="forms-patrimony-automobile-type">
                      <Form.Label>{t("Label.AutomobileType")}:</Form.Label>
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const type = e.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            automobile: {
                              ...prevState.automobile,
                              type
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        options={automobileTypesOptions}
                        value={automobileTypesOptions.find(x => x.value === patrimony.automobile.type)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className={`${isAutomobileBoard() ? "" : "hide"}`}>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="forms-patrimony-automobile-board-type">
                        <Form.Label>{t("Label.AutomobileBoardType")}:</Form.Label>
                        <Select
                          className="menu-outer-bottom"
                          classNamePrefix="select"
                          isDisabled={inputDisabled()}
                          onChange={e => {
                            const type = e.value;
                            setPatrimony(prevState => ({
                              ...prevState,
                              automobile: {
                                ...prevState.automobile,
                                board: {
                                  ...prevState.automobile.board,
                                  type
                                }
                              }
                            }));
                          }}
                          dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                          options={automobileBoardTypesOptions}
                          value={automobileBoardTypesOptions.find(x => x.value === patrimony.automobile.board.type)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="forms-patrimony-automobile-board-layout">
                        <Form.Label>{t("Label.AutomobileBoardLayout")}:</Form.Label>
                        <Select
                          className="menu-outer-bottom"
                          classNamePrefix="select"
                          isDisabled={inputDisabled()}
                          onChange={e => {
                            const layout = e.value;
                            setPatrimony(prevState => ({
                              ...prevState,
                              automobile: {
                                ...prevState.automobile,
                                board: {
                                  ...prevState.automobile.board,
                                  layout
                                }
                              }
                            }));
                          }}
                          dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                          options={automobileBoardLayoutsOptions}
                          value={automobileBoardLayoutsOptions.find(x => x.value === patrimony.automobile.board.layout)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <div className={`${isAutomobileBoardAutoVaccum() ? "" : "hide"}`}>
                    <Row>
                      <Col>
                        <Form.Group controlId="forms-patrimony-automobile-board-auto-vaccum-license">
                          <Form.Label>{t("Label.AutomobileLicense")}:</Form.Label>
                          <InputMask
                            id="forms-patrimony-automobile-board-auto-vaccum-license"
                            className={`form-control form-control-up ${isAutomobileBoardAutoVaccum() ? (!isEmpty(patrimony.automobile.board.autoVaccum.license) ? "is-valid" : "is-invalid") : ""}`}
                            type="text"
                            placeholder={t("Placeholder.AutomobileLicense")}
                            disabled={inputDisabled()}
                            onChange={e => {
                              const license = e.target.value;
                              setPatrimony(prevState => ({
                                ...prevState,
                                automobile: {
                                  ...prevState.automobile,
                                  board: {
                                    ...prevState.automobile.board,
                                    autoVaccum: {
                                      ...prevState.automobile.board.autoVaccum,
                                      license
                                    }
                                  }
                                }
                              }));
                            }}
                            dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                            mask={"999999/9999"}
                            autoComplete="off"
                            value={patrimony.automobile.board.autoVaccum.license}
                            required={isAutomobileBoardAutoVaccum() ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">
                            { t("Feedback.AutomobileLicense") }
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group controlId="forms-patrimony-automobile-board-auto-vaccum-back-cover">
                          <Form.Label>{t("Label.AutomobileBackCover")}:</Form.Label>
                          <Select
                            className="menu-outer-bottom"
                            classNamePrefix="select"
                            isDisabled={inputDisabled()}
                            onChange={e => {
                              const backCover = e.target.value;
                              setPatrimony(prevState => ({
                                ...prevState,
                                automobile: {
                                  ...prevState.automobile,
                                  board: {
                                    ...prevState.automobile.board,
                                    autoVaccum: {
                                      ...prevState.automobile.board.autoVaccum,
                                      backCover
                                    }
                                  }
                                }
                              }));
                            }}
                            dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                            options={automobileBoardAutoVaccumBackCoverOptions}
                            value={automobileBoardAutoVaccumBackCoverOptions.find(x => x.value === patrimony.automobile.board.autoVaccum.backCover)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${isAutomobileBoardDef() ? "" : "hide"}`}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="forms-patrimony-automobile-board-board-def">
                          <Form.Label>{t("Label.AutomobileBoard")}:</Form.Label>
                          <InputMask
                            id="forms-patrimony-automobile-board-board-def"
                            className={`form-control form-control-up ${isAutomobileBoardDef() ? (!isEmpty(patrimony.automobile.board.board) && isBoard(patrimony.automobile.board.board) ? "is-valid" : "is-invalid") : ""}`}
                            type="text"
                            placeholder={t("Placeholder.AutomobileBoard")}
                            disabled={inputDisabled()}
                            onChange={e => {
                              const board = e.target.value;
                              setPatrimony(prevState => ({
                                ...prevState,
                                automobile: {
                                  ...prevState.automobile,
                                  board: {
                                    ...prevState.automobile.board,
                                    board: board.toUpperCase()
                                  }
                                }
                              }));
                            }}
                            dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                            mask={"aaa-9999"}
                            autoComplete="off"
                            value={patrimony.automobile.board.board}
                            required={isAutomobileBoardDef() ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {
                              isEmpty(patrimony.automobile.board.board) ? (
                                t("Feedback.AutomobileBoard")
                              ) : (
                                t("Feedback.Format.AutomobileBoard")
                              )
                            }
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="forms-patrimony-automobile-board-description">
                          <Form.Label>{t("Label.AutomobileBoardDescription")}:</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder={t("Placeholder.AutomobileBoardDescription")}
                            disabled={inputDisabled()}
                            onChange={e => {
                              const description = e.target.value;
                              setPatrimony(prevState => ({
                                ...prevState,
                                automobile: {
                                  ...prevState.automobile,
                                  board: {
                                    ...prevState.automobile.board,
                                    description: description.toUpperCase()
                                  }
                                }
                              }));
                            }}
                            dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                            autoComplete="off"
                            value={patrimony.automobile.board.description}
                            required={false}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <div className={`${isAutomobileBoardMer() ? "" : "hide"}`}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="forms-patrimony-automobile-board-board-mer">
                          <Form.Label>{t("Label.AutomobileBoard")}:</Form.Label>
                          <InputMask
                            id="forms-patrimony-automobile-board-board-mer"
                            className={`form-control form-control-up ${isAutomobileBoardMer() ? (!isEmpty(patrimony.automobile.board.board) && isBoard(patrimony.automobile.board.board, true) ? "is-valid" : "is-invalid") : ""}`}
                            type="text"
                            placeholder={t("Placeholder.AutomobileBoard")}
                            disabled={inputDisabled()}
                            onChange={e => {
                              const board = e.target.value;
                              setPatrimony(prevState => ({
                                ...prevState,
                                automobile: {
                                  ...prevState.automobile,
                                  board: {
                                    ...prevState.automobile.board,
                                    board: board.toUpperCase()
                                  }
                                }
                              }));
                            }}
                            dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                            mask={"aaa9a99"}
                            autoComplete="off"
                            value={patrimony.automobile.board.board}
                            required={isAutomobileBoardMer() ? true : false}
                          />
                          <Form.Control.Feedback type="invalid">
                            {
                              isEmpty(patrimony.automobile.board.board) ? (
                                t("Feedback.AutomobileBoard")
                              ) : (
                                t("Feedback.Format.AutomobileBoard")
                              )
                            }
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col xs={12} md={6}>
                        <Form.Group controlId="forms-patrimony-automobile-board-country">
                          <Form.Label>{t("Label.AutomobileBoardCountry")}:</Form.Label>
                          <Select
                            className="menu-outer-bottom"
                            classNamePrefix="select"
                            isDisabled={inputDisabled()}
                            onChange={e => {
                              const country = e.value;
                              setPatrimony(prevState => ({
                                ...prevState,
                                automobile: {
                                  ...prevState.automobile,
                                  board: {
                                    ...prevState.automobile.board,
                                    country
                                  }
                                }
                              }));
                            }}
                            dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                            options={automobileBoardCountriesOptions}
                            value={automobileBoardCountriesOptions.find(x => x.value === patrimony.automobile.board.country)}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <Row>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="forms-patrimony-automobile-board-color">
                        <Form.Label>{t("Label.AutomobileBoardColor")}:</Form.Label>
                        <Select
                          className="menu-outer-bottom"
                          classNamePrefix="select"
                          isDisabled={inputDisabled()}
                          onChange={e => {
                            const color = e.value;
                            setPatrimony(prevState => ({
                              ...prevState,
                              automobile: {
                                ...prevState.automobile,
                                board: {
                                  ...prevState.automobile.board,
                                  color
                                }
                              }
                            }));
                          }}
                          dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                          options={automobileBoardColorOptions}
                          value={automobileBoardColorOptions.find(x => x.value === patrimony.automobile.board.color)}
                        />
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="forms-patrimony-automobile-color">
                        <Form.Label>{t("Label.AutomobileColor")}:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("Placeholder.AutomobileColor")}
                          disabled={inputDisabled()}
                          onChange={e => {
                            const color = e.target.value;
                            setPatrimony(prevState => ({
                              ...prevState,
                              automobile: {
                                ...prevState.automobile,
                                color: color.toUpperCase()
                              }
                            }));
                          }}
                          dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                          autoComplete="off"
                          value={patrimony.automobile.color}
                          required={false}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <div className={`${isAutomobileCard() ? "" : "hide"}`}>
                  <Row>
                    <Col>
                      <Form.Group controlId="forms-patrimony-automobile-card-number">
                        <Form.Label>{t("Label.AutomobileCardNumber")}:</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={t("Placeholder.AutomobileCardNumber")}
                          disabled={inputDisabled()}
                          onChange={e => {
                            const number = e.target.value;
                            setPatrimony(prevState => ({
                              ...prevState,
                              automobile: {
                                ...prevState.automobile,
                                card: {
                                  ...prevState.automobile.card,
                                  number: number.toUpperCase()
                                }
                              }
                            }));
                          }}
                          dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                          autoComplete="off"
                          value={patrimony.automobile.card.number}
                          isValid={isAutomobileCard() ? !isEmpty(patrimony.automobile.card.number) : false}
                          isInvalid={isAutomobileCard() ? isEmpty(patrimony.automobile.card.number) : false}
                          required={isAutomobileCard() ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">{t("Feedback.AutomobileCardNumber")}</Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-automobile-brand">
                      <Form.Label>{t("Label.AutomobileBrand")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.AutomobileBrand")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const brand = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            automobile: {
                              ...prevState.automobile,
                              brand: brand.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.automobile.brand}
                        required={false}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-automobile-model">
                      <Form.Label>{t("Label.AutomobileModel")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.AutomobileModel")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const model = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            automobile: {
                              ...prevState.automobile,
                              model: model.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.automobile.model}
                        required={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className={`${isObject() ? "" : "hide"}`}>
                <Row>
                  <Col>
                    <Form.Group controlId="forms-patrimony-object-name">
                      <Form.Label>{t("Label.ObjectName")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.ObjectName")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const name = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            object: {
                              ...prevState.object,
                              name: name.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.object.name}
                        isValid={isObject() ? !isEmpty(patrimony.object.name) : false}
                        isInvalid={isObject() ? isEmpty(patrimony.object.name) : false}
                        required={isObject() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.ObjectName")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <div className={`${isVessel() ? "" : "hide"}`}>
                <Row>
                  <Col xs={12}>
                    <Form.Group controlId="forms-patrimony-vessel-name">
                      <Form.Label>{t("Label.VesselName")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.VesselName")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const name = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              name: name.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.vessel.name}
                        isValid={isVessel() ? !isEmpty(patrimony.vessel.name) : false}
                        isInvalid={isVessel() ? isEmpty(patrimony.vessel.name) : false}
                        required={isVessel() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.VesselName")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-vessel-brand">
                      <Form.Label>{t("Label.VesselBrand")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.VesselBrand")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const brand = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              brand: brand.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.vessel.brand}
                        required={false}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-vessel-model">
                      <Form.Label>{t("Label.VesselModel")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.VesselModel")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const model = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              model: model.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.vessel.model}
                        required={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-vessel-registration-number">
                      <Form.Label>{t("Label.VesselRegistrationNumber")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.VesselRegistrationNumber")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const registrationNumber = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              registrationNumber: registrationNumber.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.vessel.registrationNumber}
                        isValid={isVessel() ? !isEmpty(patrimony.vessel.registrationNumber) : false}
                        isInvalid={isVessel() ? isEmpty(patrimony.vessel.registrationNumber) : false}
                        required={isVessel() ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">{t("Feedback.VesselRegistrationNumber")}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-vessel-description">
                      <Form.Label>{t("Label.VesselDescription")}:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={t("Placeholder.VesselDescription")}
                        disabled={inputDisabled()}
                        onChange={e => {
                          const description = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              description: description.toUpperCase()
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        autoComplete="off"
                        value={patrimony.vessel.description}
                        required={false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-vessel-motors">
                      <Form.Label>{t("Label.VesselMotors")}:</Form.Label>
                      <CurrencyInput
                        id="forms-patrimony-vessel-motors"
                        className={"form-control"}
                        type="text"
                        placeholder={t("Placeholder.VesselMotors")}
                        disabled={inputDisabled()}
                        decimalSeparator="."
                        thousandSeparator=""
                        precision="0"
                        prefix=""
                        value={patrimony.vessel.motors}
                        onChangeEvent={e => {
                          const motors = e.target.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              motors
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} md={6}>
                    <Form.Group controlId="forms-patrimony-vessel-type">
                      <Form.Label>{t("Label.VesselType")}:</Form.Label>
                      <Select
                        className="menu-outer-bottom"
                        classNamePrefix="select"
                        isDisabled={inputDisabled()}
                        onChange={e => {
                          const type = e.value;
                          setPatrimony(prevState => ({
                            ...prevState,
                            vessel: {
                              ...prevState.vessel,
                              type
                            }
                          }));
                        }}
                        dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                        options={vesselTypesOptions}
                        value={vesselTypesOptions.find(x => x.value === patrimony.vessel.type)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-install-at">
                    <Form.Label>{t("Label.InstallAt")}:</Form.Label>
                    <InputMask
                      id="forms-patrimony-install-at"
                      className={"form-control"}
                      type="text"
                      placeholder={t("Placeholder.InstallAt")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const installAt = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          installAt
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      mask="99/99/9999"
                      autoComplete="off"
                      defaultValue={patrimony.installAt}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-uninstall-at">
                    <Form.Label>{t("Label.UninstallAt")}:</Form.Label>
                    <InputMask
                      id="forms-patrimony-uninstall-at"
                      className={"form-control"}
                      type="text"
                      placeholder={t("Placeholder.UninstallAt")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const uninstallAt = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          uninstallAt
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_GENERAL_DATA}
                      mask="99/99/9999"
                      autoComplete="off"
                      defaultValue={patrimony.uninstallAt}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_PARAMETERS} onClick={e => changeActiveKey(ACTIVE_KEY_PARAMETERS)}>{t("Title.Parameters")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_PARAMETERS}>
            <Card.Body>
              <Row>
                <Col xs={12} md={4}>
                  <Form.Group controlId="forms-patrimony-parameters-consumption-average">
                    <Form.Label>{t("Label.ConsumptionAverage")}:</Form.Label>
                    <CurrencyInput
                      id="forms-patrimony-parameters-consumption-average"
                      className={"form-control"}
                      type="text"
                      placeholder={t("Placeholder.ConsumptionAverage")}
                      disabled={inputDisabled()}
                      decimalSeparator="."
                      thousandSeparator=""
                      precision="2"
                      prefix=""
                      value={patrimony.parameters.consumptionAverage}
                      onChangeEvent={e => {
                        const consumptionAverage = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            consumptionAverage
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="forms-patrimony-parameters-delayed-location">
                    <Form.Label>{t("Label.DelayedLocation")}:</Form.Label>
                    <InputMask
                      id="forms-patrimony-parameters-delayed-location"
                      className={`form-control ${isEmpty(patrimony.parameters.delayedLocation) || (!isEmpty(patrimony.parameters.delayedLocation) && Date.isTime(patrimony.parameters.delayedLocation)) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder={t("Placeholder.DelayedLocation")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const delayedLocation = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            delayedLocation
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                      mask="99:99:99"
                      autoComplete="off"
                      defaultValue={patrimony.parameters.delayedLocation}
                    />
                    <Form.Control.Feedback type="invalid">
                      { t("Feedback.Format.Time") }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="forms-patrimony-parameters-max-speed">
                    <Form.Label>{t("Label.MaxSpeed")}:</Form.Label>
                    <CurrencyInput
                      id="forms-patrimony-parameters-max-speed"
                      className={"form-control"}
                      type="text"
                      placeholder={t("Placeholder.MaxSpeed")}
                      disabled={inputDisabled()}
                      decimalSeparator="."
                      thousandSeparator=""
                      precision="0"
                      prefix=""
                      value={patrimony.parameters.maxSpeed}
                      onChangeEvent={e => {
                        const maxSpeed = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            maxSpeed
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="forms-patrimony-parameters-downtime-tolerance">
                    <Form.Label>{t("Label.DowntimeTolerance")}:</Form.Label>
                    <InputMask
                      id="forms-patrimony-parameters-downtime-tolerance"
                      className={`form-control ${isEmpty(patrimony.parameters.downtimeTolerance) || (!isEmpty(patrimony.parameters.downtimeTolerance) && Date.isTime(patrimony.parameters.downtimeTolerance)) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder={t("Placeholder.DowntimeTolerance")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const downtimeTolerance = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            downtimeTolerance
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                      mask="99:99:99"
                      autoComplete="off"
                      defaultValue={patrimony.parameters.downtimeTolerance}
                    />
                    <Form.Control.Feedback type="invalid">
                      { t("Feedback.Format.Time") }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="forms-patrimony-parameters-run-min-hour">
                    <Form.Label>{t("Label.RunMinHour")}:</Form.Label>
                    <InputMask
                      id="forms-patrimony-parameters-run-min-hour"
                      className={`form-control ${(isEmpty(patrimony.parameters.runMinHour) && isEmpty(patrimony.parameters.runMaxHour)) || ((!isEmpty(patrimony.parameters.runMinHour) && Date.isTime(patrimony.parameters.runMinHour) && Date.timeToSeconds(patrimony.parameters.runMinHour) < Date.timeToSeconds(patrimony.parameters.runMaxHour)) && (!isEmpty(patrimony.parameters.runMaxHour) && Date.isTime(patrimony.parameters.runMaxHour) && Date.timeToSeconds(patrimony.parameters.runMaxHour) > Date.timeToSeconds(patrimony.parameters.runMinHour))) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder={t("Placeholder.RunMinHour")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const runMinHour = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            runMinHour
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                      mask="99:99:99"
                      autoComplete="off"
                      defaultValue={patrimony.parameters.runMinHour}
                    />
                    <Form.Control.Feedback type="invalid">
                      { t("Feedback.Format.RunMinHour") }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={4}>
                  <Form.Group controlId="forms-patrimony-parameters-run-max-hour">
                    <Form.Label>{t("Label.RunMaxHour")}:</Form.Label>
                    <InputMask
                      id="forms-patrimony-parameters-run-max-hour"
                      className={`form-control ${(isEmpty(patrimony.parameters.runMinHour) && isEmpty(patrimony.parameters.runMaxHour)) || ((!isEmpty(patrimony.parameters.runMinHour) && Date.isTime(patrimony.parameters.runMinHour) && Date.timeToSeconds(patrimony.parameters.runMinHour) < Date.timeToSeconds(patrimony.parameters.runMaxHour)) && (!isEmpty(patrimony.parameters.runMaxHour) && Date.isTime(patrimony.parameters.runMaxHour) && Date.timeToSeconds(patrimony.parameters.runMaxHour) > Date.timeToSeconds(patrimony.parameters.runMinHour))) ? "is-valid" : "is-invalid"}`}
                      type="text"
                      placeholder={t("Placeholder.RunMaxHour")}
                      disabled={inputDisabled()}
                      onChange={e => {
                        const runMaxHour = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            runMaxHour
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                      mask="99:99:99"
                      autoComplete="off"
                      defaultValue={patrimony.parameters.runMaxHour}
                    />
                    <Form.Control.Feedback type="invalid">
                      { t("Feedback.Format.RunMaxHour") }
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-parameters-maintenance-motor-max-hours">
                    <Form.Label>{t("Label.MaintenanceMotorMaxHours")}:</Form.Label>
                    <CurrencyInput
                      id="forms-patrimony-parameters-maintenance-motor-max-hours"
                      className={"form-control"}
                      type="text"
                      placeholder={t("Placeholder.MaintenanceMotorMaxHours")}
                      disabled={inputDisabled()}
                      decimalSeparator="."
                      thousandSeparator=""
                      precision="0"
                      prefix=""
                      value={patrimony.parameters.maintenanceMotorMaxHours}
                      onChangeEvent={e => {
                        const maintenanceMotorMaxHours = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            maintenanceMotorMaxHours
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-parameters-maintenance-motor-max-months">
                    <Form.Label>{t("Label.MaintenanceMotorMaxMonths")}:</Form.Label>
                    <CurrencyInput
                      id="forms-patrimony-parameters-maintenance-motor-max-months"
                      className={"form-control"}
                      type="text"
                      placeholder={t("Placeholder.MaintenanceMotorMaxMonths")}
                      disabled={inputDisabled()}
                      decimalSeparator="."
                      thousandSeparator=""
                      precision="0"
                      prefix=""
                      value={patrimony.parameters.maintenanceMotorMaxMonths}
                      onChangeEvent={e => {
                        const maintenanceMotorMaxMonths = e.target.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            maintenanceMotorMaxMonths
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-parameters-fix-route-periodic">
                    <Form.Label>{t("Label.FixRoutePeriodic")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const fixRoutePeriodic = e.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            fixRoutePeriodic
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === patrimony.parameters.fixRoutePeriodic)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={6}>
                  <Form.Group controlId="forms-patrimony-parameters-fix-route-report">
                    <Form.Label>{t("Label.FixRouteReport")}:</Form.Label>
                    <Select
                      className="menu-outer-bottom"
                      classNamePrefix="select"
                      isDisabled={inputDisabled()}
                      onChange={e => {
                        const fixRouteReport = e.value;
                        setPatrimony(prevState => ({
                          ...prevState,
                          parameters: {
                            ...prevState.parameters,
                            fixRouteReport
                          }
                        }));
                      }}
                      dataeventkey={ACTIVE_KEY_PARAMETERS}
                      options={activeOptions}
                      value={activeOptions.find(x => x.value === patrimony.parameters.fixRouteReport)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_PHOTOS} onClick={e => changeActiveKey(ACTIVE_KEY_PHOTOS)}>{t("Title.Photos")}</Accordion.Toggle>
          <Accordion.Collapse eventKey={ACTIVE_KEY_PHOTOS}>
            <Card.Body>
              <Row>
                <SliderComponent options={{ pictures }} />
              </Row>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        {
          (!isCreate() && (
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey={ACTIVE_KEY_QRCODE} onClick={e => changeActiveKey(ACTIVE_KEY_QRCODE)}>{t("Title.QRCode")}</Accordion.Toggle>
              <Accordion.Collapse eventKey={ACTIVE_KEY_QRCODE}>
                <Card.Body>
                  <Row>
                    <Col xs={12} className="qrcode">
                      <QRCode
                        value={patrimony.id}
                        qrStyle={"square"}
                        fgColor={"#000000"}
                        size={(300)}
                      /><br />
                      <Button
                        variant="dark"
                        type="button"
                        onClick={() => { enableQrcode(patrimony.id) }}>{t("Title.Download")}</Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          ))
        }
      </Accordion>
      <Form.Group className="default-form-button">
        <Button
          variant="dark"
          type="submit"
          disabled={buttonDisabled()}
        >
          {running ? <Spinner animation="border" size="sm" /> : t("Button.Save")}
        </Button>
      </Form.Group>
    </Form>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}, NotificationsActions, QrcodesActions), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Patrimony));
