import React, { memo } from "react";
import "./index.css";

const SwitchChip = ({ checkbox = true, name, chip, selected } = {}) => {
  return (
    <div
      className="marker-all marker-all-switch"
      board-color={""}
      board-country={""}
      board-layout={""}
      board-type={"OBJ"}
      mini-markers={"false"}
    >
      <input
        className="marker-data-checkbox"
        type={`${checkbox ? "checkbox" : "radio"}`}
        checked={selected}
        name={name}
        readOnly={true}
      />
      <div className="marker-data marker-data-chip">
        <div className="marker-data-content">
          <div className="marker-mini-board-chip">
            <strong>Serial:</strong>
            <span>{chip.serial}</span>
          </div>
          <div className="marker-mini-board-chip">
            <strong>Linha:</strong>
            <span>{chip.line}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(SwitchChip);
