const schemaReduced = {
  id: "",
  legal: {
    cnpj: "",
    fantasyName: "",
    socialReason: ""
  },
  personal: {
    cpf: "",
    name: ""
  },
  type: ""
};

export default schemaReduced;
