import ClientSchemaReduced from "./../Client/SchemaReduced";

const schema = {
  active: true,
  id: "",
  client: JSON.parse(JSON.stringify(ClientSchemaReduced)),
  days: [],
  name: ""
};

export default schema;
