import React, { memo, useEffect, useState } from "react";
import {
  Accordion,
  Col,
  Container,
  Form,
  Row
} from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import debounce from "lodash.debounce";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import ReactCalendar from "react-calendar";
import "./index.css";

import DateUtil from "../../utils/Date";
import { isScopes } from "../../services/Auth";
import { maskCPF } from "../../utils/CPF";
import { maskCNPJ } from "../../utils/CNPJ";
import { isEmpty } from "../../utils/String";
import { mergeObject } from "../../utils/Object";

import CalendarService from "../../services/Calendar";
import ClientService from "../../services/Client";
import ClientSchemaReduced from "../../services/Client/SchemaReduced";

import CalendarCertificate from "./Item/Certificate";
import CalendarDayoff from "./Item/Dayoff";
import CalendarExpense from "./Item/Expense";
import CalendarHolyday from "./Item/Holyday";
import CalendarLicense from "./Item/License";
import CalendarMaintenanceSchedule from "./Item/MaintenanceSchedule";
import CalendarSinister from "./Item/Sinister";
import CalendarSupply from "./Item/Supply";
import CalendarTrafficTicket from "./Item/TrafficTicket";
import CalendarVacation from "./Item/Vacation";

const INITIAL_YEAR = Number(process.env.REACT_APP_APP_START_YEAR) || (new Date()).getFullYear();
const NOW_YEAR = (new Date()).getFullYear();

const Calendar = ({
  props: { currentUser },
  options
}) => {
  const { t } = useTranslation();
  const calendarService = new CalendarService();
  const clientService = new ClientService();

  const [calendar, setCalendar] = useState({
    year: NOW_YEAR,
    client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), currentUser.client)
  });

  const [calendarEvents, setCalendarEvents] = useState([]);

  const [activeKey, setActiveKey] = useState("-1");

  useEffect(() => {
    const diff = (NOW_YEAR + 10) - INITIAL_YEAR;
    const options = [];
    for (let i = 0; i < diff; i ++) {
      options.push({ value: (INITIAL_YEAR + i), label: (INITIAL_YEAR + i) });
    }
    setYearOptions(options);
  }, []); //eslint-disable-line

  useEffect(() => {
    if (!running) {
      setRunning(true);
      const dateStart = new Date(`${calendar.year}-01-01T03:00:00.000Z`);
      const dateEnd = new Date(`${calendar.year}-12-31T02:59:59.000Z`);
      calendarService.findCalendar({ data: {
        clientId: calendar.client.id,
        dateEnd,
        dateStart
      }, token: currentUser.token })
        .then(calendarEvents => {
          const daysEvents = [];
          calendarEvents.map(calendarEvent => {
            const myEvent = JSON.parse(JSON.stringify(calendarEvent));
            if (typeof calendarEvent.date !== "undefined") {
              const dateStartEvent = DateUtil.to({ date: new Date(calendarEvent.date), format: "YYYY-MM-DD", tz: "America/Sao_Paulo" });
              const eventIndex = daysEvents.findIndex(x => x.date === dateStartEvent);
              if (eventIndex === -1) {
                daysEvents.push({
                  date: dateStartEvent,
                  events: [myEvent]
                });
              }
              else daysEvents[eventIndex].events.push(myEvent);
              return calendarEvent;
            }
            const dateStartPeriodEvent = DateUtil.to({ date: new Date(calendarEvent.dateStart), format: "YYYY-MM-DD HH:mm:ss", tz: "America/Sao_Paulo" });
            const dateEndPeriodEvent = DateUtil.to({ date: new Date(calendarEvent.dateEnd), format: "YYYY-MM-DD HH:mm:ss", tz: "America/Sao_Paulo" });
            const diffDays = DateUtil.diff({ dateA: dateEndPeriodEvent, dateB: dateStartPeriodEvent, tz: "America/Sao_Paulo", format: "days" });
            for (let di = 0; di <= diffDays; di++) {
              const dateStartPeriodDay = DateUtil.to({ date: DateUtil.add({ add: di, date: dateStartPeriodEvent, tz: "America/Sao_Paulo", format: "days" }), format: "YYYY-MM-DD", tz: "America/Sao_Paulo"  });
              const eventIndex = daysEvents.findIndex(x => x.date === dateStartPeriodDay);
              if (eventIndex === -1) {
                daysEvents.push({
                  date: dateStartPeriodDay,
                  events: [myEvent]
                });
              }
              else daysEvents[eventIndex].events.push(myEvent);
            }
            return calendarEvent;
          });
          setCalendarEvents(daysEvents);
          setRunning(false);
        })
        .catch(error => {
          console.log(error);
          setRunning(false);
        });
    }
  }, [calendar]); //eslint-disable-line

  const [running, setRunning] = useState(false);
  const [yearOptions, setYearOptions] = useState([]);

  const [clientSelectedOption, setClientSelectedOption] = useState(calendar.client.id === "" ? null : {
    dataAux: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), calendar.client),
    label: calendar.client.type === "LEG" ? (`${calendar.client.legal.socialReason} | CNPJ: ${maskCNPJ(calendar.client.legal.cnpj)}`) : (`${calendar.client.personal.name} | CPF: ${maskCPF(calendar.client.personal.cpf)}`),
    value: calendar.client.id
  });

  const clientLoadOptions = (e, c) => {
    clientLoadOptionsDebounce(e, c);
  };

  const clientLoadOptionsDebounce = debounce(async (e, c) => {
    const clients = await clientService.findAutocomplete({ data: {
      search: e
    }, token: currentUser.token });
    c(filterClient(clients));
  }, 1000);

  const filterClient = (clients) => {
    return clients.map(e => {
      return {
        dataAux: e,
        label: e.type === "LEG" ? (`${e.legal.socialReason} | CNPJ: ${maskCNPJ(e.legal.cnpj)}`) : `${e.personal.name} | CPF: ${maskCPF(e.personal.cpf)}`,
        value: e.id
      };
    });
  };

  const inputDisabled = () => {
    if (!running) return false;
    return true;
  };

  return (
    <Container>
      <Row>
        <Col xs={12} md={6}>
          <Form.Group controlId="calendar-year">
            <Form.Label>{t("Label.Active")}:</Form.Label>
            <Select
              className="menu-outer-bottom"
              classNamePrefix="select"
              menuPlacement="auto"
              menuPosition="fixed"
              isDisabled={inputDisabled()}
              onChange={e => {
                const year = e.value;
                setCalendar(prevState => ({
                  ...prevState,
                  year
                }));
              }}
              options={yearOptions}
              value={yearOptions.find(x => x.value === calendar.year)}
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={6}>
          <Form.Group controlId="calendar-client">
            <Form.Label>{t("Title.Client")}:</Form.Label>
            <AsyncSelect
              className={`menu-outer-bottom ${!isEmpty(calendar.client.id) ? "is-valid" : "is-invalid"}`}
              classNamePrefix="select"
              menuPlacement="auto"
              menuPosition="fixed"
              cacheOptions
              defaultOptions
              isDisabled={inputDisabled() || !isScopes({
                currentUser,
                scopes: [
                  "is:master",
                  "read:calendars:all"
                ],
                every: false
              })}
              loadOptions={clientLoadOptions}
              loadingMessage={() => t("React.Select.Wait")}
              noOptionsMessage={() => t("React.Select.NoOptions")}
              onChange={e => {
                if(e === null) {
                  setClientSelectedOption(e);
                  setCalendar(prevState => ({
                    ...prevState,
                    client: JSON.parse(JSON.stringify(ClientSchemaReduced))
                  }));
                }
                else {
                  const { dataAux, label, value } = e;
                  setClientSelectedOption({ dataAux, label, value });
                  setCalendar(prevState => ({
                    ...prevState,
                    client: mergeObject(JSON.parse(JSON.stringify(ClientSchemaReduced)), dataAux)
                  }));
                }
              }}
              placeholder={t("Title.Client.Select")}
              value={clientSelectedOption}
              required={true}
            />
            <Form.Control.Feedback className={`${!isEmpty(calendar.client.id) ? "hide" : "show"}`} type="invalid">{t("Feedback.Client")}</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        {
          [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((yearMap, yearMapIndex) => {
            const yearReactCalendar = new Date();
            yearReactCalendar.setMonth(yearMap, 1);
            yearReactCalendar.setFullYear(calendar.year);

            let yearMapMonth = yearMap + 1;
            if (yearMapMonth < 10) yearMapMonth = `0${yearMapMonth}`;
            const dayEventsOfMonth = calendarEvents.filter(calendarEvent => calendarEvent.date.indexOf(`${calendar.year}-${yearMapMonth}`) !== -1);
            const eventsOfMonth = JSON.parse(JSON.stringify(dayEventsOfMonth)).map(dayEventOfMonth => dayEventOfMonth.events).reduce((previous, currentValue) => {
              if (previous) {
                previous.map(previousItem => {
                  if (!currentValue.find(x => x.id === previousItem.id)) currentValue.push(previousItem);
                  return previousItem;
                });
              }
              return currentValue;
            }, []);
            return (
              <Col className="react-calendar-col" xs={12} md={6} lg={4} xl={3} key={`calendar-year-${yearMapIndex}`}>
                <h2>{t(`Title.Month.${yearMap}`)} ({eventsOfMonth.length})</h2>
                <ReactCalendar
                  calendarType="ISO 8601"
                  defaultView="month"
                  view="month"
                  value={yearReactCalendar}
                  locale="pt-BR"
                  maxDetail="month"
                  minDetail="month"
                  nextLabel={null}
                  next2Label={null}
                  onChange={(newDate, event) => event.preventDefault()}
                  prevLabel={null}
                  prev2Label={null}
                  showDoubleView={false}
                  showNavigation={false}
                  showNeighboringMonth={false}
                  selectRange={false}
                  tileClassName={(date) => {
                    const dateFormated = DateUtil.to({ date: date.date, format: "YYYY-MM-DD", tz: "America/Sao_Paulo" });
                    if (calendarEvents.find(val => val.date === dateFormated)) return "highlight";
                  }}
                  onClickDay={(day, event) => event.preventDefault()}
                  onClickDecade={(decade, event) => event.preventDefault()}
                  onClickMonth={(month, event) => event.preventDefault()}
                  onClickWeekNumber={(weekNumber, event) => event.preventDefault()}
                  onClickYear={(year, event) => event.preventDefault()}
                  tileDisabled={(date) => {
                    const dateFormated = DateUtil.to({ date: date.date, format: "YYYY-MM-DD", tz: "America/Sao_Paulo" });
                    if (calendarEvents.find(val => val.date === dateFormated)) return false;
                    return true;
                  }}
                  tileContent={(date) => {
                    const dateFormated = DateUtil.to({ date: date.date, format: "YYYY-MM-DD", tz: "America/Sao_Paulo" });
                    const dateWeek = date.date.getDay();
                    const selectedEvents = calendarEvents.find(val => val.date === dateFormated);
                    const changeActiveKey = (key) => {
                      if (activeKey === key) return setActiveKey("-1");
                      setActiveKey(key);
                    };
                    if (selectedEvents) {
                      return (
                        <div className="popover-calendar">
                          <div className={`popover-calendar-content week-${dateWeek}`}>
                            <div className="popover-calendar-content-body">
                              <Accordion defaultActiveKey={`${dateFormated}-${selectedEvents.events[0].id}`} activeKey={activeKey}>
                                {
                                  selectedEvents.events.map((event) => {
                                    if (event.type === "trafficTicket") return <CalendarTrafficTicket options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "sinister") return <CalendarSinister options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "license") return <CalendarLicense options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "vacation") return <CalendarVacation options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "certificate") return <CalendarCertificate options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "dayoff") return <CalendarDayoff options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "maintenanceSchedule") return <CalendarMaintenanceSchedule options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "holyday") return <CalendarHolyday options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "expense") return <CalendarExpense options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    else if (event.type === "supply") return <CalendarSupply options={{ eventKey: `${dateFormated}-${event.id}`, event, changeActiveKey }} key={`${dateFormated}-${event.id}`} />;
                                    return null;
                                  })
                                }
                              </Accordion>
                            </div>
                          </div>
                        </div>
                      );
                    }
                    return null;
                  }}
                />
              </Col>
            );
          })
        }
      </Row>
    </Container>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Calendar));
