import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useTranslation } from "react-i18next";
import "./index.css";

import DateUtil from "./../../utils/Date";
import { getReason } from "./../../utils/Position";

const PatrimonyMapMenuPositionsItem = ({
  options: {
    id,
    onClick,
    position,
    selected,
    type
  },
  props: {
    currentUser
  }
}) => {
  const { t } = useTranslation();
  return (
    <li className={`patrimony-map-menu-positions-item ${selected ? "active" : ""}`} onClick={onClick}>
      <ul>
        <li>
          <div>{t("Title.Reason")}:</div>
          <div><span><strong>{t(getReason(position))} ({position.gps.reason})</strong></span></div>
        </li>
        <li>
          <div>{t("Title.ApnConnect")}:</div>
          <div><span><strong>{position.gps.apnConnect}</strong></span></div>
        </li>
        <li>
          <div>{t("Title.Date")}:</div>
          <div><span>{DateUtil.to({ date: new Date(position.gps.date), format: "DD/MM/YYYY HH:mm:ss", tz: "America/Sao_Paulo"})}</span></div>
        </li>
        <li>
          <div>{t("Speed")}:</div>
          <div><span>{position.gps.speed} Km/h</span></div>
        </li>
      </ul>
      <div className={`positions-item-ignition ${position.tracker.ignition ? "ignition-on" : "ignition-off"}`}><i className="fas fa-key"></i></div>
    </li>
  );
};

const mapStateToProps = state => ({
  props: {
    currentUser: state.users.currentUser
  }
});

const mapDispatchToProps = dispatch => ({
  funcs: bindActionCreators(Object.assign({}), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PatrimonyMapMenuPositionsItem);
