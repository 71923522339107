export function getDefaultOptions() {
  return {
    dateStart: false,
    dateEnd: false,
    filterActive: true,
    filterChip: false,
    filterMultiple: false,
    filterNiple: false,
    filterPatrimony: false,
    filterPreSelected: false,
    filterUser: false,
    layout: true
  };
};

export function getDefaultOptionsChip() {
  const basic = getDefaultOptions();
  return Object.assign({}, basic, {
    filterChip: true
  });
}

export function getDefaultOptionsPatrimony() {
  const basic = getDefaultOptions();
  return Object.assign({}, basic, {
    filterPatrimony: true
  });
}

export function getDefaultOptionsUser() {
  const basic = getDefaultOptions();
  return Object.assign({}, basic, {
    filterUser: true
  });
}
