export function filterByWord(patrimonyTests, search) {
  let filter = patrimonyTests;
  let enabled = false;
  if(typeof search !== "undefined") {
    if(typeof search.word !== "undefined" && search.word !== "") {
      enabled = true;
      filter = filter.filter((p) => p.patrimonyTest.patrimony.description.toLowerCase() === search.word.toLowerCase());
    }
  }
  return {
    enabled,
    results: filter
  };
}

export function getStatus(patrimonyTest) {
  let patrimonyTestStatus = "";
  if(patrimonyTest) {
    switch(patrimonyTest.status) {
    case "CON":
      patrimonyTestStatus = "Title.Concluded";
      break;
    case "RUN":
      patrimonyTestStatus = "Title.Running";
      break;
    case "WAI":
      patrimonyTestStatus = "Title.Waiting";
      break;
    default:
      patrimonyTestStatus = "Title.Waiting";
      break;
    }
  }
  return patrimonyTestStatus;
}
