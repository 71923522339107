import { useDispatch } from "react-redux";

import { Creators as ExportedsActions } from "../store/ducks/exporteds";
import { Creators as MenusActions } from "../store/ducks/menus";
import { Creators as RoutesActions } from "../store/ducks/routes";
import { Creators as TranshipmentsActions } from "../store/ducks/transhipments";

export function useHandleReset() {
  const dispatch = useDispatch();
  return () => {
    dispatch(ExportedsActions.removeSingleExported());
    dispatch(RoutesActions.removeSingleRoute());
    dispatch(MenusActions.menuCalendarsHandleClose());
    dispatch(MenusActions.menuProfilesHandleClose());
    dispatch(MenusActions.menuRegistersHandleClose());
    dispatch(MenusActions.menuReportsHandleClose());
    dispatch(MenusActions.menuSimulatorsHandleClose());
    dispatch(TranshipmentsActions.removeSingleTranshipment());
  };
}