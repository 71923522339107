import { createActions, createReducer } from "reduxsauce";

/**
 * Action types & creators
 */
export const { Types, Creators } = createActions({
  setPeriodRoute: ["route"],
  setPeriodRouteFix: ["routeFix"]
});

/**
 * Handlers
 */
const INITIAL_STATE = {
  periodRoute: -1,
  periodRouteFix: null
};

const setPeriodRoute = (state = INITIAL_STATE, action) => {
  const { route } = action;
  return {
    ...state,
    periodRoute: route
  };
};

const setPeriodRouteFix = (state = INITIAL_STATE, action) => {
  const { routeFix } = action;
  return {
    ...state,
    periodRouteFix: routeFix
  };
};

/**
 * Reducer
 */
export default createReducer(INITIAL_STATE, {
  [Types.SET_PERIOD_ROUTE]: setPeriodRoute,
  [Types.SET_PERIOD_ROUTE_FIX]: setPeriodRouteFix
});
