import React, { memo } from "react";
import "./index.css";

const Item = ({ getCount, icon, id, onClick, selected, title }) => {
  return (
    <li>
      <button
        className={`mrp-rpi-icon ${selected && selected() ? "active" : ""} ${id}`}
        type="button"
        onClick={onClick}>
        <div className="mrp-icon">{icon}</div>
        <div className="mrp-description"><p>{title}</p></div>
      </button>
    </li>
  );
};

export default memo(Item);
